import graphql from "babel-plugin-relay/macro";

export const CREATE_CUSTOMER_FEEDBACK_MUTATION = graphql`
	mutation createCustomerFeedbackButton_CreateCustomerFeedbackMutation(
		$input: CreateCustomerFeedbackInput!
		$connections: [ID!]!
	) {
		Admin {
			CustomerFeedback {
				createCustomerFeedback(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...customerFeedbackTable_CustomerFeedbackFragment
						}
					}
				}
			}
		}
	}
`;
