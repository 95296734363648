import { FormDialogButton } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type createCoachingLicenseDefinitionButton_CreateCoachingLicenseDefinitionMutation } from "@relay/createCoachingLicenseDefinitionButton_CreateCoachingLicenseDefinitionMutation.graphql";
import { CREATE_COACHING_LICENSE_DEFINITION_MUTATION } from "./create-coaching-license-definition-button.graphql";
import { LicenseDefinitionsScreenContext } from "../../../screens/license-definitions/license-defintions.context";
import { CoachingLicenseDefinitionForm } from "../coaching-license-definition-form";
import { type CoachingLicenseDefinitionFormState } from "../coaching-license-definition-form/coaching-license-definition-form.types";

export const CreateCoachingLicenseDefinitionButton = () => {
	const { connectionId } = useContext(LicenseDefinitionsScreenContext);

	const [create] =
		useMutation<createCoachingLicenseDefinitionButton_CreateCoachingLicenseDefinitionMutation>(
			CREATE_COACHING_LICENSE_DEFINITION_MUTATION,
		);

	const handleOnSubmit = async (values: CoachingLicenseDefinitionFormState) => {
		return await new Promise((resolve, reject) => {
			create({
				variables: {
					input: {
						data: {
							coachingId: values.coachingId,
							name: values.name,
						},
					},
					connections: [connectionId],
				},
				onCompleted: () => {
					resolve(true);
					toast.success("Coaching-Lizenzdefinition erstellt.");
				},
				onError: () => {
					reject();
					toast.error("Fehler beim Erstellen der Coaching-Lizenzdefinition.");
				},
			});
		});
	};

	return (
		<FormDialogButton<CoachingLicenseDefinitionFormState>
			buttonLabel="Coaching-Lizenzdefinition erstellen"
			dialogTitle="Coaching-Lizenzdefinition"
		>
			{({ formikRef }) => {
				return (
					<CoachingLicenseDefinitionForm
						initialState={{
							coachingId: "",
							name: "",
						}}
						ref={formikRef}
						onSubmit={handleOnSubmit}
					/>
				);
			}}
		</FormDialogButton>
	);
};
