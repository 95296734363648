/**
 * @generated SignedSource<<80515dcef28237550a0af538d7bbfc7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseProductForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_QueryFragment">;
  readonly " $fragmentType": "licenseProductForm_QueryFragment";
};
export type licenseProductForm_QueryFragment$key = {
  readonly " $data"?: licenseProductForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseProductForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licenseProductForm_QueryFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "dataKinds",
          "value": [
            "Tree",
            "Coaching",
            "AVGSPotentialAnalysis"
          ]
        }
      ],
      "kind": "FragmentSpread",
      "name": "selectLicenseDefinitionField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2322757a9ef21c36c5be413b5d0d3e98";

export default node;
