/**
 * @generated SignedSource<<e6c6c42d572623538244eeda943e446c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Icon = "arrowLoop" | "bridgeOverRiver" | "computerWithHeart" | "dotsConnected" | "flowerInCircle" | "handWithFlower" | "handsWithHeart" | "lightBulb" | "peopleHighFive" | "personWithStars" | "rocket" | "workingDesk";
import { FragmentRefs } from "relay-runtime";
export type trends_TrendFragment$data = {
  readonly id: string;
  readonly position: number | null | undefined;
  readonly trendData: {
    readonly description: string;
    readonly icon: Icon;
    readonly name: string;
  };
  readonly " $fragmentType": "trends_TrendFragment";
};
export type trends_TrendFragment$key = {
  readonly " $data"?: trends_TrendFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trends_TrendFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "trends_TrendFragment"
};

(node as any).hash = "76167d6aaee3f2cc9ff3126584407016";

export default node;
