/**
 * @generated SignedSource<<1e36cb228c3b7ce43379fe4fa8063037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDirectFeedbackFlowELearningContentConfigInput = {
  clientMutationId?: string | null | undefined;
  configId: string;
  contentId: string;
  minNumTriesTillShowAnswer: number;
};
export type setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation$variables = {
  input: EditDirectFeedbackFlowELearningContentConfigInput;
};
export type setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly editDirectFeedbackFlowELearningContentConfig: {
        readonly content: {
          readonly " $fragmentSpreads": FragmentRefs<"flowAndCurrentElementContentConfigEditor_TreeNodeFragment">;
        };
      } | null | undefined;
    };
  };
};
export type setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation = {
  response: setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation$data;
  variables: setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDirectFeedbackFlowELearningContentConfigPayload",
                "kind": "LinkedField",
                "name": "editDirectFeedbackFlowELearningContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "flowAndCurrentElementContentConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDirectFeedbackFlowELearningContentConfigPayload",
                "kind": "LinkedField",
                "name": "editDirectFeedbackFlowELearningContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "flowConfig",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "minNumTriesTillShowAnswer",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "DirectFeedbackFlowELearningContentConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ELearningContentTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0438487a119cae7792e2e95165353d7d",
    "id": null,
    "metadata": {},
    "name": "setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation",
    "operationKind": "mutation",
    "text": "mutation setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation(\n  $input: EditDirectFeedbackFlowELearningContentConfigInput!\n) {\n  Admin {\n    Tree {\n      editDirectFeedbackFlowELearningContentConfig(input: $input) {\n        content {\n          ...flowAndCurrentElementContentConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment flowAndCurrentElementContentConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ELearningContentTypeDefinition {\n      flowConfig {\n        __typename\n        id\n        configType\n        ... on DirectFeedbackFlowELearningContentConfig {\n          minNumTriesTillShowAnswer\n        }\n        ...setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment\n      }\n    }\n  }\n  ...setDirectFeedbackFlowConfigForm_TreeNodeFragment\n}\n\nfragment setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment on DirectFeedbackFlowELearningContentConfig {\n  id\n  configType\n  minNumTriesTillShowAnswer\n}\n\nfragment setDirectFeedbackFlowConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n"
  }
};
})();

(node as any).hash = "b5b9621de00ad2ed4d580bbc29273bd7";

export default node;
