/**
 * @generated SignedSource<<76e621e42df576db351ee35fe429a87f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment$data = {
  readonly dimensions: ReadonlyArray<{
    readonly dimensionId: number;
    readonly dimensionText: string;
  }>;
  readonly moduleId: number;
  readonly moduleText: string;
  readonly " $fragmentType": "potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment";
};
export type potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment$key = {
  readonly " $data"?: potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment"
};

(node as any).hash = "d0eb7916d5adbf61e60bba0b7490e514";

export default node;
