import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment selectCoachesField_QueryFragment on Query
	@argumentDefinitions(
		userId: { type: "ID" }
		userName: { type: "String" }
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
	)
	@refetchable(queryName: "selectCoachesField_QueryRefetch") {
		Admin {
			Coaching {
				CoachProfiles(
					filterByUserId: $userId
					userName: $userName
					first: $first
					after: $after
					before: $before
					last: $last
				) @connection(key: "selectCoachesField_CoachProfiles") {
					edges {
						node {
							coachingOffer {
								...selectCoachesField_CoachProfileFragment
							}
						}
					}
				}
			}
		}
	}
`;

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment selectCoachesField_CoachProfileFragment on CoachProfile @inline {
		id
		coach {
			id
			name
		}
		profileData {
			description
			avatar {
				id
				thumbnail
			}
		}
	}
`;
