import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { ActionIdColumn_DiscountActionFragment$key } from "@relay/ActionIdColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment ActionIdColumn_DiscountActionFragment on DiscountAction {
		id
	}
`;

type OwnProps = {
	discountActionFragmentRef: ActionIdColumn_DiscountActionFragment$key;
};

export const ActionIdColumn = ({ discountActionFragmentRef }: OwnProps) => {
	const discountAction = useFragment<ActionIdColumn_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return (
		<div
			className="cursor-pointer"
			onClick={() => navigator.clipboard.writeText(discountAction.id)}
		>
			<b>
				{discountAction.id}
				<i className="pi pi-copy"></i>
			</b>
		</div>
	);
};
