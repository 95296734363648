import { Button, type EpicIcons, type PathParams } from "@thekeytechnology/epic-ui";
import {
	DefaultSwitchComponent,
	InputMultiSelect,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import * as Yup from "yup";
import { DefaultTextAreaComponent, DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { type AdminIcons } from "@components/icon/icon.types";
import { IconDropdown } from "@components/icon-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { FileSelectionField, type FileV2 } from "@features/files/file-selection-field";
import { Spacing32 } from "@features/licenses/licenses-table/licenses-table.styles";
import { type trendForm_AcademiesTagFragment$key } from "@relay/trendForm_AcademiesTagFragment.graphql";
import { type trendForm_CreateTrendMutation } from "@relay/trendForm_CreateTrendMutation.graphql";
import { type trendForm_CustomerFeedbackFragment$key } from "@relay/trendForm_CustomerFeedbackFragment.graphql";
import { type trendForm_EditTrendMutation } from "@relay/trendForm_EditTrendMutation.graphql";
import { type trendForm_Query } from "@relay/trendForm_Query.graphql";
import { type trendForm_Refetch } from "@relay/trendForm_Refetch.graphql";
import { type trendForm_TrendFragment$key } from "@relay/trendForm_TrendFragment.graphql";
import { createNewTrend } from "@screens/trend-edit/utils/createTrend";
import { editExistingTrend } from "@screens/trend-edit/utils/editTrend";
import { type TrendsPath } from "@screens/trends/trends.paths";
import {
	CREATE_TREND_MUTATION,
	CUSTOMER_FEEDBACK_FRAGMENT,
	EDIT_TREND_MUTATION,
	QUERY,
	TREND_FORM_FRAGMENT,
	TREND_FRAGMENT,
} from "./trend-form.graphql";
import { StyledForm, Wrapper } from "./trend-form.styles";
import {
	type CustomerFeedback,
	type Advantage,
	type TrendInputFormState,
} from "./trend-form.types";
import { TrendAdvantagesForm } from "../trend-advantages-form/trend-advantages-form.component";
import { TrendCustomerFeedbackForm } from "../trend-customer-feedback-form";

export const TrendForm = () => {
	const { trendId } = useParams<PathParams<typeof TrendsPath>>();
	const { connectionId } = useLocation().state ?? "";

	const isCreatingTrend = trendId === "new";
	const title = isCreatingTrend ? "Trend erstellen" : "Trend bearbeiten";

	const query = useLazyLoadQuery<trendForm_Query>(QUERY, {
		id: trendId ?? "",
		skip: isCreatingTrend,
	});

	const { data: tagData } = usePaginationFragment<
		trendForm_Refetch,
		trendForm_AcademiesTagFragment$key
	>(TREND_FORM_FRAGMENT, query);

	const customerFeedbackData = useFragment<trendForm_CustomerFeedbackFragment$key>(
		CUSTOMER_FEEDBACK_FRAGMENT,
		query,
	);

	const feedbacks = customerFeedbackData?.CustomerJourney?.CustomerFeedback?.edges?.map(
		(edge) => edge?.node,
	);

	const data = useFragment<trendForm_TrendFragment$key>(TREND_FRAGMENT, query);

	const editableTrendData = {
		name: data.node?.trendData?.name,
		description: data.node?.trendData?.description,
		icon: data.node?.trendData?.icon,
		trendMedia: {
			id: data.node?.trendMedia?.file?.id,
			name: data.node?.trendMedia?.file?.name,
			fileType: data.node?.trendMedia?.file?.fileType as FileV2["fileType"],
		},
		visible: data.node?.trendData?.visible,
		tagIds: data.node?.trendData?.tags.map((tag) => tag.id),
		advantages: data.node?.trendData?.advantages.map((adv) => {
			return {
				heading: adv.heading,
				icon: adv.icon as AdminIcons,
				description: adv.description,
			};
		}),
		customerFeedbackIds: data.node?.customerData?.customerFeedbacks?.map(
			(feedback) => feedback.id,
		),
		amountCustomer: data.node?.customerData?.amountCustomer,
		coachImage: {
			id: data.node?.coachingImage?.id,
			name: data.node?.coachingImage?.name,
		},
		potentialAnalysisHeading: data.node?.potentialAnalysisHeadingAndDescription?.heading,
		potentialAnalysisDescription:
			data.node?.potentialAnalysisHeadingAndDescription?.description,
		coachingInfoHeading: data.node?.coachingHeadingAndDescription?.heading,
		coachingInfoDescription: data.node?.coachingHeadingAndDescription?.description,
		offersInfoHeading: data.node?.aboutOffersHeadingAndDescription?.heading,
		offersInfoDescription: data.node?.aboutOffersHeadingAndDescription?.description,
	};

	const editableTrend = isCreatingTrend ? null : data.node;
	const editableAdvantages = editableTrend?.trendData?.advantages.map((adv) => {
		return {
			heading: adv.heading,
			icon: adv.icon as AdminIcons,
			description: adv.description,
		};
	});

	const updateableAdvantages = editableAdvantages?.map((adv) => {
		return {
			tempId: v4(),
			heading: adv.heading,
			icon: adv.icon as AdminIcons,
			description: adv.description,
		};
	});

	const [advantages, setAdvantages] = useState<Advantage[]>(updateableAdvantages ?? []);

	const navigate = useNavigate();
	const [createTrend] = useMutation<trendForm_CreateTrendMutation>(CREATE_TREND_MUTATION);
	const [editTrend] = useMutation<trendForm_EditTrendMutation>(EDIT_TREND_MUTATION);

	const tagOptions =
		tagData.Admin?.AcademiesTag?.RootTags?.edges?.map((edge: any) => ({
			value: edge.node.id,
			label: edge.node.data.name,
		})) || [];

	const handleSubmit = (values: TrendInputFormState) => {
		if (trendId !== "new") {
			editExistingTrend(values, formik, advantages, editTrend, navigate, trendId);
		} else {
			createNewTrend(values, formik, advantages, createTrend, navigate, connectionId);
		}
	};

	const formik = useFormik<TrendInputFormState>({
		initialValues: {
			name: editableTrendData.name ?? "",
			description: editableTrendData.description ?? "",
			icon: (editableTrendData.icon as AdminIcons) ?? undefined,
			trendMedia: {
				id: editableTrendData.trendMedia.id ?? "",
				name: editableTrendData.trendMedia.name ?? "",
				fileType: editableTrendData.trendMedia.fileType,
			},
			visible: editableTrendData.visible ?? false,
			tagIds: editableTrendData.tagIds ?? [],
			advantages: editableTrendData.advantages ?? advantages,
			customerFeedbackIds: editableTrendData.customerFeedbackIds ?? [],
			amountCustomer: editableTrendData.amountCustomer ?? "",
			coachImage: {
				id: editableTrendData.coachImage.id ?? "",
				name: editableTrendData.coachImage.name ?? "",
			},
			potentialAnalysisHeading: editableTrendData.potentialAnalysisHeading ?? "",
			potentialAnalysisDescription: editableTrendData.potentialAnalysisDescription ?? "",
			coachingInfoHeading: editableTrendData.coachingInfoHeading ?? "",
			coachingInfoDescription: editableTrendData.coachingInfoDescription ?? "",
			offersInfoHeading: editableTrendData.offersInfoHeading ?? "",
			offersInfoDescription: editableTrendData?.offersInfoDescription ?? "",
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Bitte gib einen Namen ein."),
			description: Yup.string().required("Bitte gib eine Beschreibung ein."),
			icon: Yup.string().required("Bitte wähle ein Icon aus."),
			amountCustomer: Yup.number()
				.typeError("Bitte eine Zahl eingeben")
				.required("Bitte gib die Anzahl der lernenden Kunden ein."),
			trendMedia: Yup.object().required("Bitte wähle ein Bild oder Video aus."),
			coachingInfoHeading: Yup.string().required("Bitte gib eine Überschrift ein."),
			coachingInfoDescription: Yup.string().required("Bitte gib eine Beschreibung ein."),
			offersInfoHeading: Yup.string().required("Bitte gib eine Überschrift ein."),
			offersInfoDescription: Yup.string().required("Bitte gib eine Beschreibung ein."),
			coachImage: Yup.object().required("Bitte wähle ein Bild aus."),
		}),
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	return (
		<>
			<h1>{title}</h1>
			<Wrapper>
				<StyledForm onSubmit={formik.handleSubmit}>
					<ValidatedField<TrendInputFormState, string>
						name={"name"}
						label={"Name des Trends"}
						placeholder={"Trendnamen eingeben..."}
						formikConfig={formik}
						required
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<TrendInputFormState, string>
						name="description"
						label="Beschreibung des Trends"
						placeholder="Beschreibung eingeben..."
						required
						formikConfig={formik}
						component={DefaultTextAreaComponent}
					/>
					<ValidatedField<TrendInputFormState, EpicIcons>
						name="icon"
						label="Icon"
						formikConfig={formik}
						placeholder="Icon auswählen"
						required
						component={IconDropdown}
					/>
					<ValidatedField<TrendInputFormState, boolean>
						name={"visible"}
						label={"ist sichtbar"}
						component={DefaultSwitchComponent}
						formikConfig={formik}
					/>
					<ValidatedField<TrendInputFormState, FileV2>
						name={"trendMedia"}
						label={"Bild oder Video"}
						required
						component={({ fieldValue, updateField }) => {
							return (
								<FileSelectionField
									name={"media"}
									selectedFile={fieldValue}
									setSelectedFile={updateField}
									filterByFileTypes={[
										"image/png",
										"image/jpg",
										"image/jpeg",
										"video/mp4",
									]}
									canUploadFiles={true}
									canDeleteFiles={true}
								/>
							);
						}}
						formikConfig={formik}
					/>
					<ValidatedField<TrendInputFormState, string[]>
						name={"tagIds"}
						label={"Schlagwörter"}
						formikConfig={formik}
						component={(renderConfig) => (
							<InputMultiSelect
								items={tagOptions}
								onItemsChange={renderConfig.updateField}
								selectedItems={renderConfig.fieldValue}
							/>
						)}
					/>
					<TrendAdvantagesForm
						advantages={advantages}
						setAdvantages={setAdvantages}
						formik={formik}
						editableAdvantages={editableTrendData.advantages}
					/>
					<TrendCustomerFeedbackForm
						formik={formik}
						feedbacks={feedbacks as CustomerFeedback[]}
					/>
					<Spacing32 />
					<ValidatedField<TrendInputFormState, string>
						name="amountCustomer"
						label="Anzahl lernender Kunden"
						formikConfig={formik}
						required
						component={DefaultTextFieldComponent}
					/>
					<h4>Coaching Info</h4>
					<ValidatedField<TrendInputFormState, FileV2>
						name={"coachImage"}
						label={"Coaching Bild"}
						required
						component={({ fieldValue, updateField }) => {
							return (
								<FileSelectionField
									name={"coachImage"}
									selectedFile={fieldValue}
									setSelectedFile={updateField}
									filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
									canUploadFiles={true}
									canDeleteFiles={true}
								/>
							);
						}}
						formikConfig={formik}
					/>
					<ValidatedField<TrendInputFormState, string>
						name="coachingInfoHeading"
						label="Coaching Überschrift"
						formikConfig={formik}
						required
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<TrendInputFormState, string>
						name="coachingInfoDescription"
						label="Coaching Beschreibung"
						formikConfig={formik}
						required
						component={DefaultTextAreaComponent}
					/>
					<h4>Angebotsinfo</h4>
					<ValidatedField<TrendInputFormState, string>
						name="offersInfoHeading"
						label="Überschrift Angebotsinfo"
						formikConfig={formik}
						required
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<TrendInputFormState, string>
						name="offersInfoDescription"
						label="Beschreibung Angebotsinfo"
						formikConfig={formik}
						required
						component={DefaultTextAreaComponent}
					/>
					<Spacing32 />
					<Button
						disabled={formik.isSubmitting}
						onClick={() => {
							formik.setSubmitting(true);
							formik.handleSubmit();
						}}
						label="Trend speichern"
					/>
				</StyledForm>
			</Wrapper>
		</>
	);
};
