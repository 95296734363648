/**
 * @generated SignedSource<<dabb661e0a9b6637849b0a47d979cfb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ParticipationStatus = "Absent" | "AbsentExcused" | "NotConfirmed" | "Participated";
import { FragmentRefs } from "relay-runtime";
export type editParticipantsTable_ParticipantFragment$data = {
  readonly participationStatus: ParticipationStatus;
  readonly user: {
    readonly groupAssociations: ReadonlyArray<{
      readonly account: {
        readonly id: string;
      } | null | undefined;
    }>;
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "editParticipantsTable_ParticipantFragment";
};
export type editParticipantsTable_ParticipantFragment$key = {
  readonly " $data"?: editParticipantsTable_ParticipantFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editParticipantsTable_ParticipantFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "editParticipantsTable_ParticipantFragment"
};

(node as any).hash = "16cc9aed6df75492718be510ecf3c447";

export default node;
