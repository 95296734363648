/**
 * @generated SignedSource<<11a41da540c3fedc52e1066d40d31121>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type privateBillingDetailsForm_BillingDetailsFragment$data = {
  readonly city: string;
  readonly country: string;
  readonly dateOfBirth: string;
  readonly firstName: string;
  readonly houseNumber: string;
  readonly invoiceAddress: {
    readonly city: string;
    readonly companyDetails: string | null | undefined;
    readonly companyName: string;
    readonly country: string;
    readonly firstName: string;
    readonly houseNumber: string;
    readonly lastName: string;
    readonly postalCode: string;
    readonly street: string;
  } | null | undefined;
  readonly invoiceEmail: string;
  readonly lastName: string;
  readonly postalCode: string;
  readonly salutation: SalutationType;
  readonly street: string;
  readonly " $fragmentType": "privateBillingDetailsForm_BillingDetailsFragment";
};
export type privateBillingDetailsForm_BillingDetailsFragment$key = {
  readonly " $data"?: privateBillingDetailsForm_BillingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"privateBillingDetailsForm_BillingDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "houseNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "privateBillingDetailsForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceAddress",
      "kind": "LinkedField",
      "name": "invoiceAddress",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyDetails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    (v2/*: any*/),
    (v0/*: any*/),
    (v1/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/),
    (v3/*: any*/)
  ],
  "type": "PrivateBillingDetails",
  "abstractKey": null
};
})();

(node as any).hash = "7688758149494704827022cb0dd82f51";

export default node;
