import { Button } from "primereact/button";
import styled from "styled-components";

export const EmptyImageContainer = styled.div`
	height: 75px;
	width: 150px;
`;

export const ShareableButton = styled(Button)<{ sharable: boolean }>`
	${(props: any) => (props.sharable ? "opacity: 1" : "opacity: .5")}
`;
