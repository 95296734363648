import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query wordPackageRewardForm_Query {
		...selectWordPackageField_QueryFragment
	}
`;
export const WORD_PACKAGE_REWARD_FRAGMENT = graphql`
	fragment wordPackageRewardForm_WordPackageRewardFragment on WordPackageReward {
		... on Reward {
			id
		}
		superId
		data {
			wordPackage {
				id
				name
			}
		}
	}
`;

export const EDIT_WORD_PACKAGE_REWARD_MUTATION = graphql`
	mutation wordPackageRewardForm_EditWordPackageRewardMutation(
		$input: EditWordPackageRewardInput!
	) {
		Admin {
			Tree {
				editWordPackageReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
