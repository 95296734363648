/**
 * @generated SignedSource<<d3acc1d56d6b4a11ca85d76354fbbd50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ParticipationCertificateRewardVariables = "certificationName" | "description" | "firstName" | "lastName" | "partnerName" | "shortDesc" | "submissionDate";
import { FragmentRefs } from "relay-runtime";
export type educationalParticipationCertificateRewardForm_RewardFragment$data = {
  readonly data?: {
    readonly html: string;
    readonly name: string;
    readonly variables: ReadonlyArray<ParticipationCertificateRewardVariables>;
  };
  readonly id?: string;
  readonly " $fragmentType": "educationalParticipationCertificateRewardForm_RewardFragment";
};
export type educationalParticipationCertificateRewardForm_RewardFragment$key = {
  readonly " $data"?: educationalParticipationCertificateRewardForm_RewardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalParticipationCertificateRewardForm_RewardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "educationalParticipationCertificateRewardForm_RewardFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ParticipationCertificateRewardData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "html",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "variables",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ParticipationCertificateReward",
      "abstractKey": null
    }
  ],
  "type": "Reward",
  "abstractKey": "__isReward"
};

(node as any).hash = "08c85898445614682364646bfc44c279";

export default node;
