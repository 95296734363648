import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment selectDimensionsModal_QueryFragment on Query
	@refetchable(queryName: "selectDimensionsModal_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
		last: { type: "Int" }
	) {
		Admin {
			AvgsPotentialAnalysis {
				AvailableModulesAndDimensions(
					before: $before
					last: $last
					after: $after
					first: $first
				) {
					...selectDimensionsModal_AVGSPotentialAnalysisModuleFragment
				}
			}
		}
	}
`;

export const POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT = graphql`
	fragment selectDimensionsModal_AVGSPotentialAnalysisModuleFragment on AVGSPotentialAnalysis_Module
	@inline {
		moduleId
		moduleText
		dimensions {
			dimensionId
			dimensionText
		}
	}
`;

export const CREATE_POTENTIAL_ANALYSIS_MUTATION = graphql`
	mutation selectDimensionsModal_CreatePotentialAnalysisMutation(
		$input: CreatePotentialAnalysisInput!
		$connections: [ID!]!
	) {
		Admin {
			AvgsPotentialAnalysis {
				createPotentialAnalysis(input: $input) {
					clientMutationId
					edge @appendEdge(connections: $connections) {
						node {
							...potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment
						}
					}
					info {
						numAvailableLicenses
					}
				}
			}
		}
	}
`;
