import React from "react";
import { useFragment } from "react-relay";
import { customerFeedbackActionColumn_CustomerFeedbackFragment$key } from "@relay/customerFeedbackActionColumn_CustomerFeedbackFragment.graphql";
import { Wrapper } from "@screens/customer-feedback/customer-feedback.styles";
import { CUSTOMER_FEEDBACK_FRAGMENT } from "@screens/customer-feedback/parts/customer-feedback-action-column/customer-feedback-action-column.graphql";
import { CustomerFeedbackActionColumnProps } from "@screens/customer-feedback/parts/customer-feedback-action-column/customer-feedback-action-column.types";
import { DeleteCustomerFeedbackButton } from "@screens/customer-feedback/parts/delete-customer-feedback-button/delete-customer-feedback-button.component";
import { EditCustomerFeedbackButton } from "@screens/customer-feedback/parts/edit-customer-feedback-button/edit-customer-feedback-button.component";

export const CustomerFeedbackActionColumn = ({
	customerFeedbackFragmentRef,
}: CustomerFeedbackActionColumnProps) => {
	const customerFeedback = useFragment<customerFeedbackActionColumn_CustomerFeedbackFragment$key>(
		CUSTOMER_FEEDBACK_FRAGMENT,
		customerFeedbackFragmentRef,
	);

	return (
		<Wrapper>
			<EditCustomerFeedbackButton customerFeedbackFragmentRef={customerFeedback} />
			<DeleteCustomerFeedbackButton customerFeedbackId={customerFeedback.id} />
		</Wrapper>
	);
};
