import tw from "twin.macro";

export const InputWrapper = tw.div`
    tw-flex
    tw-flex-col
    tw-gap-4
`;

export const FormWrapper = tw.div`
    tw-flex
    tw-flex-col
    tw-gap-16
`;
