import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FC, useCallback, useEffect, useState } from "react";
import { usePaginationFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { type coachingOffersTable_CoachingOfferFragment$data } from "@relay/coachingOffersTable_CoachingOfferFragment.graphql";
import { type selectCoachingField_Query } from "@relay/selectCoachingField_Query.graphql";
import { type selectCoachingField_QueryFragment$key } from "@relay/selectCoachingField_QueryFragment.graphql";
import { type SelectCoachingField_Refetch } from "@relay/SelectCoachingField_Refetch.graphql";
import { QUERY, QUERY_FRAGMENT } from "./select-coaching-field.graphql";
import {
	SelectCoachingFieldProps,
	type SelectCoachingFieldWithPreloadedQueryProps,
} from "./select-coaching-field.interface";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { CoachingOffersTable } from "../coaching-offers-table/coaching-offers-table.component";

export const SelectCoachingFieldWithPreloadedQuery = ({
	updateField,
	fieldValue: selectedCoachingId,
	queryRef,
}: SelectCoachingFieldWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<selectCoachingField_Query>(QUERY, queryRef);

	const {
		data: {
			Admin: {
				Coaching: { CoachingOffers: coachings },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<SelectCoachingField_Refetch, selectCoachingField_QueryFragment$key>(
		QUERY_FRAGMENT,
		query,
	);

	const [filterTitle, setFilterTitle] = useState("");

	const updateRootNodes = useCallback(
		(newFilterTitle?: string) => {
			refetch(
				{
					name: newFilterTitle,
				},
				{
					fetchPolicy: "store-and-network",
				},
			);
		},
		[refetch],
	);

	const handleOnSubmit = useCallback(() => {
		updateRootNodes(filterTitle);
	}, [filterTitle, updateRootNodes]);

	useEffect(handleOnSubmit, []);

	useEffect(() => {
		if (
			selectedCoachingId &&
			!coachings.edges?.find((e) => e?.node.id === selectedCoachingId)
		) {
			updateField(undefined);
		}
	}, [coachings]);

	const handleClearFormOnClick = () => {
		setFilterTitle("");
		updateRootNodes(undefined);
	};

	const handelFilterTitleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterTitle(e.target.value);
	}, []);

	const handleRootSelected = useCallback(
		(event: DataTableSelectionChangeParams<coachingOffersTable_CoachingOfferFragment$data>) => {
			updateField(event.value.id);
		},
		[],
	);

	return (
		<>
			<div className="flex flex-row align-items-center">
				<InputText
					value={filterTitle}
					onChange={handelFilterTitleOnChange}
					className="mr-2"
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={handleClearFormOnClick}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					onClick={handleOnSubmit}
					type="button"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
			<CoachingOffersTable
				treeNodesConnectionFragmentRef={coachings}
				hasPrevious={hasPrevious}
				loadPrevious={loadPrevious}
				hasNext={hasNext}
				loadNext={loadNext}
				selectionMode="single"
				onNodeSelected={handleRootSelected}
				selectedId={selectedCoachingId}
			/>
		</>
	);
};

export const SelectCoachingField: FC<SelectCoachingFieldProps> = (props) => {
	const [queryReference, loadQuery] = useQueryLoader<selectCoachingField_Query>(QUERY);
	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery]);

	return queryReference ? (
		<SelectCoachingFieldWithPreloadedQuery {...props} queryRef={queryReference} />
	) : null;
};
