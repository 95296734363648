import { EpicP1Span } from "@thekeytechnology/epic-ui";
import tw from "twin.macro";

export const Wrapper = tw.div`
	tw-flex
	tw-gap-[1rem]
	tw-w-full
	tw-p-[2rem]
`;

export const StyledForm = tw.form`
tw-w-full`;

export const ButtonContainer = tw.div`tw-flex tw-gap-16 tw-mb-16`;

export const NameWrapper = tw.div`
tw-flex
tw-items-center
tw-gap-8`;

export const StyledP1Span = tw(EpicP1Span)`
tw-cursor-pointer
tw-mb-16
`;
