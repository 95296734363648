import { graphql } from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment createWordProficienciesRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

export const ADD_WORD_PROFICIENCIES_REWARD_MUTATION = graphql`
	mutation createWordProficienciesRewardButton_AddWordProficienciesRewardMutation(
		$input: AddWordProficienciesRewardInput!
	) {
		Admin {
			Tree {
				addWordProficienciesReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
