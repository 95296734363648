import { MultiSelect } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { readInlineData, useFragment, useMutation } from "react-relay";
import { useDivergingGroupAssociations } from "@features/accounts/edit-account-groups-form/edit-account-groups-form.util";
import { type editAccountGroupsForm_AccountFragment$key } from "@relay/editAccountGroupsForm_AccountFragment.graphql";
import {
	editAccountGroupsForm_AccountGroupFragment$data,
	type editAccountGroupsForm_AccountGroupFragment$key,
} from "@relay/editAccountGroupsForm_AccountGroupFragment.graphql";
import {
	ACCOUNT_FRAGMENT,
	ACCOUNT_GROUP_FRAGMENT,
	UPDATE_ACCOUNT_GROUPS_MUTATION,
} from "./edit-account-groups-form.graphql";
import {
	type EditAccountGroupsFormProps,
	type EditAccountGroupsFormState,
} from "./edit-account-groups-form.types";

export const EditAccountGroupsForm = ({
	accountFragmentRef,
	accountGroupsFragmentsRef,
}: EditAccountGroupsFormProps) => {
	const account = useFragment<editAccountGroupsForm_AccountFragment$key>(
		ACCOUNT_FRAGMENT,
		accountFragmentRef,
	);

	const availableAccountGroups: editAccountGroupsForm_AccountGroupFragment$data[] =
		accountGroupsFragmentsRef.map((groupAssociationsOption) => {
			return readInlineData<editAccountGroupsForm_AccountGroupFragment$key>(
				ACCOUNT_GROUP_FRAGMENT,
				groupAssociationsOption,
			);
		});
	const defaultGroupAssociationsIds = account?.groupAssociations?.map((e) => e.group!.id) ?? [];

	const { controllable, uncontrollable } = useDivergingGroupAssociations(availableAccountGroups);
	const options = controllable.map((data) => {
		return {
			label: data.name,
			value: data.id,
		};
	});

	const [setAccountGroups] = useMutation(UPDATE_ACCOUNT_GROUPS_MUTATION);

	const formik = useFormik<EditAccountGroupsFormState>({
		initialValues: {
			groupAssociations: defaultGroupAssociationsIds,
		},
		onSubmit: (values) => {
			// filter defaultGroupAssociationIds by in uncontrollable
			const defaultsInUncontrollable = defaultGroupAssociationsIds.filter(
				(e) => !uncontrollable.map((e) => e.id).includes(e),
			);
			const newGroupIds = defaultsInUncontrollable
				.concat(values.groupAssociations ?? [])
				.filter((e) => e !== "QWNjb3VudEdyb3VwOnJvb3QtYWNjb3VudA==")
				.filter((item, i, ar) => ar.indexOf(item) === i);

			setAccountGroups({
				variables: {
					input: {
						id: account.id,
						groupIds: newGroupIds,
					},
				},
			});
		},
	});

	const handleGroupAssociationsChanged = useCallback(
		(updatedValue: string[] | undefined) => {
			void formik.setFieldValue("groupAssociations", updatedValue);
			void formik.setFieldTouched("groupAssociations", true, false);
		},
		[formik],
	);

	const filteredValues = formik.values.groupAssociations?.filter((value) =>
		options.find((option) => option.value === value),
	);

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid p-flex">
			<MultiSelect
				placeholder={"Gruppen auswählen ..."}
				filter={true}
				options={options}
				values={filteredValues}
				onChange={handleGroupAssociationsChanged}
			/>
			<Button type="submit" label={"Gruppen Speichern"} className="mt-4"></Button>
		</form>
	);
};
