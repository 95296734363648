import graphql from "babel-plugin-relay/macro";

export const CREATE_DYNAMIC_VOCAB_ELEMENT_MUTATION = graphql`
	mutation createDynamicVocabElementButton_CreateDynamicVocabElementMutation(
		$input: CreateDynamicVocabElementInput!
	) {
		Admin {
			ElearningV2 {
				createDynamicVocabElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
