/**
 * @generated SignedSource<<b429cbba33ccbace320dff2f7063a8c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type coachProfilesTable_AvailabilityScheduleFragment$data = {
  readonly coachRef: string;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleButton_AvailabilityScheduleFragment">;
  readonly " $fragmentType": "coachProfilesTable_AvailabilityScheduleFragment";
};
export type coachProfilesTable_AvailabilityScheduleFragment$key = {
  readonly " $data"?: coachProfilesTable_AvailabilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachProfilesTable_AvailabilityScheduleFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "coachProfilesTable_AvailabilityScheduleFragment"
};

(node as any).hash = "5c281f95ad24050c7af01711d74bce20";

export default node;
