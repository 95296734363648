import {
	type DefaultFormProps,
	DefaultTextFieldComponent,
	Form,
	ValidatedField,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { type FormikProps } from "formik/dist/types";
import React, { forwardRef, useImperativeHandle } from "react";
import * as Yup from "yup";
import { type EditWordPackageFormState } from "./edit-word-package-form.interface";

export const EditWordPackageForm = forwardRef<
	FormikProps<EditWordPackageFormState>,
	DefaultFormProps<EditWordPackageFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditWordPackageFormState>({
		initialValues: initialState ?? {},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name is a required field."),
			language: Yup.string().required("Language / Description is a required field."),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<Form onSubmit={formik.handleSubmit}>
			<ValidatedField<EditWordPackageFormState, string>
				name={"name"}
				label={"Name"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>

			<ValidatedField<EditWordPackageFormState, string>
				name={"language"}
				label={"Sprache / Beschreibung"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
		</Form>
	);
});
EditWordPackageForm.displayName = "EditWordPackageForm";
