/**
 * @generated SignedSource<<edeed080ed36463f302e8845c6e50b2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransformBusinessAccountToAZAVAccountInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
};
export type transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation$variables = {
  input: TransformBusinessAccountToAZAVAccountInput;
};
export type transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation$data = {
  readonly Admin: {
    readonly Azav: {
      readonly transformBusinessAccountToAZAVAccount: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation = {
  response: transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation$data;
  variables: transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AzavAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Azav",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "TransformBusinessAccountToAZAVAccountPayload",
            "kind": "LinkedField",
            "name": "transformBusinessAccountToAZAVAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "52482b8b32b15900e29dccc6026f5fe1",
    "id": null,
    "metadata": {},
    "name": "transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation",
    "operationKind": "mutation",
    "text": "mutation transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation(\n  $input: TransformBusinessAccountToAZAVAccountInput!\n) {\n  Admin {\n    Azav {\n      transformBusinessAccountToAZAVAccount(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a2b6db130883905a203e8a4ab4c28f4";

export default node;
