import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { FC, useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { createCrmTreeHeadUpdaterRewardButton_AddCrmTreeHeadUpdaterRewardMutation } from "@relay/createCrmTreeHeadUpdaterRewardButton_AddCrmTreeHeadUpdaterRewardMutation.graphql";
import { createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment$key } from "@relay/createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment.graphql";
import {
	ADD_CRM_TREE_HEAD_UPDATER_REWARD_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./create-crm-tree-head-updater-reward-button.graphql";
import { CreateCrmTreeHeadUpdaterRewardButtonProps } from "./create-crm-tree-head-updater-reward-button.types";

export const CreateCrmTreeHeadUpdaterRewardButton: FC<
	CreateCrmTreeHeadUpdaterRewardButtonProps
> = ({ treeNodeFragmentRef }) => {
	const node = useFragment<createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [addCrmTreeHeadUpdaterRewardAdminMutation, isAddingCrmTreeHeadUpdaterReward] =
		useMutation<createCrmTreeHeadUpdaterRewardButton_AddCrmTreeHeadUpdaterRewardMutation>(
			ADD_CRM_TREE_HEAD_UPDATER_REWARD_MUTATION,
		);

	const handleOnClick = useCallback(() => {
		addCrmTreeHeadUpdaterRewardAdminMutation({
			variables: {
				input: {
					contentId: node.id,
				},
			},
		});
	}, [node, addCrmTreeHeadUpdaterRewardAdminMutation]);
	return (
		<Button
			icon={EpicIcons.PLUS_CIRCLE}
			disabled={isAddingCrmTreeHeadUpdaterReward}
			tooltip={"Hubspot Lektionsstatus hinzufügen"}
			onClick={handleOnClick}
		/>
	);
};
