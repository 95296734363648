import {
	DefaultCalendarComponent,
	DefaultTextFieldComponent,
} from "@thekeytechnology/framework-react-components";
import { getData } from "country-list";
import { type FormikProps, useFormik } from "formik";
import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import React, { useImperativeHandle, useMemo } from "react";
import * as Yup from "yup";
import { type AddressInput } from "@relay/editBaseDataButton_EditBaseDataMutation.graphql";
import {
	ADDRESS_PLACEHOLDERS,
	GENDER_DROPDOWN_OPTIONS,
	LABELS,
	PLACEHOLDERS,
} from "./edit-base-data-form.consts";
import { AddressWrapper } from "./edit-base-data-form.styles";
import { type FormProps, type EditBaseDataFormState } from "./edit-base-data-form.types";
import { type RenderConfig, ValidatedField } from "../../../../components/ValidatedField";

export const EditBaseDataForm = React.forwardRef<
	FormikProps<EditBaseDataFormState>,
	FormProps<EditBaseDataFormState>
>(({ initialState, onSubmit }, ref) => {
	const nationalityOptions = useMemo(
		() => getData().map((o) => ({ label: o.name, value: o.code })),
		[],
	);
	const formik = useFormik<EditBaseDataFormState>({
		initialValues: initialState ?? {
			address: { city: "", lineOne: "", lineTwo: "", postalCode: "" },
			birthDate: "",
			birthPlace: "",
			gender: "Male",
			nationality: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	function buildGenderOnChangeHandler<T>(renderConfig: RenderConfig<T>) {
		return (e: DropdownChangeEvent) => {
			renderConfig.updateField(e.value);
		};
	}
	function buildNationalityOnChangeHandler<T>(renderConfig: RenderConfig<T>) {
		return (e: any) => {
			renderConfig.updateField(e.value);
		};
	}

	return (
		<div className={"p-fluid w-30rem"}>
			<ValidatedField<EditBaseDataFormState, string>
				name={"birthDate"}
				placeholder={PLACEHOLDERS.birthDate}
				label={LABELS.birthDate}
				formikConfig={formik}
				component={DefaultCalendarComponent}
			/>
			<ValidatedField<EditBaseDataFormState, string>
				name={"gender"}
				label={LABELS.gender}
				formikConfig={formik}
				component={(renderConfig) => (
					<Dropdown
						value={renderConfig.fieldValue}
						onChange={buildGenderOnChangeHandler(renderConfig)}
						options={GENDER_DROPDOWN_OPTIONS}
					/>
				)}
			/>
			<ValidatedField<EditBaseDataFormState, string>
				name={"birthPlace"}
				label={LABELS.birthPlace}
				placeholder={PLACEHOLDERS.birthPlace}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditBaseDataFormState, string>
				name={"nationality"}
				label={LABELS.nationality}
				formikConfig={formik}
				component={(renderConfig) => (
					<Dropdown
						options={nationalityOptions}
						placeholder={PLACEHOLDERS.nationality}
						value={renderConfig.fieldValue ?? ""}
						onChange={buildNationalityOnChangeHandler(renderConfig)}
					/>
				)}
			/>
			<ValidatedField<EditBaseDataFormState, AddressInput>
				name={"address"}
				label={LABELS.address}
				formikConfig={formik}
				component={(renderConfig) => (
					<AddressWrapper>
						<DefaultTextFieldComponent
							fieldValue={renderConfig.fieldValue?.lineOne ?? ""}
							placeholder={ADDRESS_PLACEHOLDERS.lineOne}
							updateField={(lineOne) => {
								renderConfig.updateField({
									...formik.values.address,
									lineOne: lineOne ?? "",
								});
							}}
						/>
						<DefaultTextFieldComponent
							fieldValue={renderConfig.fieldValue?.lineTwo ?? ""}
							placeholder={ADDRESS_PLACEHOLDERS.lineTwo}
							updateField={(lineTwo) => {
								renderConfig.updateField({
									...formik.values.address,
									lineTwo: lineTwo ?? "",
								});
							}}
						/>
						<DefaultTextFieldComponent
							fieldValue={renderConfig.fieldValue?.city ?? ""}
							placeholder={ADDRESS_PLACEHOLDERS.city}
							updateField={(city) => {
								renderConfig.updateField({
									...formik.values.address,
									city: city ?? "",
								});
							}}
						/>
						<DefaultTextFieldComponent
							fieldValue={renderConfig.fieldValue?.postalCode ?? ""}
							placeholder={ADDRESS_PLACEHOLDERS.postalCode}
							updateField={(postalCode) => {
								renderConfig.updateField({
									...formik.values.address,
									postalCode: postalCode ?? "",
								});
							}}
						/>
					</AddressWrapper>
				)}
			/>
		</div>
	);
});
EditBaseDataForm.displayName = "EditBaseDataForm";
