/**
 * @generated SignedSource<<b618a264122899a85c14796f3dee6b73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteCoachingOfferInput = {
  clientMutationId?: string | null | undefined;
  coachingId: string;
};
export type deleteCoachingOfferButton_DeleteCoachingOfferMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteCoachingOfferInput;
};
export type deleteCoachingOfferButton_DeleteCoachingOfferMutation$data = {
  readonly Admin: {
    readonly Coaching: {
      readonly deleteCoachingOffer: {
        readonly deletedUnpublishedLearnableId: string;
      } | null | undefined;
    };
  };
};
export type deleteCoachingOfferButton_DeleteCoachingOfferMutation = {
  response: deleteCoachingOfferButton_DeleteCoachingOfferMutation$data;
  variables: deleteCoachingOfferButton_DeleteCoachingOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedUnpublishedLearnableId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteCoachingOfferButton_DeleteCoachingOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteCoachingOfferPayload",
                "kind": "LinkedField",
                "name": "deleteCoachingOffer",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteCoachingOfferButton_DeleteCoachingOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteCoachingOfferPayload",
                "kind": "LinkedField",
                "name": "deleteCoachingOffer",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedUnpublishedLearnableId",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9431f04aa0aaefd38d1b870684dbe046",
    "id": null,
    "metadata": {},
    "name": "deleteCoachingOfferButton_DeleteCoachingOfferMutation",
    "operationKind": "mutation",
    "text": "mutation deleteCoachingOfferButton_DeleteCoachingOfferMutation(\n  $input: DeleteCoachingOfferInput!\n) {\n  Admin {\n    Coaching {\n      deleteCoachingOffer(input: $input) {\n        deletedUnpublishedLearnableId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a3a70a16faebfbd03305cbc58e7be3d";

export default node;
