import { Button } from "@thekeytechnology/framework-react-components";
import { Calendar } from "primereact/calendar";
import { type FormEvent } from "primereact/ts-helpers";
import React, { useState } from "react";
import { useFragment } from "react-relay";
import { type editBlockOutDateModal_BlockOutDateFragment$key } from "@relay/editBlockOutDateModal_BlockOutDateFragment.graphql";
import { BLOCKOUT_DATE_FRAGMENT } from "./edit-block-out-date-modal.graphql";
import { FormWrapper, InputWrapper } from "./edit-block-out-date-modal.styles";
import { type EditBlockOutDateModalProps } from "./edit-block-out-date-modal.types";
import { parseDateTime } from "../../edit-availibility-schedule-form.utils";

export const EditBlockOutDateModal = ({
	blockOutDateFragmentRef,
	onChange,
}: EditBlockOutDateModalProps) => {
	const blockOutDate = useFragment<editBlockOutDateModal_BlockOutDateFragment$key>(
		BLOCKOUT_DATE_FRAGMENT,
		blockOutDateFragmentRef,
	);

	const [fromDate, setFromDate] = useState<Date | undefined>(
		blockOutDate?.from ? parseDateTime(blockOutDate.from) : undefined,
	);
	const [toDate, setToDate] = useState<Date | undefined>(
		blockOutDate?.to ? parseDateTime(blockOutDate.to) : undefined,
	);
	const handleOnSave = () => {
		if (!fromDate || !toDate) return;
		onChange?.(fromDate, toDate);
	};

	const handleFromDateChanged = (event?: FormEvent) => {
		setFromDate(event?.value);
	};

	const handleToDateChanged = (event?: FormEvent) => {
		setToDate(event?.value);
	};

	return (
		<FormWrapper>
			<InputWrapper>
				<span>Von:</span>
				<Calendar
					showTime
					value={fromDate}
					onChange={handleFromDateChanged}
					dateFormat="dd.mm.yy"
				></Calendar>
				<span>Bis:</span>
				<Calendar
					showTime
					value={toDate}
					onChange={handleToDateChanged}
					dateFormat="dd.mm.yy"
				></Calendar>
			</InputWrapper>
			<Button content={{ label: "Speichern" }} onClick={handleOnSave}></Button>
		</FormWrapper>
	);
};
