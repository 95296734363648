import { graphql } from "babel-plugin-relay/macro";

export const GET_DEFAULT_VIMEO_VIDEO_SOURCE_QUERY = graphql`
	query createVideoElementButton_GetDefaultVimeoVideoSourceQuery {
		Admin {
			VimeoVideoSource {
				GetDefaultVimeoVideoSource {
					id
				}
			}
		}
	}
`;
export const CREATE_VIDEO_ELEMENT_MUTATION = graphql`
	mutation createVideoElementButton_createVideoElementMutation(
		$input: CreateVideoElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createVideoElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
