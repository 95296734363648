import { Dropdown } from "@thekeytechnology/epic-ui";
import { useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";

import { itemTemplate } from "@components/video-source-dropdown/video-source-dropdown.consts";
import {
	VideoSourceDropdownOption,
	VideoSourceDropdownProps,
} from "@components/video-source-dropdown/video-source-dropdown.types";
import { videoSourceDropdown_Query } from "@relay/videoSourceDropdown_Query.graphql";
import { QUERY } from "./video-source-dropdown.graphql";

export const VideoSourceDropdown = ({
	fieldValue,
	updateField,
	onChange,
}: VideoSourceDropdownProps) => {
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			onChange?.();
		}
	};
	const videoSources = useLazyLoadQuery<videoSourceDropdown_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const videoSourceOptions =
		videoSources.Admin.VimeoVideoSource.VimeoVideoSources.edges?.map((edge) => {
			return {
				value: edge?.node.id,
				label: edge?.node.name ?? "",
				isDefault: edge?.node.isDefault,
			} as VideoSourceDropdownOption;
		}) || [];

	const selectedOption = videoSourceOptions.find((option) => option.value === fieldValue);
	const defaultOption = videoSourceOptions.find((option) => option.isDefault);
	const selectedValue = selectedOption?.value || defaultOption?.value;

	useEffect(() => {
		if (!fieldValue && selectedValue && fieldValue !== selectedValue) {
			handleOnChange(selectedValue);
		}
	}, []);

	const handleOnChange = (value: string) => {
		updateField(value);
		checkIfDropdownFieldChanged(value);
	};

	return (
		<Dropdown
			options={videoSourceOptions}
			onChange={handleOnChange}
			itemTemplate={itemTemplate}
			value={selectedValue}
		/>
	);
};
