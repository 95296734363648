/**
 * @generated SignedSource<<d3409cc00836d9fb3180b624333f9980>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePotentialAnalysisInput = {
  clientEmail: string;
  clientMutationId?: string | null | undefined;
  coachAccountId: string;
  coachId: string;
  replacementCoachId?: string | null | undefined;
  selectedModuleIds: ReadonlyArray<number>;
};
export type selectDimensionsModal_CreatePotentialAnalysisMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreatePotentialAnalysisInput;
};
export type selectDimensionsModal_CreatePotentialAnalysisMutation$data = {
  readonly Admin: {
    readonly AvgsPotentialAnalysis: {
      readonly createPotentialAnalysis: {
        readonly clientMutationId: string | null | undefined;
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment">;
          };
        };
        readonly info: {
          readonly numAvailableLicenses: number;
        };
      } | null | undefined;
    };
  };
};
export type selectDimensionsModal_CreatePotentialAnalysisMutation = {
  response: selectDimensionsModal_CreatePotentialAnalysisMutation$data;
  variables: selectDimensionsModal_CreatePotentialAnalysisMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFinished",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "coach",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_AcceptedForUser",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/)
  ],
  "type": "AVGSPotentialAnalysis_InvitedForUser",
  "abstractKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "replacementCoach",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "AVGSPotentialAnalysis_Module",
  "kind": "LinkedField",
  "name": "modules",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moduleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "moduleText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AVGSPotentialAnalysis_Dimension",
      "kind": "LinkedField",
      "name": "dimensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dimensionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dimensionText",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numAvailableLicenses",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectDimensionsModal_CreatePotentialAnalysisMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AvgsPotentialAnalysisAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AvgsPotentialAnalysis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreatePotentialAnalysisPayload",
                "kind": "LinkedField",
                "name": "createPotentialAnalysis",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PotentialAnalysisEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AVGSPotentialAnalysis_PotentialAnalysis",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment",
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "client",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v14/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AVGSPotentialAnalysis_Info",
                    "kind": "LinkedField",
                    "name": "info",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectDimensionsModal_CreatePotentialAnalysisMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AvgsPotentialAnalysisAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AvgsPotentialAnalysis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreatePotentialAnalysisPayload",
                "kind": "LinkedField",
                "name": "createPotentialAnalysis",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PotentialAnalysisEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AVGSPotentialAnalysis_PotentialAnalysis",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "client",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AVGSPotentialAnalysis_Info",
                    "kind": "LinkedField",
                    "name": "info",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0db44a1af568ade01de1ced785a6fdd",
    "id": null,
    "metadata": {},
    "name": "selectDimensionsModal_CreatePotentialAnalysisMutation",
    "operationKind": "mutation",
    "text": "mutation selectDimensionsModal_CreatePotentialAnalysisMutation(\n  $input: CreatePotentialAnalysisInput!\n) {\n  Admin {\n    AvgsPotentialAnalysis {\n      createPotentialAnalysis(input: $input) {\n        clientMutationId\n        edge {\n          node {\n            ...potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment\n            id\n          }\n        }\n        info {\n          numAvailableLicenses\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis {\n  id\n}\n\nfragment potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis {\n  id\n  createdAt\n  isFinished\n  coach {\n    id\n    name\n    email\n  }\n  client {\n    __typename\n    kind\n    ... on AVGSPotentialAnalysis_AcceptedForUser {\n      client {\n        id\n        name\n        email\n      }\n    }\n    ... on AVGSPotentialAnalysis_InvitedForUser {\n      email\n    }\n  }\n  replacementCoach {\n    id\n    name\n    email\n  }\n  modules {\n    moduleId\n    moduleText\n    dimensions {\n      dimensionId\n      dimensionText\n    }\n  }\n  ...editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment\n}\n"
  }
};
})();

(node as any).hash = "10f39d3b00f4f70cbb46156460b9ae33";

export default node;
