import {
	DndContext,
	type DragEndEvent,
	DragOverlay,
	type DragStartEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Button, Dialog } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useState, type FC, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useFragment, useMutation } from "react-relay";
import { measuring } from "@components/sortable-tree/sortable-tree.consts";
import { type sortTrendsModal_SetTrendOrderMutation } from "@relay/sortTrendsModal_SetTrendOrderMutation.graphql";
import { type sortTrendsModal_TrendFragment$key } from "@relay/sortTrendsModal_TrendFragment.graphql";
import {
	SET_TREND_ORDER_MUTATION,
	TREND_FRAGMENT,
} from "@screens/trends/parts/sort-trends-modal/sort-trends-modal.graphql";
import {
	ButtonWrapper,
	ModalContent,
	PositionWrapper,
	SortableWrapper,
} from "@screens/trends/parts/sort-trends-modal/sort-trends-modal.styles";
import { type SortTrendsModalProps } from "@screens/trends/parts/sort-trends-modal/sort-trends-modal.types";
import { SortableTrend } from "@screens/trends/parts/sortable-trend-item/sortable-trend.component";

export const SortTrendsModal: FC<SortTrendsModalProps> = ({ trendsFragmentRef }) => {
	const [isSortTrendsModalOpen, setIsSortTrendsModalOpen] = useState<boolean>(false);
	const toast = useRef<Toast>(null);

	const trends = useFragment<sortTrendsModal_TrendFragment$key>(
		TREND_FRAGMENT,
		trendsFragmentRef,
	);

	const sensors = useSensors(useSensor(PointerSensor));
	const [activeId, setActiveId] = useState<string>();
	const [sortedTrends, setSortedTrends] = useState(trends);
	const [setTrendOrder] =
		useMutation<sortTrendsModal_SetTrendOrderMutation>(SET_TREND_ORDER_MUTATION);

	const activeItem = activeId ? trends.find(({ id }) => id === activeId) : null;

	const sortedIds = useMemo(() => sortedTrends.map(({ id }) => id) as string[], [sortedTrends]);

	function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
		setActiveId(activeId as string);

		document.body.style.setProperty("cursor", "grabbing");
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		if (over && active.id !== over.id) {
			const oldIndex = sortedTrends.findIndex((trend) => trend.id === active.id);
			const newIndex = sortedTrends.findIndex((trend) => trend.id === over.id);
			const clonedTrends = [...sortedTrends];
			clonedTrends.splice(oldIndex, 1);
			clonedTrends.splice(newIndex, 0, sortedTrends[oldIndex]);
			setSortedTrends(clonedTrends);
		}
		resetState();
	}

	function handleDragCancel() {
		resetState();
	}

	function resetState() {
		setActiveId(undefined);
		document.body.style.setProperty("cursor", "");
	}

	function handleSubmit() {
		const trendIdsWithPositions = sortedTrends.map((trend, index) => ({
			trendId: trend.id,
			position: index + 1,
		}));
		setTrendOrder({
			variables: {
				input: {
					trendIdsWithPositions,
				},
			},
			onCompleted: () => {
				window.location.reload();
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					life: 3000,
					summary: "Fehler",
					detail: "Beim Speichern der Reihenfolge ist ein Fehler aufgetreten",
				});
			},
		});
	}

	return (
		<>
			<Toast ref={toast}></Toast>
			<Button
				label={"Trend-Reihenfolge festlegen"}
				onClick={() => {
					setIsSortTrendsModalOpen(true);
				}}
			/>
			<Dialog
				title={"Trend-Reihenfolge festlegen"}
				visible={isSortTrendsModalOpen}
				onHide={() => {
					setIsSortTrendsModalOpen(false);
				}}
			>
				<ModalContent>
					<DndContext
						sensors={sensors}
						measuring={measuring}
						onDragStart={handleDragStart}
						onDragEnd={(event) => {
							handleDragEnd(event);
						}}
						onDragCancel={handleDragCancel}
					>
						<SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
							{sortedTrends.map((trend, index) => {
								return (
									<SortableWrapper key={index}>
										<PositionWrapper>{`${index + 1}.`}</PositionWrapper>
										<SortableTrend
											key={trend.id}
											id={trend.id}
											dragable={true}
											trendFragmentRef={trend}
										/>
									</SortableWrapper>
								);
							})}

							{createPortal(
								<DragOverlay>
									{activeId && activeItem ? (
										<SortableTrend id={activeId} dragable={true} />
									) : null}
								</DragOverlay>,
								document.body,
							)}
						</SortableContext>
					</DndContext>
					<ButtonWrapper>
						<Button label={"Trend-Reihenfolge speichern"} onClick={handleSubmit} />
					</ButtonWrapper>
				</ModalContent>
			</Dialog>
		</>
	);
};
