import { Button } from "primereact/button";
import { readInlineData, useFragment } from "react-relay";

import { type coachingOffersTable_CoachingOfferFragment$key } from "@relay/coachingOffersTable_CoachingOfferFragment.graphql";
import {
	COACHING_OFFER_FRAGMENT,
	COACHING_OFFERS_CONNECTION_FRAGMENT,
} from "./coaching-offers-table.graphql";
import { type CoachingOffersTableProps } from "./coaching-offers-table.interface";
import { StyledDataTable } from "./coaching-offers-table.styles";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../translations/tree";

export const CoachingOffersTable = ({
	treeNodesConnectionFragmentRef,
	hasPrevious,
	loadPrevious,
	hasNext,
	loadNext,
	selectionMode,
	selectedId,
	onNodeSelected,
}: CoachingOffersTableProps) => {
	const { edges: treeNodes } = useFragment(
		COACHING_OFFERS_CONNECTION_FRAGMENT,
		treeNodesConnectionFragmentRef,
	);

	const values =
		treeNodes
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<coachingOffersTable_CoachingOfferFragment$key>(
					COACHING_OFFER_FRAGMENT,
					e!.node,
				),
			) || [];

	const handleLoadPreviousOnClick = () => {
		loadPrevious(20);
	};
	const handleLoadNextOnClick = () => {
		loadNext(20);
	};

	return (
		<>
			<StyledDataTable
				className="mb-3"
				value={values}
				emptyMessage={`Keine passenden ${TREE_I18N_MAP(TREE_I18N_KEY.treePlural)}`}
				selectionMode={selectionMode as any}
				selection={{ id: selectedId }}
				dataKey="id"
				onSelectionChange={onNodeSelected}
			>
				{(Column) => (
					<>
						<Column header="Titel" field="description.name" />
					</>
				)}
			</StyledDataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={handleLoadPreviousOnClick}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={handleLoadNextOnClick}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};
