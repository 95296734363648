import React, { useState } from "react";

export type FilesFilters = {
	filterByName?: string;
	filterByFileTypes?: string[];
	filterByFromDateTimeInclusive?: string;
	filterByToDateTimeInclusive?: string;
	filterFileTagsIncluded?: string[];
	filterFileTagsExcluded?: string[];
};

export const CertificateTag = "IssuedCertificate";

type Search = {
	filters: FilesFilters;
	setFilters: (filters: FilesFilters) => void;
	clearFilters: () => void;
};

export const FilesSearchContext = React.createContext<Search>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

type OwnProps = {
	children: any;
};

export const FileSearchContainer = ({ children }: OwnProps) => {
	const defaultValuesState = {
		filterFileTagsExcluded: [CertificateTag],
	};
	const [state, setState] = useState<FilesFilters>(defaultValuesState);

	return (
		<FilesSearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(() => filters);
				},
				clearFilters: () => {
					setState(() => defaultValuesState);
				},
			}}
		>
			{children}
		</FilesSearchContext.Provider>
	);
};
