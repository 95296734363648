import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { FeatureTogglesScreen } from "./feature-toggles.screen";

export const FeatureTogglesRoutes: RouteDefinition[] = [
	{
		path: Paths.settings.featureToggles.path,
		element: <FeatureTogglesScreen />,
		requiredPermissions: "onlyOwnerOfRoot",
	},
];
