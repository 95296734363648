import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query potentialAnalysisEdit_Query($id: ID!) {
		node(id: $id) {
			... on AVGSPotentialAnalysis_PotentialAnalysis {
				...potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment
			}
		}
	}
`;

export const POTENTIAL_ANALYSIS_V2_INLINE_FRAGMENT = graphql`
	fragment potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis
	@inline {
		id
		linkToPotentialAnalysisOrInvitation
		replacementCoach {
			...potentialAnalysisEdit_UserInlineFragment
		}
		base64EncodedReport
		isFinished
		client {
			kind
			... on AVGSPotentialAnalysis_AcceptedForUser {
				client {
					name
					...potentialAnalysisEdit_UserInlineFragment
				}
			}
			... on AVGSPotentialAnalysis_InvitedForUser {
				email
			}
		}
		coach {
			...potentialAnalysisEdit_UserInlineFragment
		}
		modules {
			moduleId
			moduleText
			dimensions {
				dimensionId
				dimensionText
			}
			...potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment
		}
		...editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment
		...editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment
	}
`;
export const USER_FRAGMENT = graphql`
	fragment potentialAnalysisEdit_UserInlineFragment on User @inline {
		id
		name
		extension(userExtensionKind: Academies) {
			... on AcademiesUserExtension {
				avatar {
					thumbnail
				}
			}
		}
	}
`;

export const POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT = graphql`
	fragment potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment on AVGSPotentialAnalysis_Module
	@inline {
		moduleId
		moduleText
		dimensions {
			dimensionId
			dimensionText
		}
	}
`;
