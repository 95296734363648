/**
 * @generated SignedSource<<31cd22b9a0e907322bb2d07e69c458c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderElementForm_OrderElementFragment$data = {
  readonly answeringTypeText: string | null | undefined;
  readonly id: string;
  readonly orderItems: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrderItemForm_OrderItemFragment">;
  }>;
  readonly title: string;
  readonly " $fragmentType": "OrderElementForm_OrderElementFragment";
};
export type OrderElementForm_OrderElementFragment$key = {
  readonly " $data"?: OrderElementForm_OrderElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderElementForm_OrderElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderElementForm_OrderElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringTypeText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderItem",
      "kind": "LinkedField",
      "name": "orderItems",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderItemForm_OrderItemFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderElement",
  "abstractKey": null
};
})();

(node as any).hash = "fb57a184998db03d63e6484c3f9331dd";

export default node;
