import {
	DefaultChipsComponent,
	DefaultNumberFieldComponent,
	DefaultSwitchComponent,
	DefaultTextAreaComponent,
	DefaultTextFieldComponent,
	InputMultiSelect,
} from "@thekeytechnology/framework-react-components";
import { type FormikProps, useFormik } from "formik";
import React, { useEffect, useImperativeHandle } from "react";
import * as Yup from "yup";
import {
	COACHING_STRONG_POINT_TOPICS_OPTIONS,
	EXPERIENCE_BRANCHES_OPTIONS,
	EXPERIENCE_BRANCHES_STEPS_OPTIONS,
	LANGUAGE_OPTIONS,
	METHODS_OPTIONS,
} from "./edit-profile-data-form.consts";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	DefaultFileSelectionField,
	type FileV2,
} from "../../../../features/files/file-selection-field";
import {
	type EditProfileDataExperienceFormState,
	type EditProfileDataFormState,
	type EditProfileDataSocialsFormState,
	type FormProps,
} from "../edit-profile-data-button/edit-profile-data-button.types";

export const EditProfileDataForm = React.forwardRef<
	FormikProps<EditProfileDataFormState>,
	FormProps<EditProfileDataFormState>
>(({ initialState, onSubmit }, ref) => {
	const urlValidation = Yup.string().url("Urls starten mit 'https://'");

	const socialsValidationSchema = Yup.object().shape({
		facebook: urlValidation,
		website: urlValidation,
		linkedIn: urlValidation,
		twitter: urlValidation,
		others: Yup.array().of(Yup.string()).max(3, "Nicht mehr als 3"),
	});
	const formik = useFormik<EditProfileDataFormState>({
		initialValues: initialState ?? {
			avatar: undefined,
			description: undefined,
			jobTitles: undefined,
			quote: undefined,
			socials: {
				others: undefined,
				website: undefined,
				facebook: undefined,
				twitter: undefined,
				linkedIn: undefined,
			},
			certificateAnswer: undefined,
			certificateFileIds: undefined,
			methods: [],
			studies: undefined,
			coachingStrongPointsTopics: undefined,
			digitalAffinity1: undefined,
			digitalAffinity2: undefined,
			educationFreeText: undefined,
			educationMoreThan150h: undefined,
			experience: {
				coachingFileIds: undefined,
				coachingHours: undefined,
				branchesSteps: [],
				branches: [],
			},
			languages: [],
			referencesCoachingExperienceIds: undefined,
			personalPhilosophyCoachingStyle: undefined,
			personalPhilosophyVisionMission: undefined,
			referencesPublications: undefined,
			basicPersonal: {},
		},
		onSubmit,
		validationSchema: Yup.object().shape({
			socials: socialsValidationSchema,
		}),
	});

	const experienceSubForm = useFormik<EditProfileDataExperienceFormState>({
		initialValues: initialState?.experience ?? {
			branches: [],
			branchesSteps: [],
			coachingHours: undefined,
			coachingFileIds: undefined,
		},
		onSubmit: () => {},
	});

	useEffect(() => {
		formik.values.experience = experienceSubForm.values;
	}, [experienceSubForm.values]);

	const socialsFormik = useFormik<EditProfileDataSocialsFormState>({
		initialErrors: {
			website: "tbd",
		},
		initialTouched: {
			website: true,
			linkedIn: true,
			facebook: true,
			twitter: true,
			others: true,
		},
		initialValues: initialState?.socials ?? {
			linkedIn: "",
			twitter: "",
			facebook: "",
			website: "",
			others: [],
		},
		validationSchema: socialsValidationSchema,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: () => {},
	});

	useEffect(() => {
		void formik.setFieldValue("socials", socialsFormik.values);
	}, [socialsFormik.values]);

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<div className={"p-fluid w-30rem"}>
			<ValidatedField<EditProfileDataFormState, FileV2>
				name={"avatar"}
				label={"Avatar"}
				formikConfig={formik}
				component={DefaultFileSelectionField(true, true)}
			/>
			<ValidatedField<EditProfileDataFormState, string>
				name={"description"}
				label={"Beschreibung"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
			<ValidatedField<EditProfileDataFormState, string>
				name={"quote"}
				label={"Zitat"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
			<ValidatedField<EditProfileDataFormState, string[]>
				name={"jobTitles"}
				label={"Job Titel"}
				formikConfig={formik}
				component={DefaultChipsComponent}
			/>
			<h3>Socials</h3>
			<ValidatedField<EditProfileDataSocialsFormState, string>
				name={"twitter"}
				label={"Twitter"}
				formikConfig={socialsFormik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditProfileDataSocialsFormState, string>
				name={"facebook"}
				label={"Facebook"}
				formikConfig={socialsFormik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditProfileDataSocialsFormState, string>
				name={"linkedIn"}
				label={"LinkedIn"}
				formikConfig={socialsFormik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditProfileDataSocialsFormState, string>
				name={"website"}
				label={"Website"}
				formikConfig={socialsFormik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditProfileDataSocialsFormState, string[]>
				name={"others"}
				label={"Andere"}
				formikConfig={socialsFormik}
				component={DefaultChipsComponent}
			/>
			<ValidatedField<EditProfileDataFormState, string[]>
				name={"languages"}
				label={"Sprachen"}
				formikConfig={formik}
				component={(renderConfig) => (
					<InputMultiSelect
						items={LANGUAGE_OPTIONS}
						onItemsChange={renderConfig.updateField}
						selectedItems={renderConfig.fieldValue}
					/>
				)}
			/>
			<ValidatedField<EditProfileDataFormState, string>
				name={"studies"}
				label={"Ausbildung/Studium"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
			<ValidatedField<EditProfileDataFormState, FileV2>
				name={"certificateFileIds"}
				label={"Ausbildungszertifikate"}
				formikConfig={formik}
				component={DefaultFileSelectionField(true, true)}
			/>
			<ValidatedField<EditProfileDataFormState, number>
				name={"certificateAnswer"}
				label={"Stundenanzahl Ausbildung"}
				formikConfig={formik}
				component={DefaultNumberFieldComponent}
			/>
			<h3>Coachingpraxis</h3>
			<ValidatedField<EditProfileDataExperienceFormState, number>
				name={"coachingHours"}
				label={"Stundenanzahl Ausbildung"}
				formikConfig={experienceSubForm}
				component={DefaultNumberFieldComponent}
			/>
			<ValidatedField<EditProfileDataExperienceFormState, FileV2>
				name={"coachingFileIds"}
				label={"Ausbildungsbelege"}
				formikConfig={experienceSubForm}
				component={DefaultFileSelectionField(true, true)}
			/>
			<h3>Branchenerfahrung</h3>
			<ValidatedField<EditProfileDataExperienceFormState, string[]>
				name={"branches"}
				label={"Branchen"}
				formikConfig={experienceSubForm}
				component={(renderConfig) => (
					<InputMultiSelect
						items={EXPERIENCE_BRANCHES_OPTIONS}
						onItemsChange={renderConfig.updateField}
						selectedItems={renderConfig.fieldValue}
					/>
				)}
			/>
			<ValidatedField<EditProfileDataExperienceFormState, string[]>
				name={"branchesSteps"}
				label={"Stufenerfahrung"}
				formikConfig={experienceSubForm}
				component={(renderConfig) => (
					<InputMultiSelect
						items={EXPERIENCE_BRANCHES_STEPS_OPTIONS}
						onItemsChange={renderConfig.updateField}
						selectedItems={renderConfig.fieldValue}
					/>
				)}
			/>
			<h3>Weiterbildungen</h3>
			<ValidatedField<EditProfileDataFormState, string>
				name={"educationFreeText"}
				label={"Weiterbildungen"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
			<ValidatedField<EditProfileDataFormState, boolean>
				name={"educationMoreThan150h"}
				label={"Weiterbildungen mehr als 150h?"}
				formikConfig={formik}
				component={DefaultSwitchComponent}
			/>
			<h3>Fachgebiete und Methoden</h3>
			<ValidatedField<EditProfileDataFormState, string[]>
				name={"coachingStrongPointsTopics"}
				label={"Coaching Schwerpunkte"}
				formikConfig={formik}
				component={(renderConfig) => (
					<InputMultiSelect
						items={COACHING_STRONG_POINT_TOPICS_OPTIONS}
						onItemsChange={renderConfig.updateField}
						selectedItems={renderConfig.fieldValue}
					/>
				)}
			/>
			<ValidatedField<EditProfileDataFormState, string[]>
				name={"methods"}
				label={"Coaching Methoden"}
				formikConfig={formik}
				component={(renderConfig) => (
					<InputMultiSelect
						items={METHODS_OPTIONS}
						onItemsChange={renderConfig.updateField}
						selectedItems={renderConfig.fieldValue}
					/>
				)}
			/>
			<h3>Digitale Affinität</h3>
			<ValidatedField<EditProfileDataFormState, boolean>
				name={"digitalAffinity1"}
				label={
					"Vertrautheit in der Anwendung von Teams und der Nutzung von digitalen Plattformen?"
				}
				formikConfig={formik}
				component={DefaultSwitchComponent}
			/>

			<ValidatedField<EditProfileDataFormState, boolean>
				name={"digitalAffinity2"}
				label={"Erfahrung im Durchführen von virtuellen Coachings?"}
				formikConfig={formik}
				component={DefaultSwitchComponent}
			/>
			<h3>Persönliche Philosophie*</h3>
			<ValidatedField<EditProfileDataFormState, string>
				name={"personalPhilosophyCoachingStyle"}
				label={"Coaching-Stil"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
			<ValidatedField<EditProfileDataFormState, string>
				name={"personalPhilosophyVisionMission"}
				label={"Vision/Mission"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
			<h3>Referenzen</h3>
			<ValidatedField<EditProfileDataFormState, FileV2>
				name={"referencesCoachingExperienceIds"}
				label={"Coachingpraxisbelege"}
				formikConfig={formik}
				component={DefaultFileSelectionField(true, true)}
			/>
			<ValidatedField<EditProfileDataFormState, string>
				name={"referencesPublications"}
				label={"Publikationen"}
				formikConfig={formik}
				component={DefaultTextAreaComponent}
			/>
		</div>
	);
});
EditProfileDataForm.displayName = "EditProfileDataForm";
