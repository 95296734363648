import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment elearningContentEditor_QueryFragment on Query {
		...elementForm_QueryFragment
	}
`;

export const TREE_NODE_FRAGMENT = graphql`
	fragment elearningContentEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				elements {
					id
					elementType
					title
					...elementForm_ElementV2Fragment
				}
			}
		}
	}
`;

export const REARRANGE_ELEMENTS_MUTATION = graphql`
	mutation elearningContentEditor_RearrangeElementsMutation($input: RearrangeElementsV2Input!) {
		Admin {
			ElearningV2 {
				rearrangeElements(input: $input) {
					eLearningContentNode {
						...elearningContentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const DELETE_ELEMENT_MUTATION = graphql`
	mutation elearningContentEditor_DeleteElementV2Mutation($input: DeleteElementV2Input!) {
		Admin {
			ElearningV2 {
				deleteElement(input: $input) {
					eLearningContentNode {
						...elearningContentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
