import { ButtonType, EpicIcons, Tooltip } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import React, { Suspense, useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { Button } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type reportsTableFilters_Query } from "@relay/reportsTableFilters_Query.graphql";
import { QUERY } from "@screens/reports/parts/reports-table-filters/reports-table-filters.graphql";
import {
	FormContentWrapper,
	Wrapper,
} from "@screens/reports/parts/reports-table-filters/reports-table-filters.styles";
import { type ReportsTableFiltersFormState } from "@screens/reports/parts/reports-table-filters/reports-table-filters.types";
import { ReportsContext } from "@screens/reports/reports.context";

export const ReportsTableFilters = () => {
	const query = useLazyLoadQuery<reportsTableFilters_Query>(QUERY, {});
	const { filters, setFilters } = useContext(ReportsContext);

	const formik = useFormik<ReportsTableFiltersFormState>({
		initialValues: {
			...filters,
		},
		onSubmit: (values: ReportsTableFiltersFormState, { setSubmitting }) => {
			setFilters({
				...filters,
				filterByReferenceNumberOpt: values.filterByReferenceNumberOpt,
			});
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		setFilters({ ...filters, filterByReferenceNumberOpt: undefined });
		void formik.setFieldValue("filterByReferenceNumberOpt", "");
		formik.handleSubmit();
	};

	return (
		<Suspense fallback={"Loading..."}>
			<Wrapper>
				<form onSubmit={formik.handleSubmit}>
					<FormContentWrapper>
						<ValidatedField<ReportsTableFiltersFormState, string>
							name="filterByReferenceNumberOpt"
							label="Referenznummer"
							className="mr-2"
							component={DefaultTextFieldComponent}
							formikConfig={formik}
						/>

						<Button
							disabled={false}
							type={ButtonType.Reset}
							onClick={clearForm}
							icon={EpicIcons.TIMES}
						/>
						<Button disabled={false} type={ButtonType.Submit} icon={EpicIcons.SEARCH} />
					</FormContentWrapper>
				</form>

				<div>
					<Tooltip
						target={"#report-system-email"}
						content={"Email an welche alle Reports gesendet werden."}
					/>
					<span id={"report-system-email"}>Email:</span>{" "}
					{query.Viewer.Reports.SystemEmail}
				</div>
			</Wrapper>
		</Suspense>
	);
};
