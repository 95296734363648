import { type DropdownItem } from "@thekeytechnology/framework-react-components/dist/components/forms/input-dropdown/input-dropdown.interface";
import { type ParticipationStatus } from "@relay/editParticipantsTable_ParticipantFragment.graphql";

export const getParticipationStatusOptions = (): Array<DropdownItem<ParticipationStatus>> => {
	return [
		{
			label: "Abwesend",
			value: "Absent",
		},
		{
			label: "Entschuldigt",
			value: "AbsentExcused",
		},
		{
			label: "Anwesend",
			value: "Participated",
		},
	];
};
