import { Dialog } from "@thekeytechnology/epic-ui";
import { Column } from "primereact/column";
import { TreeNode } from "primereact/treenode";
import {
	TreeTable,
	TreeTableCheckboxSelectionKeyType,
	TreeTableSelectionEvent,
	TreeTableSelectionKeysType,
} from "primereact/treetable";
import React, { FC, useContext, useState } from "react";
import { readInlineData, useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { DialogTemplate } from "@components/dialog-template";
import {
	selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$data,
	selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$key,
} from "@relay/selectDimensionsModal_AVGSPotentialAnalysisModuleFragment.graphql";
import { selectDimensionsModal_CreatePotentialAnalysisMutation } from "@relay/selectDimensionsModal_CreatePotentialAnalysisMutation.graphql";
import { selectDimensionsModal_QueryFragment$key } from "@relay/selectDimensionsModal_QueryFragment.graphql";
import { CreatePAModalsFlowContext } from "@screens/potential-analysis/parts/create-pa-modals-flow/create-pa-modals-flow.context";
import { SelectDimensionsModalProps } from "@screens/potential-analysis/parts/create-pa-modals-flow/parts/select-dimensions-modal/select-dimensions-modal.types";
import { PotentialAnalysisContext } from "@screens/potential-analysis/potential-analysis.context";
import {
	CREATE_POTENTIAL_ANALYSIS_MUTATION,
	POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT,
	QUERY_FRAGMENT,
} from "./select-dimensions-modal.graphql";
import { modulesToNodes } from "./select-dimensions-modal.utils";

export type FormState = {
	moduleWithDimensionIds: number[];
};
export const SelectDimensionsModal: FC<SelectDimensionsModalProps> = ({
	isVisible,
	onDismiss,
	queryFragmentRef,
}) => {
	const [create] = useMutation<selectDimensionsModal_CreatePotentialAnalysisMutation>(
		CREATE_POTENTIAL_ANALYSIS_MUTATION,
	);
	const { contextState, setContextState } = useContext(CreatePAModalsFlowContext);
	const { connectionId } = useContext(PotentialAnalysisContext);

	const [selection, setSelection] = useState<TreeTableSelectionKeysType>({});

	const handleOnSuccess = () => {
		const selectedIds = Object.keys(selection)
			.filter(
				(key) =>
					(selection[key] as TreeTableCheckboxSelectionKeyType)?.checked &&
					!key.includes("-"),
			)
			.map((e) => parseInt(e))
			.filter(Boolean);

		setContextState({
			...contextState,
			moduleWithDimensionIds: selectedIds,
		});
		if (!contextState.accountId || !contextState.coachId) return;
		create({
			variables: {
				input: {
					coachAccountId: contextState.accountId,
					coachId: contextState.coachId,
					clientEmail: contextState.email,
					replacementCoachId: contextState.substituteCoachId,
					selectedModuleIds: selectedIds,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.success("Potential-Analyse erstellt.");
				onDismiss?.();
			},
		});
	};

	const query = useFragment<selectDimensionsModal_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const modules: selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$data[] =
		query.Admin.AvgsPotentialAnalysis.AvailableModulesAndDimensions.map((e) =>
			readInlineData<selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$key>(
				POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT,
				e,
			),
		);

	const treeTableNodes: TreeNode[] = modulesToNodes(modules);

	const handleOnSelectionChange = (event: TreeTableSelectionEvent) => {
		setSelection(event.value);
	};

	return (
		<Dialog visible={isVisible} onHide={onDismiss} title={"Module & Dimensionen"}>
			<DialogTemplate
				maxWidthRem={60}
				primaryButtonLabel="Erstellen"
				onPrimaryButtonClick={handleOnSuccess}
				primaryButtonStretch={true}
			>
				<TreeTable
					value={treeTableNodes}
					selectionMode="checkbox"
					selectionKeys={selection}
					resizableColumns
					showGridlines
					onSelectionChange={handleOnSelectionChange}
					tableStyle={{ minWidth: "50rem" }}
					emptyMessage={"Zurzeit stehen keine Module/Dimensionen zur Verfügung."}
				>
					<Column
						header={"ID"}
						body={(e: TreeNode) => e.id}
						expander
						style={{ width: "15rem" }}
					/>
					<Column header="Modul/Dimension" body={(e: TreeNode) => e.label}></Column>
				</TreeTable>
			</DialogTemplate>
		</Dialog>
	);
};
