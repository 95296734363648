/**
 * @generated SignedSource<<c38399a3b853a4e8dc4cbfff4024a43b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeUsageTable_DiscountCodeUsageFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UsageIdColumn_DiscountCodeUsageFragment" | "UsageInvoiceColumn_DiscountCodeUsageFragment" | "UsageOrderColumn_DiscountCodeUsageFragment" | "UsageUsedAtColumn_DiscountCodeUsageFragment">;
  readonly " $fragmentType": "DiscountCodeUsageTable_DiscountCodeUsageFragment";
};
export type DiscountCodeUsageTable_DiscountCodeUsageFragment$key = {
  readonly " $data"?: DiscountCodeUsageTable_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeUsageTable_DiscountCodeUsageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DiscountCodeUsageTable_DiscountCodeUsageFragment"
};

(node as any).hash = "659346918b601bcdafbbd80a328e40df";

export default node;
