import { EpicIcons } from "@thekeytechnology/epic-ui";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation } from "@relay/CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation.graphql";

const CREATE_MARK_MISTAKE_ELEMENT_MUTATION = graphql`
	mutation CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation(
		$input: CreateMarkMistakesElementInput!
	) {
		Admin {
			ElearningV2 {
				createMarkMistakesElement(input: $input) {
					eLearningContent {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateMarkMistakeElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createMarkMistakeElement, isCreatingMarkMistakeElement] =
		useMutation<CreateMarkMistakeElementButton_CreateMarkMistakeElementMutation>(
			CREATE_MARK_MISTAKE_ELEMENT_MUTATION,
		);

	const handleCreateOnClick = () => {
		createMarkMistakeElement({
			variables: {
				input: {
					title: "Neues Element",
					text: "",
					eLearningContentId: eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingMarkMistakeElement}
			tooltip={`Fehler-Markieren-Baustein anlegen`}
			icon={EpicIcons.TIMES_CIRCLE}
			onClick={handleCreateOnClick}
		/>
	);
};
