import { graphql } from "babel-plugin-relay/macro";

export const LICENSE_DEFINITION_FRAGMENT = graphql`
	fragment editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {
		id
		data {
			... on MicroLearningLicenseDefinitionData {
				name
			}
		}
	}
`;

export const EDIT_MICRO_LEARNING_LICENSE_DEFINITION_MUTATION = graphql`
	mutation editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation(
		$input: EditMicroLearningLicenseDefinitionAdminMutationInput!
	) {
		Admin {
			LicenseDefinition {
				editMicroLicenseDefinitionAdminMutation(input: $input) {
					licenseDefinition {
						...editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment
					}
				}
			}
		}
	}
`;
