import { IconName } from "@thekeytechnology/epic-ui/components/icon/icon.interface";
import React, { PropsWithChildren } from "react";
import { ButtonVariant } from "@components/button";

export type DialogTemplateProps = {
	title?: string;
	subtitle?: string;
	secondaryButton?: React.ReactNode;
	secondaryButtonLabel?: string;
	secondaryButtonIconName?: IconName;
	secondaryButtonColorVersion?: ButtonVariant;
	secondaryButtonStretch?: boolean;
	onSecondaryButtonClick?: () => void;
	primaryButton?: React.ReactNode;
	primaryButtonLabel?: string;
	primaryButtonIconName?: IconName;
	primaryButtonColorVersion?: ButtonVariant;
	primaryButtonStretch?: boolean;
	primaryButtonDisabled?: boolean;
	primaryButtonLoading?: boolean;
	onPrimaryButtonClick?: () => void;
	navigateTo?: string;
	maxWidthRem?: number;
	buttonsDirection?: DialogButtonsDirection;
	hasSeperator?: boolean;
} & PropsWithChildren;

export enum DialogButtonsDirection {
	ROW = "row",
	COLUMN = "column",
}
