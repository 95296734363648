import { graphql } from "babel-plugin-relay/macro";

export const GET_VIDEO_DATA_MUTATION = graphql`
	mutation videoSourceField_GetVideoDataV2Mutation($input: GetVideoDataV2Input!) {
		Admin {
			ElearningV2 {
				getVideoData(input: $input) {
					videoData {
						name
						duration
						thumbnailUrl
					}
				}
			}
		}
	}
`;
