/**
 * @generated SignedSource<<f294d2e37b6cb2e36e8b36f58055d0d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editContractPartnerButton_ContractPartnerFragment$data = {
  readonly data: {
    readonly association: string | null | undefined;
    readonly city: string | null | undefined;
    readonly country: string | null | undefined;
    readonly email: string;
    readonly houseNumber: string | null | undefined;
    readonly linkToAGB: string | null | undefined;
    readonly name: string;
    readonly postalCode: string | null | undefined;
    readonly street: string | null | undefined;
    readonly taxNumber: string | null | undefined;
  };
  readonly id: string;
  readonly shareAmountPercentage: number;
  readonly " $fragmentType": "editContractPartnerButton_ContractPartnerFragment";
};
export type editContractPartnerButton_ContractPartnerFragment$key = {
  readonly " $data"?: editContractPartnerButton_ContractPartnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editContractPartnerButton_ContractPartnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editContractPartnerButton_ContractPartnerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareAmountPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContractPartnerData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "association",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "houseNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkToAGB",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractPartner",
  "abstractKey": null
};

(node as any).hash = "8c90c5bc07e5845ffc0f802967f620ca";

export default node;
