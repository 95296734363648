import { Dialog } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import React, { type FC, useContext } from "react";
import * as Yup from "yup";
import { DialogTemplate } from "@components/dialog-template";
import { ValidatedField } from "@components/ValidatedField";
import { SelectAvgsCoachField } from "@features/avgs/select-avgs-coach-field";

import { P2Span } from "@themes/font-tags";
import { wrapperClass } from "./select-substitute-coach-modal.styles";
import {
	SelectSubstituteCoachModalFormState,
	SelectSubstituteCoachModalProps,
} from "./select-substitute-coach-modal.types";
import { CreatePAModalsFlowContext } from "../../create-pa-modals-flow.context";

export const SelectSubstituteCoachModal: FC<SelectSubstituteCoachModalProps> = ({
	isVisible,
	onSuccess,
	onDismiss,
}) => {
	const { contextState, setContextState } = useContext(CreatePAModalsFlowContext);
	const handleOnDismiss = () => {
		onDismiss();
	};

	const handleOnSubmit = (values: SelectSubstituteCoachModalFormState) => {
		setContextState({
			...contextState,
			substituteCoachId: values.coachId,
		});
		onSuccess?.();
	};

	const formik = useFormik<SelectSubstituteCoachModalFormState>({
		initialValues: {
			coachId: undefined,
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			coachId: Yup.string().optional(),
		}),
		onSubmit: handleOnSubmit,
	});

	const handleOnChoose = () => {
		formik.submitForm();
	};

	return (
		<Dialog visible={isVisible} onHide={handleOnDismiss} title="AVGS-Coach Vertretung">
			<DialogTemplate
				maxWidthRem={50}
				primaryButtonLabel="Auswählen"
				onPrimaryButtonClick={handleOnChoose}
				primaryButtonStretch={true}
			>
				<div className={wrapperClass}>
					<P2Span>
						Wählen Sie jetzt die AVGS-Coach Vertretung oder gehen Sie weiter.
					</P2Span>
					<form onSubmit={formik.handleSubmit}>
						{contextState.accountId && (
							<ValidatedField<SelectSubstituteCoachModalFormState, string>
								name={"coachId"}
								label={"Coach"}
								formikConfig={formik}
								component={(renderConfig) => (
									<SelectAvgsCoachField
										{...renderConfig}
										accountId={contextState.accountId!}
									/>
								)}
							/>
						)}
					</form>
				</div>
			</DialogTemplate>
		</Dialog>
	);
};
