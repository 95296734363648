import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import * as Yup from "yup";
import { type wordPackageRewardForm_EditWordPackageRewardMutation } from "@relay/wordPackageRewardForm_EditWordPackageRewardMutation.graphql";
import { type wordPackageRewardForm_Query } from "@relay/wordPackageRewardForm_Query.graphql";
import { type wordPackageRewardForm_WordPackageRewardFragment$key } from "@relay/wordPackageRewardForm_WordPackageRewardFragment.graphql";
import {
	EDIT_WORD_PACKAGE_REWARD_MUTATION,
	QUERY,
	WORD_PACKAGE_REWARD_FRAGMENT,
} from "./word-package-reward-form.graphql";
import {
	type WordPackageRewardFormFormState,
	type WordPackageRewardFormProps,
} from "./word-package-reward-form.types";
import { ValidatedField } from "../../../../components/ValidatedField";
import { SelectWordPackageField } from "../../../../features/word-packages/select-word-package-field/select-word-package-field.component";
import { type SelectWordPackageFieldState } from "../../../../features/word-packages/select-word-package-field/select-word-package-field.types";
import { resetArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

export const WordPackageRewardForm = ({
	contentNodeId,
	wordPackageRewardFragmentRef,
	onBack: handleOnClick,
}: WordPackageRewardFormProps) => {
	const query = useLazyLoadQuery<wordPackageRewardForm_Query>(QUERY, {});
	const reward = useFragment<wordPackageRewardForm_WordPackageRewardFragment$key>(
		WORD_PACKAGE_REWARD_FRAGMENT,
		wordPackageRewardFragmentRef,
	);
	const [editReward, isEditingReward] =
		useMutation<wordPackageRewardForm_EditWordPackageRewardMutation>(
			EDIT_WORD_PACKAGE_REWARD_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formik = useFormik<WordPackageRewardFormFormState>({
		initialValues: {
			wordPackage: {
				id: reward.data.wordPackage?.id ?? "",
				name: reward.data.wordPackage?.name ?? "",
			},
		},
		validationSchema: Yup.object().shape({
			wordPackage: Yup.object()
				.shape({
					id: Yup.string(),
					name: Yup.string(),
				})
				.required("Bitte wähle ein Wortpaket aus"),
		}),
		onSubmit: (values, formikHelpers) => {
			if (!values.wordPackage?.id) return;
			editReward({
				variables: {
					input: {
						contentId: contentNodeId,
						rewardId: reward.id!,
						newData: { wordPackageId: values.wordPackage.id },
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});
	const isDisabled = Object.entries(formik.touched).length === 0 || isEditingReward;

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<WordPackageRewardFormFormState, SelectWordPackageFieldState>
				required
				name={"wordPackage"}
				label="Wortpaket"
				placeholder="Wortpaket auswählen..."
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectWordPackageField {...renderConfig} queryFragment={query} />
				)}
			/>

			<Button disabled={isDisabled} type="submit" label="Speichern" className="mt-2" />

			<Button
				type="button"
				onClick={handleOnClick}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
