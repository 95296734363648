/**
 * @generated SignedSource<<403dfac6a9c20c3ac469da5539c21a53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exceptionsTable_CoachProfileFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editExceptionModal_CoachProfileFragment">;
  readonly " $fragmentType": "exceptionsTable_CoachProfileFragment";
};
export type exceptionsTable_CoachProfileFragment$key = {
  readonly " $data"?: exceptionsTable_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"exceptionsTable_CoachProfileFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "exceptionsTable_CoachProfileFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editExceptionModal_CoachProfileFragment"
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};

(node as any).hash = "35b2fe2756bfcb67ce12bf80e54aa411";

export default node;
