import { graphql } from "babel-plugin-relay/macro";

export const reportsPerPage = 20;

export const QUERY = graphql`
	query reportsScreen_Query(
		$filterByReferenceNumberOpt: String
		$first: Int
		$after: String
		$last: Int
		$before: String
	) {
		...reportsTable_QueryFragment
			@arguments(
				filterByReferenceNumberOpt: $filterByReferenceNumberOpt
				first: $first
				after: $after
				before: $before
				last: $last
			)
	}
`;
