import { BaseScreen } from "@screens/BaseScreen";
import { TrendForm } from "./parts/trend-form/trend-form.component";

export const TrendEditScreen = () => {
	return (
		<BaseScreen>
			<TrendForm />
		</BaseScreen>
	);
};
