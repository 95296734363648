import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment coachProfilesTable_QueryFragment on Query
	@refetchable(queryName: "CoachProfilesTable_QueryRefetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		last: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
		userName: { type: "String" }
		filterByUserId: { type: "ID" }
	) {
		Admin {
			Coaching {
				CoachProfiles(
					before: $before
					after: $after
					first: $first
					last: $last
					userName: $userName
					filterByUserId: $filterByUserId
				) @connection(key: "CoachProfilesTable_CoachProfiles") {
					__id
					edges {
						node {
							coachingOffer {
								id
								...coachProfilesTable_CoachProfileFragment
								...editBaseDataButton_CoachProfileFragment
							}
							coachings {
								description {
									name
								}
							}
						}
					}
				}
				AvailabilitySchedules {
					edges {
						node {
							...coachProfilesTable_AvailabilityScheduleFragment
						}
					}
				}
			}
		}
	}
`;

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment coachProfilesTable_CoachProfileFragment on CoachProfile @inline {
		id
		coach {
			id
			name
		}
		profileData {
			description
		}
		averageFeedbackScore
		...editBaseDataButton_CoachProfileFragment
		...editProfileDataButton_CoachProfileFragment
		...editBusinessDataButton_CoachProfileFragment
		...editAvailabilityScheduleButton_CoachProfileFragment
		...deleteCoachProfileButton_CoachProfileFragment
		...showCoachFeedbacksButton_CoachProfileFragment
		...showCoachAppointmentsButton_CoachProfileFragment
	}
`;

export const AVAILABILITY_SCHEDULE_FRAGMENT = graphql`
	fragment coachProfilesTable_AvailabilityScheduleFragment on AvailabilitySchedule @inline {
		coachRef
		...editAvailabilityScheduleButton_AvailabilityScheduleFragment
	}
`;
