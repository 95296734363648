import type { BusinessBaseDataFormState } from "./parts/business-base-data-form/business-base-data-form.types";
import type { PrivateBaseDataFormState } from "./parts/private-base-data-form/private-base-data-form.types";

export type PrivateBaseDataSubformState = {
	accountType: AccountType.Private;
	subform?: PrivateBaseDataFormState;
};

export type BusinessBaseDataSubformState = {
	accountType: AccountType.Business;
	subform?: BusinessBaseDataFormState;
};

export type AccountFormState = PrivateBaseDataSubformState | BusinessBaseDataSubformState;

export enum AccountType {
	Business = "Business",
	Private = "Private",
}
