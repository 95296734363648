import { Button } from "primereact/button";
import React from "react";
import { useFragment } from "react-relay";
import { type invoiceDataForm_OrderFragment$key } from "@relay/invoiceDataForm_OrderFragment.graphql";
import { ORDER_FRAGMENT } from "./invoice-data-form.graphql";
import { type InvoiceDataFormProps } from "./invoice-data-form.interface";
import { TextDisplayField } from "../../../../components/text-display-field/text-display-field.component";

export const InvoiceDataForm = ({ orderFragmentRef }: InvoiceDataFormProps) => {
	const order = useFragment<invoiceDataForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	const downloadInvoicePDF = () => {
		if (order.invoiceData?.invoiceFile?.url) {
			const link = document.createElement("a");
			link.href =
				"data:application/octet-stream;base64," + order.invoiceData?.invoiceFile?.url;
			link.download = order.invoiceData.invoiceNumber + ".pdf";
			link.click();
		}
	};

	const invoiceId = order.invoiceData?.invoiceId;
	const invoiceNumber = order.invoiceData?.invoiceNumber ?? undefined;

	return order.invoiceData ? (
		<div>
			<div className="formgrid grid">
				<TextDisplayField label="Rechnungs-ID" value={invoiceId} />
				<TextDisplayField label="Rechnungsnummer" value={invoiceNumber} />
			</div>
			{order.invoiceData.invoiceFile?.url && (
				<Button onClick={downloadInvoicePDF} label="PDF Download" icon="pi pi-download" />
			)}
		</div>
	) : (
		<h4>Keine Rechnungsdaten verfügbar</h4>
	);
};
