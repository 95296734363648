import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useContext, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { ConfirmWithTextDialog } from "@components/confirm-with-text-dialog";
import { type deleteCourseLearnableButton_DeleteTreeMutation } from "@relay/deleteCourseLearnableButton_DeleteTreeMutation.graphql";
import { type deleteCourseLearnableButton_UnpublishedLearnableFragment$key } from "@relay/deleteCourseLearnableButton_UnpublishedLearnableFragment.graphql";
import {
	DELETE_MUTATION,
	UNPUBLISHED_LEARNABLE_FRAGMENT,
} from "./delete-course-learnable-button.graphql";
import { type DeleteCourseLearnableButtonProps } from "./delete-course-learnable-button.types";
import { OffersScreenContext } from "../../offers.context";

export const DeleteCourseLearnableButtonComponent = ({
	learnableFragmentRef,
	disabled,
}: DeleteCourseLearnableButtonProps) => {
	const learnable = useFragment<deleteCourseLearnableButton_UnpublishedLearnableFragment$key>(
		UNPUBLISHED_LEARNABLE_FRAGMENT,
		learnableFragmentRef,
	);
	const { connectionId } = useContext(OffersScreenContext);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const [deleteCourseLearnable] =
		useMutation<deleteCourseLearnableButton_DeleteTreeMutation>(DELETE_MUTATION);

	const handleOnClick = () => {
		deleteCourseLearnable({
			variables: {
				input: {
					rootId: learnable?.root?.id!,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				setShowConfirmDeleteDialog(false);
				toast.success("Weiterbildung wurde erfolgreich gelöscht.");
			},
		});
	};

	return (
		<>
			{showConfirmDeleteDialog && (
				<ConfirmWithTextDialog
					title={`Sind Sie sicher, dass Sie "${learnable.root?.structureDefinition.title}" löschen möchten?`}
					content={
						<>
							<div>
								Die Weiterbildung "{learnable.root?.structureDefinition.title}" und
								alle zugehörigen Daten werden gelöscht. Diese Aktion kann nicht
								rückgängig gemacht werden.
							</div>
							<div className="mt-4">
								Um die Weiterbildung dennoch zu löschen, geben Sie bitte den Namen
								der Weiterbildung ein:
							</div>
						</>
					}
					onCancel={() => {
						setShowConfirmDeleteDialog(false);
					}}
					onConfirm={handleOnClick}
					textToConfirm={learnable.root?.structureDefinition.title!}
				/>
			)}
			<Button
				disabled={disabled}
				onClick={() => {
					setShowConfirmDeleteDialog(true);
				}}
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				tooltip={"Weiterbildung löschen"}
			></Button>
		</>
	);
};
