import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useContext, useRef } from "react";
import { useMutation } from "react-relay";
import { createLicenseProductButton_CreateLicenseProductMutation } from "@relay/createLicenseProductButton_CreateLicenseProductMutation.graphql";
import { ProductsScreenContext } from "@screens/products/products.context";
import { CREATE_LICENSE_PRODUCT_MUTATION } from "./create-license-product-button.graphql";
import { CreateLicenseProductButtonProps } from "./create-license-product-button.interface";

export const CreateLicenseProductButton = ({ className }: CreateLicenseProductButtonProps) => {
	const { connectionId } = useContext(ProductsScreenContext);

	const [createProduct, isCreatingProduct] =
		useMutation<createLicenseProductButton_CreateLicenseProductMutation>(
			CREATE_LICENSE_PRODUCT_MUTATION,
		);

	const overlayPanel = useRef<OverlayPanel>(null);
	const nameOfProduct = useRef<any>(null);
	const toast = useRef<any>(null);

	return (
		<>
			<Toast ref={toast} />
			<Button
				label="Neu"
				loading={isCreatingProduct}
				disabled={isCreatingProduct}
				className={className}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className="p-float-label mb-2 mt-3">
					<InputText id="nameOfProduct" ref={nameOfProduct} />
					<label htmlFor="nameOfProduct">Name des Produkts</label>
				</span>
				<Button
					label="Produkt erstellen"
					loading={isCreatingProduct}
					disabled={isCreatingProduct}
					onClick={() => {
						createProduct({
							variables: {
								input: {
									title: nameOfProduct.current.value || "Neu",
								},
								connections: [connectionId],
							},
							onCompleted: () => {
								toast.current?.show({
									severity: "success",
									life: 3000,
									summary: "Produkt erstellt",
									detail: "Das Produkt wurde erfolgreich erstellt",
								});
							},
						});
					}}
				/>
			</OverlayPanel>
		</>
	);
};
