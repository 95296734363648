/**
 * @generated SignedSource<<f3af003d6adc7726a59aea169353193e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountsSelectWithId_QueryFragmentRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  ids: ReadonlyArray<string>;
  name?: string | null | undefined;
};
export type AccountsSelectWithId_QueryFragmentRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountsSelectWithId_QueryFragment">;
};
export type AccountsSelectWithId_QueryFragmentRefetchQuery = {
  response: AccountsSelectWithId_QueryFragmentRefetchQuery$data;
  variables: AccountsSelectWithId_QueryFragmentRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "ids",
  "variableName": "ids"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "nameOpt",
    "variableName": "name"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsSelectWithId_QueryFragmentRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AccountsSelectWithId_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountsSelectWithId_QueryFragmentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "AccountConnection",
                "kind": "LinkedField",
                "name": "SelectAccounts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "nameOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "AccountsSelectWithId_SelectAccounts",
                "kind": "LinkedHandle",
                "name": "SelectAccounts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82c7d54a8d48fe55a24baacc4dc2288e",
    "id": null,
    "metadata": {},
    "name": "AccountsSelectWithId_QueryFragmentRefetchQuery",
    "operationKind": "query",
    "text": "query AccountsSelectWithId_QueryFragmentRefetchQuery(\n  $after: String = null\n  $first: Int\n  $ids: [ID!]!\n  $name: String = null\n) {\n  ...AccountsSelectWithId_QueryFragment_4bsw58\n}\n\nfragment AccountsSelectWithId_QueryFragment_4bsw58 on Query {\n  Admin {\n    Auth {\n      SelectAccounts(first: $first, after: $after, nameOpt: $name, ids: $ids) {\n        edges {\n          cursor\n          node {\n            id\n            name\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad67e4f4c05ceff111f5ecfa6914f3f4";

export default node;
