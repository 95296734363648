/**
 * @generated SignedSource<<f5fe74cd58c2026082daa2cc93b49e6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectDimensionsModal_Refetch$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
};
export type selectDimensionsModal_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectDimensionsModal_QueryFragment">;
};
export type selectDimensionsModal_Refetch = {
  response: selectDimensionsModal_Refetch$data;
  variables: selectDimensionsModal_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectDimensionsModal_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectDimensionsModal_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectDimensionsModal_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AvgsPotentialAnalysisAdminSchema",
            "kind": "LinkedField",
            "name": "AvgsPotentialAnalysis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AVGSPotentialAnalysis_Module",
                "kind": "LinkedField",
                "name": "AvailableModulesAndDimensions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AVGSPotentialAnalysis_Dimension",
                    "kind": "LinkedField",
                    "name": "dimensions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dimensionId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dimensionText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06f11bdb43ad9ee4a29d2e60c01f444e",
    "id": null,
    "metadata": {},
    "name": "selectDimensionsModal_Refetch",
    "operationKind": "query",
    "text": "query selectDimensionsModal_Refetch(\n  $after: String\n  $before: String\n  $first: Int\n  $last: Int\n) {\n  ...selectDimensionsModal_QueryFragment_pbnwq\n}\n\nfragment selectDimensionsModal_AVGSPotentialAnalysisModuleFragment on AVGSPotentialAnalysis_Module {\n  moduleId\n  moduleText\n  dimensions {\n    dimensionId\n    dimensionText\n  }\n}\n\nfragment selectDimensionsModal_QueryFragment_pbnwq on Query {\n  Admin {\n    AvgsPotentialAnalysis {\n      AvailableModulesAndDimensions(before: $before, last: $last, after: $after, first: $first) {\n        ...selectDimensionsModal_AVGSPotentialAnalysisModuleFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e90c342ffcfda14d702fd416a610627";

export default node;
