import { type FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { CustomerFeedbackFormState } from "@screens/customer-feedback/parts/customer-feedback-form/customer-feedback-form.types";
import type { DefaultFormProps } from "@screens/offers/parts/common";

export const CustomerFeedbackForm = React.forwardRef<
	FormikProps<CustomerFeedbackFormState>,
	DefaultFormProps<CustomerFeedbackFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<CustomerFeedbackFormState>({
		enableReinitialize: true,
		initialValues: initialState ?? {
			customerFeedbackId: "",
			name: "",
			position: "",
			feedback: "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Ein Name wird benötigt."),
			position: Yup.string().required("Eine Position wird benötigt."),
			feedback: Yup.string().required("Ein Feedback wird benötigt."),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid w-30rem">
			<ValidatedField<CustomerFeedbackFormState, string>
				name={"name"}
				label="Name"
				required={true}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<CustomerFeedbackFormState, string>
				name={"position"}
				label="Position"
				required={true}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<CustomerFeedbackFormState, string>
				name={"feedback"}
				label="Feedback"
				required={true}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
		</form>
	);
});
CustomerFeedbackForm.displayName = "CustomerFeedbackForm";
