import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation } from "@relay/createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation.graphql";
import { CREATE_LIMITED_CART_DISCOUNT_MUTATION } from "./create-limited-cart-discount-button.graphql";
import { CreateLimitedCartDiscountButtonProps } from "./create-limited-cart-discount-button.interface";

export const CreateLimitedCartDiscountButton = ({
	connectionId,
}: CreateLimitedCartDiscountButtonProps) => {
	const [createLimitedCartDiscount, isCreatingLimitedCartDiscount] =
		useMutation<createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation>(
			CREATE_LIMITED_CART_DISCOUNT_MUTATION,
		);

	const handleOnClick = () => {
		createLimitedCartDiscount({
			variables: {
				connections: [connectionId],
				input: {},
			},
			onCompleted: () => {
				toast.success("Warenkorb-Rabatt erfolgreich erstellt.");
			},
		});
	};

	return (
		<Button
			label="Neu"
			onClick={handleOnClick}
			className="ml-auto"
			disabled={isCreatingLimitedCartDiscount}
		/>
	);
};
