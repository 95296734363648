import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { type FormikProps } from "formik";
import React, { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation } from "@relay/createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation.graphql";
import { CREATE_TREE_LICENSE_DEFINITION_MUTATION } from "./create-tree-license-definition-button.graphql";
import { LicenseDefinitionsScreenContext } from "../../../screens/license-definitions/license-defintions.context";
import { TreeLicenseDefinitionForm } from "../tree-license-definition-form";
import { type TreeLicenseDefinitionFormState } from "../tree-license-definition-form/tree-license-definition-form.types";

export const CreateTreeLicenseDefinitionButton = () => {
	const { connectionId } = useContext(LicenseDefinitionsScreenContext);
	const [create, isCreating] =
		useMutation<createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation>(
			CREATE_TREE_LICENSE_DEFINITION_MUTATION,
		);
	const createHandleOnSubmit = (
		ref: React.MutableRefObject<FormikProps<TreeLicenseDefinitionFormState> | null>,
		onHide: () => void,
		values: TreeLicenseDefinitionFormState,
	) => {
		create({
			variables: {
				input: {
					data: {
						rootId: values.root?.id!,
						name: values.name,
					},
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				ref.current?.setSubmitting(false);
				onHide();
				toast.success("Weiterbildung-Lizenzdefinition erstellt.");
			},
			onError: () => {
				ref.current?.setSubmitting(false);
				toast.error("Fehler beim erstellen der Weiterbildung-Lizenzdefinition.");
			},
		});
	};

	return (
		<FormDialogButton<TreeLicenseDefinitionFormState>
			disabled={isCreating}
			buttonContent={{
				label: "Weiterbildung-Lizenzdefinition erstellen",
			}}
			title={"Weiterbildung-Lizenzdefinition erstellen"}
		>
			{(ref, onHide) => {
				return (
					<TreeLicenseDefinitionForm
						initialState={{
							root: undefined,
							name: "",
						}}
						ref={ref}
						onSubmit={(values) => {
							createHandleOnSubmit(ref, onHide, values);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
