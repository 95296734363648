/**
 * @generated SignedSource<<4d53762509bf658b01740927e05c35b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dynamicVocabElementForm_DynamicVocabElementFragment$data = {
  readonly id: string;
  readonly numDecoy: number;
  readonly packageSelections: ReadonlyArray<{
    readonly wordPackage: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
  }>;
  readonly title: string;
  readonly " $fragmentType": "dynamicVocabElementForm_DynamicVocabElementFragment";
};
export type dynamicVocabElementForm_DynamicVocabElementFragment$key = {
  readonly " $data"?: dynamicVocabElementForm_DynamicVocabElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dynamicVocabElementForm_DynamicVocabElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dynamicVocabElementForm_DynamicVocabElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numDecoy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "packageSelections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WordPackage",
          "kind": "LinkedField",
          "name": "wordPackage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DynamicVocabElement",
  "abstractKey": null
};
})();

(node as any).hash = "68dbb420679531eeddad781d65e9d8ea";

export default node;
