import { graphql } from "babel-plugin-relay/macro";

export const VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT = graphql`
	fragment setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {
		id
		isDefault
	}
`;

export const SET_VIMEO_VIDEO_SOURCE_AS_DEFAULT_MUTATION = graphql`
	mutation setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation(
		$input: SetVimeoVideoSourceAsDefaultInput!
	) {
		Admin {
			VimeoVideoSource {
				setVimeoVideoSourceAsDefault(input: $input) {
					edge {
						node {
							...videoSourcesTable_Vimeo_VimeoVideoSourceFragment
						}
					}
				}
			}
		}
	}
`;
