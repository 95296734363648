import {
	Button,
	DefaultTextFieldComponent,
	Form,
	ValidatedField,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import {
	SelectWordPackagesField,
	SelectWordPackagesFieldState,
} from "@features/word-packages/select-word-packages-field";
import { type dynamicVocabElementForm_DynamicVocabElementFragment$key } from "@relay/dynamicVocabElementForm_DynamicVocabElementFragment.graphql";
import { type dynamicVocabElementForm_EditDynamicVocabElementMutation } from "@relay/dynamicVocabElementForm_EditDynamicVocabElementMutation.graphql";
import { type dynamicVocabElementForm_QueryFragment$key } from "@relay/dynamicVocabElementForm_QueryFragment.graphql";
import {
	DYNAMIC_VOCAB_ELEMENT_FRAGMENT,
	EDIT_DYNAMIC_VOCAB_ELEMENT_MUTATION,
	QUERY_FRAGMENT,
} from "./dynamic-vocab-element-form.graphql";
import {
	type DynamicVocabElementFormProps,
	type DynamicVocabElementFormState,
} from "./dynamic-vocab-element-form.types";

export const DynamicVocabElementForm = ({
	queryFragmentRef,
	dynamicVocabElementFragmentRef,
}: DynamicVocabElementFormProps) => {
	const query = useFragment<dynamicVocabElementForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const dynamicVocabElement =
		useFragment<dynamicVocabElementForm_DynamicVocabElementFragment$key>(
			DYNAMIC_VOCAB_ELEMENT_FRAGMENT,
			dynamicVocabElementFragmentRef,
		);

	const [editDynamicVocabElement] =
		useMutation<dynamicVocabElementForm_EditDynamicVocabElementMutation>(
			EDIT_DYNAMIC_VOCAB_ELEMENT_MUTATION,
		);
	const handleOnSubmit = (values: DynamicVocabElementFormState) => {
		editDynamicVocabElement({
			variables: {
				input: {
					elementId: dynamicVocabElement.id,
					title: values.title,
					wordPackageIds: values.wordPackages.map((wordPackage) => wordPackage.id),
				},
			},
		});
	};

	const formik = useFormik<DynamicVocabElementFormState>({
		initialValues: {
			title: dynamicVocabElement.title,
			wordPackages:
				dynamicVocabElement.packageSelections.map((l) => ({
					id: l.wordPackage?.id!,
					name: l.wordPackage?.name!,
				})) || [],
		},
		validationSchema: Yup.object().shape({
			wordPackages: Yup.array()
				.min(1, "Bitte wähle mindestens 1 Wortpaket")
				.required("Bitte wähle mindestens 1 Wortpaket"),
		}),
		enableReinitialize: true,
		onSubmit: handleOnSubmit,
	});

	return (
		<Form onSubmit={formik.handleSubmit}>
			<ValidatedField<DynamicVocabElementFormState, string>
				name={"title"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<DynamicVocabElementFormState, SelectWordPackagesFieldState>
				name={"wordPackages"}
				label={"Wortpakete "}
				formikConfig={formik}
				component={(renderConfig) => {
					return (
						<SelectWordPackagesField
							queryFragment={query}
							fieldValue={renderConfig.fieldValue}
							updateField={renderConfig.updateField}
							fieldName={"coaches"}
							required={true}
							isValid={true}
							disabled={false}
						/>
					);
				}}
			/>

			<Button
				content={{ label: "Speichern" }}
				disabled={!formik.isValid}
				onClick={() => {
					formik.handleSubmit();
				}}
			/>
		</Form>
	);
};
