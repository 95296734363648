import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import type { FormikProps } from "formik";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { editCustomerFeedbackButton_CustomerFeedbackFragment$key } from "@relay/editCustomerFeedbackButton_CustomerFeedbackFragment.graphql";
import { editCustomerFeedbackButton_EditCustomerFeedbackMutation } from "@relay/editCustomerFeedbackButton_EditCustomerFeedbackMutation.graphql";
import { CustomerFeedbackForm } from "@screens/customer-feedback/parts/customer-feedback-form/customer-feedback-form.component";
import { CustomerFeedbackFormState } from "@screens/customer-feedback/parts/customer-feedback-form/customer-feedback-form.types";
import {
	CUSTOMER_FEEDBACK_FRAGMENT,
	EDIT_CUSTOMER_FEEDBACK_MUTATION,
} from "@screens/customer-feedback/parts/edit-customer-feedback-button/edit-customer-feedback-button.graphql";
import { EditCustomerFeedbackButtonProps } from "@screens/customer-feedback/parts/edit-customer-feedback-button/edit-customer-feedback-button.types";

export const EditCustomerFeedbackButton = ({
	customerFeedbackFragmentRef,
}: EditCustomerFeedbackButtonProps) => {
	const toast = useRef<Toast>(null);

	const [editCustomerFeedback, isEditingCustomerFeedback] =
		useMutation<editCustomerFeedbackButton_EditCustomerFeedbackMutation>(
			EDIT_CUSTOMER_FEEDBACK_MUTATION,
		);

	const customerFeedback = useFragment<editCustomerFeedbackButton_CustomerFeedbackFragment$key>(
		CUSTOMER_FEEDBACK_FRAGMENT,
		customerFeedbackFragmentRef,
	);

	const editHandleOnSubmit = (
		formikRef: React.MutableRefObject<FormikProps<CustomerFeedbackFormState> | null>,
		onHide: () => void,
		values: CustomerFeedbackFormState,
	) => {
		editCustomerFeedback({
			variables: {
				input: {
					customerFeedbackId: customerFeedback.id,
					name: values.name,
					feedback: values.feedback,
					position: values.position,
				},
			},
			onCompleted: () => {
				formikRef.current?.setSubmitting(false);
				onHide();
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Kundenfeedback gespeichert",
					detail: "Das Kundenfeedback wurde erfolgreich aktualisiert",
				});
			},
			onError: () => {
				formikRef.current?.setSubmitting(false);
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Kundenfeedback gespeichert",
					detail: "Das Kundenfeedback wurde nicht aktualisiert",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			<FormDialogButton<CustomerFeedbackFormState>
				buttonIcon={EpicIcons.PENCIL}
				dialogTitle="Kundenfeedback bearbeiten"
				disabled={isEditingCustomerFeedback}
			>
				{({ formikRef, onHide }) => {
					return (
						<CustomerFeedbackForm
							initialState={{
								customerFeedbackId: customerFeedback.id || "",
								name: customerFeedback.name || "",
								position: customerFeedback.position || "",
								feedback: customerFeedback.feedback || "",
							}}
							ref={formikRef}
							onSubmit={(values) => {
								editHandleOnSubmit(formikRef, onHide, values);
							}}
						/>
					);
				}}
			</FormDialogButton>
		</>
	);
};
