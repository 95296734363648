import moment from "moment/moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import React, { useContext } from "react";
import { DiscountUsageSearchContext } from "./DiscountUsageSearchContext";

export const DiscountUsageFilter = () => {
	const { filters, setFilters, clearFilters } = useContext(DiscountUsageSearchContext);

	return (
		<div className="flex flex-wrap align-items-center">
			<div className="mr-2">
				<Calendar
					dateFormat={"dd.mm.yy"}
					value={
						filters.filterByUsedAtFrom
							? moment(filters.filterByUsedAtFrom).toDate()
							: undefined
					}
					showTime
					className="mr-2"
					placeholder="Von"
					showButtonBar
					onChange={(e) =>
						setFilters({
							...filters,
							filterByUsedAtFrom: e.value
								? moment(e.value as Date).toISOString()
								: undefined,
						})
					}
				/>
				<Calendar
					dateFormat={"dd.mm.yy"}
					value={
						filters.filterByUsedToFrom
							? moment(filters.filterByUsedToFrom).toDate()
							: undefined
					}
					showTime
					showButtonBar
					placeholder="Bis"
					onChange={(e) =>
						setFilters({
							...filters,
							filterByUsedToFrom: e.value
								? moment(e.value as Date).toISOString()
								: undefined,
						})
					}
				/>
			</div>
			<Button
				className="h-2rem"
				tooltip={"Zurücksetzen"}
				icon="pi pi-times"
				onClick={() => clearFilters()}
			/>
		</div>
	);
};
