import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment contractPartnerTable_ContractPartnerListFragment on Query
	@refetchable(queryName: "contractPartnerTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		name: { type: "String" }
	) {
		Admin {
			Billing {
				ContractPartners(first: $first, after: $after, name: $name)
					@connection(key: "emailTemplatesTable_ContractPartners") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...contractPartnerTable_ContractPartnerFragment
						}
					}
				}
			}
		}
	}
`;

export const CONTRACT_PARTNER_FRAGMENT = graphql`
	fragment contractPartnerTable_ContractPartnerFragment on ContractPartner @inline {
		id
		shareAmountPercentage
		stripeConnectAccountId
		data {
			name
			association
			email
		}
		...editContractPartnerButton_ContractPartnerFragment
	}
`;
