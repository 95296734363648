import graphql from "babel-plugin-relay/macro";

export const CREATE_CONTRACT_PARTNER_MUTATION = graphql`
	mutation createContractPartnerButton_CreateContractPartnerMutation(
		$input: CreateContractPartnerInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createContractPartner(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...contractPartnerTable_ContractPartnerFragment
						}
					}
				}
			}
		}
	}
`;
