import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { ActionCalcTypeColumn_DiscountActionFragment$key } from "@relay/ActionCalcTypeColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {
		calcType {
			... on DiscountActionPercentageCalcType {
				percentage
				kind
			}
			... on DiscountActionEuroCalcType {
				euro
				kind
			}
		}
	}
`;

type OwnProps = {
	discountActionFragmentRef: ActionCalcTypeColumn_DiscountActionFragment$key;
};

export const ActionCalcTypeColumn = ({ discountActionFragmentRef }: OwnProps) => {
	const discountAction = useFragment<ActionCalcTypeColumn_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return match(discountAction.calcType)
		.with({ kind: "euro" }, (calcType) => <div>{calcType.euro}€</div>)
		.with({ kind: "percentage" }, (calcType) => <div>{calcType.percentage}%</div>)
		.otherwise(() => null);
};
