import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ActionTypeColumn_DiscountActionFragment$key } from "@relay/ActionTypeColumn_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment ActionTypeColumn_DiscountActionFragment on DiscountAction {
		discountType {
			... on SystemDiscountType {
				kind
			}
			... on UserDiscountType {
				kind
			}
		}
	}
`;

type OwnProps = {
	discountActionFragmentRef: ActionTypeColumn_DiscountActionFragment$key;
};

export const ActionTypeColumn = ({ discountActionFragmentRef }: OwnProps) => {
	const discountAction = useFragment<ActionTypeColumn_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return <div>{discountAction.discountType.kind}</div>;
};
