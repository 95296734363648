/**
 * @generated SignedSource<<b8d793d3beccd3c5715a6a56e555f7b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editCustomerFeedbackButton_CustomerFeedbackFragment$data = {
  readonly feedback: string;
  readonly id: string;
  readonly name: string;
  readonly position: string;
  readonly " $fragmentType": "editCustomerFeedbackButton_CustomerFeedbackFragment";
};
export type editCustomerFeedbackButton_CustomerFeedbackFragment$key = {
  readonly " $data"?: editCustomerFeedbackButton_CustomerFeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editCustomerFeedbackButton_CustomerFeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editCustomerFeedbackButton_CustomerFeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "CustomerFeedback",
  "abstractKey": null
};

(node as any).hash = "49f8abaabf2e40d5651b06ed6643814a";

export default node;
