import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query featureTogglesScreen_Query {
		...featureTogglesScreen_QueryFragment
	}
`;

export const EDIT_FEATURE_TOGGLE_DATA_MUTATION = graphql`
	mutation featureTogglesScreen_EditFeatureToggleDataMutation(
		$input: EditFeatureToggleDataInput!
	) {
		Admin {
			FeatureToggle {
				editFeatureToggleData(input: $input) {
					featureToggle {
						id
						data {
							isActive
							name
						}
					}
				}
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment featureTogglesScreen_QueryFragment on Query
	@refetchable(queryName: "featureTogglesScreen_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			FeatureToggle {
				FeatureToggles(first: $first, after: $after)
					@connection(key: "featureTogglesScreen_FeatureToggles") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...featureTogglesScreen_FeatureToggleFragment
						}
					}
				}
			}
		}
	}
`;

export const FEATURE_TOGGLE_FRAGMENT = graphql`
	fragment featureTogglesScreen_FeatureToggleFragment on FeatureToggle @inline {
		id
		data {
			isActive
			name
		}
	}
`;
