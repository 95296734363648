import { useLazyLoadQuery } from "react-relay";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type contractPartners_ContractPartnersQuery } from "@relay/contractPartners_ContractPartnersQuery.graphql";
import { BaseScreen } from "@screens/BaseScreen";
import { ContractPartnerContextProvider } from "@screens/contract-partners/contract-partners.provider";
import { ContractPartnerTable } from "@screens/contract-partners/parts/contract-partner-table";
import { CONTRACT_PARTNERS_QUERY } from "./contract-partners.graphql";

export const ContractPartnerScreen = () => {
	const query = useLazyLoadQuery<contractPartners_ContractPartnersQuery>(
		CONTRACT_PARTNERS_QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_ContractPartnerAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<ContractPartnerContextProvider>
				<h1>Vertrags-Partner</h1>
				<ContractPartnerTable contractPartnerFragmentRef={query} />
			</ContractPartnerContextProvider>
		</BaseScreen>
	);
};
