import { graphql } from "babel-plugin-relay/macro";

export const LICENSE_DEFINITION_FRAGMENT = graphql`
	fragment editCoachingLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {
		id
		data {
			... on CoachingLicenseDefinitionData {
				name
				coachingInfo {
					coachingId
				}
			}
		}
	}
`;

export const EDIT_COACHING_LICENSE_DEFINITION_MUTATION = graphql`
	mutation editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation(
		$input: EditCoachingLicenseDefinitionAdminMutationInput!
	) {
		Admin {
			LicenseDefinition {
				editCoachingLicenseDefinitionAdminMutation(input: $input) {
					licenseDefinition {
						...editCoachingLicenseDefinitionButton_LicenseDefinitionFragment
					}
				}
			}
		}
	}
`;
