import { graphql } from "babel-plugin-relay/macro";

export const CREATE_COACHING_OFFER_MUTATION = graphql`
	mutation createCoachingOfferButton_CreateOfferMutation(
		$input: CreateCoachingOfferInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				createCoachingOffer(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							... on UnpublishedGroupCoachingLearnable {
								groupCoaching {
									id
								}
							}
							... on UnpublishedOneToOneCoachingLearnable {
								oneToOneCoaching {
									id
								}
							}
							...offersTable_UnpublishedLearnableFragment
						}
					}
				}
			}
		}
	}
`;
