import { useCheckPermissions } from "@hooks/use-check-permissions";
import { type Permission } from "@relay/permissionBasedNavigation_Query.graphql";

export const useHasPermissions = (
	necessaryPermissions: Permission[] | "onlyOwnerOfRoot",
	acceptRoot?: boolean,
) => {
	const checkPermissions = useCheckPermissions();
	return checkPermissions(necessaryPermissions, acceptRoot);
};
