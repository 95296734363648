import { graphql } from "babel-plugin-relay/macro";

export const TREND_FRAGMENT = graphql`
	fragment sortTrendsModal_TrendFragment on Trend @relay(plural: true) {
		id
		position
		...sortableTrend_TrendFragment
	}
`;

export const SET_TREND_ORDER_MUTATION = graphql`
	mutation sortTrendsModal_SetTrendOrderMutation($input: SetTrendOrderInput!) {
		Admin {
			Trend {
				setTrendOrder(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
