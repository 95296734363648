import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { type NavigateFunction, useNavigate } from "react-router-dom";
import { type showCoachAppointmentsButton_CoachProfileFragment$key } from "@relay/showCoachAppointmentsButton_CoachProfileFragment.graphql";
import { Paths } from "@routes/paths";
import { brandMain100, onBrandMain } from "@themes/colors";
import { COACH_PROFILE_FRAGMENT } from "./show-coach-appointments-button.graphql";
import { type ShowCoachAppointmentsButtonProps } from "./show-coach-appointments-button.types";

export const ShowCoachAppointmentsButton = ({
	coachProfileFragmentRef,
}: ShowCoachAppointmentsButtonProps) => {
	const profile = useFragment<showCoachAppointmentsButton_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const navigate: NavigateFunction = useNavigate();
	const handleOnClick = (): void => {
		profile?.coach?.id && navigate(Paths.coachAppointments.withId(profile.coach.id).edit.path);
	};

	return (
		<Button
			icon={EpicIcons.ARROW_RIGHT}
			tooltip="Alle Termine anzeigen"
			onClick={handleOnClick}
			backgroundColor={brandMain100}
			contentColor={onBrandMain}
		/>
	);
};
