import { graphql } from "babel-plugin-relay/macro";

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment accountSwitchMenu_AuthViewerSchemaFragment on AuthViewerSchema {
		currentUser {
			user {
				name
			}
			accounts {
				id
				name
			}
		}
	}
`;

export const SWITCH_ACCOUNT_MUTATION = graphql`
	mutation accountSwitchMenu_SwitchAccountMutation($input: SwitchAccountInput!) {
		Auth {
			switchAccount(input: $input) {
				jwtTokens {
					accessToken
					refreshToken
				}
			}
		}
	}
`;
