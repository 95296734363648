import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { useFragment, useMutation } from "react-relay";
import { type editWordPackageButton_CreateMutation } from "@relay/editWordPackageButton_CreateMutation.graphql";
import {
	type editWordPackageButton_EditMutation,
	type WordPackageDataInput,
} from "@relay/editWordPackageButton_EditMutation.graphql";
import { type editWordPackageButton_WordPackage$key } from "@relay/editWordPackageButton_WordPackage.graphql";
import {
	CREATE_WORD_PACKAGE_BUTTON,
	EDIT_WORD_PACKAGE_BUTTON,
	EDIT_WORD_PACKAGE_FRAGMENT,
} from "./edit-word-package-button.graphql";
import { type EditWordPackageButtonProps } from "./edit-word-package-button.types";
import { EditWordPackageForm, type EditWordPackageFormState } from "../edit-word-package-form";

export const EditWordPackageButton = ({
	wordPackageFragment,
	connections,
}: EditWordPackageButtonProps) => {
	const wordPackage = useFragment<editWordPackageButton_WordPackage$key>(
		EDIT_WORD_PACKAGE_FRAGMENT,
		wordPackageFragment ?? null,
	);

	const [create, isCreating] = useMutation<editWordPackageButton_CreateMutation>(
		CREATE_WORD_PACKAGE_BUTTON,
	);

	const [edit, isEditing] =
		useMutation<editWordPackageButton_EditMutation>(EDIT_WORD_PACKAGE_BUTTON);

	function handleSubmit(values: EditWordPackageFormState, onHide: () => void) {
		const dataInput: WordPackageDataInput = {
			name: values.name!,
			language: values.language!,
		};

		if (wordPackage) {
			edit({
				variables: {
					input: {
						wordPackageId: wordPackage.id,
						data: dataInput,
					},
				},
				onCompleted: () => {
					onHide();
				},
			});
		} else {
			create({
				variables: {
					input: {
						data: dataInput,
					},
					connections: connections ?? [],
				},
				onCompleted: () => {
					onHide();
				},
			});
		}
	}

	return (
		<FormDialogButton<EditWordPackageFormState>
			buttonContent={{
				label: wordPackage ? undefined : "Wortpaket anlegen",
				icon: wordPackage ? "pi pi-pencil" : undefined,
			}}
			title={wordPackage ? "Wortpaket bearbeiten" : "Wortpaket bearbeiten"}
			disabled={isCreating || isEditing}
		>
			{(ref, onHide) => {
				return (
					<EditWordPackageForm
						initialState={{
							name: wordPackage?.name,
							language: wordPackage?.language,
						}}
						ref={ref}
						onSubmit={(values) => {
							handleSubmit(values, onHide);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
