import { graphql } from "babel-plugin-relay/macro";

export const EDIT_WORD_PACKAGE_FRAGMENT = graphql`
	fragment editWordPackageButton_WordPackage on WordPackage {
		id
		name
		language
		associatedWords {
			id
		}
	}
`;

export const CREATE_WORD_PACKAGE_BUTTON = graphql`
	mutation editWordPackageButton_CreateMutation(
		$input: CreateWordPackageInput!
		$connections: [ID!]!
	) {
		Admin {
			Vocab {
				createWordPackage(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...editWordPackageButton_WordPackage
						}
					}
				}
			}
		}
	}
`;
export const EDIT_WORD_PACKAGE_BUTTON = graphql`
	mutation editWordPackageButton_EditMutation($input: EditWordPackageInput!) {
		Admin {
			Vocab {
				editWordPackage(input: $input) {
					wordPackage {
						...editWordPackageButton_WordPackage
					}
				}
			}
		}
	}
`;
