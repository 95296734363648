/**
 * @generated SignedSource<<3b2d22acec6cece83c2425a9f6bf1ddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type potentialAnalysisEdit_UserInlineFragment$data = {
  readonly extension: {
    readonly avatar?: {
      readonly thumbnail: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "potentialAnalysisEdit_UserInlineFragment";
};
export type potentialAnalysisEdit_UserInlineFragment$key = {
  readonly " $data"?: potentialAnalysisEdit_UserInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_UserInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "potentialAnalysisEdit_UserInlineFragment"
};

(node as any).hash = "a8090bc038a94658cb609dd608af2ea0";

export default node;
