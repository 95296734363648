import { DayOfWeek, type LocalTime } from "@js-joda/core";
import { Dialog } from "@thekeytechnology/epic-ui";
import { useCallback, useMemo } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { type editExceptionModal_AvailabilityScheduleFragment$key } from "@relay/editExceptionModal_AvailabilityScheduleFragment.graphql";
import { type editExceptionModal_CoachProfileFragment$key } from "@relay/editExceptionModal_CoachProfileFragment.graphql";
import { type editExceptionModal_DayAndTimesForCalendarWeekFragment$key } from "@relay/editExceptionModal_DayAndTimesForCalendarWeekFragment.graphql";
import { type editExceptionModal_EditAvailabilityScheduleMutation } from "@relay/editExceptionModal_EditAvailabilityScheduleMutation.graphql";
import { type CalendarWeekAndYear } from "@screens/coach-profiles/parts/edit-availability-schedule-form/edit-availability-schedule-form.types";
import {
	addOffset,
	removeOffset,
	toTimeSlotsMap,
} from "@screens/coach-profiles/parts/edit-availability-schedule-form/edit-availibility-schedule-form.utils";
import {
	TitleWrapper,
	Wrapper,
} from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/create-exception-modal/create-exception-modal.styles";
import { DaySchedule } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/day-schedule";
import {
	AVAILABILITY_SCHEDULE_FRAGMENT,
	COACH_PROFILE_FRAGMENT,
	DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT,
	EDIT_AVAILABILITY_SCHEDULE_MUTATION,
} from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/edit-exception-modal/edit-exception-modal.graphql";
import { type EditExceptionModalProps } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/edit-exception-modal/edit-exception-modal.types";

export const EditExceptionModal = ({
	selectedCalendarWeek,
	onDismiss,
	dayAndTimesForCalendarWeekFragmentRef,
	availabilityScheduleFragmentRef,
	coachProfileFragmentRef,
}: EditExceptionModalProps) => {
	const coachProfile = useFragment<editExceptionModal_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const [update] = useMutation<editExceptionModal_EditAvailabilityScheduleMutation>(
		EDIT_AVAILABILITY_SCHEDULE_MUTATION,
	);
	const availabilitySchedule = useFragment<editExceptionModal_AvailabilityScheduleFragment$key>(
		AVAILABILITY_SCHEDULE_FRAGMENT,
		availabilityScheduleFragmentRef,
	);
	const isVisible = !!selectedCalendarWeek;
	const exceptions = useFragment<editExceptionModal_DayAndTimesForCalendarWeekFragment$key>(
		DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT,
		dayAndTimesForCalendarWeekFragmentRef,
	);
	const filteredExceptions = useMemo(
		() =>
			exceptions.filter(
				(e) =>
					e.calendarWeek === selectedCalendarWeek?.calendarWeek &&
					e.calendarYear === selectedCalendarWeek?.calendarYear,
			),
		[exceptions, selectedCalendarWeek],
	);

	const withOffset = useMemo(
		() => addOffset(filteredExceptions.map((e) => ({ ...e, timeSlots: [...e.timeSlots] }))),
		[filteredExceptions],
	);
	const schedule = useMemo(() => toTimeSlotsMap(withOffset), [withOffset]);

	const handleOnClose = () => {
		onDismiss();
	};

	const createOnChange = useCallback(
		(selectedCalendarWeek?: CalendarWeekAndYear) =>
			(dayOfWeek: DayOfWeek) =>
			(timeSlots?: LocalTime[]) => {
				if (!selectedCalendarWeek) return;

				const scheduleDataInput = {
					blockoutDates: availabilitySchedule.data.blockoutDates,
					daysAndTimes: availabilitySchedule.data.daysAndTimes,
					overrideDaysAndTimesForCalendarWeek: [
						...addOffset(
							[...availabilitySchedule.data.overrideDaysAndTimesForCalendarWeek].map(
								(e) => ({
									...e,
									timeSlots: [...e.timeSlots],
								}),
							),
						),
					],
				};

				const existingOverrideForWeekAndDayIndex =
					scheduleDataInput.overrideDaysAndTimesForCalendarWeek.findIndex(
						(e) =>
							e.calendarWeek === selectedCalendarWeek.calendarWeek &&
							e.calendarYear === selectedCalendarWeek.calendarYear &&
							e.dayOfWeek === dayOfWeek.name(),
					);
				if (timeSlots) {
					if (existingOverrideForWeekAndDayIndex >= 0) {
						const overrides = [
							...scheduleDataInput.overrideDaysAndTimesForCalendarWeek,
						];
						overrides[existingOverrideForWeekAndDayIndex] = {
							calendarWeek: selectedCalendarWeek.calendarWeek,
							calendarYear: selectedCalendarWeek.calendarYear,
							dayOfWeek: dayOfWeek.name(),
							timeSlots: timeSlots?.map((localTime) => localTime.toString()),
						};
						scheduleDataInput.overrideDaysAndTimesForCalendarWeek = overrides;
					} else {
						scheduleDataInput.overrideDaysAndTimesForCalendarWeek.push({
							calendarWeek: selectedCalendarWeek.calendarWeek,
							calendarYear: selectedCalendarWeek.calendarYear,
							dayOfWeek: dayOfWeek.name(),
							timeSlots: timeSlots?.map((localTime) => localTime.toString()),
						});
					}
				} else if (existingOverrideForWeekAndDayIndex >= 0) {
					const overrides = [...scheduleDataInput.overrideDaysAndTimesForCalendarWeek];
					overrides.splice(existingOverrideForWeekAndDayIndex, 1);
					scheduleDataInput.overrideDaysAndTimesForCalendarWeek = overrides;
				}

				update({
					variables: {
						input: {
							scheduleData: {
								...scheduleDataInput,
								overrideDaysAndTimesForCalendarWeek: removeOffset([
									...scheduleDataInput.overrideDaysAndTimesForCalendarWeek.map(
										(e) => ({ ...e, timeSlots: [...e.timeSlots] }),
									),
								]),
							},
							coachId: coachProfile.coach!.id,
							coachAccountId: coachProfile.coachAccountId,
						},
					},
				});
			},
		[availabilitySchedule, coachProfile, exceptions, selectedCalendarWeek],
	);

	const createOnChangeHandler = useCallback(
		(dayOfWeek: DayOfWeek) => {
			if (!selectedCalendarWeek) return () => {};
			return createOnChange(selectedCalendarWeek)(dayOfWeek);
		},
		[selectedCalendarWeek, exceptions, availabilitySchedule],
	);
	return (
		<Dialog visible={isVisible} onHide={handleOnClose} title={"Ausnahme hinzufügen"}>
			<Wrapper>
				<TitleWrapper>
					<div>
						Die wiederkehrende Einstellung wird für die ausgewählte{" "}
						<mark>Woche überschrieben</mark>. Bitte prüfe deine Angaben genau.
					</div>
				</TitleWrapper>
				{DayOfWeek.values().map((day) => (
					<DaySchedule
						key={"edit-exception-modal-" + day.name()}
						dayOfWeek={day}
						timeSlots={schedule[day.name()]}
						onChange={createOnChangeHandler(day)}
					/>
				))}
			</Wrapper>

			<hr />
			<Button variant={ButtonVariant.Default} onClick={handleOnClose} label={"Zurück"} />
		</Dialog>
	);
};
