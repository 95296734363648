/**
 * @generated SignedSource<<3f8769446c875ad038068868313358dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type deleteCourseLearnableButton_UnpublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly root?: {
    readonly id: string;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null | undefined;
  readonly " $fragmentType": "deleteCourseLearnableButton_UnpublishedLearnableFragment";
};
export type deleteCourseLearnableButton_UnpublishedLearnableFragment$key = {
  readonly " $data"?: deleteCourseLearnableButton_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteCourseLearnableButton_UnpublishedLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TreeNode",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "structureDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteCourseLearnableButton_UnpublishedLearnableFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "UnpublishedCourseLearnable",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "UnpublishedMicroLearningLearnable",
      "abstractKey": null
    }
  ],
  "type": "UnpublishedLearnable",
  "abstractKey": "__isUnpublishedLearnable"
};
})();

(node as any).hash = "66f64711f83b5d3b54c650480d463562";

export default node;
