/**
 * @generated SignedSource<<ef91c5ebcc7cefab5cbc1b7451565871>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type customerFeedbackScreen_Query$variables = Record<PropertyKey, never>;
export type customerFeedbackScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"customerFeedbackTable_QueryFragment">;
};
export type customerFeedbackScreen_Query = {
  response: customerFeedbackScreen_Query$data;
  variables: customerFeedbackScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "customerFeedbackScreen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "customerFeedbackTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "customerFeedbackScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerJourneyQueries",
        "kind": "LinkedField",
        "name": "CustomerJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "CustomerFeedbackConnection",
            "kind": "LinkedField",
            "name": "CustomerFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerFeedbackEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerFeedback",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feedback",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": "CustomerFeedback(first:20)"
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "customerFeedbackTable_CustomerFeedback",
            "kind": "LinkedHandle",
            "name": "CustomerFeedback"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97e35307c5c050a070069fb449bb96f7",
    "id": null,
    "metadata": {},
    "name": "customerFeedbackScreen_Query",
    "operationKind": "query",
    "text": "query customerFeedbackScreen_Query {\n  ...customerFeedbackTable_QueryFragment\n}\n\nfragment customerFeedbackActionColumn_CustomerFeedbackFragment on CustomerFeedback {\n  id\n  ...editCustomerFeedbackButton_CustomerFeedbackFragment\n}\n\nfragment customerFeedbackTable_CustomerFeedbackFragment on CustomerFeedback {\n  id\n  name\n  position\n  feedback\n  ...customerFeedbackActionColumn_CustomerFeedbackFragment\n}\n\nfragment customerFeedbackTable_QueryFragment on Query {\n  CustomerJourney {\n    CustomerFeedback(first: 20) {\n      edges {\n        node {\n          ...customerFeedbackTable_CustomerFeedbackFragment\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n}\n\nfragment editCustomerFeedbackButton_CustomerFeedbackFragment on CustomerFeedback {\n  id\n  position\n  feedback\n  name\n}\n"
  }
};
})();

(node as any).hash = "32f5239eb31470a4d89068be4512d293";

export default node;
