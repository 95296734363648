import { ChronoUnit, DayOfWeek, type LocalTime } from "@js-joda/core";
import { InputSwitch } from "@thekeytechnology/framework-react-components";
import { useState } from "react";

import { SCHEDULE_HOURS } from "./day-schedule.consts";

import {
	AvailabilityRow,
	HeaderWrapper,
	SlotsWrapper,
	SwitchWrapper,
	Tag,
} from "./day-schedule.styles";
import { type DayScheduleProps } from "./day-schedule.types";
import { getDayOfWeekName } from "../../edit-availibility-schedule-form.utils";
export const DaySchedule = ({ dayOfWeek, timeSlots, onChange }: DayScheduleProps) => {
	const [dayActive, setDayActive] = useState(!!timeSlots);
	const dayOfWeekName = getDayOfWeekName(dayOfWeek ?? DayOfWeek.MONDAY);

	const handleOnChange = (checked?: boolean) => {
		setDayActive(!!checked);
		onChange?.(checked ? [] : undefined);
	};

	const createOnChangeHandler = (time: LocalTime) => () => {
		if (timeSlots?.find((slot) => time.equals(slot))) {
			onChange?.(timeSlots.filter((slot) => !time.equals(slot)));
		} else {
			onChange?.([...(timeSlots ?? []), time]);
		}
	};

	const renderLabel = (time: LocalTime) => {
		const isActive = !!timeSlots?.find((slot) => time.equals(slot));
		return (
			<Tag key={time.hour()} onClick={createOnChangeHandler(time)} selected={isActive}>
				{time.truncatedTo(ChronoUnit.HOURS).toString()}
			</Tag>
		);
	};

	return (
		<AvailabilityRow>
			<SwitchWrapper>
				<InputSwitch value={dayActive} onValueChange={handleOnChange} />
			</SwitchWrapper>
			<HeaderWrapper>
				<span>{dayOfWeekName}</span>
				{!dayActive && <span>Nicht verfügbar</span>}
			</HeaderWrapper>
			{dayActive && <SlotsWrapper>{SCHEDULE_HOURS.map(renderLabel)}</SlotsWrapper>}
		</AvailabilityRow>
	);
};
