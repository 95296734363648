import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type createDynamicVocabElementButton_CreateDynamicVocabElementMutation } from "@relay/createDynamicVocabElementButton_CreateDynamicVocabElementMutation.graphql";
import { CREATE_DYNAMIC_VOCAB_ELEMENT_MUTATION } from "./create-dynamic-vocab-element-button.graphql";
import { type CreateDynamicVocabElementButtonProps } from "./create-dynamic-vocab-element-button.types";

export const CreateDynamicVocabElementButton = ({
	eLearningContentNodeId,
}: CreateDynamicVocabElementButtonProps) => {
	const [createDynamicVocabElement, isCreatingDynamicVocabElement] =
		useMutation<createDynamicVocabElementButton_CreateDynamicVocabElementMutation>(
			CREATE_DYNAMIC_VOCAB_ELEMENT_MUTATION,
		);
	const handleCreateOnClick = () => {
		createDynamicVocabElement({
			variables: {
				input: {
					title: "Neu",
					eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingDynamicVocabElement}
			tooltip={`Vokabeltrainer-Baustein anlegen`}
			icon={EpicIcons.BOOK}
			onClick={handleCreateOnClick}
		/>
	);
};
