import graphql from "babel-plugin-relay/macro";

export const COACHING_OFFERS_CONNECTION_FRAGMENT = graphql`
	fragment coachingOffersTable_CoachingOfferConnectionFragment on CoachingOfferConnection {
		edges {
			node {
				...coachingOffersTable_CoachingOfferFragment
			}
		}
	}
`;

export const COACHING_OFFER_FRAGMENT = graphql`
	fragment coachingOffersTable_CoachingOfferFragment on CoachingOffer @inline {
		id
		description {
			name
			description
		}
	}
`;
