/**
 * @generated SignedSource<<dc782303a48ba8ce4f821a22f31c1ecf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usersTable_UsersListFragment$data = {
  readonly Admin: {
    readonly Auth: {
      readonly Users: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly activated: boolean;
            readonly email: string;
            readonly groupAssociations: ReadonlyArray<{
              readonly account: {
                readonly name: string;
              } | null | undefined;
              readonly group: {
                readonly id: string;
                readonly name: string;
              } | null | undefined;
            }>;
            readonly id: string;
            readonly name: string;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "usersTable_UsersListFragment";
};
export type usersTable_UsersListFragment$key = {
  readonly " $data"?: usersTable_UsersListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usersTable_UsersListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Auth",
  "Users"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "nameOrEmail"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./UsersTable_Refetch.graphql')
    }
  },
  "name": "usersTable_UsersListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthAdminSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": "Users",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterByNameOrEmail",
                  "variableName": "nameOrEmail"
                }
              ],
              "concreteType": "UserConnection",
              "kind": "LinkedField",
              "name": "__UsersTable_Users_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "activated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserGroupAssociation",
                          "kind": "LinkedField",
                          "name": "groupAssociations",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Account",
                              "kind": "LinkedField",
                              "name": "account",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserInAccountGroup",
                              "kind": "LinkedField",
                              "name": "group",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "00273d2b18ac94e1a67ab62dfdacd96b";

export default node;
