import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import React, { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { ButtonVariant } from "@components/button";
import { type createContractPartnerButton_CreateContractPartnerMutation } from "@relay/createContractPartnerButton_CreateContractPartnerMutation.graphql";
import { ContractPartnerScreenContext } from "@screens/contract-partners/contract-partners.context";
import { ContractPartnerForm } from "@screens/contract-partners/parts/contract-partner-form";
import { type ContractPartnerFormState } from "@screens/contract-partners/parts/contract-partner-form/contract-partner-form.types";
import { CREATE_CONTRACT_PARTNER_MUTATION } from "@screens/contract-partners/parts/create-contract-partner-button/create-contract-partner-button.graphql";
import { ButtonWrapper } from "./create-contract-partner-button.styles";

export const CreateContractPartnerButton = () => {
	const { connectionId } = useContext(ContractPartnerScreenContext);
	const [createContractPartner, isCreatingContractPartner] =
		useMutation<createContractPartnerButton_CreateContractPartnerMutation>(
			CREATE_CONTRACT_PARTNER_MUTATION,
		);

	const handleSubmit = (value: ContractPartnerFormState, onHide: () => void) => {
		createContractPartner({
			variables: {
				input: {
					shareAmountPercentage: value.shareAmountPercentage ?? 0,
					data: {
						name: value.name ?? "",
						email: value.email ?? "",
						association: value.association ?? "",
						street: value.street ?? "",
						houseNumber: value.houseNumber ?? "",
						city: value.city ?? "",
						postalCode: value.postalCode ?? "",
						country: value.country ?? "",
						taxNumber: value.taxNumber ?? "",
						linkToAGB: value.linkToAGB ?? "",
					},
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.success("der Vertrags-Partner wurde erfolgreich angelegt.");
				onHide();
			},
			onError: () => {
				toast.error("der Vertrags-Partner konnte nicht angelegt werden.");
				onHide();
			},
		});
	};

	return (
		<ButtonWrapper>
			<FormDialogButton<ContractPartnerFormState>
				disabled={isCreatingContractPartner}
				buttonIcon={EpicIcons.PLUS}
				buttonVariant={ButtonVariant.Strong}
				dialogTitle={"Vertrags-Partner erstellen"}
			>
				{({ formikRef, onHide }) => (
					<ContractPartnerForm
						initialState={undefined}
						ref={formikRef}
						onSubmit={(values) => {
							handleSubmit(values, onHide);
						}}
					/>
				)}
			</FormDialogButton>
		</ButtonWrapper>
	);
};
