/**
 * @generated SignedSource<<aac38ba5c920495a519706692cef9e1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type customerFeedbackTable_CustomerFeedbackFragment$data = {
  readonly feedback: string;
  readonly id: string;
  readonly name: string;
  readonly position: string;
  readonly " $fragmentSpreads": FragmentRefs<"customerFeedbackActionColumn_CustomerFeedbackFragment">;
  readonly " $fragmentType": "customerFeedbackTable_CustomerFeedbackFragment";
};
export type customerFeedbackTable_CustomerFeedbackFragment$key = {
  readonly " $data"?: customerFeedbackTable_CustomerFeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"customerFeedbackTable_CustomerFeedbackFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "customerFeedbackTable_CustomerFeedbackFragment"
};

(node as any).hash = "04216b105346167e3b63bbf5612b426a";

export default node;
