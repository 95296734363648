import { AcademiesTagsRoutes } from "@screens/academies-tags";
import { AccountsRoutes } from "@screens/accounts";
import { CoachAppointmentsRoutes } from "@screens/coach-appointments-edit";
import { CoachProfilesRoutes } from "@screens/coach-profiles";
import { CoachingsRoutes } from "@screens/coaching-edit";
import { ContractPartnersRoutes } from "@screens/contract-partners";
import { CustomerFeedbackRoutes } from "@screens/customer-feedback";
import { DiscountActionsRoutes } from "@screens/discount-actions";
import { EducationalOfferRoutes } from "@screens/educational-offer";
import { EmailTemplatesRoutes } from "@screens/email-templates";
import { ExternalLicensesRoutes } from "@screens/external-license-edit";
import { ExternalLicensePoolsRoutes } from "@screens/external-license-pools";
import { FilesRoutes } from "@screens/files/files.routes";
import { GenerateCartLinkRoutes } from "@screens/generate-cart-link";
import { InstructorsRoutes } from "@screens/instructors";
import { LicenseDefinitionsRoutes } from "@screens/license-definitions";
import { LicensesRoutes } from "@screens/licenses";
import { LimitedCartDiscountsRoutes } from "@screens/limited-cart-discounts";
import { OffersRoutes } from "@screens/offers";
import { OrdersRoutes } from "@screens/orders";
import { PotentialAnalysisRoutes } from "@screens/potential-analysis/potential-analysis.routes";
import { PotentialAnalysisEditRoutes } from "@screens/potential-analysis-edit/potential-analysis-edit.routes";
import { ProductsRoutes } from "@screens/products";
import { ReportsRoutes } from "@screens/reports/reports.routes";
import { SettingsRoutes } from "@screens/settings";
import { StagingRoutes } from "@screens/staging";
import { TasksRoutes } from "@screens/tasks";
import { TrendsRoutes } from "@screens/trends";
import { UsersRoutes } from "@screens/users";
import { VideoSourcesRoutes } from "@screens/video-sources/video-sources.routes";
import { WordPackagesRoutes } from "@screens/word-packages";

export const AppRoutes = [
	...OffersRoutes,
	...EducationalOfferRoutes,
	...FilesRoutes,
	...WordPackagesRoutes,
	...UsersRoutes,
	...TasksRoutes,
	...StagingRoutes,
	...SettingsRoutes,
	...LicensesRoutes,
	...InstructorsRoutes,
	...EmailTemplatesRoutes,
	...CoachingsRoutes,
	...CoachProfilesRoutes,
	...CoachAppointmentsRoutes,
	...AccountsRoutes,
	...AcademiesTagsRoutes,
	...OrdersRoutes,
	...ProductsRoutes,
	...LimitedCartDiscountsRoutes,
	...GenerateCartLinkRoutes,
	...DiscountActionsRoutes,
	...LicenseDefinitionsRoutes,
	...ContractPartnersRoutes,
	...ExternalLicensePoolsRoutes,
	...ExternalLicensesRoutes,
	...ReportsRoutes,
	...TrendsRoutes,
	...CustomerFeedbackRoutes,
	...SettingsRoutes,
	...PotentialAnalysisRoutes,
	...PotentialAnalysisEditRoutes,
	...VideoSourcesRoutes,
];
