import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { type createConnectAccountButton_CreateConnectAccountMutation } from "@relay/createConnectAccountButton_CreateConnectAccountMutation.graphql";
import { CREATE_CONNECT_ACCOUNT_MUTATION } from "@screens/contract-partners/parts/create-connect-account-button/create-connect-account-button.graphql";
import { type CreateConnectedAccountButtonProps } from "@screens/contract-partners/parts/create-connect-account-button/create-connect-account-button.types";

export const CreateConnectedAccountButton = ({
	contractPartnerId,
}: CreateConnectedAccountButtonProps) => {
	const [createStripeConnectAccount, isCreatingStripeConnectAccount] =
		useMutation<createConnectAccountButton_CreateConnectAccountMutation>(
			CREATE_CONNECT_ACCOUNT_MUTATION,
		);
	const handleOnClick = () => {
		createStripeConnectAccount({
			variables: {
				input: {
					contractPartnerId,
				},
			},
			onCompleted: () => {
				toast.success("Der Stripe-Connect Account wurde erfolgreich angelegt.");
			},
			onError: () => {
				toast.success("Der Stripe-Connect Account konnte nicht angelegt.");
			},
		});
	};

	return (
		<Button
			icon={EpicIcons.CHEVRON_RIGHT}
			variant={ButtonVariant.Strong}
			onClick={handleOnClick}
			disabled={isCreatingStripeConnectAccount}
			loading={isCreatingStripeConnectAccount}
		/>
	);
};
