/**
 * @generated SignedSource<<1239f2cd3cd6420f83bb564ee35e0b8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductPriceColumn_ProductFragment$data = {
  readonly netPrice: any;
  readonly " $fragmentType": "ProductPriceColumn_ProductFragment";
};
export type ProductPriceColumn_ProductFragment$key = {
  readonly " $data"?: ProductPriceColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductPriceColumn_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductPriceColumn_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "0e0d9e10e0e24dc1b407b4fbbd92d223";

export default node;
