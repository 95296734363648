import { readInlineData, usePaginationFragment } from "react-relay";
import {
	type selectContractPartnerField_ContractPartnerFragment$data,
	type selectContractPartnerField_ContractPartnerFragment$key,
} from "@relay/selectContractPartnerField_ContractPartnerFragment.graphql";
import { type selectContractPartnerField_QueryFragment$key } from "@relay/selectContractPartnerField_QueryFragment.graphql";
import { type selectContractPartnerField_Refetch } from "@relay/selectContractPartnerField_Refetch.graphql";
import { CONTRACT_PARTNER_FRAGMENT, QUERY_FRAGMENT } from "./select-contract-partner-field.graphql";
import {
	type SelectContractPartnerFieldProps,
	type SelectContractPartnerFieldState,
} from "./select-contract-partner-field.types";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";

export const SelectContractPartnerField = ({
	queryFragmentRef,
	...props
}: SelectContractPartnerFieldProps) => {
	const {
		data: {
			Admin: {
				Billing: { ContractPartners: contractPartners },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectContractPartnerField_Refetch,
		selectContractPartnerField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ name: search });
	};

	const buildIdCopyOnClickHandler = (id: string) => () => {
		navigator.clipboard.writeText(id);
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectContractPartnerField_ContractPartnerFragment$data>,
	) => {
		props.updateField({
			id: event.value.id,
			name: event.value.data.name,
		});
	};

	const values =
		contractPartners.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectContractPartnerField_ContractPartnerFragment$key>(
					CONTRACT_PARTNER_FRAGMENT,
					e!.node,
				),
			) || [];

	return (
		<SelectComplexDataField<SelectContractPartnerFieldState>
			valueField="name"
			valuePlaceholder="Kein Vertragspartner ausgewählt"
			actionButtonLabel="Vertragspartner auswählen"
			headerLabel="Wähle einen Vertragspartner aus"
			{...props}
		>
			<SearchableTable
				selectionMode="single"
				selection={props.fieldValue}
				dataKey="id"
				value={values}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnSelectionChange}
			>
				{(Column) => (
					<>
						<Column
							header={"ID"}
							body={(contractPartner) => (
								<div
									className="cursor-pointer"
									onClick={buildIdCopyOnClickHandler(contractPartner.id)}
								>
									<b>
										ID<i className="pi pi-copy"></i>
									</b>
								</div>
							)}
						/>
						<Column
							header={"Name"}
							body={(contractPartner) => (
								<div>{contractPartner.data.name ?? "-kein Name-"}</div>
							)}
						/>
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
