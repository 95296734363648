import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { OffersScreen } from "./offers.screen";

export const OffersRoutes: RouteDefinition[] = [
	{
		path: Paths.offers.path,
		element: <OffersScreen />,
		requiredPermissions: [
			"UserInAccountPermission_LearnableAdmin_Read",
			"UserInAccountPermission_CoachingOfferAdmin_Read",
		],
	},
];
