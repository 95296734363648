export const LANGUAGE_OPTIONS = [
	{ label: "Deutsch", value: "Deutsch" },
	{ label: "Englisch", value: "Englisch" },
	{ label: "Spanisch", value: "Spanisch" },
	{ label: "Italienisch", value: "Italienisch" },
	{ label: "Französisch", value: "Französisch" },
	{ label: "Portugiesisch", value: "Portugiesisch" },
	{ label: "Arbabisch", value: "Arbabisch" },
	{ label: "Chinesisch", value: "Chinesisch" },
	{ label: "Weitere", value: "Weitere" },
];

export const EXPERIENCE_BRANCHES_OPTIONS = [
	{ label: "IT und Softwareentwicklung (1412)", value: "IT und Softwareentwicklung (1412)" },
	{ label: "Analyse und Statistik (3)", value: "Analyse und Statistik (3)" },
	{ label: "Produktmanagement (30)", value: "Produktmanagement (30)" },
	{
		label: "Management und Unternehmensentwicklung (548)",
		value: "Management und Unternehmensentwicklung (548)",
	},
	{ label: "Projektmanagement (660)", value: "Projektmanagement (660)" },
	{
		label: "Administration, Sachbearbeitung und Verwaltung (3014)",
		value: "Administration, Sachbearbeitung und Verwaltung (3014)",
	},
	{ label: "Personalwesen und HR (520)", value: "Personalwesen und HR (520)" },
	{ label: "Marketing und Werbung (480)", value: "Marketing und Werbung (480)" },
	{ label: "Grafik, Design (46)", value: "Grafik, Design (46)" },
	{
		label: "Finanzen, Rechnungswesen und Controlling (1000)",
		value: "Finanzen, Rechnungswesen und Controlling (1000)",
	},
	{
		label: "Gesundheit, Medizin, Soziales (265)",
		value: "Gesundheit, Medizin, Soziales (265)",
	},
	{ label: "Beratung / Consulting (228)", value: "Beratung / Consulting (228)" },
	{
		label: "Prozessplanung und Qualitätssicherung (139)",
		value: "Prozessplanung und Qualitätssicherung (139)",
	},
	{ label: "Recht (60)", value: "Recht (60)" },
	{ label: "Sonstige Tätigkeitsfelder (776)", value: "Sonstige Tätigkeitsfelder (776)" },
];
export const EXPERIENCE_BRANCHES_STEPS_OPTIONS = [
	{
		label: "Basiskader",
		value: "Basiskader",
	},
	{
		label: "Mittleres Kader",
		value: "Mittleres Kader",
	},
	{
		label: "Top Kader",
		value: "Top Kader",
	},
];
export const COACHING_STRONG_POINT_TOPICS_OPTIONS = [
	{
		label: "Gesundheitsprävention",
		value: "Gesundheitsprävention",
	},
	{
		label: "Zusammenarbeit",
		value: "Zusammenarbeit",
	},
	{
		label: "Führung",
		value: "Führung",
	},
	{
		label: "Laufbahnberatung",
		value: "Laufbahnberatung",
	},
	{
		label: "Wellbeing, Lifestyle",
		value: "Wellbeing, Lifestyle",
	},
	{
		label: "Weitere",
		value: "Weitere",
	},
];

export const METHODS_OPTIONS = [
	{
		label: "NLP",
		value: "NLP",
	},
	{
		label: "systemisches Coaching",
		value: "systemisches Coaching",
	},
	{
		label: "Kognitive Verhaltenstherapie",
		value: "Kognitive Verhaltenstherapie",
	},
	{
		label: "Weitere",
		value: "Weitere",
	},
];
