import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment selectWordPackageField_QueryFragment on Query
	@argumentDefinitions(
		filterByName: { type: "String" }
		alwaysInclude: { type: "[ID!]" }
		first: { type: "Int" }
		last: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
	)
	@refetchable(queryName: "selectWordPackageField_QueryRefetch") {
		Admin {
			Vocab {
				WordPackages(
					filterByName: $filterByName
					alwaysInclude: $alwaysInclude
					first: $first
					after: $after
					before: $before
					last: $last
				) @connection(key: "selectWordPackagesField_WordPackages") {
					edges {
						node {
							...selectWordPackageField_WordPackageFragment
						}
					}
				}
			}
		}
	}
`;

export const WORD_PACKAGE_FRAGMENT = graphql`
	fragment selectWordPackageField_WordPackageFragment on WordPackage @inline {
		id
		name
	}
`;
