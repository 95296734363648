/**
 * @generated SignedSource<<796679b7a115c9987ca2822795d40816>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment$data = {
  readonly coachAccount: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly replacementCoach: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment";
};
export type editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key = {
  readonly " $data"?: editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "replacementCoach",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "coachAccount",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_PotentialAnalysis",
  "abstractKey": null
};
})();

(node as any).hash = "05132b46e0c10415feeee61fee0a1486";

export default node;
