/**
 * @generated SignedSource<<2a4426d2c8f1f0d700afd5b999c1f42e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Icon = "arrowLoop" | "bridgeOverRiver" | "computerWithHeart" | "dotsConnected" | "flowerInCircle" | "handWithFlower" | "handsWithHeart" | "lightBulb" | "peopleHighFive" | "personWithStars" | "rocket" | "workingDesk";
export type TrendMediaKind = "image" | "video";
export type EditTrendInput = {
  aboutOffersHeadingAndDescription: HeadingAndDescriptionInput;
  clientMutationId?: string | null | undefined;
  coachingHeadingAndDescription: HeadingAndDescriptionInput;
  coachingImageId: string;
  customerData: CustomerDataInput;
  potentialAnalysisHeadingAndDescription: HeadingAndDescriptionInput;
  trendData: TrendDataInput;
  trendId: string;
  trendMedia: TrendMediaInput;
};
export type TrendDataInput = {
  advantages: ReadonlyArray<AdvantageInput>;
  description: string;
  icon: Icon;
  name: string;
  tagIds: ReadonlyArray<string>;
  visible: boolean;
};
export type AdvantageInput = {
  description: string;
  heading: string;
  icon?: Icon | null | undefined;
};
export type TrendMediaInput = {
  image?: ImageTrendMediaInput | null | undefined;
  video?: VideoTrendMediaInput | null | undefined;
};
export type VideoTrendMediaInput = {
  fileId: string;
  trendMediaType: TrendMediaKind;
};
export type ImageTrendMediaInput = {
  fileId: string;
  trendMediaType: TrendMediaKind;
};
export type CustomerDataInput = {
  amountCustomer: string;
  customerFeedbackIds: ReadonlyArray<string>;
};
export type HeadingAndDescriptionInput = {
  description: string;
  heading: string;
};
export type trendForm_EditTrendMutation$variables = {
  input: EditTrendInput;
};
export type trendForm_EditTrendMutation$data = {
  readonly Admin: {
    readonly Trend: {
      readonly editTrend: {
        readonly edge: {
          readonly node: {
            readonly aboutOffersHeadingAndDescription: {
              readonly description: string;
              readonly heading: string;
            };
            readonly coachingHeadingAndDescription: {
              readonly description: string;
              readonly heading: string;
            };
            readonly coachingImage: {
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly customerData: {
              readonly amountCustomer: string;
              readonly customerFeedbacks: ReadonlyArray<{
                readonly id: string;
              }>;
            };
            readonly potentialAnalysisHeadingAndDescription: {
              readonly description: string;
              readonly heading: string;
            };
            readonly trendData: {
              readonly advantages: ReadonlyArray<{
                readonly description: string;
                readonly heading: string;
                readonly icon: Icon | null | undefined;
              }>;
              readonly description: string;
              readonly icon: Icon;
              readonly name: string;
              readonly tags: ReadonlyArray<{
                readonly id: string;
              }>;
              readonly visible: boolean;
            };
            readonly trendMedia: {
              readonly file: {
                readonly id: string;
                readonly name: string;
              } | null | undefined;
              readonly kind: TrendMediaKind;
            };
          };
        };
      } | null | undefined;
    };
  };
};
export type trendForm_EditTrendMutation = {
  response: trendForm_EditTrendMutation$data;
  variables: trendForm_EditTrendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visible",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Advantage",
  "kind": "LinkedField",
  "name": "advantages",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v8/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "coachingImage",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerData",
  "kind": "LinkedField",
  "name": "customerData",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountCustomer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerFeedback",
      "kind": "LinkedField",
      "name": "customerFeedbacks",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  (v3/*: any*/),
  (v8/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "HeadingAndDescription",
  "kind": "LinkedField",
  "name": "aboutOffersHeadingAndDescription",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "HeadingAndDescription",
  "kind": "LinkedField",
  "name": "coachingHeadingAndDescription",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "HeadingAndDescription",
  "kind": "LinkedField",
  "name": "potentialAnalysisHeadingAndDescription",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trendForm_EditTrendMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTrendPayload",
                "kind": "LinkedField",
                "name": "editTrend",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrendEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trend",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TrendData",
                            "kind": "LinkedField",
                            "name": "trendData",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "tags",
                                "plural": true,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "trendMedia",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trendForm_EditTrendMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTrendPayload",
                "kind": "LinkedField",
                "name": "editTrend",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrendEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trend",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TrendData",
                            "kind": "LinkedField",
                            "name": "trendData",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "tags",
                                "plural": true,
                                "selections": [
                                  (v19/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "trendMedia",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ee68d6274093baea2ecff117e4a4a75",
    "id": null,
    "metadata": {},
    "name": "trendForm_EditTrendMutation",
    "operationKind": "mutation",
    "text": "mutation trendForm_EditTrendMutation(\n  $input: EditTrendInput!\n) {\n  Admin {\n    Trend {\n      editTrend(input: $input) {\n        edge {\n          node {\n            trendData {\n              name\n              description\n              icon\n              visible\n              tags {\n                __typename\n                id\n              }\n              advantages {\n                description\n                heading\n                icon\n              }\n            }\n            trendMedia {\n              __typename\n              file {\n                id\n                name\n              }\n              kind\n            }\n            coachingImage {\n              id\n              name\n            }\n            customerData {\n              amountCustomer\n              customerFeedbacks {\n                id\n              }\n            }\n            aboutOffersHeadingAndDescription {\n              description\n              heading\n            }\n            coachingHeadingAndDescription {\n              description\n              heading\n            }\n            potentialAnalysisHeadingAndDescription {\n              description\n              heading\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3831db7e508797534fd8b21176706a17";

export default node;
