import { graphql } from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment editAvailabilityScheduleButton_CoachProfileFragment on CoachProfile {
		id
		coach {
			id
			name
		}
		...editAvailabilityScheduleForm_CoachProfileFragment
	}
`;

export const AVAILABILITY_SCHEDULE_FRAGMENT = graphql`
	fragment editAvailabilityScheduleButton_AvailabilityScheduleFragment on AvailabilitySchedule {
		...editAvailabilityScheduleForm_AvailabilityScheduleFragment
	}
`;
