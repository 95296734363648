import { Color } from "@thekeytechnology/epic-ui";
import { type Recursive, type Token } from "@styled-system/types/composition";
/**
 * @deprecated This color should not be used
 */
export const brandStrong20: Color = new Color("rgb(212, 250, 235)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong40: Color = new Color("rgb(169, 245, 215)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong80: Color = new Color("rgb(82, 234, 175)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong100: Color = new Color("rgb(39, 229, 155)");
/**
 * @deprecated This color should not be used
 */
export const brandStrong120: Color = new Color("rgb(7, 158, 131)");

/**
 * @deprecated This color should not be used
 */
export const brandMain20: Color = new Color("rgb(207, 209, 210)");
/**
 * @deprecated This color should not be used
 */
export const brandMain40: Color = new Color("rgb(159, 163, 166)");
/**
 * @deprecated This color should not be used
 */
export const brandMain80: Color = new Color("rgb(64, 70, 77)");
/**
 * @deprecated This color should not be used
 */
export const brandMain100: Color = new Color("rgb(16, 24, 32)");

/**
 * @deprecated This color should not be used
 */
export const onBrandMain: Color = new Color("rgb(244, 248, 249)");
/**
 * @deprecated This color should not be used
 */
export const onBrandStrong: Color = new Color("rgb(16, 24, 32)");

/**
 * @deprecated This color should not be used
 */
export const shade0: Color = new Color("rgb(255, 255, 255)");
/**
 * @deprecated This color should not be used
 */
export const shade5: Color = new Color("rgb(243, 243, 244)");
/**
 * @deprecated This color should not be used
 */
export const shade10: Color = new Color("rgb(231, 232, 233)");
/**
 * @deprecated This color should not be used
 */
export const shade20: Color = new Color("rgb(207, 209, 210)");
/**
 * @deprecated This color should not be used
 */
export const shade40: Color = new Color("rgb(159, 163, 166)");
/**
 * @deprecated This color should not be used
 */
export const shade60: Color = new Color("rgb(112, 116, 121)");
/**
 * @deprecated This color should not be used
 */
export const shade80: Color = new Color("rgb(64, 70, 77)");
/**
 * @deprecated This color should not be used
 */
export const shade100: Color = new Color("rgb(16, 24, 32)");

/**
 * @deprecated This color should not be used
 */
export const success0: Color = new Color("rgb(237, 249, 238)");
/**
 * @deprecated This color should not be used
 */
export const success20: Color = new Color("rgb(209, 234, 210)");
/**
 * @deprecated This color should not be used
 */
export const success100: Color = new Color("rgb(24, 152, 29)");

/**
 * @deprecated This color should not be used
 */
export const info0: Color = new Color("rgb(238, 246, 254)");
/**
 * @deprecated This color should not be used
 */
export const info20: Color = new Color("rgb(212, 234, 255)");
/**
 * @deprecated This color should not be used
 */
export const info100: Color = new Color("rgb(39, 149, 254)");

/**
 * @deprecated This color should not be used
 */
export const warning0: Color = new Color("rgb(255, 252, 239)");
/**
 * @deprecated This color should not be used
 */
export const warning20: Color = new Color("rgb(255, 246, 214)");
/**
 * @deprecated This color should not be used
 */
export const warning100: Color = new Color("rgb(224, 168, 39)");

/**
 * @deprecated This color should not be used
 */
export const error0: Color = new Color("rgb(255, 242, 237)");
/**
 * @deprecated This color should not be used
 */
export const error20: Color = new Color("rgb(251, 221, 210)");
/**
 * @deprecated This color should not be used
 */
export const error100: Color = new Color("rgb(234, 86, 28)");

/**
 * @deprecated This color should not be used
 */
export const transparent: Color = new Color("rgba(0, 0, 0, 0)", 0);

export const colors: Recursive<Token<string>> = {
	primary: {
		20: { value: "rgb(207, 209, 210)" },
		40: { value: "rgb(159, 163, 166)" },
		80: { value: "rgb(64, 70, 77)" },
		100: { value: "rgb(16, 24, 32)" },
	},
	secondary: {
		20: { value: "rgb(212, 250, 235)" },
		40: { value: "rgb(169, 245, 215)" },
		80: { value: "rgb(82, 234, 175)" },
		100: { value: "rgb(39, 229, 155)" },
		120: { value: "rgb(7, 158, 131)" },
	},
	onPrimary: { value: "rgb(244, 248, 249)" },
	onSecondary: { value: "rgb(16, 24, 32)" },
	shade: {
		0: { value: "rgb(255, 255, 255)" },
		5: { value: "rgb(243, 243, 244)" },
		10: { value: "rgb(231, 232, 233)" },
		20: { value: "rgb(207, 209, 210)" },
		40: { value: "rgb(159, 163, 166)" },
		60: { value: "rgb(112, 116, 121)" },
		80: { value: "rgb(64, 70, 77)" },
		100: { value: "rgb(16, 24, 32)" },
	},
	success: {
		0: { value: "rgb(237, 249, 238)" },
		20: { value: "rgb(209, 234, 210)" },
		100: { value: "rgb(24, 152, 29)" },
	},
	info: {
		0: { value: "rgb(238, 246, 254)" },
		20: { value: "rgb(212, 234, 255)" },
		100: { value: "rgb(39, 149, 254)" },
	},
	warning: {
		0: { value: "rgb(255, 252, 239)" },
		20: { value: "rgb(255, 246, 214)" },
		100: { value: "rgb(224, 168, 39)" },
	},
	error: {
		0: { value: "rgb(255, 242, 237)" },
		20: { value: "rgb(251, 221, 210)" },
		100: { value: "rgb(234, 86, 28)" },
	},
	transaprent: { value: "rgba(0, 0, 0, 0)" },
};
