import { classNames } from "primereact/utils";
import { useCallback } from "react";
import { GrowDiv, Label, StyledInputMask, Wrapper } from "./input-mask.styles";
import { type InputMaskProps } from "./input-mask.types";

export const InputMask = <State,>({
	formikConfig,
	name,
	label,
	onChange,
	placeholder,
	required,
	disabled,
	type = "default",
	keyFilter,
	mask,
}: InputMaskProps<State>) => {
	const error = formikConfig.errors[name];
	const hasError = error && formikConfig.touched[name];

	const value = formikConfig.values[name] as unknown as string;

	const checkIfInputFieldChanged = (e: any) => {
		if (e.target.value !== value) {
			if (onChange) {
				onChange();
			}
		}
	};

	const handleFieldUpdate = useCallback(
		(e: string) => {
			formikConfig.setFieldValue(name, e);
			formikConfig.setFieldTouched(name, true, false);
		},
		[name, formikConfig.setFieldTouched, formikConfig.setFieldValue],
	);

	return (
		<Wrapper>
			<GrowDiv>
				{label ? (
					<Label htmlFor={name} className={classNames({ "p-error": hasError })}>
						{label} {required ? "*" : ""}
					</Label>
				) : null}

				<StyledInputMask
					id={name}
					name={name}
					type={type}
					placeholder={placeholder}
					disabled={disabled}
					error={hasError as boolean}
					mask={mask}
					keyfilter={keyFilter}
					value={value}
					onChange={(e) => {
						handleFieldUpdate(e.value ?? "");
						checkIfInputFieldChanged(e);
					}}
					className={classNames({ "p-invalid": hasError })}
				/>

				{hasError ? <div className="p-error text-xs mt-1">{error as any}</div> : null}
			</GrowDiv>
		</Wrapper>
	);
};
