/**
 * @generated SignedSource<<5f7f22f046729a8f47a20e7e6f4675e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type trendForm_Query$variables = {
  id: string;
  skip: boolean;
};
export type trendForm_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"trendForm_AcademiesTagFragment" | "trendForm_CustomerFeedbackFragment" | "trendForm_TrendFragment">;
};
export type trendForm_Query = {
  response: trendForm_Query$data;
  variables: trendForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trendForm_Query",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "skip",
            "variableName": "skip"
          }
        ],
        "kind": "FragmentSpread",
        "name": "trendForm_TrendFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "trendForm_AcademiesTagFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "trendForm_CustomerFeedbackFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trendForm_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrendData",
                    "kind": "LinkedField",
                    "name": "trendData",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "visible",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Advantage",
                        "kind": "LinkedField",
                        "name": "advantages",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "trendMedia",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "coachingImage",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerData",
                    "kind": "LinkedField",
                    "name": "customerData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amountCustomer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerFeedback",
                        "kind": "LinkedField",
                        "name": "customerFeedbacks",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeadingAndDescription",
                    "kind": "LinkedField",
                    "name": "aboutOffersHeadingAndDescription",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeadingAndDescription",
                    "kind": "LinkedField",
                    "name": "coachingHeadingAndDescription",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeadingAndDescription",
                    "kind": "LinkedField",
                    "name": "potentialAnalysisHeadingAndDescription",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "type": "Trend",
                "abstractKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AcademiesRootTagsConnection",
                "kind": "LinkedField",
                "name": "RootTags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcademiesRootTagsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcademiesRootTag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AcademiesTagData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "name",
                  "isClickable",
                  "isTopic"
                ],
                "handle": "connection",
                "key": "trendForm_RootTags",
                "kind": "LinkedHandle",
                "name": "RootTags"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerJourneyQueries",
        "kind": "LinkedField",
        "name": "CustomerJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerFeedbackConnection",
            "kind": "LinkedField",
            "name": "CustomerFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerFeedbackEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerFeedback",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "feedback",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f396c104796f74b12a92ecc5ca1612cf",
    "id": null,
    "metadata": {},
    "name": "trendForm_Query",
    "operationKind": "query",
    "text": "query trendForm_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  ...trendForm_TrendFragment_3hG2L4\n  ...trendForm_AcademiesTagFragment\n  ...trendForm_CustomerFeedbackFragment\n}\n\nfragment trendForm_AcademiesTagFragment on Query {\n  Admin {\n    AcademiesTag {\n      RootTags {\n        edges {\n          node {\n            id\n            data {\n              name\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n\nfragment trendForm_CustomerFeedbackFragment on Query {\n  CustomerJourney {\n    CustomerFeedback {\n      edges {\n        node {\n          feedback\n          id\n          name\n          position\n        }\n      }\n    }\n  }\n}\n\nfragment trendForm_TrendFragment_3hG2L4 on Query {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Trend {\n      trendData {\n        name\n        description\n        icon\n        visible\n        tags {\n          __typename\n          id\n        }\n        advantages {\n          description\n          heading\n          icon\n        }\n      }\n      trendMedia {\n        __typename\n        file {\n          id\n          name\n          fileType\n        }\n        kind\n      }\n      coachingImage {\n        id\n        name\n      }\n      customerData {\n        amountCustomer\n        customerFeedbacks {\n          id\n        }\n      }\n      aboutOffersHeadingAndDescription {\n        description\n        heading\n      }\n      coachingHeadingAndDescription {\n        description\n        heading\n      }\n      potentialAnalysisHeadingAndDescription {\n        description\n        heading\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c0cabc0bce77e45927ee940b12001ee0";

export default node;
