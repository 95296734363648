import styled from "styled-components";

export const Wrapper = styled.div<{ isDragging?: boolean }>`
	opacity: 1;
	position: relative;
	z-index: 1;

	${(props) => {
		if (props.isDragging) {
			return `
        opacity: 0.5;
      `;
		}
	}}
`;

export const TrendItem = styled.div<{ isDragging?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.375rem 0.25rem;
	background-color: #ffffff;
	border-radius: 0.1875rem;

	&:hover {
		background-color: #e9ecef;
	}

	${(props) => {
		if (props.isDragging) {
			return `    
        position: relative;
        padding: 0;
        height: 0.5rem;
        border-color: #2196f3;
        background-color: #56a1f8;
				
				&:hover {
					background-color: #56a1f8;
				}


        &:before {
					position: absolute;
					left: -0.5rem;
					top: -0.25rem;
					display: block;
					content: "";
					width: 0.75rem;
					height: 0.75rem;
					border-radius: 50%;
					border: 1px solid #2196f3;
					background-color: #ffffff;
				}

				> * {
					/* Items are hidden using height and opacity to retain focus */
					opacity: 0;
					height: 0;

          box-shadow: none;
          background-color: transparent;
				}
    `;
		}
	}}
`;
