import { graphql } from "babel-plugin-relay/macro";

export const DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT = graphql`
	fragment editExceptionModal_DayAndTimesForCalendarWeekFragment on DayAndTimesForCalendarWeek
	@relay(plural: true) {
		dayOfWeek
		timeSlots
		calendarYear
		calendarWeek
	}
`;
export const AVAILABILITY_SCHEDULE_FRAGMENT = graphql`
	fragment editExceptionModal_AvailabilityScheduleFragment on AvailabilitySchedule {
		data {
			overrideDaysAndTimesForCalendarWeek {
				calendarYear
				calendarWeek
				timeSlots
				dayOfWeek
			}
			blockoutDates {
				from
				to
			}
			daysAndTimes {
				dayOfWeek
				timeSlots
			}
		}
	}
`;
export const EDIT_AVAILABILITY_SCHEDULE_MUTATION = graphql`
	mutation editExceptionModal_EditAvailabilityScheduleMutation(
		$input: EditAvailabilityScheduleInput!
	) {
		Admin {
			Coaching {
				editAvailabilitySchedule(input: $input) {
					schedule {
						id
						...editAvailabilityScheduleForm_AvailabilityScheduleFragment
						...editExceptionModal_AvailabilityScheduleFragment
					}
				}
			}
		}
	}
`;

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment editExceptionModal_CoachProfileFragment on CoachProfile {
		coach {
			id
		}
		coachAccountId
	}
`;
