/**
 * @generated SignedSource<<5bbbe12fcd171ac439ee49a1ec44f6e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type coachingOffersTable_CoachingOfferFragment$data = {
  readonly description: {
    readonly description: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "coachingOffersTable_CoachingOfferFragment";
};
export type coachingOffersTable_CoachingOfferFragment$key = {
  readonly " $data"?: coachingOffersTable_CoachingOfferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachingOffersTable_CoachingOfferFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "coachingOffersTable_CoachingOfferFragment"
};

(node as any).hash = "08ad9a41c09185ec24e992d765314233";

export default node;
