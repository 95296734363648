/**
 * @generated SignedSource<<f7542187f6fc8ef2f85fbaa118ed3b7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAvgsAccountField_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectAvgsAccountField_AccountFragment";
};
export type selectAvgsAccountField_AccountFragment$key = {
  readonly " $data"?: selectAvgsAccountField_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAvgsAccountField_AccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectAvgsAccountField_AccountFragment"
};

(node as any).hash = "455f616a499de4d46692a26351f995a8";

export default node;
