import { AdminIcons } from "@components/icon/icon.types";
import { type IconProps } from "./icon.types";

export const trendIcons: IconProps[] = [
	{ label: "dotsConnected", value: "dotsConnected", icon: AdminIcons.DotsConnected },
	{ label: "arrowLoop", value: "arrowLoop", icon: AdminIcons.ArrowLoop },
	{ label: "personWithStars", value: "personWithStars", icon: AdminIcons.PersonWithStars },
	{ label: "computerWithHeart", value: "computerWithHeart", icon: AdminIcons.ComputerWithHeart },
	{ label: "bridgeOverRiver", value: "bridgeOverRiver", icon: AdminIcons.BridgeOverRiver },
	{ label: "peopleHighFive", value: "peopleHighFive", icon: AdminIcons.PeopleHighFive },
	{ label: "handWithFlower", value: "handWithFlower", icon: AdminIcons.HandWithFlower },
	{ label: "handsWithHeart", value: "handsWithHeart", icon: AdminIcons.HandsWithHeart },
	{ label: "flowerInCircle", value: "flowerInCircle", icon: AdminIcons.FlowerInCircle },
	{ label: "lightBulb", value: "lightBulb", icon: AdminIcons.LightBulb },
];

export const advantageIcons: IconProps[] = [
	...trendIcons,
	{ label: "workingDesk", value: "workingDesk", icon: AdminIcons.WorkingDesk },
	{ label: "rocket", value: "rocket", icon: AdminIcons.Rocket },
];
