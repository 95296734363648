import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { OrderList } from "primereact/orderlist";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { limitedCartDiscountForm_EditLimitedCartDiscountMutation } from "@relay/limitedCartDiscountForm_EditLimitedCartDiscountMutation.graphql";
import { limitedCartDiscountForm_EditProductSelectionConditionMutation } from "@relay/limitedCartDiscountForm_EditProductSelectionConditionMutation.graphql";
import { limitedCartDiscountForm_LimitedCartDiscountFragment$key } from "@relay/limitedCartDiscountForm_LimitedCartDiscountFragment.graphql";
import { limitedCartDiscountForm_ProductSelectionConditionFragment$data } from "@relay/limitedCartDiscountForm_ProductSelectionConditionFragment.graphql";
import { Paths } from "@routes/paths";
import {
	EDIT_LIMITED_CART_DISCOUNT_MUTATION,
	EDIT_PRODUCT_SELECTION_CONDITION_MUTATION,
	LIMITED_CART_DISCOUNT_FRAGMENT,
} from "./limited-cart-discount-form.graphql";
import {
	LimitedCartDiscountFormProps,
	LimitedCartDiscountFormState,
} from "./limited-cart-discount-form.interface";
import {
	DefaultIntegerFieldComponent,
	DefaultTextFieldComponent,
} from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { ProductSelectionForm } from "../../../../features/products/product-selection-form";
import { ProductSelection } from "../../../../features/products/product-selection-form/product-selection-form.interface";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";
import { AddProductSelectionConditionButton } from "../add-product-selection-condition-button";
import { DeleteConditionButton } from "../delete-condition-button/delete-condition-button.component";

export const LimitedCartDiscountForm = ({
	limitedCartDiscountFragmentRef,
}: LimitedCartDiscountFormProps) => {
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const [currentlyEditingCondition, setCurrentlyEditingCondition] =
		useState<limitedCartDiscountForm_ProductSelectionConditionFragment$data>();

	const limitedCartDiscount =
		useFragment<limitedCartDiscountForm_LimitedCartDiscountFragment$key>(
			LIMITED_CART_DISCOUNT_FRAGMENT,
			limitedCartDiscountFragmentRef,
		);

	const [editLimitedCartDiscount, isEditingLimitedCartDiscount] =
		useMutation<limitedCartDiscountForm_EditLimitedCartDiscountMutation>(
			EDIT_LIMITED_CART_DISCOUNT_MUTATION,
		);

	const [editProductSelectionCondition, isEditingProductSelectionCondition] =
		useMutation<limitedCartDiscountForm_EditProductSelectionConditionMutation>(
			EDIT_PRODUCT_SELECTION_CONDITION_MUTATION,
		);

	const formId = "EditLimitedCartDiscountForm";
	const formik = useFormik<LimitedCartDiscountFormState>({
		initialValues: {
			title: limitedCartDiscount.title,
			percentageValue: limitedCartDiscount.productSelectionConfig.value || 0,
			conditions: limitedCartDiscount.conditions,
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Titel wird benötigt"),
			percentageValue: Yup.number().max(100).min(1),
		}),
		onSubmit: (values, { setSubmitting }) => {
			editLimitedCartDiscount({
				variables: {
					input: {
						limitedCartDiscountId: limitedCartDiscount.id,
						title: values.title,
						percentageValue: values.percentageValue,
					},
				},
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	const handleOnSubmit = (values: ProductSelection) => {
		if (currentlyEditingCondition && values.product.id) {
			editProductSelectionCondition({
				variables: {
					input: {
						limitedCartDiscountId: limitedCartDiscount.id,
						conditionId: currentlyEditingCondition.id,
						productId: values.product.id,
						amount: values.amount,
					},
				},
			});
		}
	};

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<LimitedCartDiscountFormState, string>
					name={"title"}
					label={"Titel"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<LimitedCartDiscountFormState, number>
					name={"percentageValue"}
					label={"Rabatt in %"}
					component={DefaultIntegerFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<LimitedCartDiscountFormState, any>
					name={"conditions"}
					label={"Bedingungen"}
					component={({ fieldValue }) => (
						<>
							<div className="p-buttonset mb-3 mt-1 w-3rem">
								<AddProductSelectionConditionButton
									limitedCartDiscountId={limitedCartDiscount.id}
								/>
							</div>
							<OrderList
								value={fieldValue}
								itemTemplate={(item) => {
									return (
										<div className="flex align-items-center">
											<div>{item.product?.title}</div>
											<div className="ml-2 mr-2">x</div>
											<div>{item.amount}</div>
											<DeleteConditionButton
												limitedCartDiscountId={limitedCartDiscount.id}
												conditionId={item.id}
											/>
											<Button
												className="ml-2"
												type="button"
												icon="pi pi-pencil"
												onClick={() => {
													setCurrentlyEditingCondition(item);
												}}
											/>
										</div>
									);
								}}
							/>
						</>
					)}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				{currentlyEditingCondition && (
					<Dialog
						header={() => <div>Produkt Bedingung</div>}
						visible={true}
						onHide={() => setCurrentlyEditingCondition(undefined)}
					>
						<ProductSelectionForm
							onSubmit={handleOnSubmit}
							isLoading={isEditingProductSelectionCondition}
							onBack={() => setCurrentlyEditingCondition(undefined)}
							initialValues={{
								product: {
									id: currentlyEditingCondition.productId,
									title: currentlyEditingCondition.product?.title,
								},
								amount: currentlyEditingCondition.amount,
							}}
						/>
					</Dialog>
				)}
				<Button
					disabled={isEditingLimitedCartDiscount}
					type="submit"
					label="Speichern"
					className="mt-2"
				/>
				<Button
					disabled={isEditingLimitedCartDiscount}
					label="Zurück"
					type="button"
					onClick={() => navigate(Paths.cartDiscounts.path)}
					className="mt-2"
				/>
			</form>
		</>
	);
};
