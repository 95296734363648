import { graphql } from "babel-plugin-relay/macro";

export const COACHING_OFFER_FRAGMENT = graphql`
	fragment visibilityCoachingOfferConfigEditor_CoachingOfferFragment on CoachingOffer {
		id
		visibilityConfigs {
			id
			configType
		}
	}
`;

export const ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityCoachingOfferConfigEditor_AddHideVisibilityConfigMutation(
		$input: AddHideVisibilityCoachingConfigInput!
	) {
		Admin {
			Coaching {
				addHideVisibilityCoachingConfig(input: $input) {
					coachingOffer {
						...visibilityCoachingOfferConfigEditor_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_BUSINESS_ACCOUNT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityCoachingOfferConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation(
		$input: AddOnlyBusinessAccountVisibilityCoachingConfigInput!
	) {
		Admin {
			Coaching {
				addOnlyBusinessAccountVisibilityCoachingConfig(input: $input) {
					coachingOffer {
						...visibilityCoachingOfferConfigEditor_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_WITH_LICENSE_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityCoachingOfferConfigEditor_AddOnlyWithLicenseVisibilityConfigMutation(
		$input: AddOnlyWithLicenseVisibilityCoachingConfigInput!
	) {
		Admin {
			Coaching {
				addOnlyWithLicenseVisibilityCoachingConfig(input: $input) {
					coachingOffer {
						...visibilityCoachingOfferConfigEditor_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const ADD_ONLY_AZAV_ACCOUNT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation(
		$input: AddOnlyAZAVAccountVisibilityCoachingConfigInput!
	) {
		Admin {
			Coaching {
				addOnlyAZAVAccountVisibilityCoachingConfig(input: $input) {
					coachingOffer {
						...visibilityCoachingOfferConfigEditor_CoachingOfferFragment
					}
				}
			}
		}
	}
`;

export const REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation visibilityCoachingOfferConfigEditor_RemoveVisibilityConfigMutation(
		$input: RemoveVisibilityCoachingConfigInput!
	) {
		Admin {
			Coaching {
				removeVisibilityCoachingConfig(input: $input) {
					coachingOffer {
						...visibilityCoachingOfferConfigEditor_CoachingOfferFragment
					}
				}
			}
		}
	}
`;
