import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query transformToAvgsAccountButton_Query($id: ID!) {
		Admin {
			AccountBaseData {
				AccountBaseData(filterByAccountId: $id) {
					__typename
				}
			}
		}
	}
`;

export const ACCOUNT_FRAGMENT = graphql`
	fragment transformToAvgsAccountButton_AccountFragment on Account {
		groupAssociations {
			group {
				id
				name
			}
		}
	}
`;

export const TRANSFORM_PRIVATE_ACCOUNT_TO_AVGS_ACCOUNT_MUTATION = graphql`
	mutation transformToAvgsAccountButton_TransformPrivateAccountToAvgsAccountMutation(
		$input: TransformPrivateAccountToAVGSAccountInput!
	) {
		Admin {
			AvgsPotentialAnalysis {
				transformPrivateAccountToAVGSAccount(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const TRANSFORM_BUSINESS_ACCOUNT_TO_AVGS_ACCOUNT_MUTATION = graphql`
	mutation transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation(
		$input: TransformBusinessAccountToAVGSAccountInput!
	) {
		Admin {
			AvgsPotentialAnalysis {
				transformBusinessAccountToAVGSAccount(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
