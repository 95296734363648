import { Button, EpicIcons } from "@thekeytechnology/epic-ui";

import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { type createWordProficienciesRewardButton_AddWordProficienciesRewardMutation } from "@relay/createWordProficienciesRewardButton_AddWordProficienciesRewardMutation.graphql";
import { type createWordProficienciesRewardButton_TreeNodeFragment$key } from "@relay/createWordProficienciesRewardButton_TreeNodeFragment.graphql";
import {
	ADD_WORD_PROFICIENCIES_REWARD_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./create-word-proficiencies-reward-button.graphql";
import { type CreateWordProficienciesRewardButtonProps } from "./create-word-proficiencies-reward-button.types";

export const CreateWordProficienciesRewardButton = ({
	treeNodeFragmentRef,
}: CreateWordProficienciesRewardButtonProps) => {
	const node = useFragment<createWordProficienciesRewardButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [addWordProficienciesReward, isAddingWordProficienciesReward] =
		useMutation<createWordProficienciesRewardButton_AddWordProficienciesRewardMutation>(
			ADD_WORD_PROFICIENCIES_REWARD_MUTATION,
		);

	const handleOnClick = useCallback(() => {
		addWordProficienciesReward({
			variables: {
				input: {
					contentId: node.id,
				},
			},
		});
	}, [node, addWordProficienciesReward]);

	return (
		<Button
			disabled={isAddingWordProficienciesReward}
			tooltip={"Wortfertigkeiten verknüpfen"}
			icon={EpicIcons.BOLT}
			onClick={handleOnClick}
		/>
	);
};
