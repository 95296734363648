/**
 * @generated SignedSource<<1b9a559c6b84123bbf83548179160592>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMicroLearningLicenseDefinitionAdminMutationInput = {
  clientMutationId?: string | null | undefined;
  data: MicroLearningLicenseDefinitionDataInput;
  licenseDefinitionId: string;
};
export type MicroLearningLicenseDefinitionDataInput = {
  name: string;
};
export type editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation$variables = {
  input: EditMicroLearningLicenseDefinitionAdminMutationInput;
};
export type editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation$data = {
  readonly Admin: {
    readonly LicenseDefinition: {
      readonly editMicroLicenseDefinitionAdminMutation: {
        readonly licenseDefinition: {
          readonly " $fragmentSpreads": FragmentRefs<"editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation = {
  response: editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation$data;
  variables: editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMicroLearningLicenseDefinitionAdminMutationPayload",
                "kind": "LinkedField",
                "name": "editMicroLicenseDefinitionAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinition",
                    "kind": "LinkedField",
                    "name": "licenseDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditMicroLearningLicenseDefinitionAdminMutationPayload",
                "kind": "LinkedField",
                "name": "editMicroLicenseDefinitionAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinition",
                    "kind": "LinkedField",
                    "name": "licenseDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "type": "MicroLearningLicenseDefinitionData",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5f96579465768ba64037650b5526eed",
    "id": null,
    "metadata": {},
    "name": "editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation editMicroLearningLicenseDefinitionButton_editMicroLearningLicenseDefinitionMutation(\n  $input: EditMicroLearningLicenseDefinitionAdminMutationInput!\n) {\n  Admin {\n    LicenseDefinition {\n      editMicroLicenseDefinitionAdminMutation(input: $input) {\n        licenseDefinition {\n          ...editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    ... on MicroLearningLicenseDefinitionData {\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1451f573de5698ee70e84bdabe73eba";

export default node;
