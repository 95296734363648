/**
 * @generated SignedSource<<31e2d0ded6b690b814015102a68ee25f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveParticipantAdminInput = {
  availabilityId: string;
  clientMutationId?: string | null | undefined;
  participantId: string;
};
export type editParticipantsTable_RemoveParticipantMutation$variables = {
  input: RemoveParticipantAdminInput;
};
export type editParticipantsTable_RemoveParticipantMutation$data = {
  readonly Admin: {
    readonly Coaching: {
      readonly removeParticipant: {
        readonly availability: {
          readonly " $fragmentSpreads": FragmentRefs<"editParticipantsTable_AvailabilityFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editParticipantsTable_RemoveParticipantMutation = {
  response: editParticipantsTable_RemoveParticipantMutation$data;
  variables: editParticipantsTable_RemoveParticipantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "participationStatus",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserGroupAssociation",
        "kind": "LinkedField",
        "name": "groupAssociations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editParticipantsTable_RemoveParticipantMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveParticipantAdminPayload",
                "kind": "LinkedField",
                "name": "removeParticipant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Availability",
                    "kind": "LinkedField",
                    "name": "availability",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editParticipantsTable_AvailabilityFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editParticipantsTable_RemoveParticipantMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveParticipantAdminPayload",
                "kind": "LinkedField",
                "name": "removeParticipant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Availability",
                    "kind": "LinkedField",
                    "name": "availability",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "booking",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Participant",
                                "kind": "LinkedField",
                                "name": "participant",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "BookedOneToOne",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CoachingOffer",
                                "kind": "LinkedField",
                                "name": "offer",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "setting",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "maxParticipants",
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "GroupCoachingSetting",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Participant",
                                "kind": "LinkedField",
                                "name": "participants",
                                "plural": true,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "BookedForGroup",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AvailabilityData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDateTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endDateTime",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "347895db70e5e09d013168c82cffc068",
    "id": null,
    "metadata": {},
    "name": "editParticipantsTable_RemoveParticipantMutation",
    "operationKind": "mutation",
    "text": "mutation editParticipantsTable_RemoveParticipantMutation(\n  $input: RemoveParticipantAdminInput!\n) {\n  Admin {\n    Coaching {\n      removeParticipant(input: $input) {\n        availability {\n          ...editParticipantsTable_AvailabilityFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editParticipantsTable_AvailabilityFragment on Availability {\n  id\n  booking {\n    __typename\n    kind\n    ... on BookedOneToOne {\n      participant {\n        ...editParticipantsTable_ParticipantFragment\n      }\n    }\n    ... on BookedForGroup {\n      offer {\n        setting {\n          __typename\n          ... on GroupCoachingSetting {\n            maxParticipants\n          }\n        }\n        id\n      }\n      participants {\n        ...editParticipantsTable_ParticipantFragment\n      }\n    }\n  }\n  data {\n    startDateTime\n    endDateTime\n  }\n}\n\nfragment editParticipantsTable_ParticipantFragment on Participant {\n  participationStatus\n  user {\n    groupAssociations {\n      account {\n        id\n      }\n    }\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0e9858e0160b9fa01dd44f8e278b426";

export default node;
