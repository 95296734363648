/**
 * @generated SignedSource<<1a402029e914d06860e2458368e6f75c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment" | "DownloadUsageCsvButton_SearchDiscountUsageResultFragment">;
  readonly " $fragmentType": "DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment";
};
export type DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key = {
  readonly " $data"?: DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DownloadUsageCsvButton_SearchDiscountUsageResultFragment"
    }
  ],
  "type": "SearchDiscountCodeUsagesResult",
  "abstractKey": null
};

(node as any).hash = "b9bae434359886f486edcdc9c04fdff4";

export default node;
