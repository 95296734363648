import { Tooltip } from "@thekeytechnology/epic-ui";
import { Button, Rating } from "@thekeytechnology/framework-react-components";
import React, { useContext, useEffect } from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { type coachProfilesTable_AvailabilityScheduleFragment$key } from "@relay/coachProfilesTable_AvailabilityScheduleFragment.graphql";
import {
	type coachProfilesTable_CoachProfileFragment$data,
	type coachProfilesTable_CoachProfileFragment$key,
} from "@relay/coachProfilesTable_CoachProfileFragment.graphql";
import { type coachProfilesTable_QueryFragment$key } from "@relay/coachProfilesTable_QueryFragment.graphql";
import { type CoachProfilesTable_QueryRefetch } from "@relay/CoachProfilesTable_QueryRefetch.graphql";
import {
	AVAILABILITY_SCHEDULE_FRAGMENT,
	COACH_PROFILE_FRAGMENT,
	QUERY_FRAGMENT,
} from "./coach-profiles-table.graphql";
import {
	ActionsWrapper,
	AssociatedCoachingsWrapper,
	PaginationWrapper,
} from "./coach-profiles-table.styles";
import { type CoachProfilesTableProps } from "./coach-profiles-table.types";
import { DataTable } from "../../../../components/data-table";
import { CoachProfilesScreenContext } from "../../coach-profiles.context";
import { DeleteCoachProfileButton } from "../delete-coach-profile-button";
import { EditAvailabilitySchedulesButton } from "../edit-availability-schedule-button/edit-availability-schedule-button.component";
import { EditBaseDataButton } from "../edit-base-data-button";
import { EditBusinessDataButton } from "../edit-business-data-button";
import { EditProfileDataButton } from "../edit-profile-data-button";
import { ShowCoachAppointmentsButton } from "../show-coach-appointments-button/show-coach-appointments-button.component";
import { ShowCoachFeedbacksButton } from "../show-coach-feedbacks-button/show-coach-feedbacks-button.component";

export const CoachProfilesTable = ({
	queryFragmentRef,
	onFetchKeyChanged,
}: CoachProfilesTableProps) => {
	const { setConnectionId } = useContext(CoachProfilesScreenContext);
	const { data, hasPrevious, loadPrevious, hasNext, loadNext } = usePaginationFragment<
		CoachProfilesTable_QueryRefetch,
		coachProfilesTable_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const coachProfiles = data.Admin.Coaching.CoachProfiles.edges?.map((e) => ({
		coachProfile: readInlineData<coachProfilesTable_CoachProfileFragment$key>(
			COACH_PROFILE_FRAGMENT,
			e!.node.coachingOffer,
		),
		coachings: e?.node.coachings,
	}));

	const availabilitySchedules = data.Admin.Coaching.AvailabilitySchedules.edges?.map((e) =>
		readInlineData<coachProfilesTable_AvailabilityScheduleFragment$key>(
			AVAILABILITY_SCHEDULE_FRAGMENT,
			e!.node,
		),
	);

	const getAvailabilitySchedulesByCoach = (
		profile: coachProfilesTable_CoachProfileFragment$data,
	) => {
		return availabilitySchedules!.find((schedule) => schedule.coachRef === profile?.coach?.id);
	};

	useEffect(() => {
		setConnectionId(data.Admin.Coaching.CoachProfiles.__id);
	}, [data]);

	const handleOnLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleOnLoadNext = () => {
		loadNext(20);
	};

	return (
		<>
			<DataTable value={coachProfiles}>
				{(Column) => (
					<React.Fragment>
						<Column
							header={"Name"}
							body={(profile) => profile.coachProfile?.coach?.name}
						/>
						<Column
							header={"Durchnittliches Feedback"}
							body={(profile) => (
								<Rating
									value={profile.coachProfile?.averageFeedbackScore}
									readOnly={true}
									cancel={false}
									tooltip={`${profile.coachProfile?.averageFeedbackScore}`}
								></Rating>
							)}
						></Column>
						<Column
							header={"Coachings"}
							body={(profile) => (
								<>
									<Tooltip
										target={
											"#icon-" + profile.coachProfile?.id.replace(/=/g, "")
										}
										content={profile.coachings
											?.map((e) => e.description?.name)
											.join(", ")}
									/>
									<AssociatedCoachingsWrapper
										id={"icon-" + profile.coachProfile?.id.replace(/=/g, "")}
									>
										{profile.coachings
											?.map((e) => e.description?.name)
											.join(", ")}
									</AssociatedCoachingsWrapper>
								</>
							)}
						/>
						<Column
							header={"Aktionen"}
							body={(profile) =>
								profile?.coachProfile && (
									<ActionsWrapper>
										<EditBaseDataButton
											coachProfileFragmentRef={profile.coachProfile}
										/>
										<EditProfileDataButton
											coachProfileFragmentRef={profile.coachProfile}
										/>
										<EditBusinessDataButton
											coachProfileFragmentRef={profile.coachProfile}
										/>
										<EditAvailabilitySchedulesButton
											coachProfileFragmentRef={profile.coachProfile}
											availabilityScheduleFragmentRef={getAvailabilitySchedulesByCoach(
												profile.coachProfile,
											)}
											onFetchKeyChanged={onFetchKeyChanged}
										/>
										<ShowCoachFeedbacksButton
											coachProfileFragmentRef={profile.coachProfile}
										/>
										<DeleteCoachProfileButton
											coachProfileFragmentRef={profile.coachProfile}
										/>
										<ShowCoachAppointmentsButton
											coachProfileFragmentRef={profile.coachProfile}
										/>
									</ActionsWrapper>
								)
							}
						/>
					</React.Fragment>
				)}
			</DataTable>
			<PaginationWrapper>
				<Button
					disabled={!hasPrevious}
					onClick={handleOnLoadPrevious}
					content={{ label: "Zurück" }}
				/>
				<Button
					disabled={!hasNext}
					onClick={handleOnLoadNext}
					content={{ label: "Weiter" }}
				/>
			</PaginationWrapper>
		</>
	);
};
