/**
 * @generated SignedSource<<89c90e2773a031b898a2942fbc4c9afa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dynamicVocabElementForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectWordPackagesField_QueryFragment">;
  readonly " $fragmentType": "dynamicVocabElementForm_QueryFragment";
};
export type dynamicVocabElementForm_QueryFragment$key = {
  readonly " $data"?: dynamicVocabElementForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dynamicVocabElementForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "dynamicVocabElementForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectWordPackagesField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "74a8f5328f20a6a4a8f7b51ba6df7d86";

export default node;
