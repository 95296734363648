import { type FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { type MicroLearningLicenseDefinitionFormState } from "./micro-learning-license-definition-form.types";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";
import { type DefaultFormProps } from "../../../screens/offers/parts/common";
import { addEditedFormToEditedFormsArray } from "../../../store/slices/CoreSlice";

export const MicroLearningLicenseDefinitionForm = React.forwardRef<
	FormikProps<MicroLearningLicenseDefinitionFormState>,
	DefaultFormProps<MicroLearningLicenseDefinitionFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<MicroLearningLicenseDefinitionFormState>({
		initialValues: initialState ?? {
			name: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));
	const formId = "EditCoachingLicenseDefinitionForm";
	const dispatch = useDispatch();
	const handleOnChange = () => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	};
	return (
		<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
			<ValidatedField<MicroLearningLicenseDefinitionFormState, string>
				name={"name"}
				label={"Name"}
				placeholder="Namen eingeben..."
				component={DefaultTextFieldComponent}
				onChange={handleOnChange}
				formikConfig={formik}
			/>
		</form>
	);
});
MicroLearningLicenseDefinitionForm.displayName = "MicroLearningLicenseDefinitionForm";
