/**
 * @generated SignedSource<<05534e0183b3af60fc89ab756baa542d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditExternalLicensePoolInput = {
  clientMutationId?: string | null | undefined;
  data: ExternalLicensePoolDataInput;
  id: string;
};
export type ExternalLicensePoolDataInput = {
  link?: string | null | undefined;
  name: string;
  usageInformation: string;
  validNumDaysAfterIssuing: number;
};
export type editExternalLicensePoolForm_EditExternalLicensePoolMutation$variables = {
  input: EditExternalLicensePoolInput;
};
export type editExternalLicensePoolForm_EditExternalLicensePoolMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly editExternalLicensePool: {
        readonly externalLicensePool: {
          readonly " $fragmentSpreads": FragmentRefs<"editExternalLicensePoolForm_ExternalLicensePoolFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editExternalLicensePoolForm_EditExternalLicensePoolMutation = {
  response: editExternalLicensePoolForm_EditExternalLicensePoolMutation$data;
  variables: editExternalLicensePoolForm_EditExternalLicensePoolMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editExternalLicensePoolForm_EditExternalLicensePoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditExternalLicensePoolPayload",
                "kind": "LinkedField",
                "name": "editExternalLicensePool",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePool",
                    "kind": "LinkedField",
                    "name": "externalLicensePool",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editExternalLicensePoolForm_ExternalLicensePoolFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editExternalLicensePoolForm_EditExternalLicensePoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditExternalLicensePoolPayload",
                "kind": "LinkedField",
                "name": "editExternalLicensePool",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePool",
                    "kind": "LinkedField",
                    "name": "externalLicensePool",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePoolData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "link",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "usageInformation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "validNumDaysAfterIssuing",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ceb43a61efd5c417c933512a3e03bd30",
    "id": null,
    "metadata": {},
    "name": "editExternalLicensePoolForm_EditExternalLicensePoolMutation",
    "operationKind": "mutation",
    "text": "mutation editExternalLicensePoolForm_EditExternalLicensePoolMutation(\n  $input: EditExternalLicensePoolInput!\n) {\n  Admin {\n    Billing {\n      editExternalLicensePool(input: $input) {\n        externalLicensePool {\n          ...editExternalLicensePoolForm_ExternalLicensePoolFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editExternalLicensePoolForm_ExternalLicensePoolFragment on ExternalLicensePool {\n  id\n  data {\n    name\n    link\n    usageInformation\n    validNumDaysAfterIssuing\n  }\n}\n"
  }
};
})();

(node as any).hash = "3959648aa6959812235d5e4db8b2309e";

export default node;
