import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment selectContractPartnerField_QueryFragment on Query
	@refetchable(queryName: "selectContractPartnerField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		name: { type: "String" }
	) {
		Admin {
			Billing {
				ContractPartners(first: $first, after: $after, name: $name)
					@connection(key: "selectContractPartnerField_ContractPartners") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							...selectContractPartnerField_ContractPartnerFragment
						}
					}
				}
			}
		}
	}
`;

export const CONTRACT_PARTNER_FRAGMENT = graphql`
	fragment selectContractPartnerField_ContractPartnerFragment on ContractPartner @inline {
		id
		data {
			name
		}
	}
`;
