import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { useDialogLogic } from "@hooks/use-dialog-logic";
import { type deleteContractPartnerButton_DeleteContractPartnerMutation } from "@relay/deleteContractPartnerButton_DeleteContractPartnerMutation.graphql";
import { ContractPartnerScreenContext } from "@screens/contract-partners/contract-partners.context";
import { type DeleteContractPartnerButtonProps } from "@screens/contract-partners/parts/delete-contract-partner-button/delete-contract-partner-button.interface";
import { DELETE_CONTRACT_PARTNER_MUTATION } from "./delete-contract-partner-button.graphql";

export const DeleteContractPartnerButton = ({
	contractPartnerId,
}: DeleteContractPartnerButtonProps) => {
	const { showDialog, dialogComponent } = useDialogLogic();
	const { connectionId } = useContext(ContractPartnerScreenContext);
	const [deleteContractPartner, isDeleting] =
		useMutation<deleteContractPartnerButton_DeleteContractPartnerMutation>(
			DELETE_CONTRACT_PARTNER_MUTATION,
		);

	const handleOnClick = () => {
		showDialog({
			title: "Vertrags-Partner löschen?",
			content:
				"Möchten Sie diesen Vertrags-Partner wirklich löschen? Das kann nicht rückgängig gemacht werden",
			dialogCallback: (result) => {
				if (result === "Accept") {
					deleteContractPartner({
						variables: {
							input: {
								ids: [contractPartnerId],
							},
							connections: [connectionId],
						},
					});
				}
			},
		});
	};

	return (
		<>
			{dialogComponent}
			<Button
				onClick={handleOnClick}
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				loading={isDeleting}
				disabled={isDeleting}
				tooltip={"Vertrags-Partner löschen"}
			/>
		</>
	);
};
