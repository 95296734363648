import { useIsFeatureActive } from "@hooks/use-is-feature-active";
import { editAccountGroupsForm_AccountGroupFragment$data } from "@relay/editAccountGroupsForm_AccountGroupFragment.graphql";

export const useDivergingGroupAssociations = (
	availableAccountGroups: editAccountGroupsForm_AccountGroupFragment$data[],
) => {
	const isAvgs = useIsFeatureActive("potentialAnalysisV2");
	const blackList = ["AZAV Account", "Firmen-Account"].concat(isAvgs ? ["AVGS Account"] : []);
	const uncontrollable = availableAccountGroups.filter((e) => !blackList.includes(e.name));
	const controllable = availableAccountGroups.filter((e) => uncontrollable.includes(e));
	return { controllable, uncontrollable };
};
