/**
 * @generated SignedSource<<60766f5a2a03fb9f4537e735cef7ba25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CodeActionColumn_DiscountCodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "CodeActionColumn_DiscountCodeFragment";
};
export type CodeActionColumn_DiscountCodeFragment$key = {
  readonly " $data"?: CodeActionColumn_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodeActionColumn_DiscountCodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeActionColumn_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountCode",
  "abstractKey": null
};

(node as any).hash = "3caf4c7627d3a470eae8c64a5960799c";

export default node;
