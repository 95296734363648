/**
 * @generated SignedSource<<da97c7031c43bbf874ab405d9a259a55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type PaymentProviderDataType = "AppleIap" | "Default" | "GoogleIap" | "Sepa" | "Stripe" | "StripeSepa";
import { FragmentRefs } from "relay-runtime";
export type paymentProviderLink_OrderFragment$data = {
  readonly paymentData: {
    readonly dataType: PaymentProviderDataType;
    readonly paymentIntentId?: string;
    readonly paymentMethod: PaymentMethodType;
  } | null | undefined;
  readonly status: OrderStatus;
  readonly " $fragmentType": "paymentProviderLink_OrderFragment";
};
export type paymentProviderLink_OrderFragment$key = {
  readonly " $data"?: paymentProviderLink_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentProviderLink_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentProviderLink_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "paymentData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dataType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "StripeData",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "StripeSepaData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "6a609628986d886b6affd7282f7e9453";

export default node;
