import graphql from "babel-plugin-relay/macro";

export const CUSTOMER_FEEDBACK_FRAGMENT = graphql`
	fragment editCustomerFeedbackButton_CustomerFeedbackFragment on CustomerFeedback {
		id
		position
		feedback
		name
	}
`;

export const EDIT_CUSTOMER_FEEDBACK_MUTATION = graphql`
	mutation editCustomerFeedbackButton_EditCustomerFeedbackMutation(
		$input: EditCustomerFeedbackInput!
	) {
		Admin {
			CustomerFeedback {
				editCustomerFeedback(input: $input) {
					edge {
						node {
							id
							...editCustomerFeedbackButton_CustomerFeedbackFragment
						}
					}
				}
			}
		}
	}
`;
