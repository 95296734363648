/**
 * @generated SignedSource<<8a7453c00c19ae1afb41c605b4315a1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rewardEditor_Query$variables = Record<PropertyKey, never>;
export type rewardEditor_Query$data = {
  readonly Admin: {
    readonly Billing: {
      readonly GetExternalLicensePools: {
        readonly " $fragmentSpreads": FragmentRefs<"createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment">;
      };
    };
    readonly Vocab: {
      readonly WordPackages: {
        readonly " $fragmentSpreads": FragmentRefs<"createWordPackageRewardButton_WordPackageConnectionFragment">;
      };
    };
  };
};
export type rewardEditor_Query = {
  response: rewardEditor_Query$data;
  variables: rewardEditor_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "rewardEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalLicensePoolsConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicensePools",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WordPackageConnection",
                "kind": "LinkedField",
                "name": "WordPackages",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "createWordPackageRewardButton_WordPackageConnectionFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "rewardEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalLicensePoolsConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicensePools",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePoolsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v0/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WordPackageConnection",
                "kind": "LinkedField",
                "name": "WordPackages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordPackage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v0/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56e142b16c62dbbe1e0a29bc04828890",
    "id": null,
    "metadata": {},
    "name": "rewardEditor_Query",
    "operationKind": "query",
    "text": "query rewardEditor_Query {\n  Admin {\n    Billing {\n      GetExternalLicensePools {\n        ...createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment\n      }\n    }\n    Vocab {\n      WordPackages {\n        ...createWordPackageRewardButton_WordPackageConnectionFragment\n      }\n    }\n  }\n}\n\nfragment createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment on ExternalLicensePoolsConnection {\n  edges {\n    node {\n      id\n    }\n  }\n}\n\nfragment createWordPackageRewardButton_WordPackageConnectionFragment on WordPackageConnection {\n  edges {\n    node {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c1bc66057457cfe3a0ce86e3415fe1f";

export default node;
