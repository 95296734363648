import { useFormik } from "formik";
import { Button } from "primereact/button";
import {
	type LicenseDefinitionsTableFilterProps,
	type LicenseDefinitionsTableFilterState,
} from "./license-definitions-table-filter.interface";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";

export const LicenseDefinitionsTableFilter = ({ refetch }: LicenseDefinitionsTableFilterProps) => {
	const initialValues = {
		name: "",
	};

	const formik = useFormik<LicenseDefinitionsTableFilterState>({
		initialValues,
		onSubmit: (values: LicenseDefinitionsTableFilterState, { setSubmitting }) => {
			refetch(values.name);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<LicenseDefinitionsTableFilterState, string>
					name="name"
					label="Name"
					className="mr-2 w-25rem"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
		</form>
	);
};
