/**
 * @generated SignedSource<<d2512bf446aad5525b292d06ec9f2dd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editWordButton_WordFragment$data = {
  readonly explanation: string;
  readonly id: string;
  readonly translation: string | null | undefined;
  readonly word: string;
  readonly " $fragmentType": "editWordButton_WordFragment";
};
export type editWordButton_WordFragment$key = {
  readonly " $data"?: editWordButton_WordFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editWordButton_WordFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editWordButton_WordFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "word",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "translation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "explanation",
      "storageKey": null
    }
  ],
  "type": "Word",
  "abstractKey": null
};

(node as any).hash = "036542f0e681c50a3d8c3ac6005f153b";

export default node;
