import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment customerFeedbackTable_QueryFragment on Query
	@refetchable(queryName: "CustomerFeedbackTable_QueryRefetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		last: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
	) {
		CustomerJourney {
			CustomerFeedback(before: $before, after: $after, first: $first, last: $last)
				@connection(key: "customerFeedbackTable_CustomerFeedback") {
				__id
				edges {
					node {
						...customerFeedbackTable_CustomerFeedbackFragment
					}
				}
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
			}
		}
	}
`;

export const CUSTOMER_FEEDBACK_FRAGMENT = graphql`
	fragment customerFeedbackTable_CustomerFeedbackFragment on CustomerFeedback @inline {
		id
		name
		position
		feedback
		...customerFeedbackActionColumn_CustomerFeedbackFragment
	}
`;
