import { DefaultNumberFieldComponent } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useFragment } from "react-relay";
import { type createLicensesForm_QueryFragment$key } from "@relay/createLicensesForm_QueryFragment.graphql";
import { QUERY_FRAGMENT } from "./create-licenses-form.graphql";
import { Submit } from "./create-licenses-form.styles";
import {
	type CreateLicensesFormProps,
	type CreateLicensesFormState,
} from "./create-licenses-form.types";
import { ValidatedField } from "../../../components/ValidatedField";
import {
	SelectLicenseDefinitionField,
	type SelectLicenseDefinitionFieldState,
} from "../../license-definitions/select-license-definition-field";

export const CreateLicensesForm = ({ onSubmit, queryFragmentRef }: CreateLicensesFormProps) => {
	const query = useFragment<createLicensesForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const formik = useFormik<CreateLicensesFormState>({
		initialValues: {
			account: undefined,
			licenseDefinition: undefined,
			amount: 1,
		},
		enableReinitialize: true,
		onSubmit,
	});

	const clearForm = () => {
		formik.resetForm();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-column ">
				<ValidatedField<CreateLicensesFormState, number>
					name="amount"
					label="Anzahl"
					component={DefaultNumberFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<CreateLicensesFormState, SelectLicenseDefinitionFieldState>
					name="licenseDefinition"
					label="Lizenzdefinition"
					component={(renderConfig) => (
						<SelectLicenseDefinitionField queryFragmentRef={query} {...renderConfig} />
					)}
					formikConfig={formik}
				/>
				<div>
					<Submit disabled={false} type="submit" icon="pi pi-save" />
					<Button disabled={false} type="reset" onClick={clearForm} icon="pi pi-times" />
				</div>
			</div>
		</form>
	);
};
