import { Dropdown, type EpicIcons, Icon, type DropdownProps } from "@thekeytechnology/epic-ui";
import { type RenderConfig } from "@components/ValidatedField";
import { Wrapper } from "./icon-dropdown.styles";
import { advantageIcons, trendIcons } from "./icon.consts";
import { type IconProps } from "./icon.types";

export const IconDropdown = ({
	fieldName,
	fieldValue,
	placeholder,
	onChange,
	updateField,
}: RenderConfig<EpicIcons>) => {
	const options = fieldName === "icon" ? trendIcons : advantageIcons;
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			if (onChange) {
				onChange();
			}
		}
	};

	const iconTemplate = (option: IconProps) => {
		return <Icon sizeRem={1} icon={option.icon} />;
	};

	const valueTemplate = (option?: IconProps, props?: DropdownProps) => {
		if (option) {
			return <Icon sizeRem={1} icon={option.icon} />;
		}
		return <span>{props?.placeholder}</span>;
	};

	return (
		<Wrapper>
			<Dropdown
				id={fieldName}
				options={options}
				itemTemplate={iconTemplate}
				value={fieldValue}
				valueTemplate={valueTemplate}
				placeholder={placeholder}
				onChange={(value) => {
					updateField(value as EpicIcons);
					checkIfDropdownFieldChanged(value);
				}}
			/>
		</Wrapper>
	);
};
