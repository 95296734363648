/**
 * @generated SignedSource<<a1b19d64b4c4da441167eb47dd7375bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditCoachingLicenseDefinitionAdminMutationInput = {
  clientMutationId?: string | null | undefined;
  data: CoachingLicenseDefinitionDataInput;
  licenseDefinitionId: string;
};
export type CoachingLicenseDefinitionDataInput = {
  coachingId: string;
  name: string;
};
export type editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation$variables = {
  input: EditCoachingLicenseDefinitionAdminMutationInput;
};
export type editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation$data = {
  readonly Admin: {
    readonly LicenseDefinition: {
      readonly editCoachingLicenseDefinitionAdminMutation: {
        readonly licenseDefinition: {
          readonly " $fragmentSpreads": FragmentRefs<"editCoachingLicenseDefinitionButton_LicenseDefinitionFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation = {
  response: editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation$data;
  variables: editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditCoachingLicenseDefinitionAdminMutationPayload",
                "kind": "LinkedField",
                "name": "editCoachingLicenseDefinitionAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinition",
                    "kind": "LinkedField",
                    "name": "licenseDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editCoachingLicenseDefinitionButton_LicenseDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditCoachingLicenseDefinitionAdminMutationPayload",
                "kind": "LinkedField",
                "name": "editCoachingLicenseDefinitionAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinition",
                    "kind": "LinkedField",
                    "name": "licenseDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LicenseDefinitionCoachingInfo",
                                "kind": "LinkedField",
                                "name": "coachingInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "coachingId",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "CoachingLicenseDefinitionData",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "761f7b7d0feedd0fc1a6590f6874b298",
    "id": null,
    "metadata": {},
    "name": "editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation(\n  $input: EditCoachingLicenseDefinitionAdminMutationInput!\n) {\n  Admin {\n    LicenseDefinition {\n      editCoachingLicenseDefinitionAdminMutation(input: $input) {\n        licenseDefinition {\n          ...editCoachingLicenseDefinitionButton_LicenseDefinitionFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editCoachingLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    ... on CoachingLicenseDefinitionData {\n      name\n      coachingInfo {\n        coachingId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a050f9d2410f3b20abe56e408b961b5";

export default node;
