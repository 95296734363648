import { graphql } from "babel-plugin-relay/macro";

export const DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT = graphql`
	fragment exceptionsTable_DayAndTimesForCalendarWeekFragment on DayAndTimesForCalendarWeek
	@relay(plural: true) {
		dayOfWeek
		timeSlots
		calendarYear
		calendarWeek
		...editExceptionModal_DayAndTimesForCalendarWeekFragment
	}
`;

export const AVAILABILITY_SCHEDULE_FRAGMENT = graphql`
	fragment exceptionsTable_AvailabilityScheduleFragment on AvailabilitySchedule {
		...editExceptionModal_AvailabilityScheduleFragment
	}
`;
export const COACH_PROFILE_FRAGMENT = graphql`
	fragment exceptionsTable_CoachProfileFragment on CoachProfile {
		...editExceptionModal_CoachProfileFragment
	}
`;
