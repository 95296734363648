import { Suspense } from "react";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { Card } from "../../components/card";
import { NoAccess } from "../../components/no-access";
import { UsersTable } from "../../features/users/users-table";
import { BaseScreen } from "../BaseScreen";

export const UsersScreen = () => {
	const canRead = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<Card
				header={
					<div className="flex p-3 align-items-center justify-content-between card-flat">
						<h1 className="mt-0 mr-3 mb-0 ml-0">Benutzer</h1>
					</div>
				}
			>
				<Suspense fallback={<div>Lade...</div>}>
					<UsersTable />
				</Suspense>
			</Card>
		</BaseScreen>
	);
};
