/**
 * @generated SignedSource<<8cdfbd80cd056992d2438dd771dfc760>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateAndAppendChildTagInput = {
  clientMutationId?: string | null | undefined;
  data: TagDataInput;
  parentId: string;
};
export type TagDataInput = {
  isClickable: boolean;
  isTopic: boolean;
  name: string;
};
export type createChildTag_CreateAndAppendChildTagMutation$variables = {
  input: CreateAndAppendChildTagInput;
};
export type createChildTag_CreateAndAppendChildTagMutation$data = {
  readonly Admin: {
    readonly AcademiesTag: {
      readonly createAndAppendChildTag: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"academiesTagsTable_AcademiesTagFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createChildTag_CreateAndAppendChildTagMutation = {
  response: createChildTag_CreateAndAppendChildTagMutation$data;
  variables: createChildTag_CreateAndAppendChildTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeDeleted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AcademiesTagData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "AcademiesTagData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v7/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subId",
  "storageKey": null
},
v11 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "editAcademiesTagButton_AcademiesTagFragment"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createChildTag_CreateAndAppendChildTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateAndAppendChildTagPayload",
                "kind": "LinkedField",
                "name": "createAndAppendChildTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcademiesChildTagsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcademiesChildTag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "academiesTagsTable_AcademiesTagFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/),
                                      (v8/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcademiesChildTagsConnection",
                                        "kind": "LinkedField",
                                        "name": "children",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AcademiesChildTagsEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AcademiesChildTag",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v2/*: any*/),
                                                  (v9/*: any*/),
                                                  (v4/*: any*/),
                                                  (v10/*: any*/),
                                                  (v3/*: any*/),
                                                  (v11/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "AcademiesRootTag",
                                    "abstractKey": null
                                  },
                                  (v11/*: any*/)
                                ],
                                "type": "AcademiesTag",
                                "abstractKey": "__isAcademiesTag"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createChildTag_CreateAndAppendChildTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminMutationSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateAndAppendChildTagPayload",
                "kind": "LinkedField",
                "name": "createAndAppendChildTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcademiesChildTagsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcademiesChildTag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcademiesChildTagsConnection",
                                    "kind": "LinkedField",
                                    "name": "children",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcademiesChildTagsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AcademiesChildTag",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v9/*: any*/),
                                              (v4/*: any*/),
                                              (v10/*: any*/),
                                              (v3/*: any*/),
                                              {
                                                "kind": "TypeDiscriminator",
                                                "abstractKey": "__isAcademiesTag"
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "AcademiesRootTag",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v8/*: any*/)
                                ],
                                "type": "AcademiesChildTag",
                                "abstractKey": null
                              }
                            ],
                            "type": "AcademiesTag",
                            "abstractKey": "__isAcademiesTag"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d98e8cdf9279b871d78aa40b689dd477",
    "id": null,
    "metadata": {},
    "name": "createChildTag_CreateAndAppendChildTagMutation",
    "operationKind": "mutation",
    "text": "mutation createChildTag_CreateAndAppendChildTagMutation(\n  $input: CreateAndAppendChildTagInput!\n) {\n  Admin {\n    AcademiesTag {\n      createAndAppendChildTag(input: $input) {\n        edge {\n          node {\n            ...academiesTagsTable_AcademiesTagFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment academiesTagsTable_AcademiesTagFragment on AcademiesTag {\n  __isAcademiesTag: __typename\n  id\n  ... on AcademiesRootTag {\n    kind\n    canBeDeleted\n    data {\n      name\n      isClickable\n      isTopic\n    }\n    children {\n      edges {\n        node {\n          id\n          data {\n            name\n            isTopic\n            isClickable\n          }\n          canBeDeleted\n          subId\n          kind\n          ...editAcademiesTagButton_AcademiesTagFragment\n        }\n      }\n    }\n  }\n  ...editAcademiesTagButton_AcademiesTagFragment\n}\n\nfragment editAcademiesTagButton_AcademiesTagFragment on AcademiesTag {\n  __isAcademiesTag: __typename\n  id\n  ... on AcademiesRootTag {\n    data {\n      name\n      isTopic\n      isClickable\n    }\n  }\n  ... on AcademiesChildTag {\n    data {\n      name\n      isClickable\n      isTopic\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd3de4dc501219d4a6edaa33923ee227";

export default node;
