/**
 * @generated SignedSource<<219eb12df7f15c87c13b218aaab969ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectDimensionsModal_QueryFragment$data = {
  readonly Admin: {
    readonly AvgsPotentialAnalysis: {
      readonly AvailableModulesAndDimensions: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"selectDimensionsModal_AVGSPotentialAnalysisModuleFragment">;
      }>;
    };
  };
  readonly " $fragmentType": "selectDimensionsModal_QueryFragment";
};
export type selectDimensionsModal_QueryFragment$key = {
  readonly " $data"?: selectDimensionsModal_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectDimensionsModal_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./selectDimensionsModal_Refetch.graphql')
    }
  },
  "name": "selectDimensionsModal_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AvgsPotentialAnalysisAdminSchema",
          "kind": "LinkedField",
          "name": "AvgsPotentialAnalysis",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "after",
                  "variableName": "after"
                },
                {
                  "kind": "Variable",
                  "name": "before",
                  "variableName": "before"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                },
                {
                  "kind": "Variable",
                  "name": "last",
                  "variableName": "last"
                }
              ],
              "concreteType": "AVGSPotentialAnalysis_Module",
              "kind": "LinkedField",
              "name": "AvailableModulesAndDimensions",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "selectDimensionsModal_AVGSPotentialAnalysisModuleFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "moduleId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "moduleText",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AVGSPotentialAnalysis_Dimension",
                      "kind": "LinkedField",
                      "name": "dimensions",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dimensionId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dimensionText",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7e90c342ffcfda14d702fd416a610627";

export default node;
