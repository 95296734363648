/**
 * @generated SignedSource<<6f123d9af87078dba566a5c813851cd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment$data = {
  readonly id: string;
  readonly podcastFile: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly timestampsWithImage: ReadonlyArray<{
    readonly end: number;
    readonly imageFile: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly start: number;
  }>;
  readonly title: string;
  readonly " $fragmentType": "PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment";
};
export type PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment$key = {
  readonly " $data"?: PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "podcastFile",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimestampWithImage",
      "kind": "LinkedField",
      "name": "timestampsWithImage",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "start",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "imageFile",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PodcastWithTimestamp",
  "abstractKey": null
};
})();

(node as any).hash = "15cb629dc64a19d72cc1a58c815f1d41";

export default node;
