import { Dialog } from "@thekeytechnology/epic-ui";
import { Button, DialogButton } from "@thekeytechnology/framework-react-components";
import React, { useState } from "react";
import { readInlineData } from "react-relay";
import { type blockOutDatesTable_BlockOutDateFragment$key } from "@relay/blockOutDatesTable_BlockOutDateFragment.graphql";
import { BLOCKOUT_DATE_FRAGMENT } from "./block-out-dates-table.graphql";
import { ButtonWrapper, ButtonWrapperCenter } from "./block-out-dates-table.styles";
import { type BlockOutDatesProps } from "./block-out-dates-table.types";
import { DataTable } from "../../../../../../components/data-table";
import { parseDateTimeTimeStamp } from "../../edit-availibility-schedule-form.utils";
import { EditBlockOutDateModal } from "../edit-block-out-date-modal/edit-block-out-date-modal.component";

export const BlockOutDatesTable = ({
	blockOutDateFragmentRef,
	onChange,
	onCreate,
}: BlockOutDatesProps) => {
	const blockOutDates = blockOutDateFragmentRef.map((entry) =>
		readInlineData<blockOutDatesTable_BlockOutDateFragment$key>(BLOCKOUT_DATE_FRAGMENT, entry),
	);

	const [isCreateVisible, setIsCreateVisible] = useState(false);

	const showCreate = () => {
		setIsCreateVisible(true);
	};

	const hideCreate = () => {
		setIsCreateVisible(false);
	};

	const buildOnChangeHandler = (index: number) => (from: Date, to: Date) => {
		onChange?.(index, from, to);
	};

	const buildOnDeleteHandler = (index: number) => () => {
		onChange?.(index);
	};

	const handleOnCreate = (from: Date, to: Date) => {
		onCreate?.(from, to);
		setIsCreateVisible(false);
	};

	return (
		<>
			<DataTable value={blockOutDates} className={"p-w100"}>
				{(Column) => (
					<>
						<Column header="Von" body={(date) => parseDateTimeTimeStamp(date.from)} />
						<Column header="Bis" body={(date) => parseDateTimeTimeStamp(date.to)} />
						<Column
							header="Aktionen"
							body={(date, options) => (
								<ButtonWrapper>
									<DialogButton
										buttonContent={{ icon: "pi pi-pencil" }}
										title={"Bearbeiten"}
									>
										{() => (
											<EditBlockOutDateModal
												blockOutDateFragmentRef={date}
												onChange={buildOnChangeHandler(options.rowIndex)}
											></EditBlockOutDateModal>
										)}
									</DialogButton>
									<Button
										content={{ icon: "pi pi-trash" }}
										onClick={buildOnDeleteHandler(options.rowIndex)}
									></Button>
								</ButtonWrapper>
							)}
						></Column>
					</>
				)}
			</DataTable>
			<ButtonWrapperCenter>
				<Button content={{ icon: "pi pi-plus" }} onClick={showCreate}></Button>
			</ButtonWrapperCenter>
			<Dialog title="Neue Abwesenheit anlegen" visible={isCreateVisible} onHide={hideCreate}>
				<EditBlockOutDateModal
					onChange={handleOnCreate}
					blockOutDateFragmentRef={null}
				></EditBlockOutDateModal>
			</Dialog>
		</>
	);
};
