/**
 * @generated SignedSource<<6c1bfce60fbe0379a335185d9647c66c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type CountryCode = "AT" | "CH" | "DE";
export type TransformPrivateAccountToAZAVAccountInput = {
  accountId: string;
  businessBaseData: BusinessBaseDataInput;
  clientMutationId?: string | null | undefined;
};
export type BusinessBaseDataInput = {
  accountData: AccountDataInput;
  billingOffice: string;
  city: string;
  companyLegalForm: CompanyType;
  companyName: string;
  countryCode: CountryCode;
  houseNumber: string;
  invoiceEmail: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  taxData: TaxDataInput;
};
export type TaxDataInput = {
  taxIdentificationNumber: string;
  taxNumber: string;
};
export type AccountDataInput = {
  bic?: string | null | undefined;
  iban: string;
};
export type transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation$variables = {
  input: TransformPrivateAccountToAZAVAccountInput;
};
export type transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation$data = {
  readonly Admin: {
    readonly Azav: {
      readonly transformPrivateAccountToAZAVAccount: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation = {
  response: transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation$data;
  variables: transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AzavAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Azav",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "TransformPrivateAccountToAZAVAccountPayload",
            "kind": "LinkedField",
            "name": "transformPrivateAccountToAZAVAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6339b39d1163fe504a3388b5b83cebef",
    "id": null,
    "metadata": {},
    "name": "transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation",
    "operationKind": "mutation",
    "text": "mutation transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation(\n  $input: TransformPrivateAccountToAZAVAccountInput!\n) {\n  Admin {\n    Azav {\n      transformPrivateAccountToAZAVAccount(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "511d626141f283338c9f973eda0c12e9";

export default node;
