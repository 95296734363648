/**
 * @generated SignedSource<<7f6b9daf5e2257b235299e4510bf143e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment";
};
export type editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key = {
  readonly " $data"?: editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_PotentialAnalysis",
  "abstractKey": null
};

(node as any).hash = "218b217a53faab71f747596648d8f1c1";

export default node;
