import graphql from "babel-plugin-relay/macro";

export const PLACEMENT_PRODUCT_FRAGMENT = graphql`
	fragment placementProductForm_PlacementProductFragment on Product {
		id
		title
		isHidden
		isTaxFree
		netPrice
		extensions {
			kind
			... on AcademiesProductExtension {
				costUnit
			}
		}
	}
`;

export const EDIT_PRODUCT_MUTATION = graphql`
	mutation placementProductForm_EditProductMutation($input: EditProductInput!) {
		Admin {
			Billing {
				editProduct(input: $input) {
					editedProduct {
						...placementProductForm_PlacementProductFragment
					}
				}
			}
		}
	}
`;

export const EDIT_ACADEMIES_PRODUCT_EXTENSION_MUTATION = graphql`
	mutation placementProductForm_EditAcademiesProductExtensionMutation(
		$input: EditAcademiesProductExtensionInput!
	) {
		Admin {
			Billing {
				editAcademiesProductExtension(input: $input) {
					product {
						...placementProductForm_PlacementProductFragment
					}
				}
			}
		}
	}
`;
