import { type IconName } from "@thekeytechnology/epic-ui";

export enum AdminIcons {
	DotsConnected = "dotsConnected",
	ArrowLoop = "arrowLoop",
	BridgeOverRiver = "bridgeOverRiver",
	ComputerWithHeart = "computerWithHeart",
	FlowerInCircle = "flowerInCircle",
	HandsWithHeart = "handsWithHeart",
	HandWithFlower = "handWithFlower",
	LightBulb = "lightBulb",
	PeopleHighFive = "peopleHighFive",
	PersonWithStars = "personWithStars",
	WorkingDesk = "workingDesk",
	Rocket = "rocket",
}

export type IconProps = {
	icon?: IconName;
};

export type AdminIconKeys = { [key in AdminIcons]: unknown };
