import { OrderList } from "primereact/orderlist";
import styled from "styled-components";
import tw from "twin.macro";

export const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}
`;

export const ButtonWrapper = tw.div`
tw-mb-[2rem]
tw-flex
tw-gap-[0.5rem]
`;
