import { DeleteButton } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type deleteCoachProfileButton_CoachProfileFragment$key } from "@relay/deleteCoachProfileButton_CoachProfileFragment.graphql";
import { type deleteCoachProfileButton_DeleteCoachProfileMutation } from "@relay/deleteCoachProfileButton_DeleteCoachProfileMutation.graphql";
import {
	COACH_PROFILE_FRAGMENT,
	DELETE_COACH_PROFILE_MUTATION,
} from "./delete-coach-profile-button.graphql";
import { type DeleteCoachProfileButtonProps } from "./delete-coach-profile-button.types";
import { CoachProfilesScreenContext } from "../../coach-profiles.context";

export const DeleteCoachProfileButton = ({
	coachProfileFragmentRef,
}: DeleteCoachProfileButtonProps) => {
	const { connectionId } = useContext(CoachProfilesScreenContext);
	const coachProfile = useFragment<deleteCoachProfileButton_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const [doDelete, isDeleting] = useMutation<deleteCoachProfileButton_DeleteCoachProfileMutation>(
		DELETE_COACH_PROFILE_MUTATION,
	);
	const onDelete = () => {
		doDelete({
			variables: {
				input: {
					ids: [coachProfile.id],
				},
				connections: [connectionId],
			},

			onError: () => {
				toast.success(`Es gab einen Fehler beim Löschvorgang.`);
			},
			onCompleted: () => {
				toast.success(`Coach ${coachProfile.id} wurde gelöscht.`);
			},
		});
	};
	return (
		<DeleteButton
			disabled={isDeleting}
			onDelete={onDelete}
			singularName={"Coach"}
			pluralName={"Coaches"}
			value={[coachProfile.id]}
			tooltip="Coach löschen"
		/>
	);
};
