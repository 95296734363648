/**
 * @generated SignedSource<<7bb5641e6cc55532e34f1cfaaef2ad1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectContractPartnerField_ContractPartnerFragment$data = {
  readonly data: {
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "selectContractPartnerField_ContractPartnerFragment";
};
export type selectContractPartnerField_ContractPartnerFragment$key = {
  readonly " $data"?: selectContractPartnerField_ContractPartnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectContractPartnerField_ContractPartnerFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectContractPartnerField_ContractPartnerFragment"
};

(node as any).hash = "7f984f8a1a83571dd26a3b6d99585ba1";

export default node;
