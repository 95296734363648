import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { type createWordPackageRewardButton_AddWordPackageRewardMutation } from "@relay/createWordPackageRewardButton_AddWordPackageRewardMutation.graphql";
import { type createWordPackageRewardButton_TreeNodeFragment$key } from "@relay/createWordPackageRewardButton_TreeNodeFragment.graphql";
import { type createWordPackageRewardButton_WordPackageConnectionFragment$key } from "@relay/createWordPackageRewardButton_WordPackageConnectionFragment.graphql";
import {
	ADD_WORD_PACKAGE_REWARD_MUTATION,
	TREE_NODE_FRAGMENT,
	WORD_PACKAGE_CONNECTION_FRAGMENT,
} from "./create-word-package-reward-button.graphql";
import { type CreateWordPackageRewardButtonProps } from "./create-word-package-reward-button.types";

export const CreateWordPackageRewardButton = ({
	treeNodeFragmentRef,
	wordPackageConnectionFragmentRef,
}: CreateWordPackageRewardButtonProps) => {
	const node = useFragment<createWordPackageRewardButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const wordPackageConnection =
		useFragment<createWordPackageRewardButton_WordPackageConnectionFragment$key>(
			WORD_PACKAGE_CONNECTION_FRAGMENT,
			wordPackageConnectionFragmentRef,
		);

	const [addWordPackageReward, isAddingWordPackageReward] =
		useMutation<createWordPackageRewardButton_AddWordPackageRewardMutation>(
			ADD_WORD_PACKAGE_REWARD_MUTATION,
		);

	const noWordPackage = wordPackageConnection.edges?.length === 0;

	const tooltip = noWordPackage
		? "Bitte legen Sie zuerst ein Wortpaket an"
		: "Wortpaket freischalten";

	const disabled = isAddingWordPackageReward || noWordPackage;

	const handleOnClick = useCallback(() => {
		const wordPackageId = wordPackageConnection?.edges?.map((e) => e?.node).shift()?.id;
		if (!wordPackageId) return;

		addWordPackageReward({
			variables: {
				input: {
					contentId: node.id,
					data: {
						wordPackageId,
					},
				},
			},
		});
	}, [wordPackageConnection, node, addWordPackageReward]);

	return (
		<Button
			disabled={disabled}
			tooltip={tooltip}
			icon={EpicIcons.LANGUAGE}
			onClick={handleOnClick}
		/>
	);
};
