/**
 * @generated SignedSource<<cdc679ae74546e1b8d8e4f3f50606f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editCoachingLicenseDefinitionButton_LicenseDefinitionFragment$data = {
  readonly data: {
    readonly coachingInfo?: {
      readonly coachingId: string;
    } | null | undefined;
    readonly name?: string;
  };
  readonly id: string;
  readonly " $fragmentType": "editCoachingLicenseDefinitionButton_LicenseDefinitionFragment";
};
export type editCoachingLicenseDefinitionButton_LicenseDefinitionFragment$key = {
  readonly " $data"?: editCoachingLicenseDefinitionButton_LicenseDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editCoachingLicenseDefinitionButton_LicenseDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editCoachingLicenseDefinitionButton_LicenseDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LicenseDefinitionCoachingInfo",
              "kind": "LinkedField",
              "name": "coachingInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coachingId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "CoachingLicenseDefinitionData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LicenseDefinition",
  "abstractKey": null
};

(node as any).hash = "041debeefb0e3dd5b9186d9c17f7f83c";

export default node;
