import { DialogButton, EpicIcons } from "@thekeytechnology/epic-ui";
import { Suspense } from "react";
import { useFragment } from "react-relay";

import {
	AVAILABILITY_SCHEDULE_FRAGMENT,
	COACH_PROFILE_FRAGMENT,
} from "./edit-availability-schedule-button.graphql";
import { type EditAvailabilityScheduleButtonProps } from "./edit-availability-schedule-button.types";
import { EditAvailabilitySchedulesForm } from "../edit-availability-schedule-form/edit-availability-schedule-form.component";

export const EditAvailabilitySchedulesButton = ({
	coachProfileFragmentRef,
	availabilityScheduleFragmentRef,
	onFetchKeyChanged,
}: EditAvailabilityScheduleButtonProps) => {
	const coachProfile = useFragment(COACH_PROFILE_FRAGMENT, coachProfileFragmentRef);
	const availabilitySchedule = useFragment(
		AVAILABILITY_SCHEDULE_FRAGMENT,
		availabilityScheduleFragmentRef!,
	);

	return (
		<DialogButton
			buttonIcon={EpicIcons.CALENDAR}
			dialogTitle={`Verfügbarkeiten vom Coach: ${coachProfile?.coach?.name}`}
			tooltip="Verfügbarkeitsslots"
		>
			{() => (
				<Suspense fallback={"Lade..."}>
					<EditAvailabilitySchedulesForm
						coachProfileFragmentRef={coachProfile}
						availabilityScheduleFragmentRef={availabilitySchedule}
						onFetchKeyChanged={onFetchKeyChanged}
					/>
				</Suspense>
			)}
		</DialogButton>
	);
};
