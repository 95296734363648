/**
 * @generated SignedSource<<071f6a45ecf997980960f9d4e64a700d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contractPartnerTable_ContractPartnerFragment$data = {
  readonly data: {
    readonly association: string | null | undefined;
    readonly email: string;
    readonly name: string;
  };
  readonly id: string;
  readonly shareAmountPercentage: number;
  readonly stripeConnectAccountId: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"editContractPartnerButton_ContractPartnerFragment">;
  readonly " $fragmentType": "contractPartnerTable_ContractPartnerFragment";
};
export type contractPartnerTable_ContractPartnerFragment$key = {
  readonly " $data"?: contractPartnerTable_ContractPartnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contractPartnerTable_ContractPartnerFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "contractPartnerTable_ContractPartnerFragment"
};

(node as any).hash = "9b014c20d0cf09e5cac6d5eb1513df07";

export default node;
