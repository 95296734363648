import { PathBase, PathWithId } from "@thekeytechnology/epic-ui";

export class CoachAppointmentsEditPath extends PathBase {
	static readonly pathKey = "edit";
}

export class CoachAppointmentsPath extends PathWithId<typeof CoachAppointmentsPath> {
	static readonly pathKey = "coachAppointments";
	static readonly pathName = "coach-appointments";
	static readonly idKey = "coachId";
	static readonly childPaths = [CoachAppointmentsEditPath];
}
