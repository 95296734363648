import { Dialog, EpicP2Span } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { FC, useContext } from "react";
import * as Yup from "yup";
import { ButtonVariant } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { DialogTemplate } from "@components/dialog-template";
import { ValidatedField } from "@components/ValidatedField";
import { CreatePAModalsFlowContext } from "@screens/potential-analysis/parts/create-pa-modals-flow/create-pa-modals-flow.context";
import { addEditedFormToEditedFormsArray } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import { InputWrapper, Wrapper } from "./for-client-modal.styles";
import { ForClientModalProps } from "./for-client-modal.types";

export type FormState = {
	email: string;
};

export const ForClientModal: FC<ForClientModalProps> = ({ isVisible, onDismiss, onSuccess }) => {
	const { setContextState, contextState } = useContext(CreatePAModalsFlowContext);
	const handlePrimaryOnClick = () => {
		formik.submitForm();
	};
	const dispatch = useTypedDispatch();
	const formId = "pa-for-client";
	const formik = useFormik<FormState>({
		initialValues: {
			email: contextState.email,
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine richtige E-Mail-Adresse an.")
				.required("E-Mail-Adresse wird benötigt."),
		}),
		onSubmit: (values) => {
			setContextState((old) => ({ ...old, email: values.email }));
			onSuccess?.();
		},
	});

	return (
		<Dialog visible={isVisible} onHide={onDismiss} title={"AVGS Client"}>
			<DialogTemplate
				maxWidthRem={25}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				secondaryButtonColorVersion={ButtonVariant.Error}
				secondaryButtonStretch={false}
				primaryButtonLabel={`Weiter`}
				onPrimaryButtonClick={handlePrimaryOnClick}
			>
				<Wrapper>
					<EpicP2Span>
						Geben Sie hier die E-Mail-Adresse des AVGS Clients ein, um diesen nach
						Erstellung einzuladen.
					</EpicP2Span>
					<InputWrapper>
						<form onSubmit={formik.handleSubmit}>
							<ValidatedField<FormState, string>
								name={"email"}
								formikConfig={formik}
								component={DefaultTextFieldComponent}
								onChange={() =>
									dispatch(addEditedFormToEditedFormsArray({ form: formId! }))
								}
							/>
						</form>
					</InputWrapper>
				</Wrapper>
			</DialogTemplate>
		</Dialog>
	);
};
