/**
 * @generated SignedSource<<3a546d528155c83d35aada1dcf8e064c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditWordPackageInput = {
  clientMutationId?: string | null | undefined;
  data: WordPackageDataInput;
  wordPackageId: string;
};
export type WordPackageDataInput = {
  language: string;
  name: string;
};
export type editWordPackageButton_EditMutation$variables = {
  input: EditWordPackageInput;
};
export type editWordPackageButton_EditMutation$data = {
  readonly Admin: {
    readonly Vocab: {
      readonly editWordPackage: {
        readonly wordPackage: {
          readonly " $fragmentSpreads": FragmentRefs<"editWordPackageButton_WordPackage">;
        };
      } | null | undefined;
    };
  };
};
export type editWordPackageButton_EditMutation = {
  response: editWordPackageButton_EditMutation$data;
  variables: editWordPackageButton_EditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editWordPackageButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditWordPackagePayload",
                "kind": "LinkedField",
                "name": "editWordPackage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackage",
                    "kind": "LinkedField",
                    "name": "wordPackage",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editWordPackageButton_WordPackage"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editWordPackageButton_EditMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditWordPackagePayload",
                "kind": "LinkedField",
                "name": "editWordPackage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackage",
                    "kind": "LinkedField",
                    "name": "wordPackage",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "language",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Word",
                        "kind": "LinkedField",
                        "name": "associatedWords",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47add104d8946f4e7ed069e119a68053",
    "id": null,
    "metadata": {},
    "name": "editWordPackageButton_EditMutation",
    "operationKind": "mutation",
    "text": "mutation editWordPackageButton_EditMutation(\n  $input: EditWordPackageInput!\n) {\n  Admin {\n    Vocab {\n      editWordPackage(input: $input) {\n        wordPackage {\n          ...editWordPackageButton_WordPackage\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editWordPackageButton_WordPackage on WordPackage {\n  id\n  name\n  language\n  associatedWords {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b94a7ae3c512d4291a7dbfec541c2ec2";

export default node;
