import { ZonedDateTime } from "@js-joda/core";
import { Pagination, Table } from "@thekeytechnology/framework-react-components";
import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import {
	type editCoachAppointmentsTable_AvailabilityFragment$data,
	type editCoachAppointmentsTable_AvailabilityFragment$key,
} from "@relay/editCoachAppointmentsTable_AvailabilityFragment.graphql";
import { type editCoachAppointmentsTable_AvailabilityQueryFragment$key } from "@relay/editCoachAppointmentsTable_AvailabilityQueryFragment.graphql";
import { type editCoachAppointmentsTable_QueryRefetch } from "@relay/editCoachAppointmentsTable_QueryRefetch.graphql";
import { AVAILABILITY_FRAGMENT, QUERY_FRAGMENT } from "./edit-coach-appointments-table.graphql";
import { type EditCoachAppointmentsTableProps } from "./edit-coach-appointments-table.types";
import { EditParticipantsButton } from "../edit-participants-button/edit-participants-button.component";

export const EditCoachAppointmentsTable = ({ queryRef }: EditCoachAppointmentsTableProps) => {
	const { data, hasNext, loadNext } = usePaginationFragment<
		editCoachAppointmentsTable_QueryRefetch,
		editCoachAppointmentsTable_AvailabilityQueryFragment$key
	>(QUERY_FRAGMENT, queryRef);

	const availabilities =
		data?.Admin?.Coaching?.Availabilities?.edges?.map((e) =>
			readInlineData<editCoachAppointmentsTable_AvailabilityFragment$key>(
				AVAILABILITY_FRAGMENT,
				e!.node,
			),
		) ?? [];
	const buildDateEntry = (entry: editCoachAppointmentsTable_AvailabilityFragment$data) => {
		const startTime = new Date(
			ZonedDateTime.parse(entry.data.startDateTime).toOffsetDateTime().toString(),
		);
		const endTime = new Date(
			ZonedDateTime.parse(entry.data.endDateTime).toOffsetDateTime().toString(),
		);
		return `${startTime.toLocaleString()} - ${endTime.toLocaleString()}`;
	};

	const resolveBookingKind = (kind: string) => {
		switch (kind) {
			case "NotBooked":
				return "Noch nicht gebucht";
			case "BookedOneToOne":
				return "1-1 Coaching";
			case "BookedForGroup":
				return "Gruppencoaching";
			default:
				return kind;
		}
	};

	const resolveParticipantCount = (
		entry: editCoachAppointmentsTable_AvailabilityFragment$data,
	) => {
		if (entry.booking.kind === "BookedOneToOne") {
			return "1/1";
		} else {
			const participantCount = entry.booking.participants?.length ?? 0;
			const maxCount = entry.booking.offer?.setting.maxParticipants ?? 1;
			return `${participantCount}/${maxCount}`;
		}
	};

	const handleLoadNext = () => loadNext(20);

	return (
		<>
			<Table items={availabilities}>
				{(Column) => {
					return (
						<>
							<Column
								header={"Datum"}
								body={(entry) => <>{buildDateEntry(entry)}</>}
							/>
							<Column
								header={"Art"}
								body={(entry) => <>{resolveBookingKind(entry.booking.kind)}</>}
							/>
							<Column
								header={"Teilnehmeranzahl"}
								body={(entry) => <>{resolveParticipantCount(entry)}</>}
							/>
							<Column
								header={"Bearbeiten"}
								body={(entry) => (
									<EditParticipantsButton availabilityFragmentRef={entry} />
								)}
							></Column>
						</>
					);
				}}
			</Table>
			<Pagination hasNext={hasNext} loadNext={handleLoadNext}></Pagination>
		</>
	);
};
