import { type PathParams } from "@thekeytechnology/epic-ui";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { type coachAppointmentsEdit_AvailabilityQuery } from "@relay/coachAppointmentsEdit_AvailabilityQuery.graphql";
import { QUERY } from "./coach-appointments-edit.graphql";
import { type CoachAppointmentsPath } from "./coach-appointments-edit.paths";
import { EditCoachAppointmentsTable } from "./parts/edit-coach-appointments-table";
import { BaseScreen } from "../BaseScreen";

export const CoachAppointmentsEditScreen = () => {
	const { coachId } = useParams<PathParams<typeof CoachAppointmentsPath>>();

	const query = useLazyLoadQuery<coachAppointmentsEdit_AvailabilityQuery>(
		QUERY,
		{
			coachIds: coachId ? [coachId] : [],
		},
		{},
	);

	return (
		<BaseScreen>
			<h1>Gebuchte Coachings</h1>
			<EditCoachAppointmentsTable queryRef={query} />
		</BaseScreen>
	);
};
