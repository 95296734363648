import { graphql } from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_V2_FRAGMENT = graphql`
	fragment editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis {
		id
		isFinished
		client {
			kind
			... on AVGSPotentialAnalysis_InvitedForUser {
				email
			}
			... on AVGSPotentialAnalysis_AcceptedForUser {
				client {
					email
				}
			}
		}
	}
`;

export const CHANGE_INVITED_FOR_USER_MUTATION = graphql`
	mutation editInvitedForUserButton_ChangeInvitedForUserMutation(
		$input: ChangeInvitedForUserInput!
	) {
		Admin {
			AvgsPotentialAnalysis {
				changeInvitedForUser(input: $input) {
					potentialAnalysis {
						id
						...potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment
					}
				}
			}
		}
	}
`;
