/**
 * @generated SignedSource<<6747bf5fda5fea17a8aa371a2dc8f988>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AVGSPotentialAnalysis_ForUserKind = "AcceptedForUser" | "InvitedForUser";
import { FragmentRefs } from "relay-runtime";
export type potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment$data = {
  readonly client: {
    readonly client?: {
      readonly email: string;
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly email?: string;
    readonly kind: AVGSPotentialAnalysis_ForUserKind;
  };
  readonly coach: {
    readonly email: string;
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly createdAt: string;
  readonly id: string;
  readonly isFinished: boolean;
  readonly modules: ReadonlyArray<{
    readonly dimensions: ReadonlyArray<{
      readonly dimensionId: number;
      readonly dimensionText: string;
    }>;
    readonly moduleId: number;
    readonly moduleText: string;
  }>;
  readonly replacementCoach: {
    readonly email: string;
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment">;
  readonly " $fragmentType": "potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment";
};
export type potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment$key = {
  readonly " $data"?: potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment"
};

(node as any).hash = "53fcdfde6da4c694d8396b363f4b3744";

export default node;
