import { type FormikProps, useFormik } from "formik";
import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { type CreateCoachingOfferInput } from "../../../../__generated__/createCoachingOfferButton_CreateOfferMutation.graphql";
import { type RenderConfig, ValidatedField } from "../../../../components/ValidatedField";
import { type DefaultFormProps } from "../common";

// eslint-disable-next-line react/display-name
export const CreateCoachingOfferForm = React.forwardRef<
	FormikProps<CreateCoachingOfferInput>,
	DefaultFormProps<CreateCoachingOfferInput>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<CreateCoachingOfferInput>({
		initialValues: initialState ?? {
			kind: "OneToOne",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			kind: Yup.string().required("Feld 'Kind' wird benötigt"),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	const buildHandleOnChange = (
		values: DropdownChangeEvent,
		renderConfig: RenderConfig<string>,
	) => {
		renderConfig.updateField(values.value);
	};

	return (
		<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
			<ValidatedField<CreateCoachingOfferInput, string>
				name={"kind"}
				label={"Coachingtyp"}
				formikConfig={formik}
				component={(renderConfig) => (
					<Dropdown
						value={renderConfig.fieldValue}
						options={[
							{ label: "1-1 Coaching", value: "OneToOne" },
							{ label: "Gruppencoaching", value: "Group" },
						]}
						onChange={(v) => {
							buildHandleOnChange(v, renderConfig);
						}}
					/>
				)}
			/>
		</form>
	);
});
