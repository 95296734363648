import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { ButtonVariant } from "@components/button";
import { type editContractPartnerButton_ContractPartnerFragment$key } from "@relay/editContractPartnerButton_ContractPartnerFragment.graphql";
import { type editContractPartnerButton_EditContractPartnerMutation } from "@relay/editContractPartnerButton_EditContractPartnerMutation.graphql";
import { ContractPartnerForm } from "@screens/contract-partners/parts/contract-partner-form/contract-partner-form.component";
import { type ContractPartnerFormState } from "@screens/contract-partners/parts/contract-partner-form/contract-partner-form.types";
import {
	CONTRACT_PARTNER_FRAGMENT,
	EDIT_CONTRACT_PARTNER_MUTATION,
} from "@screens/contract-partners/parts/edit-contract-partner-button/edit-contract-partner-button.graphql";
import { type EditContractPartnerButtonProps } from "@screens/contract-partners/parts/edit-contract-partner-button/edit-contract-partner-button.types";
import { ButtonWrapper } from "./edit-contract-partner-button.styles";

export const EditContractPartnerButton = ({
	contractPartnerFragmentRef,
}: EditContractPartnerButtonProps) => {
	const contractPartner = useFragment<editContractPartnerButton_ContractPartnerFragment$key>(
		CONTRACT_PARTNER_FRAGMENT,
		contractPartnerFragmentRef,
	);
	const [editContractPartner, isEditingContractPartner] =
		useMutation<editContractPartnerButton_EditContractPartnerMutation>(
			EDIT_CONTRACT_PARTNER_MUTATION,
		);
	const handleSubmit = (values: ContractPartnerFormState, onHide: () => void) => {
		if (contractPartner) {
			editContractPartner({
				variables: {
					input: {
						id: contractPartner.id,
						shareAmountPercentage: values.shareAmountPercentage ?? 0,
						data: {
							city: values.city ?? "",
							email: values.email ?? "",
							association: values.association ?? "",
							country: values.country ?? "",
							houseNumber: values.houseNumber ?? "",
							linkToAGB: values.linkToAGB,
							name: values.name ?? "",
							postalCode: values.postalCode ?? "",
							street: values.street ?? "",
							taxNumber: values.taxNumber ?? "",
						},
					},
				},
				onCompleted: () => {
					toast.success("der Vertrags-Partner wurde erfolgreich bearbeitet.");
					onHide();
				},
				onError: () => {
					toast.error("der Vertrags-Partner konnte nicht bearbeitet werden.");
					onHide();
				},
			});
		}
	};
	return (
		<ButtonWrapper>
			<FormDialogButton<ContractPartnerFormState>
				disabled={isEditingContractPartner}
				buttonIcon={EpicIcons.FILE_EDIT}
				buttonVariant={ButtonVariant.Strong}
				dialogTitle={`Vertrags-Partner ${contractPartner.data.name} Bearbeiten`}
			>
				{({ formikRef, onHide }) => (
					<ContractPartnerForm
						initialState={{
							shareAmountPercentage: contractPartner.shareAmountPercentage,
							name: contractPartner.data.name,
							email: contractPartner.data.email,
							city: contractPartner.data.city ?? undefined,
							association: contractPartner.data.association ?? undefined,
							country: contractPartner.data.country ?? undefined,
							houseNumber: contractPartner.data.houseNumber ?? undefined,
							linkToAGB: contractPartner.data.linkToAGB ?? undefined,
							postalCode: contractPartner.data.postalCode ?? undefined,
							street: contractPartner.data.street ?? undefined,
							taxNumber: contractPartner.data.taxNumber ?? undefined,
						}}
						ref={formikRef}
						onSubmit={(values) => {
							handleSubmit(values, onHide);
						}}
					/>
				)}
			</FormDialogButton>
		</ButtonWrapper>
	);
};
