/**
 * @generated SignedSource<<185c3afd106f6c9b6c60b9a16f13125b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createExceptionModal_FreeCalenderWeeksFragment$data = ReadonlyArray<{
  readonly calendarWeek: number;
  readonly calendarYear: number;
  readonly " $fragmentType": "createExceptionModal_FreeCalenderWeeksFragment";
}>;
export type createExceptionModal_FreeCalenderWeeksFragment$key = ReadonlyArray<{
  readonly " $data"?: createExceptionModal_FreeCalenderWeeksFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createExceptionModal_FreeCalenderWeeksFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "createExceptionModal_FreeCalenderWeeksFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    }
  ],
  "type": "CalendarWeekAndYear",
  "abstractKey": null
};

(node as any).hash = "d9038b9c964bf0744cab481e9d340729";

export default node;
