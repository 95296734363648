import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment editParticipantsTable_AvailabilityFragment on Availability {
		id
		booking {
			kind
			... on BookedOneToOne {
				participant {
					...editParticipantsTable_ParticipantFragment
				}
			}
			... on BookedForGroup {
				offer {
					setting {
						... on GroupCoachingSetting {
							maxParticipants
						}
					}
				}
				participants {
					...editParticipantsTable_ParticipantFragment
				}
			}
		}
		data {
			startDateTime
			endDateTime
		}
	}
`;

export const PARTICIPANT_FRAGMENT = graphql`
	fragment editParticipantsTable_ParticipantFragment on Participant @inline {
		participationStatus
		user {
			groupAssociations {
				account {
					id
				}
			}
			id
			name
		}
	}
`;

export const ADD_PARTICIPANT_MUTATION = graphql`
	mutation editParticipantsTable_AddParticipantMutation($input: AddParticipantAdminInput!) {
		Admin {
			Coaching {
				addParticipant(input: $input) {
					availability {
						...editParticipantsTable_AvailabilityFragment
					}
				}
			}
		}
	}
`;

export const REMOVE_PARTICIPANT_MUTATION = graphql`
	mutation editParticipantsTable_RemoveParticipantMutation($input: RemoveParticipantAdminInput!) {
		Admin {
			Coaching {
				removeParticipant(input: $input) {
					availability {
						...editParticipantsTable_AvailabilityFragment
					}
				}
			}
		}
	}
`;

export const REGISTER_PARTICIPATION_MUTATION = graphql`
	mutation editParticipantsTable_RegisterParticipationMutation(
		$input: RegisterParticipationAdminInput!
	) {
		Admin {
			Coaching {
				registerParticipationAdmin(input: $input) {
					availability {
						...editParticipantsTable_AvailabilityFragment
					}
				}
			}
		}
	}
`;
