/**
 * @generated SignedSource<<a44ad90555ddcc40167ef21fa709a230>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type editCourseLearnableButton_UnpublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind?: LearnableKind;
  readonly root?: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "editCourseLearnableButton_UnpublishedLearnableFragment";
};
export type editCourseLearnableButton_UnpublishedLearnableFragment$key = {
  readonly " $data"?: editCourseLearnableButton_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editCourseLearnableButton_UnpublishedLearnableFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TreeNode",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editCourseLearnableButton_UnpublishedLearnableFragment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "UnpublishedCourseLearnable",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "UnpublishedMicroLearningLearnable",
      "abstractKey": null
    }
  ],
  "type": "UnpublishedLearnable",
  "abstractKey": "__isUnpublishedLearnable"
};
})();

(node as any).hash = "e9e041b0cf3117c915533fcc92a1c1dc";

export default node;
