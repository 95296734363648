/**
 * @generated SignedSource<<0bf76195a686697160c89602c1358411>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type showCoachFeedbacksTable_CoachFeedbackFragment$data = {
  readonly createdAt: string;
  readonly data: {
    readonly comment: string | null | undefined;
    readonly score: number;
  };
  readonly feedbackGiver: {
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "showCoachFeedbacksTable_CoachFeedbackFragment";
};
export type showCoachFeedbacksTable_CoachFeedbackFragment$key = {
  readonly " $data"?: showCoachFeedbacksTable_CoachFeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"showCoachFeedbacksTable_CoachFeedbackFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "showCoachFeedbacksTable_CoachFeedbackFragment"
};

(node as any).hash = "04a4c7c5d0a1280730577f2e77e098dc";

export default node;
