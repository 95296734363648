/**
 * @generated SignedSource<<752e7e713b711b753b644e9ad9cf2982>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment$data = {
  readonly extension: {
    readonly contractPartner?: {
      readonly data: {
        readonly name: string;
      };
      readonly id: string;
    } | null | undefined;
  };
  readonly " $fragmentType": "setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment";
};
export type setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment$key = {
  readonly " $data"?: setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContractPartner",
              "kind": "LinkedField",
              "name": "contractPartner",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContractPartnerData",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ConstellationRootExtensionExtension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RootExtensionImpl",
  "abstractKey": null
};

(node as any).hash = "f51d2efe447075a3064cf4a8fd831ff1";

export default node;
