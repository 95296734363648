import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import {
	SelectContractPartnerField,
	type SelectContractPartnerFieldState,
} from "@features/contract-partners/select-contract-partner-field";
import { type setContractPartnerInRootExtensionImplForm_QueryFragment$key } from "@relay/setContractPartnerInRootExtensionImplForm_QueryFragment.graphql";
import { type setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment$key } from "@relay/setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment.graphql";
import { type setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation } from "@relay/setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation.graphql";
import {
	type SetContractPartnerInRootExtensionImplFormProps,
	type SetContractPartnerInRootExtensionImplFormState,
} from "./set-contract-partner-in-root-extension-form.types";
import {
	QUERY_FRAGMENT,
	ROOT_EXTENSION_IMPL_FRAGMENT,
	SET_CONTRACT_PARTNER_IN_CONSTELLATION_ROOT_EXTENSION_EXTENSION_MUTATION,
} from "./set-contract-partner-in-root-extension-impl-form.graphql";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	removeEditedFormFromEditedFormsArray,
	addEditedFormToEditedFormsArray,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

export const SetContractPartnerInRootExtensionImplForm = ({
	rootId,
	queryFragmentRef,
	rootExtensionImplFragmentRef,
}: SetContractPartnerInRootExtensionImplFormProps) => {
	const query = useFragment<setContractPartnerInRootExtensionImplForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const extension =
		useFragment<setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment$key>(
			ROOT_EXTENSION_IMPL_FRAGMENT,
			rootExtensionImplFragmentRef,
		);

	const dispatch = useTypedDispatch();

	const [
		setContractPartnerInConstellationRootExtensionExtension,
		isSettingContractPartnerInConstellationRootExtensionExtension,
	] =
		useMutation<setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation>(
			SET_CONTRACT_PARTNER_IN_CONSTELLATION_ROOT_EXTENSION_EXTENSION_MUTATION,
		);

	const formId = "SetContractPartnerInRootExtensionImplForm";
	const formik = useFormik<SetContractPartnerInRootExtensionImplFormState>({
		enableReinitialize: true,
		initialValues: {
			contractPartner: extension.extension.contractPartner
				? {
						id: extension.extension.contractPartner.id,
						name: extension.extension.contractPartner.data.name,
				  }
				: undefined,
		},
		validationSchema: Yup.object().shape({
			contractPartner: Yup.object().optional(),
		}),
		onSubmit: (values, { setSubmitting }) => {
			setContractPartnerInConstellationRootExtensionExtension({
				variables: {
					input: {
						rootId,
						contractPartner: values.contractPartner?.id,
					},
				},
				onCompleted: () => {
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	return (
		<Card className="mb-2">
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<
					SetContractPartnerInRootExtensionImplFormState,
					SelectContractPartnerFieldState
				>
					name={"contractPartner"}
					label={"Vertragspartner"}
					placeholder="Vertragspartner auswählen..."
					component={(renderConfig) => (
						<SelectContractPartnerField {...renderConfig} queryFragmentRef={query} />
					)}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<Button
					disabled={
						Object.entries(formik.touched).length === 0 ||
						isSettingContractPartnerInConstellationRootExtensionExtension
					}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};
