import { EpicIcons } from "@thekeytechnology/epic-ui";
import { OverlayPanel } from "primereact/overlaypanel";
import { type MouseEvent, useRef } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { type sendEmailButton_SendEmailMutation } from "@relay/sendEmailButton_SendEmailMutation.graphql";
import { SEND_EMAIL_MUTATION } from "./send-email-button.graphql";
import { ButtonWrapper } from "./send-email-button.styles";
import { type SendEmailButtonProps } from "./send-email-button.types";
import { SendEmailForm } from "../send-email-form";
import { type SendEmailFormState } from "../send-email-form/send-email-form.types";

export const SendEmailButton = ({ personalizedEmailTemplateId }: SendEmailButtonProps) => {
	const [sendEmail] = useMutation<sendEmailButton_SendEmailMutation>(SEND_EMAIL_MUTATION);

	const ref = useRef<OverlayPanel>(null);
	const handleOnSubmit = ({ email }: SendEmailFormState) => {
		if (email) {
			sendEmail({
				variables: {
					input: {
						email,
						personalizedEmailTemplateId,
					},
				},
				onCompleted: () => {
					toast.success("E-Mail wurde versendet");
				},
				onError: () => {
					toast.error("Leider ist ein Fehler aufgetreten");
				},
			});
		}
	};

	const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
		ref.current?.toggle(e);
	};

	return (
		<ButtonWrapper>
			<Button icon={EpicIcons.SEND} variant={ButtonVariant.Strong} onClick={handleOnClick} />
			<OverlayPanel ref={ref}>
				<SendEmailForm
					onSubmit={(values: SendEmailFormState) => {
						handleOnSubmit(values);
					}}
				/>
			</OverlayPanel>
		</ButtonWrapper>
	);
};
