import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editProfileDataButton_CoachProfileFragment$key } from "@relay/editProfileDataButton_CoachProfileFragment.graphql";
import { type editProfileDataButton_EditProfileDataMutation } from "@relay/editProfileDataButton_EditProfileDataMutation.graphql";
import {
	COACH_PROFILE_FRAGMENT,
	EDIT_PROFILE_DATA_MUTATION,
} from "./edit-profile-data-button.graphql";
import {
	type EditProfileDataButtonProps,
	type EditProfileDataFormState,
} from "./edit-profile-data-button.types";
import { type FileV2 } from "../../../../features/files/file-selection-field";
import { CoachProfilesScreenContext } from "../../coach-profiles.context";
import { EditProfileDataForm } from "../edit-profile-data-form";

export const EditProfileDataButton = ({ coachProfileFragmentRef }: EditProfileDataButtonProps) => {
	const { connectionId } = useContext(CoachProfilesScreenContext);
	const { id: coachProfileId, profileData } =
		useFragment<editProfileDataButton_CoachProfileFragment$key>(
			COACH_PROFILE_FRAGMENT,
			coachProfileFragmentRef,
		);
	const [edit, isEditing] = useMutation<editProfileDataButton_EditProfileDataMutation>(
		EDIT_PROFILE_DATA_MUTATION,
	);

	const handleOnSubmit = async (values: EditProfileDataFormState) => {
		const {
			avatar,
			basicPersonal,
			experience,
			referencesCoachingExperienceIds,
			certificateFileIds,
			...allValues
		} = values;

		return await new Promise((resolve, reject) => {
			edit({
				variables: {
					input: {
						profileData: {
							...allValues,
							avatarId: avatar?.id ?? null,
							description: values.description,
							jobTitles: values.jobTitles ?? null,
							quote: values.quote,
							socials: values.socials,
							certificateAnswer: values.certificateAnswer,
							experience: {
								branches: experience?.branches ?? [],
								branchesSteps: experience?.branchesSteps ?? [],
								coachingFileIds: experience?.coachingFileIds?.id
									? [experience.coachingFileIds.id]
									: [],
								coachingHours: experience?.coachingHours,
							},
							basicPersonal: {
								salutation: basicPersonal?.salutation,
								email: basicPersonal?.email,
								firstName: basicPersonal?.firstName,
								lastName: basicPersonal?.lastName,
								title: basicPersonal?.title,
								phoneNumber: basicPersonal?.phoneNumber,
							},
							certificateFileIds: certificateFileIds?.id
								? [certificateFileIds.id]
								: [],
							referencesCoachingExperienceIds: referencesCoachingExperienceIds?.id
								? [referencesCoachingExperienceIds.id]
								: [],
							coachingStrongPointsTopics: allValues.coachingStrongPointsTopics ?? [],
							languages: allValues.languages ?? [],
							methods: allValues.methods ?? [],
						},
						coachProfileId,
					},
					connections: [connectionId],
				},
				onCompleted: () => {
					resolve(true);
					toast.success("ProfileData angepasst.");
				},
				onError: () => {
					reject();
					toast.error("Fehler beim Anpassen der ProfileData.");
				},
			});
		});
	};

	const initialState: EditProfileDataFormState = {
		...profileData,
		avatar: profileData?.avatar,
		description: profileData?.description ?? "",
		jobTitles: [...(profileData.jobTitles ?? [])],
		quote: profileData.quote ?? "",
		socials: {
			facebook: profileData.socials?.facebook ?? "",
			linkedIn: profileData.socials?.linkedIn ?? "",
			twitter: profileData.socials?.twitter ?? "",
			website: profileData.socials?.website ?? "",
			others: [...(profileData.socials?.others ?? [])],
		},
		basicPersonal: {
			salutation: profileData.basicPersonal.salutation,
			title: profileData.basicPersonal.title,
			firstName: profileData.basicPersonal.firstName,
			lastName: profileData.basicPersonal.lastName,
			email: profileData.basicPersonal.email,
			phoneNumber: profileData.basicPersonal.phoneNumber,
		},
		languages: [...profileData.languages],
		studies: profileData.studies,
		certificateFileIds: [...profileData.certificateFileIds]?.shift() as FileV2 | undefined,
		certificateAnswer: profileData.certificateAnswer,
		experience: {
			coachingFileIds: [...profileData.experience.coachingFileIds]?.shift() as
				| FileV2
				| undefined,
			coachingHours: profileData.experience.coachingHours,
			branches: [...profileData.experience.branches],
			branchesSteps: [...profileData.experience.branchesSteps],
		},
		coachingStrongPointsTopics: [...profileData.coachingStrongPointsTopics],
		methods: [...profileData.methods],
		referencesCoachingExperienceIds: [
			...profileData.referencesCoachingExperienceIds,
		]?.shift() as FileV2 | undefined,
		referencesPublications: profileData.referencesPublications ?? "",
		digitalAffinity1: profileData.digitalAffinity1,
		digitalAffinity2: profileData.digitalAffinity2,
		personalPhilosophyCoachingStyle: profileData.personalPhilosophyCoachingStyle,
		educationMoreThan150h: profileData.educationMoreThan150h,
		personalPhilosophyVisionMission: profileData.personalPhilosophyVisionMission,
		educationFreeText: profileData.educationFreeText,
	};
	return (
		<FormDialogButton<EditProfileDataFormState>
			disabled={isEditing}
			buttonIcon={EpicIcons.PENCIL}
			dialogTitle={"ProfileData ändern"}
			tooltip="ProfileData ändern"
		>
			{({ formikRef }) => {
				return (
					<EditProfileDataForm
						initialState={initialState}
						ref={formikRef}
						onSubmit={handleOnSubmit}
					/>
				);
			}}
		</FormDialogButton>
	);
};
