/**
 * @generated SignedSource<<dc2cf73b6a12f0b09ff40aea8b6b8ac3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateDiscountCodeInput = {
  clientMutationId?: string | null | undefined;
  code: string;
  discountActionId: string;
};
export type CreateSingleCodeButton_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateDiscountCodeInput;
};
export type CreateSingleCodeButton_CreateMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createDiscountCode: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeTable_DiscountCodeFragment">;
          };
        }>;
      } | null | undefined;
    };
  };
};
export type CreateSingleCodeButton_CreateMutation = {
  response: CreateSingleCodeButton_CreateMutation$data;
  variables: CreateSingleCodeButton_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSingleCodeButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountCodePayload",
                "kind": "LinkedField",
                "name": "createDiscountCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "DiscountCodeTable_DiscountCodeFragment",
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeIdColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeCodeColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeNumUsagesColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeCreatedAtColumn_DiscountCodeFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "CodeActionColumn_DiscountCodeFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateSingleCodeButton_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateDiscountCodePayload",
                "kind": "LinkedField",
                "name": "createDiscountCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numUsages",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "prependEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edges",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d319b7012bf5c3eb72474fe73b66274",
    "id": null,
    "metadata": {},
    "name": "CreateSingleCodeButton_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSingleCodeButton_CreateMutation(\n  $input: CreateDiscountCodeInput!\n) {\n  Admin {\n    Billing {\n      createDiscountCode(input: $input) {\n        edges {\n          node {\n            ...DiscountCodeTable_DiscountCodeFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CodeActionColumn_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment CodeCodeColumn_DiscountCodeFragment on DiscountCode {\n  code\n}\n\nfragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCode {\n  createdAt\n}\n\nfragment CodeIdColumn_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCode {\n  numUsages\n}\n\nfragment DiscountCodeTable_DiscountCodeFragment on DiscountCode {\n  ...CodeIdColumn_DiscountCodeFragment\n  ...CodeCodeColumn_DiscountCodeFragment\n  ...CodeNumUsagesColumn_DiscountCodeFragment\n  ...CodeCreatedAtColumn_DiscountCodeFragment\n  ...CodeActionColumn_DiscountCodeFragment\n}\n"
  }
};
})();

(node as any).hash = "4d960f7ac39ede99dc7f066995e14649";

export default node;
