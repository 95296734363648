import { Tag as PrTag } from "primereact/tag";
import styled from "styled-components";
import tw from "twin.macro";

export const Tag = styled(PrTag)<{ selected: boolean }>`
	background-color: ${({ selected }) => (selected ? "" : "#afafaf")};
	&:hover {
		cursor: pointer;
	}
`;
export const AvailabilityRow = tw.div`
	tw-grid
	tw-grid-rows-[min-content_1fr]
	tw-grid-cols-[1fr_min-content]
	md:tw-grid-cols-[min-content_1fr]
	tw-gap-16
	tw-pb-8
`;

export const SwitchWrapper = tw.div`
	tw-col-start-2
	md:tw-col-start-1
	tw-col-end-3
	md:tw-col-end-2
	tw-row-start-1
`;

export const HeaderWrapper = tw.div`
	tw-col-start-1
	md:tw-col-start-2
	tw-col-end-2
	md:tw-col-end-3
	tw-row-start-1
	tw-flex
	tw-flex-col
`;

export const SlotsWrapper = tw.div`
	tw-col-start-1
	md:tw-col-start-2
	tw-col-end-3
	tw-flex
	tw-flex-wrap
	tw-gap-8
`;
