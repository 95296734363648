import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useContext, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { Button, ButtonVariant } from "@components/button";
import { ConfirmWithTextDialog } from "@components/confirm-with-text-dialog";
import { COACHING_OFFER_FRAGMENT, DELETE_MUTATION } from "./delete-coaching-offer-button.graphql";
import { type DeleteCoachingOfferButtonProps } from "./delete-coaching-offer-button.types";
import { type deleteCoachingOfferButton_CoachingOfferFragment$key } from "../../../../__generated__/deleteCoachingOfferButton_CoachingOfferFragment.graphql";
import { type deleteCoachingOfferButton_DeleteCoachingOfferMutation } from "../../../../__generated__/deleteCoachingOfferButton_DeleteCoachingOfferMutation.graphql";
import { OffersScreenContext } from "../../offers.context";

export const DeleteCoachingOfferButton = ({ offerFragmentRef }: DeleteCoachingOfferButtonProps) => {
	const offer = useFragment<deleteCoachingOfferButton_CoachingOfferFragment$key>(
		COACHING_OFFER_FRAGMENT,
		offerFragmentRef,
	);
	const { connectionId } = useContext(OffersScreenContext);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

	const coaching = offer.groupCoaching ?? offer.oneToOneCoaching;

	const [dispatchDelete] =
		useMutation<deleteCoachingOfferButton_DeleteCoachingOfferMutation>(DELETE_MUTATION);
	const handleOnClick = () => {
		dispatchDelete({
			variables: {
				input: { coachingId: coaching!.id },
				connections: [connectionId],
			},
			onCompleted: () => {
				setShowConfirmDeleteDialog(false);
				toast.success("Coaching wurde erfolgreich gelöscht.");
			},
		});
	};

	return (
		<>
			{showConfirmDeleteDialog && (
				<ConfirmWithTextDialog
					title={`Sind Sie sicher, dass Sie "${coaching?.description?.name}" löschen möchten?`}
					content={
						<>
							<div>
								Das Coachingangebot "{coaching?.description?.name}" und alle
								zugehörigen Daten werden gelöscht. Diese Aktion kann nicht
								rückgängig gemacht werden.
							</div>
							<div className="mt-4">
								Um das Coaching dennoch zu löschen, geben Sie bitte den Namen des
								Coachingangebots ein:
							</div>
						</>
					}
					onCancel={() => {
						setShowConfirmDeleteDialog(false);
					}}
					onConfirm={handleOnClick}
					textToConfirm={coaching?.description?.name!}
				/>
			)}
			<Button
				onClick={() => {
					setShowConfirmDeleteDialog(true);
				}}
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				tooltip={"Coaching löschen"}
			/>
		</>
	);
};
