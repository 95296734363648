import tw from "twin.macro";

export const ButtonWrapper = tw.div`
    tw-flex
    tw-flex-row
    tw-gap-4
`;

export const ButtonWrapperCenter = tw.div`
	tw-flex
	tw-justify-center
    tw-mt-8
`;
