import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useContext, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { createPaButton_QueryFragment$key } from "@relay/createPaButton_QueryFragment.graphql";
import { CreatePaButtonProps } from "@screens/potential-analysis/parts/create-pa-button/create-pa-button.types";
import { CreatePAModalsFlow } from "@screens/potential-analysis/parts/create-pa-modals-flow";
import {
	CreatePAModalsFlowContext,
	CreatePAModalsFlowContextProvider,
} from "@screens/potential-analysis/parts/create-pa-modals-flow/create-pa-modals-flow.context";
import { QUERY_FRAGMENT } from "./create-pa-button.grapqhl";

const CreatePaButtonComponent = ({ queryFragmentRef }: CreatePaButtonProps) => {
	const { resetContextState } = useContext(CreatePAModalsFlowContext);
	const queryFragment = useFragment<createPaButton_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const [showCreatePAFlow, setShowCreatePAFlow] = useState(false);
	const toggle = () => {
		setShowCreatePAFlow((old) => !old);
	};
	const handleOnClick = () => {
		toggle();
	};
	const handleOnDismiss = () => {
		toggle();
		resetContextState();
	};
	return (
		<>
			<Button onClick={handleOnClick} icon={EpicIcons.PLUS} />
			{showCreatePAFlow && (
				<CreatePAModalsFlow
					queryFragmentRef={queryFragment}
					isVisible
					onDismiss={handleOnDismiss}
				/>
			)}
		</>
	);
};

export const CreatePaButton = (props: CreatePaButtonProps) => {
	return (
		<CreatePAModalsFlowContextProvider>
			<CreatePaButtonComponent {...props} />{" "}
		</CreatePAModalsFlowContextProvider>
	);
};
