/**
 * @generated SignedSource<<605828fbd25b5c64ea6fbf0b4d5bf564>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment" | "editVideoSourceButton_Vimeo_VimeoVideoSourceFragment" | "setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment">;
  readonly " $fragmentType": "videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment";
};
export type videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$key = {
  readonly " $data"?: videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editVideoSourceButton_Vimeo_VimeoVideoSourceFragment"
    }
  ],
  "type": "Vimeo_VimeoVideoSource",
  "abstractKey": null
};

(node as any).hash = "7908ce1fe5f35d1a07d48670c64aa5ff";

export default node;
