import { type FormikProps, useFormik } from "formik";
import React, { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { videoSourceForm_Vimeo_VimeoVideoSourceFragment$key } from "@relay/videoSourceForm_Vimeo_VimeoVideoSourceFragment.graphql";
import { getVideoSourceFormSchema } from "@screens/video-sources/parts/video-source-form/video-source-form.consts";
import { VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT } from "@screens/video-sources/parts/video-source-form/video-source-form.graphql";
import {
	VideoSourceFormProps,
	VideoSourceFormState,
} from "@screens/video-sources/parts/video-source-form/video-source-form.types";

export const VideoSourceForm = forwardRef<FormikProps<VideoSourceFormState>, VideoSourceFormProps>(
	function VideoSourceForm({ onSubmit, videoSourceFragmentRef, isCreating = false }, ref) {
		const videoSource = useFragment<videoSourceForm_Vimeo_VimeoVideoSourceFragment$key>(
			VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
			videoSourceFragmentRef ?? null,
		);

		const initialValues: VideoSourceFormState = {
			name: videoSource?.name ?? "",
			accessToken: "",
		};

		const formik = useFormik<VideoSourceFormState>({
			initialValues,
			validationSchema: getVideoSourceFormSchema(isCreating),
			onSubmit,
		});

		useImperativeHandle(ref, () => ({
			...formik,
		}));

		return (
			<form onSubmit={formik.handleSubmit}>
				<ValidatedField<VideoSourceFormState, string>
					name={"name"}
					label="Name"
					required={true}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				{isCreating && (
					<ValidatedField<VideoSourceFormState, string>
						name={"accessToken"}
						label="Vimeo-Access-Token"
						required={true}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
				)}
			</form>
		);
	},
);
