import { EpicIcons } from "@thekeytechnology/epic-ui";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation } from "../../../__generated__/CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation.graphql";
import { E_LEARNING_I18N_KEY, E_LEARNING_I18N_MAP } from "../../../translations/tree";

const CREATE_MULTIPLE_CHOICE_ELEMENT_MUTATION = graphql`
	mutation CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation(
		$input: CreateMultipleChoiceElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createMultipleChoiceElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateMultipleChoiceElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [createMultipleChoiceElement, isCreatingMultipleChoiceElement] =
		useMutation<CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation>(
			CREATE_MULTIPLE_CHOICE_ELEMENT_MUTATION,
		);

	const handleCreateOnClick = () => {
		createMultipleChoiceElement({
			variables: {
				input: {
					title: "Neues Element",
					question: "",
					answeringType: "allowOnlyOneAnswer",
					answerOptions: [],
					eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingMultipleChoiceElement}
			tooltip={`${E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.multipleChoiceElement)} anlegen`}
			icon={EpicIcons.LIST}
			onClick={handleCreateOnClick}
		/>
	);
};
