import { graphql } from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment createEducationalParticipationCertificateRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

export const ADD_EDUCATIONAL_PARTICIPATION_CERTIFICATE_REWARD_MUTATION = graphql`
	mutation createEducationalParticipationCertificateRewardButton_AddParticipationCertificateRewardMutation(
		$input: AddEducationalParticipationCertificateRewardInput!
	) {
		Admin {
			Tree {
				addEducationalParticipationCertificateReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
