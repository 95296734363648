import { DefaultNumberFieldComponent } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import {
	DefaultTextFieldComponent,
	DefaultPriceFieldComponent,
} from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { RootCostFormProps, RootCostFormState } from "./root-cost-form.interface";

export const RootCostForm = ({ onSubmit, initialValues }: RootCostFormProps) => {
	const formik = useFormik<RootCostFormState>({
		initialValues,
		validationSchema: Yup.object().shape({
			price: Yup.number()
				.min(0.01, "Der Preis muss mindestens 0.01 € betragen.")
				.required("Das Feld Kosten wird benötigt."),
			percentageShare: Yup.number()
				.min(0, "Anteil darf nicht kleiner als 0 sein")
				.max(100, "der Anteil darf nicht höher sein als 100%"),
			supplier: Yup.string().required("Das Feld Zulieferer wird benötigt."),
		}),
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(false);
			onSubmit?.(values);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<RootCostFormState, number>
				required
				name={"price"}
				label={"Kosten"}
				component={DefaultPriceFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<RootCostFormState, number>
				required
				name={"percentageShare"}
				label={"Vertragspartner-Anteil in % (0% bedeutet: Kein Anteil)"}
				component={DefaultNumberFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<RootCostFormState, string>
				required
				name={"supplier"}
				label={"Zulieferer"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<Button
				disabled={Object.entries(formik.touched).length === 0}
				type="submit"
				label="Fertig"
				className="p-mt-2"
			/>
		</form>
	);
};
