import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment videoSourcesTable_QueryFragment on Query
	@refetchable(queryName: "videoSourcesTable_QueryRefetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		last: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
	) {
		Admin {
			VimeoVideoSource {
				VimeoVideoSources(before: $before, after: $after, first: $first, last: $last)
					@connection(key: "videoSourcesTable_VimeoVideoSources") {
					__id
					edges {
						node {
							...videoSourcesTable_Vimeo_VimeoVideoSourceFragment
						}
					}
					pageInfo {
						endCursor
						hasNextPage
						hasPreviousPage
						startCursor
					}
				}
			}
		}
	}
`;

export const VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT = graphql`
	fragment videoSourcesTable_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource @inline {
		id
		name
		isDefault
		accessToken
		...videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment
	}
`;
