import { Paths } from "@routes/paths";
import type { RouteDefinition } from "@routes/route-definition";
import { TrendEditScreen } from "./trend-edit.screen";

export const TrendEditRoutes: RouteDefinition[] = [
	{
		path: Paths.trends.withIdPlaceholder().edit.path,
		element: <TrendEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_AuthAdmin_Read",
			"UserInAccountPermission_AuthAdmin_Modify",
		],
	},
];
