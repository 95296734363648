import { DataTable as PRDataTable } from "primereact/datatable";
import React, { Children } from "react";
import { type DataTableProps } from "./data-table.interface";
import { Column } from "../column/column.component";

export const DataTable = <T,>({ children, ...props }: DataTableProps<T>) => {
	const renderenChildren = Children.map(children?.(Column), (child: any) => {
		if (child?.type === React.Fragment) {
			return child?.props.children;
		}
		return child;
	});

	return <PRDataTable {...(props as any)}>{renderenChildren}</PRDataTable>;
};
