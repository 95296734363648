import { type AdminIcons } from "@components/icon/icon.types";
import { type TrendMediaInput } from "@relay/trendForm_CreateTrendMutation.graphql";
import { type Advantage, type TrendInputFormState } from "../parts/trend-form/trend-form.types";

export const transformMediaData = (values: TrendInputFormState): TrendMediaInput => {
	if (values.trendMedia.fileType === "video/mp4") {
		return {
			video: {
				fileId: values.trendMedia.id,
				trendMediaType: "video",
			},
		};
	} else {
		return {
			image: {
				fileId: values.trendMedia.id,
				trendMediaType: "image",
			},
		};
	}
};

export const transformAdvantages = (advantages: Advantage[]) => {
	return advantages.map((adv) => ({
		heading: adv.heading,
		icon: adv.icon as AdminIcons,
		description: adv.description,
	}));
};
