import { graphql } from "babel-plugin-relay/macro";

export const CREATE_COACH_PROFILE_MUTATION = graphql`
	mutation createCoachProfileButton_CreateCoachProfileMutation(
		$input: CreateCoachProfileInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				createCoachProfile(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...coachProfilesTable_CoachProfileFragment
						}
					}
				}
			}
		}
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment createCoachProfileButton_QueryFragment on Query {
		...createCoachProfileForm_QueryFragment
	}
`;
