/**
 * @generated SignedSource<<4169ee1b8603ec9ba790b39cc70b6994>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetMatrixElementColumnTitleInput = {
  clientMutationId?: string | null | undefined;
  idx: number;
  matrixElementId: string;
  newTitle: string;
};
export type displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation$variables = {
  input: SetMatrixElementColumnTitleInput;
};
export type displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly setMatrixElementColumnTitle: {
        readonly matrixElement: {
          readonly " $fragmentSpreads": FragmentRefs<"matrixFieldElement_MatrixElementFragment">;
        };
      } | null | undefined;
    };
  };
};
export type displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation = {
  response: displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation$data;
  variables: displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetMatrixElementColumnTitlePayload",
                "kind": "LinkedField",
                "name": "setMatrixElementColumnTitle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatrixElement",
                    "kind": "LinkedField",
                    "name": "matrixElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "matrixFieldElement_MatrixElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetMatrixElementColumnTitlePayload",
                "kind": "LinkedField",
                "name": "setMatrixElementColumnTitle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatrixElement",
                    "kind": "LinkedField",
                    "name": "matrixElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "cells",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "xIdx",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "yIdx",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "DisplayMatrixElementCell",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MatrixElementCellCorrectAnswer",
                                "kind": "LinkedField",
                                "name": "correctAnswer",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MatrixElementCellWrongAnswer",
                                "kind": "LinkedField",
                                "name": "wrongAnswers",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "AnswerMatrixElementCell",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bfb341421032f1617353615ff6c73a0b",
    "id": null,
    "metadata": {},
    "name": "displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation",
    "operationKind": "mutation",
    "text": "mutation displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation(\n  $input: SetMatrixElementColumnTitleInput!\n) {\n  Admin {\n    ElearningV2 {\n      setMatrixElementColumnTitle(input: $input) {\n        matrixElement {\n          ...matrixFieldElement_MatrixElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment answerMatrixElementCellForm_AnswerMatrixElementCellFragment on AnswerMatrixElementCell {\n  xIdx\n  yIdx\n  correctAnswer {\n    content\n  }\n  wrongAnswers {\n    content\n    id\n  }\n  ...editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment\n}\n\nfragment displayMatrixElementCellForm_DisplayMatrixElementCellFragment on DisplayMatrixElementCell {\n  id\n  content\n  xIdx\n  yIdx\n}\n\nfragment editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment on AnswerMatrixElementCell {\n  id\n  correctAnswer {\n    content\n  }\n  wrongAnswers {\n    content\n    id\n  }\n  ...editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment\n}\n\nfragment editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment on AnswerMatrixElementCell {\n  id\n}\n\nfragment matrixFieldElement_MatrixElementFragment on MatrixElement {\n  id\n  cells {\n    __typename\n    id\n    kind\n    xIdx\n    yIdx\n    ... on DisplayMatrixElementCell {\n      ...displayMatrixElementCellForm_DisplayMatrixElementCellFragment\n    }\n    ... on AnswerMatrixElementCell {\n      ...answerMatrixElementCellForm_AnswerMatrixElementCellFragment\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c43e4c392421b446deeb620680da53dc";

export default node;
