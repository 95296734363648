import { type FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { type TreeLicenseDefinitionFormState } from "./tree-license-definition-form.types";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";
import { type DefaultFormProps } from "../../../screens/offers/parts/common";
import { addEditedFormToEditedFormsArray } from "../../../store/slices/CoreSlice";
import { SelectPublishedRootField } from "../../educational-offer/select-published-root-field";
import { type SelectPublishedRootFieldState } from "../../educational-offer/select-published-root-field/select-published-root-field.types";

export const TreeLicenseDefinitionForm = React.forwardRef<
	FormikProps<TreeLicenseDefinitionFormState>,
	DefaultFormProps<TreeLicenseDefinitionFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<TreeLicenseDefinitionFormState>({
		initialValues: initialState ?? {
			root: undefined,
			name: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			root: Yup.object().required("Weiterbildung wird benötigt"),
			name: Yup.string().required("Lizenzdefinitionsname wird benötigt"),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));
	const formId = "EditCoachingLicenseDefinitionForm";
	const dispatch = useDispatch();

	return (
		<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
			<ValidatedField<TreeLicenseDefinitionFormState, string>
				name={"name"}
				label={"Name"}
				placeholder="Namen eingeben..."
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<TreeLicenseDefinitionFormState, SelectPublishedRootFieldState>
				name={"root"}
				label={"Weiterbildung"}
				placeholder="Weiterbildung auswählen..."
				component={SelectPublishedRootField}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
		</form>
	);
});
TreeLicenseDefinitionForm.displayName = "TreeLicenseDefinitionForm";
