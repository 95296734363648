/**
 * @generated SignedSource<<7ed124078f90402e76e41b140d7daa21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type trendFragment_Refetch$variables = {
  id: string;
  skip: boolean;
};
export type trendFragment_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"trendForm_TrendFragment">;
};
export type trendFragment_Refetch = {
  response: trendFragment_Refetch$data;
  variables: trendFragment_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "trendFragment_Refetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "skip",
            "variableName": "skip"
          }
        ],
        "kind": "FragmentSpread",
        "name": "trendForm_TrendFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "trendFragment_Refetch",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrendData",
                    "kind": "LinkedField",
                    "name": "trendData",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "visible",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "tags",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Advantage",
                        "kind": "LinkedField",
                        "name": "advantages",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "trendMedia",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "file",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "coachingImage",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerData",
                    "kind": "LinkedField",
                    "name": "customerData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amountCustomer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerFeedback",
                        "kind": "LinkedField",
                        "name": "customerFeedbacks",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeadingAndDescription",
                    "kind": "LinkedField",
                    "name": "aboutOffersHeadingAndDescription",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeadingAndDescription",
                    "kind": "LinkedField",
                    "name": "coachingHeadingAndDescription",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeadingAndDescription",
                    "kind": "LinkedField",
                    "name": "potentialAnalysisHeadingAndDescription",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "type": "Trend",
                "abstractKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b04ffd8171bc577752d76947dfa4ce5d",
    "id": null,
    "metadata": {},
    "name": "trendFragment_Refetch",
    "operationKind": "query",
    "text": "query trendFragment_Refetch(\n  $id: ID!\n  $skip: Boolean!\n) {\n  ...trendForm_TrendFragment_3hG2L4\n}\n\nfragment trendForm_TrendFragment_3hG2L4 on Query {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Trend {\n      trendData {\n        name\n        description\n        icon\n        visible\n        tags {\n          __typename\n          id\n        }\n        advantages {\n          description\n          heading\n          icon\n        }\n      }\n      trendMedia {\n        __typename\n        file {\n          id\n          name\n          fileType\n        }\n        kind\n      }\n      coachingImage {\n        id\n        name\n      }\n      customerData {\n        amountCustomer\n        customerFeedbacks {\n          id\n        }\n      }\n      aboutOffersHeadingAndDescription {\n        description\n        heading\n      }\n      coachingHeadingAndDescription {\n        description\n        heading\n      }\n      potentialAnalysisHeadingAndDescription {\n        description\n        heading\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "81973a62c9fd75f1c1266ffed45dd355";

export default node;
