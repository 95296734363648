import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_SCHEDULE_FRAGMENT = graphql`
	fragment editAvailabilityScheduleForm_AvailabilityScheduleFragment on AvailabilitySchedule {
		coachRef
		id
		data {
			daysAndTimes {
				dayOfWeek
				timeSlots
			}
			blockoutDates {
				from
				to
				...blockOutDatesTable_BlockOutDateFragment
			}
			overrideDaysAndTimesForCalendarWeek {
				...editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment
				...exceptionsTable_DayAndTimesForCalendarWeekFragment
				...editExceptionModal_DayAndTimesForCalendarWeekFragment
			}
		}
		...exceptionsTable_AvailabilityScheduleFragment
	}
`;

export const DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT = graphql`
	fragment editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment on DayAndTimesForCalendarWeek
	@relay(plural: true) {
		timeSlots
		dayOfWeek
		calendarWeek
		calendarYear
		...exceptionsTable_DayAndTimesForCalendarWeekFragment
		...editExceptionModal_DayAndTimesForCalendarWeekFragment
	}
`;
export const COACH_PROFILE_FRAGMENT = graphql`
	fragment editAvailabilityScheduleForm_CoachProfileFragment on CoachProfile {
		coach {
			id
		}
		coachAccountId
		...exceptionsTable_CoachProfileFragment
	}
`;

export const EDIT_AVAILABILITY_SCHEDULE_MUTATION = graphql`
	mutation editAvailabilityScheduleForm_EditAvailabilityScheduleMutation(
		$input: EditAvailabilityScheduleInput!
	) {
		Admin {
			Coaching {
				editAvailabilitySchedule(input: $input) {
					schedule {
						id
						...editAvailabilityScheduleForm_AvailabilityScheduleFragment
					}
				}
			}
		}
	}
`;

export const QUERY = graphql`
	query editAvailabilityScheduleForm_Query($coachingUserId: ID) {
		Admin {
			Coaching {
				FreeCalendarWeeks(coachUserId: $coachingUserId) {
					...createExceptionModal_FreeCalenderWeeksFragment
				}
			}
		}
	}
`;
