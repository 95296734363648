/**
 * @generated SignedSource<<4b55e63516dd76ee232073e500eff181>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicenseRewardForm_ExternalLicenseRewardFragment$data = {
  readonly data: {
    readonly pool: {
      readonly data: {
        readonly name: string;
      };
      readonly id: string;
    } | null | undefined;
  };
  readonly id?: string;
  readonly superId: string;
  readonly " $fragmentType": "externalLicenseRewardForm_ExternalLicenseRewardFragment";
};
export type externalLicenseRewardForm_ExternalLicenseRewardFragment$key = {
  readonly " $data"?: externalLicenseRewardForm_ExternalLicenseRewardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"externalLicenseRewardForm_ExternalLicenseRewardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "externalLicenseRewardForm_ExternalLicenseRewardFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "Reward",
      "abstractKey": "__isReward"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "superId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalLicenseRewardData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExternalLicensePool",
          "kind": "LinkedField",
          "name": "pool",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ExternalLicensePoolData",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalLicenseReward",
  "abstractKey": null
};
})();

(node as any).hash = "c755c0a7cbb69b9288af3d4ca5582333";

export default node;
