/**
 * @generated SignedSource<<9ec806c56c64cc398859e87cb4fb13c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetTrendOrderInput = {
  clientMutationId?: string | null | undefined;
  trendIdsWithPositions: ReadonlyArray<TrendIdWithPosition>;
};
export type TrendIdWithPosition = {
  position: number;
  trendId: string;
};
export type sortTrendsModal_SetTrendOrderMutation$variables = {
  input: SetTrendOrderInput;
};
export type sortTrendsModal_SetTrendOrderMutation$data = {
  readonly Admin: {
    readonly Trend: {
      readonly setTrendOrder: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type sortTrendsModal_SetTrendOrderMutation = {
  response: sortTrendsModal_SetTrendOrderMutation$data;
  variables: sortTrendsModal_SetTrendOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TrendAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Trend",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "SetTrendOrderPayload",
            "kind": "LinkedField",
            "name": "setTrendOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sortTrendsModal_SetTrendOrderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sortTrendsModal_SetTrendOrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a437f15a7c26b8867735f5491427d685",
    "id": null,
    "metadata": {},
    "name": "sortTrendsModal_SetTrendOrderMutation",
    "operationKind": "mutation",
    "text": "mutation sortTrendsModal_SetTrendOrderMutation(\n  $input: SetTrendOrderInput!\n) {\n  Admin {\n    Trend {\n      setTrendOrder(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c60fdbd4a0e39b92245ad63969e9a435";

export default node;
