import {
	DeleteButton,
	DialogButton,
	Pagination,
	Table,
} from "@thekeytechnology/framework-react-components";
import { readInlineData, useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";

import {
	HorizontalElementSpacingWrapper,
	VerticalContainerSpacingWrapper,
} from "@corestyle/component-theme/default-spacings.component";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type wordPackagesTable_DeleteMutation } from "@relay/wordPackagesTable_DeleteMutation.graphql";
import { type wordPackagesTable_Query } from "@relay/wordPackagesTable_Query.graphql";
import { type WordPackagesTable_Refetch } from "@relay/WordPackagesTable_Refetch.graphql";
import {
	type wordPackagesTable_WordPackagesInlineFragment$data,
	type wordPackagesTable_WordPackagesInlineFragment$key,
} from "@relay/wordPackagesTable_WordPackagesInlineFragment.graphql";
import { type wordPackagesTable_WordPackagesListFragment$key } from "@relay/wordPackagesTable_WordPackagesListFragment.graphql";
import {
	DELETE_WORD_PACKAGE_MUTATION,
	QUERY,
	WORD_PACKAGE_INLINE_FRAGMENT,
	WORD_PACKAGES_FRAGMENT,
} from "./word-packages-table.graphql";
import { ButtonWrapper } from "./word-packages-table.styles";
import { EditWordPackageButton } from "../edit-word-package-button/edit-word-package-button.component";
import { WordsTable } from "../word-table/words-table.component";

export const WordPackagesTable = () => {
	const data = useLazyLoadQuery<wordPackagesTable_Query>(
		QUERY,
		{ first: 20 },
		{ fetchPolicy: "network-only" },
	);

	const {
		hasNext,
		loadNext,
		data: {
			Admin: {
				Vocab: { WordPackages },
			},
		},
	} = usePaginationFragment<
		WordPackagesTable_Refetch,
		wordPackagesTable_WordPackagesListFragment$key
	>(WORD_PACKAGES_FRAGMENT, data);

	const [deletePackage, isDeleting] = useMutation<wordPackagesTable_DeleteMutation>(
		DELETE_WORD_PACKAGE_MUTATION,
	);

	const wordPackages = WordPackages?.edges?.map((e) =>
		readInlineData<wordPackagesTable_WordPackagesInlineFragment$key>(
			WORD_PACKAGE_INLINE_FRAGMENT,
			e?.node!,
		),
	);

	const handleDeletion = (selectedIds: string[]) => {
		deletePackage({
			variables: {
				input: {
					ids: selectedIds,
				},
				connections: [WordPackages.__id] || [],
			},
		});
	};

	const handleLoadNextOnClick = () => {
		loadNext(20);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_VocabAdmin_ModifyWordPackages"]);

	return (
		<>
			<VerticalContainerSpacingWrapper>
				<EditWordPackageButton connections={[WordPackages.__id]} />
			</VerticalContainerSpacingWrapper>

			<Table<wordPackagesTable_WordPackagesInlineFragment$data>
				emptyMessage="Es wurden keine Wortpakete gefunden."
				items={wordPackages}
			>
				{(Column) => (
					<>
						<Column header="Name" field="name" />
						<Column header="Sprache" field="language" />
						<Column
							header="Wörter"
							body={(row) => {
								return (
									<ButtonWrapper>
										<HorizontalElementSpacingWrapper>
											{row.associatedWords.length} Wörter
										</HorizontalElementSpacingWrapper>
										<DialogButton
											title={"Wörter bearbeiten"}
											buttonContent={{ label: "Bearbeiten" }}
										>
											{() => {
												return <WordsTable wordPackageFragmentRef={row} />;
											}}
										</DialogButton>
									</ButtonWrapper>
								);
							}}
						/>
						{canModify && (
							<Column
								header="Aktionen"
								body={(row) => {
									return (
										<ButtonWrapper>
											<HorizontalElementSpacingWrapper>
												<EditWordPackageButton wordPackageFragment={row} />
											</HorizontalElementSpacingWrapper>
											<DeleteButton
												hideLabel={true}
												isDeleting={isDeleting}
												selectedIds={[row.id]}
												singularName={"Wortpaket"}
												pluralName={"Wortpakete"}
												doDelete={handleDeletion}
											/>
										</ButtonWrapper>
									);
								}}
							/>
						)}
					</>
				)}
			</Table>
			<Pagination hasNext={hasNext} loadNext={handleLoadNextOnClick} />
		</>
	);
};
