/**
 * @generated SignedSource<<f5db2e5311eaea6689add45ef67568bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAvailabilityScheduleButton_AvailabilityScheduleFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleForm_AvailabilityScheduleFragment">;
  readonly " $fragmentType": "editAvailabilityScheduleButton_AvailabilityScheduleFragment";
};
export type editAvailabilityScheduleButton_AvailabilityScheduleFragment$key = {
  readonly " $data"?: editAvailabilityScheduleButton_AvailabilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleButton_AvailabilityScheduleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAvailabilityScheduleButton_AvailabilityScheduleFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editAvailabilityScheduleForm_AvailabilityScheduleFragment"
    }
  ],
  "type": "AvailabilitySchedule",
  "abstractKey": null
};

(node as any).hash = "c04a1ab97d78545bb78f8be23632b6ef";

export default node;
