/**
 * @generated SignedSource<<4df6239ad6b0e34e49f75182eec7848e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteTrendInput = {
  clientMutationId?: string | null | undefined;
  ids: ReadonlyArray<string>;
};
export type trends_DeleteTrandMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteTrendInput;
};
export type trends_DeleteTrandMutation$data = {
  readonly Admin: {
    readonly Trend: {
      readonly deleteTrend: {
        readonly clientMutationId: string | null | undefined;
        readonly deletedIds: ReadonlyArray<string>;
      } | null | undefined;
    };
  };
};
export type trends_DeleteTrandMutation = {
  response: trends_DeleteTrandMutation$data;
  variables: trends_DeleteTrandMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "trends_DeleteTrandMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteTrendPayload",
                "kind": "LinkedField",
                "name": "deleteTrend",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "trends_DeleteTrandMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteTrendPayload",
                "kind": "LinkedField",
                "name": "deleteTrend",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae49dc16651659405c002c9501b3367e",
    "id": null,
    "metadata": {},
    "name": "trends_DeleteTrandMutation",
    "operationKind": "mutation",
    "text": "mutation trends_DeleteTrandMutation(\n  $input: DeleteTrendInput!\n) {\n  Admin {\n    Trend {\n      deleteTrend(input: $input) {\n        deletedIds\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a12fe9c17408bd72cdbfd64bdd0aeafd";

export default node;
