import { graphql } from "babel-plugin-relay/macro";

export const POTENTIAL_ANALYSIS_V2_FRAGMENT = graphql`
	fragment editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis {
		id
		replacementCoach {
			id
			name
		}
		coachAccount {
			id
		}
	}
`;

export const CHANGE_REPLACEMENT_COACH_MUTATION = graphql`
	mutation editSubstituteCoachButton_ChangeReplacementCoachMutation(
		$input: ChangeReplacementCoachInput!
	) {
		Admin {
			AvgsPotentialAnalysis {
				changeReplacementCoach(input: $input) {
					edge {
						node {
							id
							...potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment
						}
					}
				}
			}
		}
	}
`;
