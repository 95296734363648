/**
 * @generated SignedSource<<2ad5ff03e9d950153d29c0b09db299ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type videoSourceDropdown_Query$variables = Record<PropertyKey, never>;
export type videoSourceDropdown_Query$data = {
  readonly Admin: {
    readonly VimeoVideoSource: {
      readonly VimeoVideoSources: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly isDefault: boolean;
            readonly name: string;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
};
export type videoSourceDropdown_Query = {
  response: videoSourceDropdown_Query$data;
  variables: videoSourceDropdown_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VimeoVideoSourceAdminSchema",
        "kind": "LinkedField",
        "name": "VimeoVideoSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vimeo_VimeoVideoSourceConnection",
            "kind": "LinkedField",
            "name": "VimeoVideoSources",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Vimeo_VimeoVideoSourceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vimeo_VimeoVideoSource",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDefault",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "videoSourceDropdown_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "videoSourceDropdown_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2f726532da51ba20fce5292fe82430e2",
    "id": null,
    "metadata": {},
    "name": "videoSourceDropdown_Query",
    "operationKind": "query",
    "text": "query videoSourceDropdown_Query {\n  Admin {\n    VimeoVideoSource {\n      VimeoVideoSources {\n        edges {\n          node {\n            id\n            name\n            isDefault\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "540574f7911ddf0cf84df39e7af21507";

export default node;
