import {
	type SelectPublishedRootFieldProps,
	type SelectPublishedRootFieldState,
} from "./select-published-root-field.types";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";
import { SearchablePublishedRootTable } from "../searchable-published-root-table";

export const SelectPublishedRootField = ({ ...props }: SelectPublishedRootFieldProps) => {
	return (
		<>
			<SelectComplexDataField<SelectPublishedRootFieldState>
				valuePlaceholder="Keine Weiterbildung ausgewählt"
				actionButtonLabel="Weiterbildung auswählen"
				headerLabel="Wähle eine Weiterbildung aus"
				valueField="title"
				{...props}
			>
				<SearchablePublishedRootTable
					selection={props.fieldValue}
					onChange={props.updateField}
				/>
			</SelectComplexDataField>
		</>
	);
};
