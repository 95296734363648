/**
 * @generated SignedSource<<7e6792a46289a153db6109f194e74727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectAvgsCoachField_SearchAVGSCoachesQuery$variables = {
  accountId: string;
  after?: string | null | undefined;
  before?: string | null | undefined;
  filterByNameOrEmail?: string | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
};
export type selectAvgsCoachField_SearchAVGSCoachesQuery$data = {
  readonly Admin: {
    readonly AvgsPotentialAnalysis: {
      readonly SearchAVGSCoaches: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
};
export type selectAvgsCoachField_SearchAVGSCoachesQuery = {
  response: selectAvgsCoachField_SearchAVGSCoachesQuery$data;
  variables: selectAvgsCoachField_SearchAVGSCoachesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterByNameOrEmail"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminQueryType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AvgsPotentialAnalysisAdminSchema",
        "kind": "LinkedField",
        "name": "AvgsPotentialAnalysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              {
                "kind": "Variable",
                "name": "after",
                "variableName": "after"
              },
              {
                "kind": "Variable",
                "name": "before",
                "variableName": "before"
              },
              {
                "kind": "Variable",
                "name": "filterByNameOrEmail",
                "variableName": "filterByNameOrEmail"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "last",
                "variableName": "last"
              }
            ],
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "SearchAVGSCoaches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "selectAvgsCoachField_SearchAVGSCoachesQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "selectAvgsCoachField_SearchAVGSCoachesQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "a6ccf8a1a908737a10a51d019e0394c0",
    "id": null,
    "metadata": {},
    "name": "selectAvgsCoachField_SearchAVGSCoachesQuery",
    "operationKind": "query",
    "text": "query selectAvgsCoachField_SearchAVGSCoachesQuery(\n  $first: Int\n  $last: Int\n  $after: String\n  $before: String\n  $filterByNameOrEmail: String\n  $accountId: ID!\n) {\n  Admin {\n    AvgsPotentialAnalysis {\n      SearchAVGSCoaches(first: $first, after: $after, before: $before, last: $last, accountId: $accountId, filterByNameOrEmail: $filterByNameOrEmail) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe92f827897af55f131afdc233490d23";

export default node;
