/**
 * @generated SignedSource<<ee73c3acb221663d3f60e0230e962a60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAvailabilityScheduleForm_Query$variables = {
  coachingUserId?: string | null | undefined;
};
export type editAvailabilityScheduleForm_Query$data = {
  readonly Admin: {
    readonly Coaching: {
      readonly FreeCalendarWeeks: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"createExceptionModal_FreeCalenderWeeksFragment">;
      }>;
    };
  };
};
export type editAvailabilityScheduleForm_Query = {
  response: editAvailabilityScheduleForm_Query$data;
  variables: editAvailabilityScheduleForm_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "coachingUserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "coachUserId",
    "variableName": "coachingUserId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAvailabilityScheduleForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CalendarWeekAndYear",
                "kind": "LinkedField",
                "name": "FreeCalendarWeeks",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "createExceptionModal_FreeCalenderWeeksFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAvailabilityScheduleForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CalendarWeekAndYear",
                "kind": "LinkedField",
                "name": "FreeCalendarWeeks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "calendarWeek",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "calendarYear",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44da3759f5093c6a1396f6dfd7341d6e",
    "id": null,
    "metadata": {},
    "name": "editAvailabilityScheduleForm_Query",
    "operationKind": "query",
    "text": "query editAvailabilityScheduleForm_Query(\n  $coachingUserId: ID\n) {\n  Admin {\n    Coaching {\n      FreeCalendarWeeks(coachUserId: $coachingUserId) {\n        ...createExceptionModal_FreeCalenderWeeksFragment\n      }\n    }\n  }\n}\n\nfragment createExceptionModal_FreeCalenderWeeksFragment on CalendarWeekAndYear {\n  calendarWeek\n  calendarYear\n}\n"
  }
};
})();

(node as any).hash = "ee19796c2462d31df97db8775b14c12b";

export default node;
