import React from "react";
import { type ContractPartnerFiltersState } from "@screens/contract-partners/parts/contract-partner-form-filter/contract-partner-form-filter.types";

export type ContractPartnerContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
	filters: ContractPartnerFiltersState;
	setFilters: (e: ContractPartnerFiltersState) => void;
};

export const ContractPartnerScreenContext = React.createContext<ContractPartnerContext>({
	connectionId: "",
	setConnectionId: () => {},
	filters: {
		name: undefined,
	},
	setFilters: () => {},
});
