import React, { createContext, useState } from "react";
import { type ReportsTableFiltersFormState } from "@screens/reports/parts/reports-table-filters/reports-table-filters.types";

export type IReportsContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
	filters: ReportsTableFiltersFormState;
	setFilters: (filters: IReportsContext["filters"]) => void;
};
export const ReportsContext = createContext<IReportsContext>({
	connectionId: "",
	setConnectionId: () => {},
	filters: {
		filterByReferenceNumberOpt: undefined,
	},
	setFilters: () => {},
});
export const ReportsContextProvider = ({ children }: React.PropsWithChildren) => {
	const [connectionId, setConnectionId] = useState("");
	const [filters, setFilters] = useState<ReportsTableFiltersFormState>({
		filterByReferenceNumberOpt: "",
	});

	const value: IReportsContext = {
		connectionId,
		setConnectionId,
		filters,
		setFilters,
	};
	return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};
