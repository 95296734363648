import graphql from "babel-plugin-relay/macro";

export const ACCOUNT_GROUP_PRODUCT_FRAGMENT = graphql`
	fragment accountGroupProductForm_AccountGroupProductFragment on Product {
		id
		title
		isHidden
		isTaxFree
		netPrice
		data {
			... on AccountGroupProduct {
				accountGroupId
			}
		}
		extensions {
			kind
			... on AcademiesProductExtension {
				costUnit
			}
		}
	}
`;

export const EDIT_PRODUCT_MUTATION = graphql`
	mutation accountGroupProductForm_EditProductMutation($input: EditProductInput!) {
		Admin {
			Billing {
				editProduct(input: $input) {
					editedProduct {
						...accountGroupProductForm_AccountGroupProductFragment
					}
				}
			}
		}
	}
`;

export const EDIT_ACCOUNT_GROUP_PRODUCT_MUTATION = graphql`
	mutation accountGroupProductForm_EditAccountGroupProductMutation(
		$input: EditAccountGroupProductInput!
	) {
		Admin {
			Billing {
				editAccountGroupProduct(input: $input) {
					product {
						...accountGroupProductForm_AccountGroupProductFragment
					}
				}
			}
		}
	}
`;

export const EDIT_ACADEMIES_PRODUCT_EXTENSION_MUTATION = graphql`
	mutation accountGroupProductForm_EditAcademiesProductExtensionMutation(
		$input: EditAcademiesProductExtensionInput!
	) {
		Admin {
			Billing {
				editAcademiesProductExtension(input: $input) {
					product {
						...accountGroupProductForm_AccountGroupProductFragment
					}
				}
			}
		}
	}
`;
