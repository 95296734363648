import { type FormikProps, useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useEffect, useImperativeHandle } from "react";
import * as Yup from "yup";
import { type EditGroupSettingFormState } from "./edit-group-setting-form.types";
import { DefaultIntegerFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { type DefaultFormProps } from "../common";

// eslint-disable-next-line react/display-name
export const EditGroupSettingForm = React.forwardRef<
	FormikProps<EditGroupSettingFormState>,
	DefaultFormProps<EditGroupSettingFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditGroupSettingFormState>({
		initialValues: initialState ?? {
			kind: "OneToOne",
			maxParticipants: 1,
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			maxParticipants: Yup.number().test(
				"test",
				"Ungültige Anzahl an Teilnehmern",
				(value, ctx) => {
					if (!value) return false;
					if (value <= 0)
						ctx.createError({
							path: "maxParticipants",
							message: "Ungültige Anzahl an Teilnehmern",
						});
					if (value > 20)
						ctx.createError({
							path: "maxParticipants",
							message: "Ungültige Anzahl an Teilnehmern",
						});

					return true;
				},
			),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	useEffect(() => {
		if (formik.values.kind === "OneToOne") formik.setFieldValue("maxParticipants", 1);
	}, [formik.values.kind]);

	if (formik.values.kind === "OneToOne") return null;

	return (
		<Card>
			<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
				<ValidatedField
					name={"maxParticipants"}
					label={"Maximale Teilnehmer"}
					formikConfig={formik}
					component={DefaultIntegerFieldComponent}
				/>
				<Button type={"submit"}>Speichern</Button>
			</form>
		</Card>
	);
});
