import { Button, Dialog, type PathParams } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { type transformToAzavAccountButton_AccountFragment$key } from "@relay/transformToAzavAccountButton_AccountFragment.graphql";
import { type transformToAzavAccountButton_Query } from "@relay/transformToAzavAccountButton_Query.graphql";
import {
	ACCOUNT_FRAGMENT,
	QUERY,
	TRANSFORM_BUSINESS_ACCOUNT_TO_AZAV_ACCOUNT_MUTATION,
	TRANSFORM_PRIVATE_ACCOUNT_TO_AZAV_ACCOUNT_MUTATION,
} from "@screens/account-edit/parts/transform-to-azav-account-button/transform-to-azav-account-button.graphql";
import { type TransformToAZAVAccountButtonProps } from "@screens/account-edit/parts/transform-to-azav-account-button/transform-to-azav-account-button.types";
import { BusinessBaseDataForm } from "@screens/account-edit-base-data/parts/business-base-data-form";
import {
	type BusinessBaseDataFormState,
	type BusinessBaseDataRef,
} from "@screens/account-edit-base-data/parts/business-base-data-form/business-base-data-form.types";
import type { AccountsPath } from "@screens/accounts";

export const TransformToAZAVAccountButton = ({
	accountFragmentRef,
}: TransformToAZAVAccountButtonProps) => {
	const toast = useRef<Toast>(null);
	const { accountId } = useParams<PathParams<typeof AccountsPath>>();
	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const query = useLazyLoadQuery<transformToAzavAccountButton_Query>(
		QUERY,
		{
			id: accountId ?? "",
		},
		{ fetchPolicy: "network-only" },
	);
	const accountFragment = useFragment<transformToAzavAccountButton_AccountFragment$key>(
		ACCOUNT_FRAGMENT,
		accountFragmentRef,
	);

	const isBusinessAccount =
		query?.Admin.AccountBaseData.AccountBaseData.__typename === "BusinessBaseData";

	const isAZAVAccount = accountFragment?.groupAssociations?.some(
		(group) => group?.group?.name === "AZAV Account",
	);

	const [transformAccount] = useMutation(
		isBusinessAccount
			? TRANSFORM_BUSINESS_ACCOUNT_TO_AZAV_ACCOUNT_MUTATION
			: TRANSFORM_PRIVATE_ACCOUNT_TO_AZAV_ACCOUNT_MUTATION,
	);

	const handlePrivateTransform = (values: BusinessBaseDataFormState) => {
		if (!accountId) return;

		transformAccount({
			variables: {
				input: {
					accountId: accountId!,
					businessBaseData: {
						companyName: values.companyName,
						billingOffice: values.billingOffice,
						companyLegalForm: values.companyLegalForm!,
						invoiceEmail: values.invoiceEmail,
						street: values.street,
						houseNumber: values.houseNumber,
						postalCode: values.postalCode,
						city: values.city,
						countryCode: values.countryCode,
						phoneNumber: values.phoneNumber,
						taxData: {
							taxNumber: values.taxNumber,
							taxIdentificationNumber: values.taxIdentificationNumber,
						},
						accountData: {
							iban: values.iban,
							bic: values.bic,
						},
					},
				},
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					summary: "Konto erfolgreich umgewandelt",
					detail: "Konto wurde erfolgreich zu AZAV Konto umgewandelt",
				});
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					summary: "Fehler",
					detail: "Fehler beim Umwandeln des Kontos",
				});
			},
		});
	};

	const handleBusinessTransform = () => {
		if (!accountId) return;
		const variables = {
			input: {
				accountId,
			},
		};

		transformAccount({
			variables,
			onCompleted: () => {
				setModalOpen(false);
				toast.current?.show({
					severity: "success",
					summary: "Konto erfolgreich umgewandelt",
					detail: "Konto wurde erfolgreich zu einem AZAV Konto umgewandelt",
				});
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					summary: "Fehler",
					detail: "Fehler beim Umwandeln des Kontos",
				});
			},
		});
	};

	const businessBaseDataFormRef = useRef<BusinessBaseDataRef>(null);
	return (
		<>
			<Toast ref={toast}></Toast>
			{isBusinessAccount ? (
				<Button
					label="In AZAV Konto umwandeln"
					disabled={isAZAVAccount}
					onClick={handleBusinessTransform}
				/>
			) : (
				<>
					<Button
						onClick={() => {
							setModalOpen(true);
						}}
						disabled={isAZAVAccount}
						label="In AZAV Konto umwandeln"
					/>

					<Dialog
						visible={isModalOpen}
						onHide={() => {
							setModalOpen(false);
						}}
						title={
							"Zur Umwandlung in einen AZAV Account werden Geschäftskonten-Stammdaten benötigt"
						}
						footerNode={
							<div>
								<Button
									label={"Speichern"}
									onClick={() => {
										businessBaseDataFormRef.current?.submit();
									}}
								/>
							</div>
						}
					>
						<BusinessBaseDataForm
							ref={businessBaseDataFormRef}
							onSubmit={(values) => {
								handlePrivateTransform(values);
							}}
						/>
					</Dialog>
				</>
			)}
		</>
	);
};
