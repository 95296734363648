import tw from "twin.macro";

export const Wrapper = tw.div`
	md:tw-min-w-[25rem]
	tw-flex
	tw-flex-col
	tw-gap-16
`;

export const InputWrapper = tw.div`
  tw-relative
`;
