/**
 * @generated SignedSource<<152cbff26cf06fe8446922cce7e009cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectWordPackagesField_QueryRefetch$variables = {
  after?: string | null | undefined;
  alwaysInclude?: ReadonlyArray<string> | null | undefined;
  before?: string | null | undefined;
  filterByName?: string | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
};
export type selectWordPackagesField_QueryRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectWordPackagesField_QueryFragment">;
};
export type selectWordPackagesField_QueryRefetch = {
  response: selectWordPackagesField_QueryRefetch$data;
  variables: selectWordPackagesField_QueryRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "alwaysInclude"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "alwaysInclude",
    "variableName": "alwaysInclude"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filterByName",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectWordPackagesField_QueryRefetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectWordPackagesField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectWordPackagesField_QueryRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "WordPackageConnection",
                "kind": "LinkedField",
                "name": "WordPackages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordPackage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filterByName",
                  "alwaysInclude"
                ],
                "handle": "connection",
                "key": "selectWordPackagesField_WordPackages",
                "kind": "LinkedHandle",
                "name": "WordPackages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fd9f68fecfc3503b263225462af5b11",
    "id": null,
    "metadata": {},
    "name": "selectWordPackagesField_QueryRefetch",
    "operationKind": "query",
    "text": "query selectWordPackagesField_QueryRefetch(\n  $after: String\n  $alwaysInclude: [ID!]\n  $before: String\n  $filterByName: String\n  $first: Int\n  $last: Int\n) {\n  ...selectWordPackagesField_QueryFragment_r1YOk\n}\n\nfragment selectWordPackagesField_QueryFragment_r1YOk on Query {\n  Admin {\n    Vocab {\n      WordPackages(filterByName: $filterByName, alwaysInclude: $alwaysInclude, first: $first, after: $after, before: $before, last: $last) {\n        edges {\n          node {\n            ...selectWordPackagesField_WordPackageFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n\nfragment selectWordPackagesField_WordPackageFragment on WordPackage {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "b075925b8c6d0253ee933ce2548f1a84";

export default node;
