/**
 * @generated SignedSource<<72ec90ea6fa710700fa0cd6e01b6f4ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "ParticipationCertificate" | "WordPackage" | "WordProficiencies";
import { FragmentRefs } from "relay-runtime";
export type rewardForm_RewardFragment$data = {
  readonly id: string;
  readonly kind: RewardKind;
  readonly " $fragmentSpreads": FragmentRefs<"educationalParticipationCertificateRewardForm_RewardFragment" | "externalLicenseRewardForm_ExternalLicenseRewardFragment" | "wordPackageRewardForm_WordPackageRewardFragment">;
  readonly " $fragmentType": "rewardForm_RewardFragment";
};
export type rewardForm_RewardFragment$key = {
  readonly " $data"?: rewardForm_RewardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rewardForm_RewardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rewardForm_RewardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "externalLicenseRewardForm_ExternalLicenseRewardFragment"
        }
      ],
      "type": "ExternalLicenseReward",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "wordPackageRewardForm_WordPackageRewardFragment"
        }
      ],
      "type": "WordPackageReward",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "educationalParticipationCertificateRewardForm_RewardFragment"
    }
  ],
  "type": "Reward",
  "abstractKey": "__isReward"
};

(node as any).hash = "8e0278b180fee47c77cb56d4b35eaa56";

export default node;
