import { FormikProps, useFormik } from "formik";

import React, { useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";

import { selectAvgsAccountAndCoachForm_QueryFragment$key } from "@relay/selectAvgsAccountAndCoachForm_QueryFragment.graphql";
import { QUERY_FRAGMENT } from "./select-avgs-account-and-coach-form.graphql";
import { wrapperClass } from "./select-avgs-account-and-coach-form.styles";
import {
	SelectAvgsAccountAndCoachFormProps,
	SelectAvgsAccountAndCoachFormState,
} from "./select-avgs-account-and-coach-form.types";

import { SelectAvgsAccountField, SelectAvgsAccountFieldState } from "../select-avgs-account-field";
import { SelectAvgsCoachField } from "../select-avgs-coach-field";

export const SelectAvgsAccountAndCoachForm = React.forwardRef<
	FormikProps<SelectAvgsAccountAndCoachFormState>,
	SelectAvgsAccountAndCoachFormProps
>(function SelectAccountAndUserForm({ accountId, onSubmit, queryFragmentRef }, ref) {
	const query = useFragment<selectAvgsAccountAndCoachForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const formik = useFormik<SelectAvgsAccountAndCoachFormState>({
		initialValues: {
			account: {
				id: accountId ?? "",
				name: "",
			},
			coachId: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			account: Yup.object().required(),
			coachId: Yup.string().required("Bitte wählen Sie einen Coach aus."),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<form onSubmit={formik.handleSubmit} className={wrapperClass}>
			{!accountId && (
				<ValidatedField<SelectAvgsAccountAndCoachFormState, SelectAvgsAccountFieldState>
					name={"account"}
					label={"Account"}
					formikConfig={formik}
					component={(renderConfig) => (
						<SelectAvgsAccountField queryFragmentRef={query} {...renderConfig} />
					)}
				/>
			)}
			{formik.values?.account?.id ? (
				<ValidatedField<SelectAvgsAccountAndCoachFormState, string>
					name={"coachId"}
					label={"Coach"}
					formikConfig={formik}
					component={(renderConfig) => (
						<SelectAvgsCoachField
							{...renderConfig}
							accountId={formik.values.account.id!}
						/>
					)}
				/>
			) : (
				<div>Konto auswählen um fortzufahren.</div>
			)}
		</form>
	);
});
