import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router";
import { Button } from "@components/button";
import { editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key } from "@relay/editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment.graphql";
import { Paths } from "@routes/paths";
import { POTENTIAL_ANALYSIS_V2_FRAGMENT } from "@screens/potential-analysis/parts/edit-pa-button/edit-pa-button.graphql";
import { EditPaButtonProps } from "@screens/potential-analysis/parts/edit-pa-button/edit-pa-button.types";

export const EditPaButton = ({ potentialAnalysisV2FragmentRef }: EditPaButtonProps) => {
	const pa = useFragment<editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key>(
		POTENTIAL_ANALYSIS_V2_FRAGMENT,
		potentialAnalysisV2FragmentRef,
	);
	const navigate = useNavigate();
	const handleOnClick = () => {
		navigate(Paths.potentialAnalysis.withId(pa.id).edit.path);
	};
	return <Button icon={EpicIcons.EYE} tooltip={"details ansehen"} onClick={handleOnClick} />;
};
