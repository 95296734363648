import { EpicIcons } from "@thekeytechnology/epic-ui";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type CreatePodcastWithTimestampElementButton_CreatePodcastWithTimestampElementMutation } from "@relay/CreatePodcastWithTimestampElementButton_CreatePodcastWithTimestampElementMutation.graphql";
import { type CreatePodcastWithTimestampElementButtonProps } from "./create-podcast-with-timestamp-element-button.interface";

const CREATE_PODCAST_WITH_TIMESTAMP_ELEMENT_MUTATION = graphql`
	mutation CreatePodcastWithTimestampElementButton_CreatePodcastWithTimestampElementMutation(
		$input: CreatePodcastWithTimestampInput!
	) {
		Admin {
			ElearningV2 {
				createPodcastWithTimestampElement(input: $input) {
					eLearningContent {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

export const CreatePodcastWithTimestampElementButton = ({
	eLearningContentId,
}: CreatePodcastWithTimestampElementButtonProps) => {
	const [createPodcastWithTimestampElement, isCreatingPodcastWithTimestampElement] =
		useMutation<CreatePodcastWithTimestampElementButton_CreatePodcastWithTimestampElementMutation>(
			CREATE_PODCAST_WITH_TIMESTAMP_ELEMENT_MUTATION,
		);

	const handleCreateOnClick = () => {
		createPodcastWithTimestampElement({
			variables: {
				input: {
					title: "Neues Element",
					podcastFileId: undefined,
					timestampsWithImage: [],
					eLearningContentId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingPodcastWithTimestampElement}
			tooltip={`Zeitintervall-Podcast-Baustein anlegen`}
			icon={EpicIcons.MICROPHONE}
			onClick={handleCreateOnClick}
		/>
	);
};
