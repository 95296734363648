import { OrderList, OrderListProps } from "primereact/orderlist";
import React from "react";
import styled from "styled-components";

type Props<T> = {
	selectedConfigs: T[];
	template: (c: T) => React.ReactElement;
} & OrderListProps;

const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}

	.p-orderlist-list {
		.p-disabled {
			opacity: 1;
		}
	}
`;

export function ConfigPreview<ConfigType>(props: Props<ConfigType>) {
	return (
		<OrderListWithoutControls
			{...props}
			className={"mt-2"}
			value={props.selectedConfigs || []}
			dragdrop
			listStyle={{ height: "auto" }}
			itemTemplate={(config: ConfigType) => props.template(config)}
		/>
	);
}
