import { graphql } from "babel-plugin-relay/macro";

export const CREATE_TREE_LICENSE_DEFINITION_MUTATION = graphql`
	mutation createTreeLicenseDefinitionButton_CreateTreeLicenseDefinitionMutation(
		$input: CreateTreeLicenseDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			LicenseDefinition {
				createTreeLicenseDefinition(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							data {
								name
								kind
							}
							...editCoachingLicenseDefinitionButton_LicenseDefinitionFragment
							...editTreeLicenseDefinitionButton_LicenseDefinitionFragment
							...editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment
						}
					}
				}
			}
		}
	}
`;
