import graphql from "babel-plugin-relay/macro";

export const WORD_PACKAGE_FRAGMENT = graphql`
	fragment wordsTable_WordPackageFragment on WordPackage {
		id
		associatedWords {
			id
			word
			explanation
			...editWordButton_WordFragment
		}
	}
`;

export const DELETE_WORD_MUTATION = graphql`
	mutation wordsTable_DeleteWordMutation($input: DeleteWordInput!) {
		Admin {
			Vocab {
				deleteWord(input: $input) {
					wordPackage {
						...wordsTable_WordPackageFragment
					}
				}
			}
		}
	}
`;
