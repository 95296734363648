/**
 * @generated SignedSource<<00efb82528a95c6c30a8e1dc85950bcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_ProductFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProductActionColumn_ProductFragment" | "ProductIdColumn_ProductFragment" | "ProductIsHiddenColumn_ProductFragment" | "ProductPriceColumn_ProductFragment" | "ProductTitleColumn_ProductFragment" | "productTaxFreeColumn_ProductFragment">;
  readonly " $fragmentType": "productsTable_ProductFragment";
};
export type productsTable_ProductFragment$key = {
  readonly " $data"?: productsTable_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "productsTable_ProductFragment"
};

(node as any).hash = "b092ce5c7e47c76036b85c4bedac2040";

export default node;
