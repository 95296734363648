import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { CREATE_COACH_STATEMENTS_FROM_UNBILLED_MUTATION } from "./create-coach-statements-from-unbilled-button.graphql";

export const CreateCoachStatementsFromUnbilledButton = () => {
	const [createCoachStatementsFromUnbilled, isCreatingCoachStatementsFromUnbilled] = useMutation(
		CREATE_COACH_STATEMENTS_FROM_UNBILLED_MUTATION,
	);

	const handleOnClick = () => {
		createCoachStatementsFromUnbilled({
			variables: {},
			onCompleted: () => {
				toast.success("Coaching-Abrechnungen erstellt");
			},
		});
	};

	return (
		<Button
			label="Coaching-Abrechnungen erstellen"
			tooltip={"Dies gilt NUR für nicht bereits erstellte"}
			tooltipOptions={{ position: "bottom" }}
			disabled={isCreatingCoachStatementsFromUnbilled}
			onClick={handleOnClick}
		/>
	);
};
