/**
 * @generated SignedSource<<8c235cdc301714db2432f19279ab5f59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordPackageRewardForm_WordPackageRewardFragment$data = {
  readonly data: {
    readonly wordPackage: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
  };
  readonly id?: string;
  readonly superId: string;
  readonly " $fragmentType": "wordPackageRewardForm_WordPackageRewardFragment";
};
export type wordPackageRewardForm_WordPackageRewardFragment$key = {
  readonly " $data"?: wordPackageRewardForm_WordPackageRewardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordPackageRewardForm_WordPackageRewardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "wordPackageRewardForm_WordPackageRewardFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "Reward",
      "abstractKey": "__isReward"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "superId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WordPackageRewardData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WordPackage",
          "kind": "LinkedField",
          "name": "wordPackage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WordPackageReward",
  "abstractKey": null
};
})();

(node as any).hash = "b22791a630719f290bad4a16f7da78bd";

export default node;
