import React, { useState } from "react";
import {
	IPotentialAnalysisContext,
	PotentialAnalysisContext,
} from "@screens/potential-analysis/potential-analysis.context";

export function PotentialAnalysisContextProvider<T>({ children }: React.PropsWithChildren<T>) {
	const [connectionId, setConnectionId] = useState("");

	const value: IPotentialAnalysisContext = {
		connectionId,
		setConnectionId,
	};
	return (
		<PotentialAnalysisContext.Provider value={value}>
			{children}
		</PotentialAnalysisContext.Provider>
	);
}
