/**
 * @generated SignedSource<<8b1c385ba9c3e3eee4d915f1697bf0f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CoachingSettingKind = "Group" | "OneToOne";
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type offersTable_UnpublishedLearnableFragment$data = {
  readonly groupCoaching?: {
    readonly associatedCoaches: ReadonlyArray<{
      readonly coach: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    }>;
    readonly description: {
      readonly description: string | null | undefined;
      readonly image: {
        readonly name: string;
        readonly thumbnail: string | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly shortDescription: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly setting: {
      readonly kind: CoachingSettingKind;
    };
  } | null | undefined;
  readonly id: string;
  readonly kind: LearnableKind;
  readonly oneToOneCoaching?: {
    readonly associatedCoaches: ReadonlyArray<{
      readonly coach: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    }>;
    readonly description: {
      readonly description: string | null | undefined;
      readonly image: {
        readonly name: string;
        readonly thumbnail: string | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly shortDescription: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly setting: {
      readonly kind: CoachingSettingKind;
    };
  } | null | undefined;
  readonly root?: {
    readonly id: string;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"deleteCoachingOfferButton_CoachingOfferFragment" | "deleteCourseLearnableButton_UnpublishedLearnableFragment" | "editCoachingOfferButton_UnpublishedLearnableFragment" | "editCourseLearnableButton_UnpublishedLearnableFragment">;
  readonly " $fragmentType": "offersTable_UnpublishedLearnableFragment";
};
export type offersTable_UnpublishedLearnableFragment$key = {
  readonly " $data"?: offersTable_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offersTable_UnpublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "offersTable_UnpublishedLearnableFragment"
};

(node as any).hash = "c46af20c8db7e75deca695b43d5f5b60";

export default node;
