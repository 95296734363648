import { FormDialogButton } from "@thekeytechnology/epic-ui";
import { Fragment } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { useCanModifyAvgsPotentialAnalysis } from "@permissions/avgs-potential-analysis";
import { editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key } from "@relay/editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment.graphql";
import { editInvitedForUserButton_ChangeInvitedForUserMutation } from "@relay/editInvitedForUserButton_ChangeInvitedForUserMutation.graphql";
import {
	CHANGE_INVITED_FOR_USER_MUTATION,
	POTENTIAL_ANALYSIS_V2_FRAGMENT,
} from "@screens/potential-analysis-edit/parts/edit-invited-for-user-button/edit-invited-for-user-button.graphql";
import { EditInvitedForUserButtonProps } from "@screens/potential-analysis-edit/parts/edit-invited-for-user-button/edit-invited-for-user-button.types";
import { EditInvitedForUserForm } from "@screens/potential-analysis-edit/parts/edit-invited-for-user-form";
import { EditInvitedForUserFormState } from "@screens/potential-analysis-edit/parts/edit-invited-for-user-form/edit-invited-for-user-form.types";

export const EditInvitedForUserButton = ({
	potentialAnalysisV2FragmentRef,
}: EditInvitedForUserButtonProps) => {
	const pa =
		useFragment<editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key>(
			POTENTIAL_ANALYSIS_V2_FRAGMENT,
			potentialAnalysisV2FragmentRef,
		);

	const canModifyAvgsPotentialAnalysis = useCanModifyAvgsPotentialAnalysis();

	const hasClientAcceptedPotentialAnalysis = pa.client.kind === "AcceptedForUser";
	const [edit, isEditing] = useMutation<editInvitedForUserButton_ChangeInvitedForUserMutation>(
		CHANGE_INVITED_FOR_USER_MUTATION,
	);

	function handleSubmit(values: EditInvitedForUserFormState, onHide: () => void) {
		if (hasClientAcceptedPotentialAnalysis || !values.email) return;
		edit({
			variables: {
				input: {
					potentialAnalysisId: pa.id!,
					newClientEmail: values.email,
				},
			},
			onCompleted: () => {
				toast.success("Klient erfolgreich eingeladen.");
				onHide();
			},
		});
	}

	if (!canModifyAvgsPotentialAnalysis) return <Fragment />;

	const disabledEdit = hasClientAcceptedPotentialAnalysis || isEditing;

	return (
		!disabledEdit && (
			<FormDialogButton<EditInvitedForUserFormState>
				dialogTitle={"Klient E-Mail ändern"}
				buttonLabel={"Klient E-Mail ändern"}
				{...(hasClientAcceptedPotentialAnalysis ? { tooltip: "Nicht mehr möglich" } : {})}
			>
				{({ formikRef, onHide }) => {
					return (
						<EditInvitedForUserForm
							initialState={{
								email: pa.client?.email ?? undefined,
							}}
							ref={formikRef}
							onSubmit={(values) => {
								handleSubmit(values, onHide);
							}}
						/>
					);
				}}
			</FormDialogButton>
		)
	);
};
