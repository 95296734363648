import { grid, stack } from "@styled-system/patterns";

export const videoInputContainerClass = grid({
	gridTemplateColumns: "[1fr 1fr]",
});

export const buttonWrapperClass = stack({
	my: "16",
	gap: "8",
});
