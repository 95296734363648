/**
 * @generated SignedSource<<08f1d528aff50339ef2bdfddddf0a1c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editCoachingOfferForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_QueryFragment" | "selectContractPartnerField_QueryFragment">;
  readonly " $fragmentType": "editCoachingOfferForm_QueryFragment";
};
export type editCoachingOfferForm_QueryFragment$key = {
  readonly " $data"?: editCoachingOfferForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editCoachingOfferForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editCoachingOfferForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectAcademiesTagField_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectContractPartnerField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "48ea4a26631c35dc73038cee1570024c";

export default node;
