import { graphql } from "babel-plugin-relay/macro";

export const SEARCH_AVGS_COACHES_QUERY = graphql`
	query selectAvgsCoachField_SearchAVGSCoachesQuery(
		$first: Int
		$last: Int
		$after: String
		$before: String
		$filterByNameOrEmail: String
		$accountId: ID!
	) {
		Admin {
			AvgsPotentialAnalysis {
				SearchAVGSCoaches(
					first: $first
					after: $after
					before: $before
					last: $last
					accountId: $accountId
					filterByNameOrEmail: $filterByNameOrEmail
				) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	}
`;
