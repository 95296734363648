/**
 * @generated SignedSource<<3483e4453bd6c5a57f732eddf9bae069>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type LearnableKind = "Course" | "GroupCoaching" | "MicroLearning" | "OneToOneCoaching";
import { FragmentRefs } from "relay-runtime";
export type offersTable_QueryFragment$data = {
  readonly Admin: {
    readonly Learnable: {
      readonly UnpublishedLearnables: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly kind: LearnableKind;
            readonly " $fragmentSpreads": FragmentRefs<"offersTable_UnpublishedLearnableFragment">;
          };
        } | null | undefined> | null | undefined;
        readonly pageInfo: {
          readonly endCursor: string | null | undefined;
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
          readonly startCursor: string | null | undefined;
        };
      };
    };
  };
  readonly " $fragmentType": "offersTable_QueryFragment";
};
export type offersTable_QueryFragment$key = {
  readonly " $data"?: offersTable_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offersTable_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Learnable",
  "UnpublishedLearnables"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingOfferDescription",
    "kind": "LinkedField",
    "name": "description",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortDescription",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachProfile",
    "kind": "LinkedField",
    "name": "associatedCoaches",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "coach",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "setting",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TreeNode",
    "kind": "LinkedField",
    "name": "root",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "structureDefinition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isVisible"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tagIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./offersTable_Refetch.graphql')
    }
  },
  "name": "offersTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnableAdminSchema",
          "kind": "LinkedField",
          "name": "Learnable",
          "plural": false,
          "selections": [
            {
              "alias": "UnpublishedLearnables",
              "args": [
                {
                  "kind": "Variable",
                  "name": "isVisible",
                  "variableName": "isVisible"
                },
                {
                  "kind": "Variable",
                  "name": "kinds",
                  "variableName": "kinds"
                },
                {
                  "kind": "Variable",
                  "name": "tagIds",
                  "variableName": "tagIds"
                },
                {
                  "kind": "Variable",
                  "name": "text",
                  "variableName": "text"
                }
              ],
              "concreteType": "UnpublishedLearnablesConnection",
              "kind": "LinkedField",
              "name": "__offersTable_UnpublishedLearnables_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnpublishedLearnablesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "offersTable_UnpublishedLearnableFragment",
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CoachingOffer",
                                  "kind": "LinkedField",
                                  "name": "groupCoaching",
                                  "plural": false,
                                  "selections": (v4/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "type": "UnpublishedGroupCoachingLearnable",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CoachingOffer",
                                  "kind": "LinkedField",
                                  "name": "oneToOneCoaching",
                                  "plural": false,
                                  "selections": (v4/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "type": "UnpublishedOneToOneCoachingLearnable",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v5/*: any*/),
                              "type": "UnpublishedCourseLearnable",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v5/*: any*/),
                              "type": "UnpublishedMicroLearningLearnable",
                              "abstractKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "editCoachingOfferButton_UnpublishedLearnableFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "editCourseLearnableButton_UnpublishedLearnableFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "deleteCoachingOfferButton_CoachingOfferFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "deleteCourseLearnableButton_UnpublishedLearnableFragment"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7d9cb146c96b59ecbf3dea0d1ef28d29";

export default node;
