import { graphql } from "babel-plugin-relay/macro";

export const TREND_FRAGMENT = graphql`
	fragment sortableTrend_TrendFragment on Trend {
		id
		position
		trendData {
			name
			description
		}
	}
`;
