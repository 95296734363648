import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editBaseDataButton_CoachProfileFragment$key } from "@relay/editBaseDataButton_CoachProfileFragment.graphql";
import { type editBaseDataButton_EditBaseDataMutation } from "@relay/editBaseDataButton_EditBaseDataMutation.graphql";
import { COACH_PROFILE_FRAGMENT, EDIT_BASE_DATA_MUTATION } from "./edit-base-data-button.graphql";
import { type EditBaseDataButtonProps } from "./edit-base-data-button.types";
import { CoachProfilesScreenContext } from "../../coach-profiles.context";
import { EditBaseDataForm } from "../edit-base-data-form";
import { type EditBaseDataFormState } from "../edit-base-data-form/edit-base-data-form.types";

export const EditBaseDataButton = ({ coachProfileFragmentRef }: EditBaseDataButtonProps) => {
	const { connectionId } = useContext(CoachProfilesScreenContext);
	const { id: coachProfileId, baseData } =
		useFragment<editBaseDataButton_CoachProfileFragment$key>(
			COACH_PROFILE_FRAGMENT,
			coachProfileFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<editBaseDataButton_EditBaseDataMutation>(EDIT_BASE_DATA_MUTATION);

	const handleOnSubmit = async (values: EditBaseDataFormState) => {
		return await new Promise((resolve, reject) => {
			edit({
				variables: {
					input: {
						baseData: values,
						coachProfileId,
					},
					connections: [connectionId],
				},
				onCompleted: () => {
					resolve(true);
					toast.success("BaseData angepasst.");
				},
				onError: () => {
					reject();
					toast.error("Fehler beim anpassen der BaseData");
				},
			});
		});
	};

	const initialState: EditBaseDataFormState = {
		...baseData,
	};
	return (
		<FormDialogButton<EditBaseDataFormState>
			disabled={isEditing}
			buttonIcon={EpicIcons.PENCIL}
			dialogTitle="BaseData ändern"
			tooltip="BaseData ändern"
		>
			{({ formikRef }) => {
				return (
					<EditBaseDataForm
						initialState={initialState}
						ref={formikRef}
						onSubmit={handleOnSubmit}
					/>
				);
			}}
		</FormDialogButton>
	);
};
