import {
	type DefaultFormProps,
	DefaultNumberFieldComponent,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { type FormikProps } from "formik/dist/types";
import React, { forwardRef, useImperativeHandle } from "react";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type ContractPartnerFormState } from "./contract-partner-form.types";

export const ContractPartnerForm = forwardRef<
	FormikProps<ContractPartnerFormState>,
	DefaultFormProps<ContractPartnerFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<ContractPartnerFormState>({
		initialValues: initialState ?? {},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
			email: Yup.string().required("E-Mail wird benötigt"),
			association: Yup.string().required("Gesellschaft wird benötigt"),
			street: Yup.string().required("Straße wird benötigt"),
			houseNumber: Yup.string().required("Hausnummer wird benötigt"),
			city: Yup.string().required("Ort wird benötigt"),
			postalCode: Yup.string().required("PLZ wird benötigt"),
			country: Yup.string().required("Land wird benötigt"),
			taxNumber: Yup.string().required("UmsatzsteuerID wird benötigt"),
			linkToABG: Yup.string().optional(),
			shareAmountPercentage: Yup.number()
				.min(0, "Anteil muss mindestens 0% sein")
				.max(100, "Anteil darf maximal 100% sein")
				.required("Anteil wird benötigt"),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<form onSubmit={formik.handleSubmit}>
			<ValidatedField<ContractPartnerFormState, number>
				name={"shareAmountPercentage"}
				label={"Anteil in %"}
				component={DefaultNumberFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"name"}
				label={"Name"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"email"}
				label={"E-Mail"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"association"}
				label={"Gesellschaft"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"street"}
				label={"Straße"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"houseNumber"}
				label={"Hausnummer"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"city"}
				label={"Ort"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"postalCode"}
				label={"PLZ"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"country"}
				label={"Land"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>
			<ValidatedField<ContractPartnerFormState, string>
				name={"taxNumber"}
				label={"UmsatzsteuerID"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				required
			/>

			<ValidatedField<ContractPartnerFormState, string>
				name={"linkToAGB"}
				label={"Link zu den AGB"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
		</form>
	);
});
ContractPartnerForm.displayName = "ContractPartnerForm";
