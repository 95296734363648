import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query wordPackagesTable_Query($first: Int) {
		...wordPackagesTable_WordPackagesListFragment @arguments(first: $first)
	}
`;

export const WORD_PACKAGES_FRAGMENT = graphql`
	fragment wordPackagesTable_WordPackagesListFragment on Query
	@refetchable(queryName: "WordPackagesTable_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 20 }, after: { type: "String" }) {
		Admin {
			Vocab {
				WordPackages(first: $first, after: $after)
					@connection(key: "WordPackagesTable_WordPackages") {
					__id
					edges {
						node {
							...wordPackagesTable_WordPackagesInlineFragment
						}
					}
				}
			}
		}
	}
`;

export const WORD_PACKAGE_INLINE_FRAGMENT = graphql`
	fragment wordPackagesTable_WordPackagesInlineFragment on WordPackage @inline {
		id
		name
		language
		associatedWords {
			id
		}
		...editWordPackageButton_WordPackage
		...wordsTable_WordPackageFragment
	}
`;

export const DELETE_WORD_PACKAGE_MUTATION = graphql`
	mutation wordPackagesTable_DeleteMutation(
		$input: DeleteWordPackageInput!
		$connections: [ID!]!
	) {
		Admin {
			Vocab {
				deleteWordPackage(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
