/**
 * @generated SignedSource<<80c42bf92e28b8f7a5ee13481b77f33b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordPackagesTable_Query$variables = {
  first?: number | null | undefined;
};
export type wordPackagesTable_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"wordPackagesTable_WordPackagesListFragment">;
};
export type wordPackagesTable_Query = {
  response: wordPackagesTable_Query$data;
  variables: wordPackagesTable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "wordPackagesTable_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "wordPackagesTable_WordPackagesListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "wordPackagesTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "WordPackageConnection",
                "kind": "LinkedField",
                "name": "WordPackages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordPackage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "language",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Word",
                            "kind": "LinkedField",
                            "name": "associatedWords",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "word",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "explanation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "translation",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "WordPackagesTable_WordPackages",
                "kind": "LinkedHandle",
                "name": "WordPackages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60446823dd33886fce48363aa5340a8c",
    "id": null,
    "metadata": {},
    "name": "wordPackagesTable_Query",
    "operationKind": "query",
    "text": "query wordPackagesTable_Query(\n  $first: Int\n) {\n  ...wordPackagesTable_WordPackagesListFragment_3ASum4\n}\n\nfragment editWordButton_WordFragment on Word {\n  id\n  word\n  translation\n  explanation\n}\n\nfragment editWordPackageButton_WordPackage on WordPackage {\n  id\n  name\n  language\n  associatedWords {\n    id\n  }\n}\n\nfragment wordPackagesTable_WordPackagesInlineFragment on WordPackage {\n  id\n  name\n  language\n  associatedWords {\n    id\n  }\n  ...editWordPackageButton_WordPackage\n  ...wordsTable_WordPackageFragment\n}\n\nfragment wordPackagesTable_WordPackagesListFragment_3ASum4 on Query {\n  Admin {\n    Vocab {\n      WordPackages(first: $first) {\n        edges {\n          node {\n            ...wordPackagesTable_WordPackagesInlineFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment wordsTable_WordPackageFragment on WordPackage {\n  id\n  associatedWords {\n    id\n    word\n    explanation\n    ...editWordButton_WordFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c0af6a1bc59f653ab46fb76377a7ea2";

export default node;
