import { Skeleton } from "@thekeytechnology/epic-ui";
import { MultiSelect, type MultiSelectChangeEvent } from "primereact/multiselect";
import { type FC, useEffect, useMemo } from "react";
import { usePreloadedQuery, useQueryLoader } from "react-relay";
import { type selectAccountGroups_Query } from "@relay/selectAccountGroups_Query.graphql";
import { QUERY } from "./select-account-groups.graphql";
import {
	SelectAccountGroupsWithPreloadedQueryProps,
	type SelectAccountGroupsProps,
} from "./select-account-groups.interface";
import { convertGroupName } from "./select-account-groups.util";

export const SelectAccountGroupsWithPreloadedQuery = ({
	fieldValue,
	fieldName,
	updateField,
	placeholder,
	queryRef,
}: SelectAccountGroupsWithPreloadedQueryProps) => {
	const {
		Admin: {
			Auth: { GetUserInAccountGroups: groups },
		},
	} = usePreloadedQuery<selectAccountGroups_Query>(QUERY, queryRef);

	const options = useMemo(
		() =>
			groups.map((g) => ({
				label: convertGroupName(g.name),
				value: g.id,
			})),
		[groups],
	);

	const handleOnChange = (event: MultiSelectChangeEvent) => {
		updateField(event.value);
	};

	return (
		<MultiSelect
			name={fieldName}
			placeholder={placeholder}
			value={fieldValue}
			options={options}
			onChange={handleOnChange}
			filter={true}
			filterBy={"label"}
		/>
	);
};

export const SelectAccountGroups: FC<SelectAccountGroupsProps> = ({ accountId, ...config }) => {
	const [queryReference, loadQuery] = useQueryLoader<selectAccountGroups_Query>(QUERY);

	useEffect(() => {
		loadQuery({ id: accountId }, { fetchPolicy: "store-and-network" });
	}, [loadQuery]);

	return queryReference ? (
		<SelectAccountGroupsWithPreloadedQuery {...config} queryRef={queryReference} />
	) : (
		<Skeleton />
	);
};
