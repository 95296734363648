import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment dynamicVocabElementForm_QueryFragment on Query {
		...selectWordPackagesField_QueryFragment
	}
`;

export const DYNAMIC_VOCAB_ELEMENT_FRAGMENT = graphql`
	fragment dynamicVocabElementForm_DynamicVocabElementFragment on DynamicVocabElement {
		id
		title
		numDecoy
		packageSelections {
			wordPackage {
				id
				name
			}
		}
	}
`;

export const EDIT_DYNAMIC_VOCAB_ELEMENT_MUTATION = graphql`
	mutation dynamicVocabElementForm_EditDynamicVocabElementMutation(
		$input: EditDynamicVocabElementInput!
	) {
		Admin {
			ElearningV2 {
				editDynamicVocabElement(input: $input) {
					dynamicVocabElement {
						...dynamicVocabElementForm_DynamicVocabElementFragment
					}
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
