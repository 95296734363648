/**
 * @generated SignedSource<<ba3eb07f81345971b436f1b2aa4fbc20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createPaModalsFlow_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectCoachModal_QueryFragment" | "selectDimensionsModal_QueryFragment">;
  readonly " $fragmentType": "createPaModalsFlow_QueryFragment";
};
export type createPaModalsFlow_QueryFragment$key = {
  readonly " $data"?: createPaModalsFlow_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createPaModalsFlow_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createPaModalsFlow_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectDimensionsModal_QueryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectCoachModal_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2f371eabc02dce037a4ff7146499dd5a";

export default node;
