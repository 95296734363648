import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { CoachingEditScreen } from ".";

export const CoachingsRoutes: RouteDefinition[] = [
	{
		path: Paths.coachings.withIdPlaceholder().edit.path,
		element: <CoachingEditScreen />,
		requiredPermissions: ["UserInAccountPermission_CoachingOfferAdmin_Modify"],
	},
];
