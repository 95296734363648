/**
 * @generated SignedSource<<8a714996af4c00375eeea87b74f1dca0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "ParticipationCertificate" | "WordPackage" | "WordProficiencies";
import { FragmentRefs } from "relay-runtime";
export type createWordPackageRewardButton_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly rewards?: ReadonlyArray<{
      readonly data?: {
        readonly wordPackage: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      };
      readonly id: string;
      readonly kind: RewardKind;
    }>;
  };
  readonly " $fragmentType": "createWordPackageRewardButton_TreeNodeFragment";
};
export type createWordPackageRewardButton_TreeNodeFragment$key = {
  readonly " $data"?: createWordPackageRewardButton_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createWordPackageRewardButton_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createWordPackageRewardButton_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WordPackageRewardData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WordPackage",
                          "kind": "LinkedField",
                          "name": "wordPackage",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "WordPackageReward",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "8b12a57413f1ddf61142377a4af77905";

export default node;
