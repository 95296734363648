import graphql from "babel-plugin-relay/macro";

export const DELETE_CUSTOMER_FEEDBACK_MUTATION = graphql`
	mutation deleteCustomerFeedbackButton_DeleteCustomerFeedbackMutation(
		$input: DeleteCustomerFeedbackInput!
		$connections: [ID!]!
	) {
		Admin {
			CustomerFeedback {
				deleteCustomerFeedback(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
