import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectCoachingFieldDialog_Query($coachingId: ID!, $skip: Boolean!) {
		node(id: $coachingId) @skip(if: $skip) {
			... on CoachingOffer {
				id
				description {
					name
				}
			}
		}
	}
`;
