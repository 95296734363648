/**
 * @generated SignedSource<<0a976cf297efb4984577b8d76fe4836b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type coachProfilesTable_QueryFragment$data = {
  readonly Admin: {
    readonly Coaching: {
      readonly AvailabilitySchedules: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"coachProfilesTable_AvailabilityScheduleFragment">;
          };
        } | null | undefined> | null | undefined;
      };
      readonly CoachProfiles: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly coachingOffer: {
              readonly id: string;
              readonly " $fragmentSpreads": FragmentRefs<"coachProfilesTable_CoachProfileFragment" | "editBaseDataButton_CoachProfileFragment">;
            };
            readonly coachings: ReadonlyArray<{
              readonly description: {
                readonly name: string;
              } | null | undefined;
            }>;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "coachProfilesTable_QueryFragment";
};
export type coachProfilesTable_QueryFragment$key = {
  readonly " $data"?: coachProfilesTable_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachProfilesTable_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Coaching",
  "CoachProfiles"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "editBaseDataButton_CoachProfileFragment"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByUserId"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userName"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CoachProfilesTable_QueryRefetch.graphql')
    }
  },
  "name": "coachProfilesTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CoachingAdminSchema",
          "kind": "LinkedField",
          "name": "Coaching",
          "plural": false,
          "selections": [
            {
              "alias": "CoachProfiles",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterByUserId",
                  "variableName": "filterByUserId"
                },
                {
                  "kind": "Variable",
                  "name": "userName",
                  "variableName": "userName"
                }
              ],
              "concreteType": "CoachProfileWithCoachingsConnection",
              "kind": "LinkedField",
              "name": "__CoachProfilesTable_CoachProfiles_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CoachProfileWithCoachingsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CoachProfileWithCoachings",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CoachProfile",
                          "kind": "LinkedField",
                          "name": "coachingOffer",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "coachProfilesTable_CoachProfileFragment",
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "User",
                                  "kind": "LinkedField",
                                  "name": "coach",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProfileData",
                                  "kind": "LinkedField",
                                  "name": "profileData",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "description",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "averageFeedbackScore",
                                  "storageKey": null
                                },
                                (v3/*: any*/),
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "editProfileDataButton_CoachProfileFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "editBusinessDataButton_CoachProfileFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "editAvailabilityScheduleButton_CoachProfileFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "deleteCoachProfileButton_CoachProfileFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "showCoachFeedbacksButton_CoachProfileFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "showCoachAppointmentsButton_CoachProfileFragment"
                                }
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            },
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CoachingOffer",
                          "kind": "LinkedField",
                          "name": "coachings",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CoachingOfferDescription",
                              "kind": "LinkedField",
                              "name": "description",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AvailabilityScheduleConnection",
              "kind": "LinkedField",
              "name": "AvailabilitySchedules",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AvailabilityScheduleEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AvailabilitySchedule",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "coachProfilesTable_AvailabilityScheduleFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "coachRef",
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "editAvailabilityScheduleButton_AvailabilityScheduleFragment"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "82527f830a4f7d85393a50e56fa1c436";

export default node;
