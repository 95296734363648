import { Paths } from "@routes/paths";
import { TrendEditRoutes } from "@screens/trend-edit/trend-edit.routes";
import { TrendsScreen } from "./trends.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const TrendsRoutes: RouteDefinition[] = [
	{
		path: Paths.trends.path,
		element: <TrendsScreen />,
		requiredPermissions: ["UserInAccountPermission_AuthAdmin_Read"],
	},
	...TrendEditRoutes,
];
