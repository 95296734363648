import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { Button } from "primereact/button";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { TreeConfigurationEditDialogProps } from "./Config.interfaces";
import { ConfigDatePicker } from "./ConfigDatePicker";
import { AfterDateTimeVisibilityConfigForm_EditMutation } from "../../../__generated__/AfterDateTimeVisibilityConfigForm_EditMutation.graphql";
import { AfterDateTimeVisibilityConfigForm_TreeNodeFragment$key } from "../../../__generated__/AfterDateTimeVisibilityConfigForm_TreeNodeFragment.graphql";
import { AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "../../../__generated__/AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import { RenderConfig, ValidatedField } from "../../../components/ValidatedField";
import { CONFIGS_TRANSLATIONS } from "../../../translations/educational-offer";

const CONFIGURATION_FRAGMENT = graphql`
	fragment AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment on NotAfterDateTimeVisibilityTreeConfig {
		id
		dateTime
		configType
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment AfterDateTimeVisibilityConfigForm_TreeNodeFragment on TreeNode {
		id
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation AfterDateTimeVisibilityConfigForm_EditMutation(
		$input: EditAfterDateTimeVisibilityTreeConfigInput!
	) {
		Admin {
			Tree {
				editAfterDateTimeVisibilityTreeConfig(input: $input) {
					rootNode {
						...visibilityTreeConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type FormState = {
	date: string;
};

type Props = {
	configurationFragmentRef: AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key;
	treeNodeFragmentRef: AfterDateTimeVisibilityConfigForm_TreeNodeFragment$key;
	onBack?: () => void;
} & TreeConfigurationEditDialogProps;

export const AfterDateTimeVisibilityConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode = useFragment<AfterDateTimeVisibilityConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] = useMutation<AfterDateTimeVisibilityConfigForm_EditMutation>(
		EDIT_CONFIGURATION_MUTATION,
	);

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			date: configuration.dateTime || moment().add(1, "week").format(),
		},
		onSubmit: (values, { setSubmitting }) => {
			edit({
				variables: {
					input: {
						rootNodeId: treeNode.id,
						configId: configuration.id,
						newDateTime: values.date,
					},
				},
			});
			setSubmitting(false);
			onBack!();
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"date"}
					label={CONFIGS_TRANSLATIONS[configuration.configType]}
					className="mb-4"
					formikConfig={formik}
					component={({
						fieldName,
						fieldValue,
						updateField,
						disabled,
					}: RenderConfig<string>) => {
						return (
							<ConfigDatePicker
								name={fieldName}
								value={fieldValue}
								onUpdate={updateField}
								disabled={disabled}
							/>
						);
					}}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditing}
					type="submit"
					label="Speichern"
					className="mt-2"
				/>

				<Button
					type="button"
					onClick={() => {
						onBack!();
					}}
					label="Zurück"
					className="p-button-secondary mt-2"
				/>
			</form>
		</>
	);
};
