/**
 * @generated SignedSource<<cae4a8d4a8eaa02dff347deb1cffa234>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type coachProfilesTable_CoachProfileFragment$data = {
  readonly averageFeedbackScore: number;
  readonly coach: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly profileData: {
    readonly description: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"deleteCoachProfileButton_CoachProfileFragment" | "editAvailabilityScheduleButton_CoachProfileFragment" | "editBaseDataButton_CoachProfileFragment" | "editBusinessDataButton_CoachProfileFragment" | "editProfileDataButton_CoachProfileFragment" | "showCoachAppointmentsButton_CoachProfileFragment" | "showCoachFeedbacksButton_CoachProfileFragment">;
  readonly " $fragmentType": "coachProfilesTable_CoachProfileFragment";
};
export type coachProfilesTable_CoachProfileFragment$key = {
  readonly " $data"?: coachProfilesTable_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"coachProfilesTable_CoachProfileFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "coachProfilesTable_CoachProfileFragment"
};

(node as any).hash = "cd0d06e6b2f63649f892490356371585";

export default node;
