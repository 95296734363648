import styled from "styled-components";
import tw from "twin.macro";
import { DialogButtonsDirection } from "./dialog-template.types";

export const Wrapper = styled.div<{ maxWidthRem?: number }>`
	${tw`
		tw-flex
		tw-flex-col
		md:tw-gap-[1.5rem]
	`}
	${({ maxWidthRem }) => (maxWidthRem ? `max-width: ${maxWidthRem}rem;` : "")}
`;

export const TextWrapper = tw.div`
	tw-flex
	tw-flex-col
	tw-gap-16
`;

export const ButtonWrapper = styled.div<{
	onlyPrimaryButton: boolean;
	buttonsDirection?: DialogButtonsDirection;
}>`
	${tw`
		tw-flex
		tw-flex-col
		md:tw-flex-row
		md:tw-justify-between
		tw-gap-8
	`}
	${({ onlyPrimaryButton }) => (onlyPrimaryButton ? tw`md:tw-justify-end` : "")}
	${({ buttonsDirection }) =>
		buttonsDirection === DialogButtonsDirection.COLUMN
			? tw`md:tw-flex-col md:tw-justify-start`
			: ""}
`;
export const Divider = tw.div`tw-h-[0.0625rem] tw-bg-[#2196f3]`;
