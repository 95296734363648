import graphql from "babel-plugin-relay/macro";
export const EDIT_CONTRACT_PARTNER_MUTATION = graphql`
	mutation editContractPartnerButton_EditContractPartnerMutation(
		$input: EditContractPartnerDataInput!
	) {
		Admin {
			Billing {
				editContractPartnerData(input: $input) {
					contractPartner {
						...editContractPartnerButton_ContractPartnerFragment
					}
				}
			}
		}
	}
`;

export const CONTRACT_PARTNER_FRAGMENT = graphql`
	fragment editContractPartnerButton_ContractPartnerFragment on ContractPartner {
		id
		shareAmountPercentage
		data {
			email
			name
			association
			street
			houseNumber
			city
			postalCode
			country
			taxNumber
			linkToAGB
		}
	}
`;
