/**
 * @generated SignedSource<<8cdb2dfea315a0a70e80beb603cb6253>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type trendForm_CustomerFeedbackFragment$data = {
  readonly CustomerJourney: {
    readonly CustomerFeedback: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly feedback: string;
          readonly id: string;
          readonly name: string;
          readonly position: string;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentType": "trendForm_CustomerFeedbackFragment";
};
export type trendForm_CustomerFeedbackFragment$key = {
  readonly " $data"?: trendForm_CustomerFeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendForm_CustomerFeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "trendForm_CustomerFeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerJourneyQueries",
      "kind": "LinkedField",
      "name": "CustomerJourney",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerFeedbackConnection",
          "kind": "LinkedField",
          "name": "CustomerFeedback",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerFeedbackEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomerFeedback",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "feedback",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "position",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0c1adeeb1d3951e0785a80b431ef8f7b";

export default node;
