export const HistoryEventType: { [key: string]: string } = {
	OrderCreationUpdate: "Warenkorb erstellt",
	CartUpdate: "Warenkorb aktualisiert",
	BillingDetailsUpdate: "Kundendaten aktualisiert",
	PaymentMethodUpdate: "Zahlungsweise aktualisiert",
	PaymentInProcessUpdate: "Bezahlung ausstehend",
	PurchaseUpdate: "Gekauft",
	InvoiceSentUpdate: "Rechnung versendet",
	CreditNoteSentUpdate: "Gutschrift versendet",
};
