import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment contentCard_QueryFragment on Query {
		...elearningContentEditor_QueryFragment
	}
`;
export const TREE_NODE_FRAGMENT = graphql`
	fragment contentCard_TreeNodeFragment on TreeNode {
		typeDefinition {
			definitionType
			... on ContentTypeDefinition {
				contentKind
			}
		}
		...elearningContentEditor_TreeNodeFragment
		...asyncContentEditor_TreeNodeFragment
	}
`;
