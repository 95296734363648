import type { LearnableKind } from "@relay/offers_Query.graphql";

export const kindsOptions: Array<{ label: String; value: LearnableKind }> = [
	{
		label: "1-1 Coachingsangebot",
		value: "OneToOneCoaching",
	},
	{
		label: "Gruppencoachingangebot",
		value: "GroupCoaching",
	},
	{
		label: "Weiterbildung",
		value: "Course",
	},
	{
		label: "Micro-Learning",
		value: "MicroLearning",
	},
];
