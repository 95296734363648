/**
 * @generated SignedSource<<bc59b031a0801a251f43823ac35e2c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LicenseDefinitionDataKind = "AVGSPotentialAnalysis" | "Coaching" | "MicroLearning" | "Tree";
export type selectLicenseDefinitionField_Refetch$variables = {
  after?: string | null | undefined;
  dataKinds: ReadonlyArray<LicenseDefinitionDataKind>;
  first?: number | null | undefined;
  nameMatchRegex?: string | null | undefined;
};
export type selectLicenseDefinitionField_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_QueryFragment">;
};
export type selectLicenseDefinitionField_Refetch = {
  response: selectLicenseDefinitionField_Refetch$data;
  variables: selectLicenseDefinitionField_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dataKinds"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nameMatchRegex"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "dataKinds",
    "variableName": "dataKinds"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "nameMatchRegex",
    "variableName": "nameMatchRegex"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectLicenseDefinitionField_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectLicenseDefinitionField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectLicenseDefinitionField_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LicenseDefinitionsConnection",
                "kind": "LinkedField",
                "name": "SearchLicenseDefinitions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinitionsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LicenseDefinition",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "nameMatchRegex",
                  "dataKinds"
                ],
                "handle": "connection",
                "key": "selectLicenseDefinitionField_SearchLicenseDefinitions",
                "kind": "LinkedHandle",
                "name": "SearchLicenseDefinitions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3223320bcf5be32c5cbe843a8aa0fe30",
    "id": null,
    "metadata": {},
    "name": "selectLicenseDefinitionField_Refetch",
    "operationKind": "query",
    "text": "query selectLicenseDefinitionField_Refetch(\n  $after: String\n  $dataKinds: [LicenseDefinitionDataKind!]!\n  $first: Int = 20\n  $nameMatchRegex: String\n) {\n  ...selectLicenseDefinitionField_QueryFragment_2Dfdw6\n}\n\nfragment selectLicenseDefinitionField_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    name\n  }\n}\n\nfragment selectLicenseDefinitionField_QueryFragment_2Dfdw6 on Query {\n  Admin {\n    LicenseDefinition {\n      SearchLicenseDefinitions(first: $first, after: $after, nameMatchRegex: $nameMatchRegex, dataKinds: $dataKinds) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            ...selectLicenseDefinitionField_LicenseDefinitionFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf5ddf2229f91845b13eaced7246c604";

export default node;
