import {
	type DefaultFormProps,
	DefaultTextFieldComponent,
	Form,
	ValidatedField,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { type FormikProps } from "formik/dist/types";
import React, { forwardRef, useImperativeHandle } from "react";
import * as Yup from "yup";
import { type EditWordFormState } from "./edit-word-form.interface";

export const EditWordForm = forwardRef<
	FormikProps<EditWordFormState>,
	DefaultFormProps<EditWordFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditWordFormState>({
		initialValues: initialState ?? {},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			word: Yup.string().required("Das Feld Wort wird benötigt."),
			explanation: Yup.string().required("Das Feld Erklärung wird benötigt."),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<Form onSubmit={formik.handleSubmit}>
			<ValidatedField<EditWordFormState, string>
				name={"word"}
				label={"Wort"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditWordFormState, string>
				name={"translation"}
				label={"Übersetzung"}
				required={false}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>

			<ValidatedField<EditWordFormState, string>
				name={"explanation"}
				label={"Erklärung"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
		</Form>
	);
});
EditWordForm.displayName = "EditWordForm";
