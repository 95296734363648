import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query trendForm_Query($id: ID!, $skip: Boolean!) {
		...trendForm_TrendFragment @arguments(id: $id, skip: $skip)
		...trendForm_AcademiesTagFragment
		...trendForm_CustomerFeedbackFragment
	}
`;

export const TREND_FRAGMENT = graphql`
	fragment trendForm_TrendFragment on Query
	@refetchable(queryName: "trendFragment_Refetch")
	@argumentDefinitions(id: { type: "ID!" }, skip: { type: "Boolean!" }) {
		node(id: $id) @skip(if: $skip) {
			... on Trend {
				__id

				trendData {
					name
					description
					icon
					visible
					tags {
						id
					}
					advantages {
						description
						heading
						icon
					}
				}
				trendMedia {
					file {
						id
						name
						fileType
					}
					kind
				}
				coachingImage {
					id
					name
				}
				customerData {
					amountCustomer
					customerFeedbacks {
						id
					}
				}
				aboutOffersHeadingAndDescription {
					description
					heading
				}
				coachingHeadingAndDescription {
					description
					heading
				}
				potentialAnalysisHeadingAndDescription {
					description
					heading
				}
			}
		}
	}
`;

export const CREATE_TREND_MUTATION = graphql`
	mutation trendForm_CreateTrendMutation($input: CreateTrendInput!, $connections: [ID!]!) {
		Admin {
			Trend {
				createTrend(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...trends_TrendFragment
						}
					}
				}
			}
		}
	}
`;

export const TREND_FORM_FRAGMENT = graphql`
	fragment trendForm_AcademiesTagFragment on Query
	@refetchable(queryName: "trendForm_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
		name: { type: "String" }
		isClickable: { type: "Boolean" }
		isTopic: { type: "Boolean" }
	) {
		Admin {
			AcademiesTag {
				RootTags(
					first: $first
					after: $after
					last: $last
					before: $before
					name: $name
					isClickable: $isClickable
					isTopic: $isTopic
				) @connection(key: "trendForm_RootTags") {
					__id
					edges {
						node {
							id
							data {
								name
							}
						}
					}
				}
			}
		}
	}
`;

export const EDIT_TREND_MUTATION = graphql`
	mutation trendForm_EditTrendMutation($input: EditTrendInput!) {
		Admin {
			Trend {
				editTrend(input: $input) {
					edge {
						node {
							trendData {
								name
								description
								icon
								visible
								tags {
									id
								}
								advantages {
									description
									heading
									icon
								}
							}
							trendMedia {
								file {
									id
									name
								}
								kind
							}
							coachingImage {
								id
								name
							}
							customerData {
								amountCustomer
								customerFeedbacks {
									id
								}
							}
							aboutOffersHeadingAndDescription {
								description
								heading
							}
							coachingHeadingAndDescription {
								description
								heading
							}
							potentialAnalysisHeadingAndDescription {
								description
								heading
							}
						}
					}
				}
			}
		}
	}
`;

export const CUSTOMER_FEEDBACK_FRAGMENT = graphql`
	fragment trendForm_CustomerFeedbackFragment on Query {
		CustomerJourney {
			CustomerFeedback {
				edges {
					node {
						feedback
						id
						name
						position
					}
				}
			}
		}
	}
`;
