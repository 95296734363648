/**
 * @generated SignedSource<<38d2a8cb21f36f6831164e225dd1db4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deleteCoachingOfferButton_CoachingOfferFragment$data = {
  readonly groupCoaching?: {
    readonly description: {
      readonly name: string;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly oneToOneCoaching?: {
    readonly description: {
      readonly name: string;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "deleteCoachingOfferButton_CoachingOfferFragment";
};
export type deleteCoachingOfferButton_CoachingOfferFragment$key = {
  readonly " $data"?: deleteCoachingOfferButton_CoachingOfferFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteCoachingOfferButton_CoachingOfferFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "CoachingOfferDescription",
    "kind": "LinkedField",
    "name": "description",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteCoachingOfferButton_CoachingOfferFragment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CoachingOffer",
          "kind": "LinkedField",
          "name": "groupCoaching",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "type": "UnpublishedGroupCoachingLearnable",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CoachingOffer",
          "kind": "LinkedField",
          "name": "oneToOneCoaching",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "type": "UnpublishedOneToOneCoachingLearnable",
      "abstractKey": null
    }
  ],
  "type": "UnpublishedLearnable",
  "abstractKey": "__isUnpublishedLearnable"
};
})();

(node as any).hash = "85e70fbff1e691a6539d3daedbe3f0bf";

export default node;
