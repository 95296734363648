/**
 * @generated SignedSource<<ce175a773a863f7768ce69a84e6aa73b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SwitchAccountInput = {
  clientMutationId?: string | null | undefined;
  refreshToken: string;
  targetAccount: string;
};
export type accountSwitchMenu_SwitchAccountMutation$variables = {
  input: SwitchAccountInput;
};
export type accountSwitchMenu_SwitchAccountMutation$data = {
  readonly Auth: {
    readonly switchAccount: {
      readonly jwtTokens: {
        readonly accessToken: string;
        readonly refreshToken: string;
      };
    } | null | undefined;
  };
};
export type accountSwitchMenu_SwitchAccountMutation = {
  response: accountSwitchMenu_SwitchAccountMutation$data;
  variables: accountSwitchMenu_SwitchAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SwitchAccountPayload",
        "kind": "LinkedField",
        "name": "switchAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JwtLoginInformation",
            "kind": "LinkedField",
            "name": "jwtTokens",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refreshToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountSwitchMenu_SwitchAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountSwitchMenu_SwitchAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84aaa51578f625a4e25869a19e9c2f42",
    "id": null,
    "metadata": {},
    "name": "accountSwitchMenu_SwitchAccountMutation",
    "operationKind": "mutation",
    "text": "mutation accountSwitchMenu_SwitchAccountMutation(\n  $input: SwitchAccountInput!\n) {\n  Auth {\n    switchAccount(input: $input) {\n      jwtTokens {\n        accessToken\n        refreshToken\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "727bac5fbbe96f75ae875a559fc96de8";

export default node;
