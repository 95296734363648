import { Button, DataTable, EpicIcons, Icon } from "@thekeytechnology/epic-ui";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { readInlineData, useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { type AdminIcons } from "@components/icon/icon.types";
import { Spacing32 } from "@features/licenses/licenses-table/licenses-table.styles";
import { type trends_Query } from "@relay/trends_Query.graphql";
import { type trends_TrendFragment$key } from "@relay/trends_TrendFragment.graphql";
import { type trends_TrendsFragment$key } from "@relay/trends_TrendsFragment.graphql";
import { Paths } from "@routes/paths";
import { BaseScreen } from "@screens/BaseScreen";
import { SortTrendsModal } from "@screens/trends/parts/sort-trends-modal";
import { DELETE_TREND_MUTATION, QUERY, TREND_FRAGMENT, TRENDS_FRAGMENT } from "./trends.graphql";
import { ButtonContainer, ActionsWrapper } from "./trends.styles";

export const TrendsScreen = () => {
	const query = useLazyLoadQuery<trends_Query>(QUERY, {});
	const [deleteTrend] = useMutation(DELETE_TREND_MUTATION);
	const navigate = useNavigate();

	const trendsFragment = useFragment<trends_TrendsFragment$key>(TRENDS_FRAGMENT, query);
	const trendFragments = trendsFragment.Admin.Trend.trend.edges?.map((edge) => edge?.node!);

	const connectionId = trendsFragment.Admin.Trend.trend.__id;

	const trends = trendsFragment.Admin.Trend.trend.edges?.map((edge) =>
		readInlineData<trends_TrendFragment$key>(TREND_FRAGMENT, edge?.node!),
	);

	const formattedTrends = trends?.map((trend) => ({
		id: trend.id,
		name: trend.trendData.name,
		description: trend.trendData.description,
		icon: trend.trendData.icon,
		position: trend.position,
	}));

	const handleOnCreateTrendClick = () => {
		navigate(Paths.trends.withId("new").edit.path, { state: { connectionId } });
	};

	const handleOnEditTrendClick = (trendId: string) => {
		navigate(Paths.trends.withId(trendId).edit.path);
	};
	const handleOnDeleteTrendClick = (trendId: string) => {
		confirmDialog({
			header: "Trend löschen",
			message: "Bist du sicher, dass du diesen Trend löschen möchtest?",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				deleteTrend({
					variables: {
						input: {
							ids: [trendId],
						},
						connections: [connectionId],
					},
				}),
		});
	};

	return (
		<BaseScreen>
			<h1>Trends</h1>
			<ActionsWrapper>
				<Button label="Trend erstellen" onClick={handleOnCreateTrendClick} />
				{trendFragments && <SortTrendsModal trendsFragmentRef={trendFragments} />}
			</ActionsWrapper>
			<Spacing32 />
			<DataTable
				value={formattedTrends ?? []}
				emptyMessage={<p>Du hast noch keine Trends angelegt.</p>}
			>
				{(Column) => (
					<>
						<Column header="#" field="position" />
						<Column header="Name" field="name" />
						<Column header="Beschreibung" field="description" />
						<Column
							header="Icon"
							body={(rowData) => {
								return <Icon icon={rowData.icon as AdminIcons} />;
							}}
						/>
						<Column
							header="Aktionen"
							body={(rowData) => {
								return (
									<ButtonContainer>
										<Button
											icon={EpicIcons.PENCIL}
											onClick={() => {
												rowData.id && handleOnEditTrendClick(rowData.id);
											}}
										/>
										<Button
											icon={EpicIcons.TRASH}
											onClick={() => {
												rowData.id && handleOnDeleteTrendClick(rowData.id);
											}}
										/>
									</ButtonContainer>
								);
							}}
						/>
					</>
				)}
			</DataTable>
			<ConfirmDialog />
		</BaseScreen>
	);
};
