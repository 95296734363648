/**
 * @generated SignedSource<<bd84e0231d500b4f73024e0d1ac138e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAvgsAccountAndCoachForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAvgsAccountField_QueryFragment">;
  readonly " $fragmentType": "selectAvgsAccountAndCoachForm_QueryFragment";
};
export type selectAvgsAccountAndCoachForm_QueryFragment$key = {
  readonly " $data"?: selectAvgsAccountAndCoachForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAvgsAccountAndCoachForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "selectAvgsAccountAndCoachForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectAvgsAccountField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "49216ed0058675288c60d93b9f611846";

export default node;
