import { Button, type PathParams } from "@thekeytechnology/epic-ui";
import { Card } from "primereact/card";
import { useState } from "react";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { useIsFeatureActive } from "@hooks/use-is-feature-active";
import { type accountEditScreen_AccountFragment$key } from "@relay/accountEditScreen_AccountFragment.graphql";
import { type accountEditScreen_AccountGroupsQuery } from "@relay/accountEditScreen_AccountGroupsQuery.graphql";
import { type accountEditScreen_CreateMutation } from "@relay/accountEditScreen_CreateMutation.graphql";
import { type accountEditScreen_Query } from "@relay/accountEditScreen_Query.graphql";
import { type AccountEditScreen_Refetch } from "@relay/AccountEditScreen_Refetch.graphql";
import { type accountEditScreen_UpdateMutation } from "@relay/accountEditScreen_UpdateMutation.graphql";
import { Paths } from "@routes/paths";
import { TransformToAVGSAccountButton } from "@screens/account-edit/parts/transform-to-avgs-account-button";
import { TransformToAZAVAccountButton } from "@screens/account-edit/parts/transform-to-azav-account-button";
import { type AccountsPath } from "@screens/accounts";
import {
	ACCOUNT_FRAGMENT,
	ACCOUNT_GROUP_QUERY,
	ACCOUNT_QUERY,
	CREATE_MUTATION,
	UPDATE_MUTATION,
} from "./account-edit.graphql";
import { StyledDialog } from "./account-edit.styles";
import { AddUserToAccount } from "./parts/add-user-to-account";
import { EditAccountForm } from "./parts/edit-account-form";
import { type EditAccountFormState } from "./parts/edit-account-form/edit-account-form.types";
import { NoAccess } from "../../components/no-access";
import { EditAccountGroupsButton } from "../../features/accounts/edit-account-groups-button/edit-account-groups-button.component";
import { UsersInAccountTable } from "../../features/accounts/users-in-account-table";
import { LicensesTable } from "../../features/licenses/licenses-table";
import { OrdersTable } from "../../features/orders/orders-table/orders-table.component";
import { BaseScreen } from "../BaseScreen";

export const AccountEditScreen = () => {
	const { accountId } = useParams<PathParams<typeof AccountsPath>>();
	const isFeatureActive = useIsFeatureActive("potentialAnalysisV2");
	const isCreatingAccount = accountId === "new";
	const query = useLazyLoadQuery<accountEditScreen_Query>(
		ACCOUNT_QUERY,
		{
			id: accountId ?? "",
			skip: !accountId || isCreatingAccount,
		},
		{ fetchPolicy: "network-only" },
	);

	const accountGroupsQuery = useLazyLoadQuery<accountEditScreen_AccountGroupsQuery>(
		ACCOUNT_GROUP_QUERY,
		{},
	);

	const {
		hasNext,
		loadNext,
		data: account,
	} = usePaginationFragment<AccountEditScreen_Refetch, accountEditScreen_AccountFragment$key>(
		ACCOUNT_FRAGMENT,
		query.node ?? null,
	);

	const [create, isCreating] = useMutation<accountEditScreen_CreateMutation>(CREATE_MUTATION);
	const [update, isUpdating] = useMutation<accountEditScreen_UpdateMutation>(UPDATE_MUTATION);

	const navigate = useNavigate();

	const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);

	const handleLoadNextOnClick = () => {
		loadNext(20);
	};

	const handleAddUserOnClick = () => {
		setAddUserDialogVisible(true);
	};

	const handleAddUserDialogOnHide = () => {
		setAddUserDialogVisible(false);
	};

	const handleOnUserAdded = () => {
		setAddUserDialogVisible(false);
	};

	const handleEditAccountBaseDataOnClick = () => {
		navigate(Paths.accounts.withId(accountId ?? "").edit.basedata.path);
	};

	const connections = account?.extension?.users?.__id ? [account?.extension.users?.__id] : [];

	const title = isCreatingAccount ? "Konto erstellen" : "Konto bearbeiten";

	const updateAccount = (values: EditAccountFormState) => {
		if (!account?.id) return;
		update({
			variables: {
				input: {
					id: account.id,
					name: values.name!,
				},
			},
		});
	};

	const createAccount = (values: EditAccountFormState) => {
		create({
			variables: {
				input: {
					name: values.name!,
				},
			},
			onCompleted: (response) => {
				const accountId = response.Admin.Auth.createAccount?.edge.node.id;
				accountId &&
					navigate(Paths.accounts.withId(accountId).edit.path, { replace: true });
			},
		});
	};

	const handleAccountFormOnSubmit = (values: EditAccountFormState) => {
		if (account) {
			updateAccount(values);
		} else {
			createAccount(values);
		}
	};

	const canViewOrders = useHasPermissions(["UserInAccountPermission_OrderAdmin_Read"]);

	const canModifyOrders = useHasPermissions(["UserInAccountPermission_OrderAdmin_Modify"]);

	const canReadAuth = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);

	const canModifyAuth = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	const canReadLicenses = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Read"]);

	const accountGroupsFragmentsRefs = accountGroupsQuery.Admin.Management.AccountGroups.edges?.map(
		(e) => e?.node!,
	);

	const accountGroups = account?.groupAssociations?.map((group) => group.group?.name).join(", ");

	if (!canReadAuth || !canModifyAuth) {
		return <NoAccess />;
	}

	return (
		<BaseScreen>
			<h1 className="mb-3">{title}</h1>
			<div className="max-w-30rem flex flex-col align-items-end gap-4">
				<EditAccountForm
					initialValues={{
						name: account?.name,
					}}
					loading={isCreating || isUpdating}
					onSubmit={handleAccountFormOnSubmit}
				/>
			</div>
			<div className="flex flex-col align-items-center mt-4 gap-4">
				<span>
					<b>Berechtigungen:</b>
				</span>
				<span>{accountGroups}</span>
				{account && accountGroupsFragmentsRefs && (
					<EditAccountGroupsButton
						accountFragmentRef={account}
						accountGroupsFragmentsRef={accountGroupsFragmentsRefs}
					></EditAccountGroupsButton>
				)}
			</div>
			<div className="mt-4">
				<Button label="Stammdaten bearbeiten" onClick={handleEditAccountBaseDataOnClick} />
			</div>
			{account && (
				<div className="mt-4">
					<TransformToAZAVAccountButton accountFragmentRef={account} />
				</div>
			)}
			{isFeatureActive && account && (
				<div className="mt-4">
					<TransformToAVGSAccountButton accountFragmentRef={account} />
				</div>
			)}
			{account?.extension?.users && (
				<Card className="mt-6">
					<div className="flex justify-content-between align-items-center">
						<h2 className="mb-3">Benutzer</h2>
						<Button label="Benutzer hinzufügen" onClick={handleAddUserOnClick} />
					</div>
					<UsersInAccountTable
						accountId={accountId!}
						connections={connections}
						hasNext={hasNext}
						loadNext={handleLoadNextOnClick}
						userInAccountConnectionRef={account.extension.users}
					/>
				</Card>
			)}
			{!isCreatingAccount && canViewOrders && (
				<Card className="mt-6">
					<h2 className="mb-3">Bestellungen</h2>
					<OrdersTable
						ordersFragmentRef={query}
						ordersPerPage={10}
						enableFilter={false}
						canModifyOrders={canModifyOrders}
					/>
				</Card>
			)}
			{!isCreatingAccount && canReadLicenses && (
				<Card className="mt-6">
					<h2 className="mb-3">Lizenzen</h2>
					<LicensesTable
						licensesFragmentRef={query}
						accountName={account?.name}
						accountId={accountId}
						showDeleteButton
					/>
				</Card>
			)}
			<StyledDialog visible={addUserDialogVisible} onHide={handleAddUserDialogOnHide}>
				<AddUserToAccount
					accountId={accountId!}
					connections={connections}
					onUserAdded={handleOnUserAdded}
				/>
			</StyledDialog>
		</BaseScreen>
	);
};
