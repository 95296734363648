type OwnProps = {
	children: any;
};

export const BaseContentEditor = ({ children }: OwnProps) => {
	return (
		<div>
			<div className="flex align-items-center">
				<h2>Elemente</h2>
			</div>
			{children}
		</div>
	);
};
