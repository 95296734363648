/**
 * @generated SignedSource<<ba49b23b4318d097e6fa6231f5fe0446>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BookingKind = "BookedForGroup" | "BookedOneToOne" | "NotBooked";
export type ParticipationStatus = "Absent" | "AbsentExcused" | "NotConfirmed" | "Participated";
import { FragmentRefs } from "relay-runtime";
export type editCoachAppointmentsTable_AvailabilityFragment$data = {
  readonly booking: {
    readonly kind: BookingKind;
    readonly offer?: {
      readonly setting: {
        readonly maxParticipants?: number;
      };
    } | null | undefined;
    readonly participant?: {
      readonly participationStatus: ParticipationStatus;
      readonly user: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    };
    readonly participants?: ReadonlyArray<{
      readonly participationStatus: ParticipationStatus;
      readonly user: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    }>;
  };
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"editParticipantsButton_AvailabilityFragment">;
  readonly " $fragmentType": "editCoachAppointmentsTable_AvailabilityFragment";
};
export type editCoachAppointmentsTable_AvailabilityFragment$key = {
  readonly " $data"?: editCoachAppointmentsTable_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editCoachAppointmentsTable_AvailabilityFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "editCoachAppointmentsTable_AvailabilityFragment"
};

(node as any).hash = "353c5b0d368db2ec57f3ae1709fb4464";

export default node;
