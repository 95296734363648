import graphql from "babel-plugin-relay/macro";

export const CREATE_COACH_STATEMENTS_FROM_UNBILLED_MUTATION = graphql`
	mutation createCoachStatementsFromUnbilledButton_CreateCoachStatementsFromUnbilledMutation {
		Admin {
			Staging {
				createCoachStatementsFromUnbilled(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;
