import { readInlineData, usePaginationFragment } from "react-relay";
import {
	type selectLicenseDefinitionField_LicenseDefinitionFragment$data,
	type selectLicenseDefinitionField_LicenseDefinitionFragment$key,
} from "@relay/selectLicenseDefinitionField_LicenseDefinitionFragment.graphql";
import { type selectLicenseDefinitionField_QueryFragment$key } from "@relay/selectLicenseDefinitionField_QueryFragment.graphql";
import { type selectLicenseDefinitionField_Refetch } from "@relay/selectLicenseDefinitionField_Refetch.graphql";
import {
	LICENSE_DEFINITION_FRAGMENT,
	QUERY_FRAGMENT,
} from "./select-license-definition-field.graphql";
import {
	type SelectLicenseDefinitionFieldProps,
	type SelectLicenseDefinitionFieldState,
} from "./select-license-definition-field.type";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";

export const SelectLicenseDefinitionField = ({
	queryFragmentRef,
	...props
}: SelectLicenseDefinitionFieldProps) => {
	const {
		data: {
			Admin: {
				LicenseDefinition: { SearchLicenseDefinitions: licenseDefinitions },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectLicenseDefinitionField_Refetch,
		selectLicenseDefinitionField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ nameMatchRegex: search });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectLicenseDefinitionField_LicenseDefinitionFragment$data>,
	) => {
		props.updateField({ id: event.value.id, name: event.value.data.name });
	};

	const values =
		licenseDefinitions.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectLicenseDefinitionField_LicenseDefinitionFragment$key>(
					LICENSE_DEFINITION_FRAGMENT,
					e!.node,
				),
			) || [];

	return (
		<SelectComplexDataField<SelectLicenseDefinitionFieldState>
			valueField="name"
			valuePlaceholder="Kein Lizenzdefinition ausgewählt"
			actionButtonLabel="Lizenzdefinition auswählen"
			headerLabel="Wähle eine Lizenzdefinition aus"
			{...props}
		>
			<SearchableTable
				selectionMode="single"
				selection={props.fieldValue}
				dataKey="id"
				value={values}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnSelectionChange}
			>
				{(Column) => (
					<>
						<Column header="Name" field="data.name" />
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
