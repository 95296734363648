import { EpicIcons } from "@thekeytechnology/epic-ui";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type CreateClozeTextElementButton_CreateClozeTextElementMutation } from "@relay/CreateClozeTextElementButton_CreateClozeTextElementMutation.graphql";

const CREATE_CLOZE_TEXT_ELEMENT_MUTATION = graphql`
	mutation CreateClozeTextElementButton_CreateClozeTextElementMutation(
		$input: CreateClozeTextElementV2Input!
	) {
		Admin {
			ElearningV2 {
				createClozeTextElement(input: $input) {
					eLearningContentNode {
						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type OwnProps = {
	eLearningContentNodeId: string;
};

export const CreateClozeTextElementButton = ({ eLearningContentNodeId }: OwnProps) => {
	const [CreateClozeTextElement, isCreatingClozeTextElement] =
		useMutation<CreateClozeTextElementButton_CreateClozeTextElementMutation>(
			CREATE_CLOZE_TEXT_ELEMENT_MUTATION,
		);

	const handleCreateOnClick = () => {
		CreateClozeTextElement({
			variables: {
				input: {
					title: "Neues Element",
					text: "",
					additionalWords: [],
					eLearningContentNodeId,
				},
			},
		});
	};
	return (
		<Button
			disabled={isCreatingClozeTextElement}
			tooltip={`Lückentext-Baustein anlegen`}
			icon={EpicIcons.WINDOW_MINIMIZE}
			onClick={handleCreateOnClick}
		/>
	);
};
