import { ButtonType } from "@thekeytechnology/epic-ui";
import { type FormikProps, useFormik } from "formik";
import { Card } from "primereact/card";
import React, { useCallback, useImperativeHandle } from "react";
import * as Yup from "yup";
import { Button } from "@components/button";
import { DefaultTextAreaComponent } from "@components/DefaultTextInput";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { ValidatedField } from "@components/ValidatedField";
import {
	Form,
	Wrapper,
} from "@screens/coaching-edit/parts/participation-certificate-form/participation-certificate-form.styles";
import {
	type EditCoachingOfferParticipationCertificateFormState,
	type ParticipationCertificateFormProps,
} from "@screens/coaching-edit/parts/participation-certificate-form/participation-certificate-form.types";
import { type DefaultFormProps } from "@screens/offers/parts/common";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
	selectArrayOfEditedForms,
} from "@store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store.redux";

export const ParticipationCertificateForm = React.forwardRef<
	FormikProps<EditCoachingOfferParticipationCertificateFormState>,
	DefaultFormProps<EditCoachingOfferParticipationCertificateFormState> &
		ParticipationCertificateFormProps
>(({ initialState, onSubmit, onDelete, onCreate, hasCertificate, variables }, ref) => {
	const dispatch = useTypedDispatch();
	const formName = "edit-coaching-offer-participation-certificate";
	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);
	const hasForm = arrayOfEditedForm.some((form) => form === formName);
	const formik = useFormik<EditCoachingOfferParticipationCertificateFormState>({
		initialValues: initialState ?? {
			name: "",
			variables: [],
			html: "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name muss angegeben werden."),
			html: Yup.string().required("Html muss angegeben werden."),
		}),
		onSubmit: (values, formikHelpers) => {
			onSubmit(values, formikHelpers);
			dispatch(removeEditedFormFromEditedFormsArray({ form: formName }));
		},
	});
	useImperativeHandle(ref, () => ({
		...formik,
	}));

	const isDisabled = !hasForm || Object.entries(formik.touched).length === 0;
	const handleFormEdited = useCallback(
		() =>
			dispatch(
				addEditedFormToEditedFormsArray({
					form: formName,
				}),
			),
		[dispatch],
	);

	const CreateParticipationCertificateComponent = (
		<Wrapper>
			<h3>Teilnehmerbestätigung anlegen?</h3>
			<Button label={"Anlegen"} onClick={onCreate} stretch />
		</Wrapper>
	);

	const FormComponent = (
		<Wrapper>
			<Form onSubmit={formik.handleSubmit} className={"p-fluid"}>
				<ValidatedField<EditCoachingOfferParticipationCertificateFormState, string>
					required
					name={"name"}
					label="Name"
					placeholder="Name..."
					formikConfig={formik}
					component={(renderConfig) => <DefaultTextAreaComponent {...renderConfig} />}
				/>

				<ValidatedField<EditCoachingOfferParticipationCertificateFormState, string>
					required
					name={"html"}
					label="Html"
					placeholder="Html..."
					formikConfig={formik}
					component={(renderConfig) => (
						<MemoizedRichTextEditor
							{...renderConfig}
							toolbar={RichTextEditorToolbar.Default}
							onChange={handleFormEdited}
						/>
					)}
				/>
				{
					<div>
						<h3>Verfügbare Variabeln</h3>
						{variables?.map((k, i, arr) => (
							<span key={k}>
								{k}
								{i + 1 === arr.length ? "" : ", "}
							</span>
						))}
					</div>
				}

				<Button
					disabled={isDisabled}
					type={ButtonType.Submit}
					label="Speichern"
					stretch={true}
				/>
				<Button
					label={"Löschen"}
					type={ButtonType.Button}
					onClick={onDelete}
					stretch={true}
				/>
			</Form>
		</Wrapper>
	);
	return (
		<Card className={"mt-2"}>
			{hasCertificate ? FormComponent : CreateParticipationCertificateComponent}
		</Card>
	);
});
ParticipationCertificateForm.displayName = "ParticipationCertificateForm";
