import { DialogButton } from "@thekeytechnology/framework-react-components";
import React from "react";
import { useFragment } from "react-relay";
import { type editParticipantsButton_AvailabilityFragment$key } from "@relay/editParticipantsButton_AvailabilityFragment.graphql";
import { AVAILABILITY_FRAGMENT } from "./edit-participants-button.graphql";
import { type EditParticipantsButtonProps } from "./edit-participants-button.types";
import { EditParticipantsTable } from "../edit-participants-table/edit-participants-table.component";

export const EditParticipantsButton = ({
	availabilityFragmentRef,
}: EditParticipantsButtonProps) => {
	const availability = useFragment<editParticipantsButton_AvailabilityFragment$key>(
		AVAILABILITY_FRAGMENT,
		availabilityFragmentRef,
	);

	const disabled = availability.booking.kind === "NotBooked";
	return (
		<DialogButton
			buttonContent={{ icon: "pi pi-pencil" }}
			title={"Teilnehmer Bearbeiten"}
			disabled={disabled}
		>
			{() => <EditParticipantsTable availabilityFragmentRef={availability} />}
		</DialogButton>
	);
};
