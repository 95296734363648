import { TreeNode } from "primereact/treenode";
import { selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$data } from "@relay/selectDimensionsModal_AVGSPotentialAnalysisModuleFragment.graphql";

export const modulesToNodes = (
	modules: Omit<
		selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$data,
		" $fragmentType"
	>[],
): TreeNode[] => {
	return modules.map((module) => ({
		key: module.moduleId,
		id: String(module.moduleId),
		label: module.moduleText,
		children: module.dimensions.map((dimension) => ({
			key: module.moduleId + "-" + dimension.dimensionId,
			id: String(dimension.dimensionId),
			label: dimension.dimensionText,
			children: [],
			selectable: false,
		})),
	}));
};
