import { createContext, Dispatch } from "react";

export type IVideoSourcesScreenContext = {
	connectionId: string;
	setConnectionId: Dispatch<string>;
};

export const VideoSourcesScreenContext = createContext<IVideoSourcesScreenContext>({
	connectionId: "",
	setConnectionId: () => {},
});
