import { FormDialogButton } from "@thekeytechnology/epic-ui";
import type { FormikProps } from "formik";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useMutation } from "react-relay";
import { type createCustomerFeedbackButton_CreateCustomerFeedbackMutation } from "@relay/createCustomerFeedbackButton_CreateCustomerFeedbackMutation.graphql";
import { CustomerFeedbackScreenContext } from "@screens/customer-feedback/customer-feedback.context";
import { CustomerFeedbackForm } from "@screens/customer-feedback/parts/customer-feedback-form/customer-feedback-form.component";
import { type CustomerFeedbackFormState } from "@screens/customer-feedback/parts/customer-feedback-form/customer-feedback-form.types";
import { CREATE_CUSTOMER_FEEDBACK_MUTATION } from "./create-customer-feedback-button.graphql";

export const CreateCustomerFeedbackButton = () => {
	const toast = useRef<Toast>(null);
	const { connectionId } = useContext(CustomerFeedbackScreenContext);

	const [createCustomerFeedback, isCreatingCustomerFeedback] =
		useMutation<createCustomerFeedbackButton_CreateCustomerFeedbackMutation>(
			CREATE_CUSTOMER_FEEDBACK_MUTATION,
		);

	const handleOnSubmit = (
		formikRef: React.MutableRefObject<FormikProps<CustomerFeedbackFormState> | null>,
		onHide: () => void,
		values: CustomerFeedbackFormState,
	) => {
		createCustomerFeedback({
			variables: {
				input: {
					name: values.name,
					feedback: values.feedback,
					position: values.position,
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				formikRef.current?.setSubmitting(false);
				onHide();
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Kundenfeedback gespeichert",
					detail: "Das Kundenfeedback wurde erfolgreich aktualisiert",
				});
			},
			onError: () => {
				formikRef.current?.setSubmitting(false);
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Kundenfeedback gespeichert",
					detail: "Das Kundenfeedback wurde nicht aktualisiert",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			<FormDialogButton<CustomerFeedbackFormState>
				buttonLabel="Kundenfeedback erstellen"
				dialogTitle="Kundenfeedback erstellen"
				disabled={isCreatingCustomerFeedback}
			>
				{({ formikRef, onHide }) => {
					return (
						<CustomerFeedbackForm
							initialState={{
								customerFeedbackId: "",
								name: "",
								position: "",
								feedback: "",
							}}
							ref={formikRef}
							onSubmit={(values) => {
								handleOnSubmit(formikRef, onHide, values);
							}}
						/>
					);
				}}
			</FormDialogButton>
		</>
	);
};
