import { readInlineData, usePaginationFragment } from "react-relay";
import { type DataTableSelectionChangeParams } from "@components/data-table";
import { SearchableTable } from "@components/searchable-table";
import { SelectComplexDataField } from "@components/select-complex-data-field";
import { type selectWordPackagesField_QueryFragment$key } from "@relay/selectWordPackagesField_QueryFragment.graphql";
import { type selectWordPackagesField_QueryRefetch } from "@relay/selectWordPackagesField_QueryRefetch.graphql";
import {
	type selectWordPackagesField_WordPackageFragment$data,
	type selectWordPackagesField_WordPackageFragment$key,
} from "@relay/selectWordPackagesField_WordPackageFragment.graphql";
import { QUERY_FRAGMENT, WORD_PACKAGE_FRAGMENT } from "./select-word-packages-field.graphql";
import { type SelectWordPackagesFieldProps } from "./select-word-packages-field.types";

export const SelectWordPackagesField = ({
	queryFragment,
	...props
}: SelectWordPackagesFieldProps) => {
	const {
		data: {
			Admin: {
				Vocab: { WordPackages },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectWordPackagesField_QueryRefetch,
		selectWordPackagesField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragment);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};
	const handleOnSearch = (filterByName: string) => {
		refetch({ filterByName });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectWordPackagesField_WordPackageFragment$data[]>,
	) => {
		props.updateField(
			event.value.map((k) => ({
				id: k.id,
				name: k.name,
			})),
		);
	};

	const values =
		WordPackages.edges?.map((e) =>
			readInlineData<selectWordPackagesField_WordPackageFragment$key>(
				WORD_PACKAGE_FRAGMENT,
				e!.node,
			),
		) ?? [];

	return (
		<>
			<SelectComplexDataField
				valuePlaceholder={"Kein Wortpaket ausgewählt"}
				actionButtonLabel={"Wortpaket auswählen"}
				headerLabel={"Wähle ein Wortpaket aus"}
				{...props}
			>
				<SearchableTable
					selectionMode={"multiple"}
					selection={props.fieldValue}
					dataKey={"id"}
					value={values}
					metaKeySelection={false}
					hasPrevious={hasPrevious}
					hasNext={hasNext}
					loadPrevious={handleLoadPrevious}
					loadNext={handleLoadNext}
					onSearch={handleOnSearch}
					onSelectionChange={handleOnSelectionChange}
				>
					{(Column) => (
						<>
							<Column header="ID" body={(wordPackage) => wordPackage.id} />
							<Column header="Name" body={(wordPackage) => wordPackage.name} />
						</>
					)}
				</SearchableTable>
			</SelectComplexDataField>
		</>
	);
};
