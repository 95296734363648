import {
	type DefaultFormProps,
	DefaultNumberFieldComponent,
	DefaultTextFieldComponent,
	Form,
	ValidatedField,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { type FormikProps } from "formik/dist/types";
import { Button } from "primereact/button";
import { forwardRef, useImperativeHandle } from "react";
import * as Yup from "yup";
import { type Price } from "@screens/product-edit/products-edit.types";
import { type EditCoachingRemunerationFormState } from "./edit-coaching-remuneration-form.types";
import { Card } from "../../../../components/card";
import { PriceField } from "../../../../components/PriceField";

export const EditCoachingRemunerationForm = forwardRef<
	FormikProps<EditCoachingRemunerationFormState>,
	DefaultFormProps<EditCoachingRemunerationFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditCoachingRemunerationFormState>({
		initialValues: initialState ?? {
			sessionRate: {
				grossPrice: 0,
				netPrice: 0,
				taxRatePercentage: 0,
			},
			percentageShare: 0,
			additionalInformation: null,
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			sessionRate: Yup.object().shape({
				netPrice: Yup.number().required("Ein Nettopreis wird benötigt."),
				grossPrice: Yup.number().required("Ein Bruttopreis wird benötigt."),
				taxRatePercentage: Yup.number().required("Ein Steuersatz wird benötigt."),
			}),
			percentageShare: Yup.number()
				.min(0, "Anteil darf nicht kleiner als 0 sein")
				.max(100, "der Anteil darf nicht höher sein als 100%"),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<Card className="mb-2 mt-2">
			<Form onSubmit={formik.handleSubmit} className={"p-fluid"}>
				<ValidatedField<EditCoachingRemunerationFormState, Price>
					name={"sessionRate"}
					label="Stundensatz pro Coaching"
					component={({ fieldValue, updateField }) => (
						<PriceField fieldValue={fieldValue} onUpdate={updateField} />
					)}
					formikConfig={formik}
				/>
				<ValidatedField<EditCoachingRemunerationFormState, number>
					name={"percentageShare"}
					label={"Vertragspartner-Anteil in % (0% bedeutet: Kein Anteil)"}
					formikConfig={formik}
					component={DefaultNumberFieldComponent}
				/>
				<ValidatedField<EditCoachingRemunerationFormState, string>
					name={"additionalInformation"}
					label={"Zusätzliche Informationen (erscheint auf der Abrechnung)"}
					formikConfig={formik}
					component={DefaultTextFieldComponent}
				/>
				<Button type={"submit"}>Speichern</Button>
			</Form>
		</Card>
	);
});
EditCoachingRemunerationForm.displayName = "EditCoachingRemunerationForm";
