import { type FormikProps, useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { ValidatedField } from "@components/ValidatedField";

import { SelectContractPartnerField } from "@features/contract-partners/select-contract-partner-field";
import { type SelectContractPartnerFieldState } from "@features/contract-partners/select-contract-partner-field";
import { FileSelectionField, type FileV2 } from "@features/files/file-selection-field";
import { SelectProductFieldDialog } from "@features/products/select-product-field-dialog";
import { type Product } from "@features/products/select-product-field-dialog/select-product-field-dialog.interface";
import {
	SelectAcademiesTagField,
	type SelectAcademiesTagFieldState,
} from "@features/tags/select-academies-tag-field";
import { type editCoachingOfferForm_QueryFragment$key } from "@relay/editCoachingOfferForm_QueryFragment.graphql";
import { QUERY_FRAGMENT } from "./edit-coaching-offer-form.graphql";
import { type EditCoachingOfferFormState } from "./edit-coaching-offer-form.types";
import { type DefaultFormProps } from "../common";

// eslint-disable-next-line react/display-name
export const EditCoachingOfferForm = React.forwardRef<
	FormikProps<EditCoachingOfferFormState>,
	DefaultFormProps<EditCoachingOfferFormState> & {
		queryFragmentRef: editCoachingOfferForm_QueryFragment$key;
	}
>(({ initialState, onSubmit, queryFragmentRef }, ref) => {
	const query = useFragment<editCoachingOfferForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const formik = useFormik<EditCoachingOfferFormState>({
		initialValues: initialState ?? {
			name: "",
			shortDescription: "",
			description: "",
			contents: "",
			modules: "",
			contractPartner: {
				id: "",
				name: "",
			},
			tags: [],
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name"),
			shortDescription: Yup.string(),
			description: Yup.string(),
			contractPartner: Yup.object().required("Das Feld Vertragspartner wird benötigt."),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<Card>
			<form onSubmit={formik.handleSubmit} className={"p-fluid "}>
				<ValidatedField<EditCoachingOfferFormState, string>
					name={"name"}
					label={"Name"}
					formikConfig={formik}
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<EditCoachingOfferFormState, string>
					name={"shortDescription"}
					label={"Kurzbeschreibung"}
					formikConfig={formik}
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<EditCoachingOfferFormState, string>
					name={"description"}
					label={"Was dich erwartet"}
					formikConfig={formik}
					component={MemoizedRichTextEditor}
				/>
				<ValidatedField<EditCoachingOfferFormState, string>
					name={"contents"}
					label={"Inhalte"}
					formikConfig={formik}
					component={MemoizedRichTextEditor}
				/>{" "}
				<ValidatedField<EditCoachingOfferFormState, string>
					name={"modules"}
					label={"Module"}
					formikConfig={formik}
					component={MemoizedRichTextEditor}
				/>
				<ValidatedField<EditCoachingOfferFormState, SelectAcademiesTagFieldState>
					name={"tags"}
					label={"Academies Tags"}
					formikConfig={formik}
					component={(renderConfig) => {
						return (
							<SelectAcademiesTagField queryFragmentRef={query} {...renderConfig} />
						);
					}}
				/>
				<ValidatedField<EditCoachingOfferFormState, FileV2>
					name={"image"}
					label={"Bild"}
					formikConfig={formik}
					component={({ fieldName, fieldValue, updateField, onChange }) => {
						return (
							<FileSelectionField
								name={fieldName}
								selectedFile={fieldValue}
								setSelectedFile={updateField}
								filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
								onChange={onChange}
								canUploadFiles={true}
								canDeleteFiles={true}
							/>
						);
					}}
				/>
				<ValidatedField<EditCoachingOfferFormState, Product>
					name={"licenseProduct"}
					label={"Produkt"}
					component={SelectProductFieldDialog}
					required
					formikConfig={formik}
				/>
				<ValidatedField<EditCoachingOfferFormState, SelectContractPartnerFieldState>
					name={"contractPartner"}
					label={"Vertragspartner"}
					formikConfig={formik}
					required
					component={(renderConfig) => {
						return (
							<SelectContractPartnerField
								queryFragmentRef={query}
								fieldValue={renderConfig.fieldValue}
								updateField={renderConfig.updateField}
								fieldName={"contractPartner"}
								required={true}
								isValid={true}
								disabled={false}
							/>
						);
					}}
				/>
				<Button type={"submit"}>Speichern</Button>
			</form>
		</Card>
	);
});
