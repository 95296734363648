import { FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { ValidatedField } from "@components/ValidatedField";
import { SelectAvgsCoachField } from "@features/avgs/select-avgs-coach-field";
import type { FormProps } from "@screens/coach-profiles/parts/edit-base-data-form/edit-base-data-form.types";
import {
	EditSubstituteCoachFormProps,
	EditSubstituteCoachFormState,
} from "@screens/potential-analysis-edit/parts/edit-substitute-coach-form/edit-substitute-coach-form.types";

export const EditSubstituteCoachForm = React.forwardRef<
	FormikProps<EditSubstituteCoachFormState>,
	FormProps<EditSubstituteCoachFormState> & EditSubstituteCoachFormProps
>(({ accountId, initialState, onSubmit }, ref) => {
	const formik = useFormik<EditSubstituteCoachFormState>({
		initialValues: initialState ?? {
			substitueCoachId: undefined,
		},
		enableReinitialize: true,

		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<div className={"p-fluid w-30rem"}>
			<ValidatedField<EditSubstituteCoachFormState, string>
				name={"substitueCoachId"}
				placeholder={"Vertretung"}
				label={"Vertretung"}
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectAvgsCoachField {...renderConfig} accountId={accountId} />
				)}
			/>
		</div>
	);
});
EditSubstituteCoachForm.displayName = "EditSubstituteCoachForm";
