/**
 * @generated SignedSource<<7120ecb4a0a5650be7659cfe938da81b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sortTrendsModal_TrendFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly position: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"sortableTrend_TrendFragment">;
  readonly " $fragmentType": "sortTrendsModal_TrendFragment";
}>;
export type sortTrendsModal_TrendFragment$key = ReadonlyArray<{
  readonly " $data"?: sortTrendsModal_TrendFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"sortTrendsModal_TrendFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "sortTrendsModal_TrendFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "sortableTrend_TrendFragment"
    }
  ],
  "type": "Trend",
  "abstractKey": null
};

(node as any).hash = "73163446c951ee0d8afcaedc8c24747a";

export default node;
