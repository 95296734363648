import moment from "moment-timezone";
import { Card } from "primereact/card";
import { useFragment, useMutation } from "react-relay";
import { formatDateTime } from "@components/DateTimeDisplay";
import { AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "@relay/AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import { NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key } from "@relay/NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment.graphql";
import {
	AddAfterDateTimeVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation.graphql";
import {
	AddHideVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddHideVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddHideVisibilityConfigMutation.graphql";
import {
	AddNotAfterDateTimeVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation.graphql";
import {
	AddOnlyAdminsVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation.graphql";
import {
	AddOnlyAZAVAccountVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation.graphql";
import {
	AddOnlyBusinessAccountVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation.graphql";
import {
	AddOnlyEditorsVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddOnlyEditorsVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddOnlyEditorsVisibilityConfigMutation.graphql";
import {
	AddOnlyIfTreeStateVisibilityTreeConfigInput,
	visibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation,
} from "@relay/visibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation.graphql";
import { visibilityTreeConfigEditor_RemoveVisibilityConfigMutation } from "@relay/visibilityTreeConfigEditor_RemoveVisibilityConfigMutation.graphql";
import {
	visibilityTreeConfigEditor_TreeNodeFragment$key,
	VisibilityTreeConfigType,
} from "@relay/visibilityTreeConfigEditor_TreeNodeFragment.graphql";
import { AfterDateTimeVisibilityConfigForm } from "@screens/educational-offer/parts/AfterDateTimeVisibilityConfigForm";
import {
	Config,
	ConfigurationGraphqlInterface,
} from "@screens/educational-offer/parts/Config.interfaces";
import {
	ConfigDropdown,
	ConfigDropdownOptions,
} from "@screens/educational-offer/parts/ConfigDropdown";
import { ConfigItem } from "@screens/educational-offer/parts/ConfigItem";
import { ConfigPreview } from "@screens/educational-offer/parts/ConfigPreview";
import { NotAfterDateTimeVisibilityConfigForm } from "@screens/educational-offer/parts/NotAfterDateTimeVisibilityConfigForm";
import {
	ADD_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION,
	ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
	ADD_NOT_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_ADMINS_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_AZAV_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_BUSINESS_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_EDITORS_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_IF_TREE_STATE_CONDITION_CONFIGURATION_MUTATION,
	REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
	TREE_NODE_FRAGMENT,
} from "@screens/educational-offer/parts/visibility-tree-config-editor/visibility-tree-config-editor.graphql";
import { VisibilityTreeConfigEditorProps } from "@screens/educational-offer/parts/visibility-tree-config-editor/visibility-tree-config-editor.types";
import { VISIBILITY_LABEL } from "../../../../translations/visibility-label";

export const VisibilityTreeConfigEditor = ({
	treeNodeFragmentRef,
}: VisibilityTreeConfigEditorProps) => {
	const treeNode = useFragment<visibilityTreeConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [removeVisibilityConfig, isRemovingVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_RemoveVisibilityConfigMutation>(
			REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addHideVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddHideVisibilityConfigMutation>(
			ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addOnlyAdminsVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddOnlyAdminsVisibilityConfigMutation>(
			ADD_ONLY_ADMINS_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addOnlyBusinessAccountVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation>(
			ADD_ONLY_BUSINESS_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addAfterDateTimeVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddAfterDateTimeVisibilityConfigMutation>(
			ADD_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addNotAfterDateTimeVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddNotAfterDateTimeVisibilityConfigMutation>(
			ADD_NOT_AFTER_DATE_TIME_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addOnlyIfTreeStateVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddOnlyIfTreeStateVisibilityConfigMutation>(
			ADD_ONLY_IF_TREE_STATE_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addOnlyEditorsVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddOnlyEditorsVisibilityConfigMutation>(
			ADD_ONLY_EDITORS_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addOnlyAZAVAccountVisibilityConfig] =
		useMutation<visibilityTreeConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation>(
			ADD_ONLY_AZAV_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
		);

	const hideVisibilityConfig: Config<VisibilityTreeConfigType, AddHideVisibilityTreeConfigInput> =
		{
			configKey: "VisibilityTree_Hide",
			addMutation: (input: AddHideVisibilityTreeConfigInput) => {
				addHideVisibilityConfig({
					variables: {
						input,
					},
				});
			},
			editable: false,
		};

	const onlyAdminsVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddOnlyAdminsVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyAdmins",
		addMutation: (input: AddOnlyAdminsVisibilityTreeConfigInput) => {
			addOnlyAdminsVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyEditorsVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddOnlyAdminsVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyEditors",
		addMutation: (input: AddOnlyEditorsVisibilityTreeConfigInput) => {
			addOnlyEditorsVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyBusinessAccountVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddOnlyBusinessAccountVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyBusinessAccount",
		addMutation: (input: AddOnlyBusinessAccountVisibilityTreeConfigInput) => {
			addOnlyBusinessAccountVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyIfTreeState: Config<
		VisibilityTreeConfigType,
		AddOnlyIfTreeStateVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyIfTreeState",
		addMutation: (input: AddOnlyIfTreeStateVisibilityTreeConfigInput) => {
			addOnlyIfTreeStateVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const notAfterDateTimeVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddNotAfterDateTimeVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_NotAfterDateTime",
		addMutation: (input: AddNotAfterDateTimeVisibilityTreeConfigInput) => {
			addNotAfterDateTimeVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			dateTime: moment().add(1, "year").format(),
		},
		editable: true,
	};
	const onlyAZAVAccountVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddOnlyAZAVAccountVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_OnlyAZAVAccount",
		addMutation: (input: AddOnlyAZAVAccountVisibilityTreeConfigInput) => {
			addOnlyAZAVAccountVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const afterDateTimeVisibilityConfig: Config<
		VisibilityTreeConfigType,
		AddAfterDateTimeVisibilityTreeConfigInput
	> = {
		configKey: "VisibilityTree_AfterDateTime",
		addMutation: (input: AddAfterDateTimeVisibilityTreeConfigInput) => {
			addAfterDateTimeVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			dateTime: moment().add(1, "week").format(),
		},
		editable: true,
	};

	const configs: Config<VisibilityTreeConfigType, any | never>[] = [
		hideVisibilityConfig,
		onlyAdminsVisibilityConfig,
		onlyEditorsVisibilityConfig,
		onlyBusinessAccountVisibilityConfig,
		notAfterDateTimeVisibilityConfig,
		afterDateTimeVisibilityConfig,
		onlyIfTreeState,
		onlyAZAVAccountVisibilityConfig,
	];

	const options: ConfigDropdownOptions<VisibilityTreeConfigType>[] = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const previewOptions: ConfigDropdownOptions<VisibilityTreeConfigType>[] = configs.map((c) => {
		const selectedConfig = treeNode.structureDefinition.visibilityConfigs?.find(
			(r) => r.configType === c.configKey,
		);

		if (selectedConfig?.dateTime) {
			return {
				label: `(${formatDateTime(selectedConfig?.dateTime as string)})`,
				value: c.configKey,
			};
		} else {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		}
	});

	return (
		<Card className="mb-2">
			<h2>{VISIBILITY_LABEL["visibility_label"]}</h2>

			<ConfigDropdown<VisibilityTreeConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							rootNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<VisibilityTreeConfigType>>
				selectedConfigs={treeNode.structureDefinition.visibilityConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<VisibilityTreeConfigType>,
				) => (
					<>
						<ConfigItem<VisibilityTreeConfigType, string>
							isPresentational={true}
							isLoading={isRemovingVisibilityConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeVisibilityConfig({
									variables: {
										input: {
											rootNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
							editDialog={() => (
								<>
									{configuration.configType ===
										"VisibilityTree_NotAfterDateTime" && (
										<NotAfterDateTimeVisibilityConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as NotAfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key
											}
										/>
									)}

									{configuration.configType ===
										"VisibilityTree_AfterDateTime" && (
										<AfterDateTimeVisibilityConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as AfterDateTimeVisibilityConfigForm_VisibilityTreeConfigFragment$key
											}
										/>
									)}
								</>
							)}
						/>
					</>
				)}
			/>
		</Card>
	);
};
