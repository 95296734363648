/**
 * @generated SignedSource<<8e5d96d1781e243c699c4fe5c4531b03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Gender = "Diverse" | "Female" | "Male" | "NotDefined";
import { FragmentRefs } from "relay-runtime";
export type editBaseDataButton_CoachProfileFragment$data = {
  readonly baseData: {
    readonly address: {
      readonly city: string;
      readonly lineOne: string;
      readonly lineTwo: string;
      readonly postalCode: string;
    };
    readonly birthDate: any;
    readonly birthPlace: string;
    readonly gender: Gender;
    readonly nationality: string;
  };
  readonly coach: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "editBaseDataButton_CoachProfileFragment";
};
export type editBaseDataButton_CoachProfileFragment$key = {
  readonly " $data"?: editBaseDataButton_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editBaseDataButton_CoachProfileFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editBaseDataButton_CoachProfileFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BaseData",
      "kind": "LinkedField",
      "name": "baseData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineOne",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineTwo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthPlace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gender",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nationality",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};
})();

(node as any).hash = "105e8d2bcc1d6a52b0a41a751fc895b2";

export default node;
