/**
 * @generated SignedSource<<da5154365deb1d6e4c9a301baa5b8e60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateStripeConnectAccountInput = {
  clientMutationId?: string | null | undefined;
  contractPartnerId: string;
};
export type createConnectAccountButton_CreateConnectAccountMutation$variables = {
  input: CreateStripeConnectAccountInput;
};
export type createConnectAccountButton_CreateConnectAccountMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createStripeConnectAccount: {
        readonly contractPartner: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"contractPartnerTable_ContractPartnerFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createConnectAccountButton_CreateConnectAccountMutation = {
  response: createConnectAccountButton_CreateConnectAccountMutation$data;
  variables: createConnectAccountButton_CreateConnectAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shareAmountPercentage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeConnectAccountId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "association",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createConnectAccountButton_CreateConnectAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateStripeConnectAccountPayload",
                "kind": "LinkedField",
                "name": "createStripeConnectAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractPartnersEdge",
                    "kind": "LinkedField",
                    "name": "contractPartner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractPartner",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "contractPartnerTable_ContractPartnerFragment",
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContractPartnerData",
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "editContractPartnerButton_ContractPartnerFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createConnectAccountButton_CreateConnectAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateStripeConnectAccountPayload",
                "kind": "LinkedField",
                "name": "createStripeConnectAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractPartnersEdge",
                    "kind": "LinkedField",
                    "name": "contractPartner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractPartner",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContractPartnerData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "street",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "houseNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "city",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "postalCode",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "linkToAGB",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6e3412395b92822c77f37f521c886eb",
    "id": null,
    "metadata": {},
    "name": "createConnectAccountButton_CreateConnectAccountMutation",
    "operationKind": "mutation",
    "text": "mutation createConnectAccountButton_CreateConnectAccountMutation(\n  $input: CreateStripeConnectAccountInput!\n) {\n  Admin {\n    Billing {\n      createStripeConnectAccount(input: $input) {\n        contractPartner {\n          node {\n            ...contractPartnerTable_ContractPartnerFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment contractPartnerTable_ContractPartnerFragment on ContractPartner {\n  id\n  shareAmountPercentage\n  stripeConnectAccountId\n  data {\n    name\n    association\n    email\n  }\n  ...editContractPartnerButton_ContractPartnerFragment\n}\n\nfragment editContractPartnerButton_ContractPartnerFragment on ContractPartner {\n  id\n  shareAmountPercentage\n  data {\n    email\n    name\n    association\n    street\n    houseNumber\n    city\n    postalCode\n    country\n    taxNumber\n    linkToAGB\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d83d46c54ce6d2fc917baa35b981035";

export default node;
