/**
 * @generated SignedSource<<eebb477e867febea161db15b84af3426>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editExceptionModal_AvailabilityScheduleFragment$data = {
  readonly data: {
    readonly blockoutDates: ReadonlyArray<{
      readonly from: string;
      readonly to: string;
    }>;
    readonly daysAndTimes: ReadonlyArray<{
      readonly dayOfWeek: any;
      readonly timeSlots: ReadonlyArray<any>;
    }>;
    readonly overrideDaysAndTimesForCalendarWeek: ReadonlyArray<{
      readonly calendarWeek: number;
      readonly calendarYear: number;
      readonly dayOfWeek: any;
      readonly timeSlots: ReadonlyArray<any>;
    }>;
  };
  readonly " $fragmentType": "editExceptionModal_AvailabilityScheduleFragment";
};
export type editExceptionModal_AvailabilityScheduleFragment$key = {
  readonly " $data"?: editExceptionModal_AvailabilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editExceptionModal_AvailabilityScheduleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeSlots",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayOfWeek",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editExceptionModal_AvailabilityScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DayAndTimesForCalendarWeek",
          "kind": "LinkedField",
          "name": "overrideDaysAndTimesForCalendarWeek",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calendarYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calendarWeek",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BlockoutDate",
          "kind": "LinkedField",
          "name": "blockoutDates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "from",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "to",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailabilityScheduleDayAndTimes",
          "kind": "LinkedField",
          "name": "daysAndTimes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AvailabilitySchedule",
  "abstractKey": null
};
})();

(node as any).hash = "383bba8d74d75e69ee3c70e069297c34";

export default node;
