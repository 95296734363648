import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";

import {
	type selectCoachesField_CoachProfileFragment$data,
	type selectCoachesField_CoachProfileFragment$key,
} from "@relay/selectCoachesField_CoachProfileFragment.graphql";
import { type selectCoachesField_QueryFragment$key } from "@relay/selectCoachesField_QueryFragment.graphql";
import { type selectCoachesField_QueryRefetch } from "@relay/selectCoachesField_QueryRefetch.graphql";
import { COACH_PROFILE_FRAGMENT, QUERY_FRAGMENT } from "./select-coaches-field.graphql";
import {
	type SelectCoachesFieldProps,
	type SelectCoachesFieldState,
} from "./select-coaches-field.types";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";

export const SelectCoachesField = ({ queryFragmentRef, ...props }: SelectCoachesFieldProps) => {
	const {
		data: {
			Admin: {
				Coaching: { CoachProfiles },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectCoachesField_QueryRefetch,
		selectCoachesField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ userName: search });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectCoachesField_CoachProfileFragment$data[]>,
	) => {
		props.updateField(
			event.value.map((v) => ({
				id: v.id!,
				coach: { id: v.coach!.id, name: v.coach!.name },
			})),
		);
	};

	const values =
		CoachProfiles.edges?.map((e) =>
			readInlineData<selectCoachesField_CoachProfileFragment$key>(
				COACH_PROFILE_FRAGMENT,
				e!.node.coachingOffer,
			),
		) || [];
	return (
		<>
			<SelectComplexDataField<SelectCoachesFieldState>
				valuePlaceholder="Kein Coach ausgewählt"
				actionButtonLabel="Coach auswählen"
				headerLabel="Wähle einen Coach aus"
				{...props}
			>
				<SearchableTable<selectCoachesField_CoachProfileFragment$data>
					selectionMode="multiple"
					selection={props.fieldValue}
					dataKey="coach.id"
					value={values}
					metaKeySelection={false}
					hasPrevious={hasPrevious}
					hasNext={hasNext}
					loadPrevious={handleLoadPrevious}
					loadNext={handleLoadNext}
					onSearch={handleOnSearch}
					onSelectionChange={handleOnSelectionChange}
				>
					{(Column) => (
						<>
							<Column header="Name" body={(coach) => coach.coach?.name} />
						</>
					)}
				</SearchableTable>
			</SelectComplexDataField>
		</>
	);
};
