import React from "react";
import { useFragment } from "react-relay";
import { CurrencyDisplay } from "@components/CurrencyDisplay";
import { type orderPriceDisplay_OrderFragment$key } from "@relay/orderPriceDisplay_OrderFragment.graphql";
import { ORDER_FRAGMENT } from "./order-price-display.graphql";
import { type OrderPriceDisplayProps } from "./order-price-display.interface";
import { InvoiceProviderLink } from "../invoice-provider-link/invoice-provider-link.component";

export const OrderPriceDisplay = ({ orderFragmentRef }: OrderPriceDisplayProps) => {
	const order = useFragment<orderPriceDisplay_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	if (order.status === "Purchased" || order.status === "PaymentReceived") {
		const Price = order.selectedPaymentMethod?.chosenOption ? (
			<>
				Monatlich:{" "}
				<CurrencyDisplay
					value={order.selectedPaymentMethod.chosenOption.monthlyRate.netPrice}
				/>
			</>
		) : (
			<CurrencyDisplay value={order.cart?.totals.includingAllDiscounts?.netPrice!} />
		);

		if (order.invoiceData) {
			return (
				<div className="flex">
					{Price}&nbsp;(
					<InvoiceProviderLink invoiceDataFragmentRef={order.invoiceData} />)
				</div>
			);
		} else {
			return Price;
		}
	} else {
		return <div>unbezahlt</div>;
	}
};
