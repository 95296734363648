import { EpicIcons, InputText } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { videoSourceField_GetVideoDataV2Mutation } from "@relay/videoSourceField_GetVideoDataV2Mutation.graphql";
import { GET_VIDEO_DATA_MUTATION } from "@screens/educational-offer/parts/video-source-field/video-source-field.graphql";
import {
	inputWrapperClass,
	videoDisplayWrapper,
} from "@screens/educational-offer/parts/video-source-field/video-source-field.styles";
import {
	VideoDataState,
	VideoSourceFieldProps,
} from "@screens/educational-offer/parts/video-source-field/video-source-field.types";

export const VideoSourceField = ({
	vimeoId,
	videoSourceId,
	onUpdate,
	onChange,
}: VideoSourceFieldProps) => {
	const [getVideoData, isGettingVideoData] =
		useMutation<videoSourceField_GetVideoDataV2Mutation>(GET_VIDEO_DATA_MUTATION);

	const [state, setState] = useState<VideoDataState | undefined>();

	const handleGetVideoDataOnClick = () => {
		getVideoData({
			variables: {
				input: {
					vimeoId: vimeoId ?? "",
					vimeoVideoSourceId: videoSourceId ?? "",
				},
			},
			onCompleted: (response) => {
				setState(
					response.Admin.ElearningV2.getVideoData
						? {
								...response.Admin.ElearningV2.getVideoData?.videoData,
						  }
						: undefined,
				);
			},
		});
	};

	const handleInputOnChange = (input: string | undefined) => {
		onUpdate(input);
		onChange?.();
	};

	return (
		<div>
			<div className={inputWrapperClass}>
				<InputText
					placeholder={"Vimeo-ID"}
					id="vimeoId"
					value={vimeoId}
					onChange={handleInputOnChange}
				/>
				<Button
					icon={EpicIcons.REFRESH}
					disabled={isGettingVideoData}
					onClick={handleGetVideoDataOnClick}
				/>
			</div>
			{state && (
				<div className={videoDisplayWrapper}>
					<div>Name: {state.name}</div>
					<div>Dauer: {state.duration} Sekunden</div>
					{state.thumbnailUrl && (
						<img height={200} alt={state.name} src={state.thumbnailUrl} />
					)}
				</div>
			)}
		</div>
	);
};
