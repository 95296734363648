import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { type selectWordPackageField_QueryFragment$key } from "@relay/selectWordPackageField_QueryFragment.graphql";
import { type selectWordPackageField_QueryRefetch } from "@relay/selectWordPackageField_QueryRefetch.graphql";
import {
	type selectWordPackageField_WordPackageFragment$data,
	type selectWordPackageField_WordPackageFragment$key,
} from "@relay/selectWordPackageField_WordPackageFragment.graphql";
import { QUERY_FRAGMENT, WORD_PACKAGE_FRAGMENT } from "./select-word-package-field.graphql";
import { type SelectWordPackageFieldProps } from "./select-word-package-field.types";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";

export const SelectWordPackageField = ({
	queryFragment,
	...props
}: SelectWordPackageFieldProps) => {
	const {
		data: {
			Admin: {
				Vocab: { WordPackages },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectWordPackageField_QueryRefetch,
		selectWordPackageField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragment);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};
	const handleOnSearch = (filterByName: string) => {
		refetch({ filterByName });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectWordPackageField_WordPackageFragment$data>,
	) => {
		props.updateField({
			id: event.value.id,
			name: event.value.name,
		});
	};

	const values =
		WordPackages.edges?.map((e) =>
			readInlineData<selectWordPackageField_WordPackageFragment$key>(
				WORD_PACKAGE_FRAGMENT,
				e!.node,
			),
		) ?? [];

	return (
		<>
			<SelectComplexDataField
				valuePlaceholder={"Kein Wortpaket ausgewählt"}
				actionButtonLabel={"Wortpaket auswählen"}
				headerLabel={"Wähle ein Wortpaket aus"}
				valueField={"name"}
				{...props}
			>
				<SearchableTable
					selectionMode={"single"}
					selection={props.fieldValue}
					dataKey={"name"}
					value={values}
					hasPrevious={hasPrevious}
					hasNext={hasNext}
					loadPrevious={handleLoadPrevious}
					loadNext={handleLoadNext}
					onSearch={handleOnSearch}
					onSelectionChange={handleOnSelectionChange}
				>
					{(Column) => (
						<>
							<Column header="ID" body={(wordPackage) => wordPackage.id} />
							<Column header="Name" body={(wordPackage) => wordPackage.name} />
						</>
					)}
				</SearchableTable>
			</SelectComplexDataField>
		</>
	);
};
