/**
 * @generated SignedSource<<e8df27ce65ce6beb5b9f7845a143c8c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type elementForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"dynamicVocabElementForm_QueryFragment">;
  readonly " $fragmentType": "elementForm_QueryFragment";
};
export type elementForm_QueryFragment$key = {
  readonly " $data"?: elementForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"elementForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "elementForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "dynamicVocabElementForm_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b140a3a7f9cb6b118c020b8b2e6dcbe5";

export default node;
