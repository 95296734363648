/**
 * @generated SignedSource<<88a7d332f6e51b89e1503b1559ba86c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type customerFeedbackActionColumn_CustomerFeedbackFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"editCustomerFeedbackButton_CustomerFeedbackFragment">;
  readonly " $fragmentType": "customerFeedbackActionColumn_CustomerFeedbackFragment";
};
export type customerFeedbackActionColumn_CustomerFeedbackFragment$key = {
  readonly " $data"?: customerFeedbackActionColumn_CustomerFeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"customerFeedbackActionColumn_CustomerFeedbackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "customerFeedbackActionColumn_CustomerFeedbackFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editCustomerFeedbackButton_CustomerFeedbackFragment"
    }
  ],
  "type": "CustomerFeedback",
  "abstractKey": null
};

(node as any).hash = "59f3929b8fd6459d11a190f05b9db84c";

export default node;
