/**
 * @generated SignedSource<<d4863ce347998e2412650046c479474b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type videoElementForm_VideoElementFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly vimeoId: string;
  readonly vimeoVideoSource: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "videoElementForm_VideoElementFragment";
};
export type videoElementForm_VideoElementFragment$key = {
  readonly " $data"?: videoElementForm_VideoElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"videoElementForm_VideoElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "videoElementForm_VideoElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vimeoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Vimeo_VimeoVideoSource",
      "kind": "LinkedField",
      "name": "vimeoVideoSource",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "VideoElement",
  "abstractKey": null
};
})();

(node as any).hash = "11a3b37fc1eb0f70decff4fadbb81296";

export default node;
