import { Dialog } from "@thekeytechnology/epic-ui";
import { FormikProps } from "formik";
import React, { type FC, useContext } from "react";
import { useFragment } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import {
	SelectAvgsAccountAndCoachForm,
	SelectAvgsAccountAndCoachFormState,
} from "@features/avgs/select-avgs-account-and-coach-form";
import { selectCoachModal_QueryFragment$key } from "@relay/selectCoachModal_QueryFragment.graphql";
import { CreatePAModalsFlowContext } from "@screens/potential-analysis/parts/create-pa-modals-flow/create-pa-modals-flow.context";
import { Wrapper } from "@screens/potential-analysis/parts/create-pa-modals-flow/parts/for-client-modal/for-client-modal.styles";
import { P2Span } from "@themes/font-tags";
import { QUERY_FRAGMENT } from "./select-coach-modal.graphql";
import { type SelectCoachModalProps } from "./select-coach-modal.types";

export const SelectCoachModal: FC<SelectCoachModalProps> = ({
	isVisible,
	onSuccess,
	onDismiss,
	queryFragmentRef,
}) => {
	const queryFragment = useFragment<selectCoachModal_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const formRef = React.useRef<FormikProps<SelectAvgsAccountAndCoachFormState>>(null);

	const { contextState, setContextState } = useContext(CreatePAModalsFlowContext);
	const handleOnDismiss = () => {
		onDismiss();
	};

	const handleOnSubmit = (values: SelectAvgsAccountAndCoachFormState) => {
		setContextState({
			...contextState,
			coachId: values.coachId,
			accountId: values.account.id,
		});
		onSuccess?.();
	};

	const handleOnChoose = () => {
		formRef.current?.submitForm();
	};

	return (
		<Dialog visible={isVisible} onHide={handleOnDismiss} title="AVGS-Coach">
			<DialogTemplate
				maxWidthRem={50}
				primaryButtonLabel="Auswählen"
				onPrimaryButtonClick={handleOnChoose}
				primaryButtonStretch={true}
			>
				<Wrapper>
					<P2Span>Wählen Sie einen AVGS-Coach aus.</P2Span>
					<SelectAvgsAccountAndCoachForm
						ref={formRef}
						accountId={contextState.accountId}
						onSubmit={handleOnSubmit}
						queryFragmentRef={queryFragment}
					/>
				</Wrapper>
			</DialogTemplate>
		</Dialog>
	);
};
