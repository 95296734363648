import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query rewardEditor_Query {
		Admin {
			Billing {
				GetExternalLicensePools {
					...createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment
				}
			}
			Vocab {
				WordPackages {
					...createWordPackageRewardButton_WordPackageConnectionFragment
				}
			}
		}
	}
`;

export const TREE_NODE_FRAGMENT = graphql`
	fragment rewardEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewards {
					...rewardEditor_RewardInlineFragment
				}
			}
		}
		...rewardForm_TreeNodeFragment
		...createExternalLicenseRewardButton_TreeNodeFragment
		...createIhkCertificateRewardButton_TreeNodeFragment
		...createWordPackageRewardButton_TreeNodeFragment
		...createWordProficienciesRewardButton_TreeNodeFragment
		...createEducationalParticipationCertificateRewardButton_TreeNodeFragment
		...createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment
	}
`;

export const REWARD_INLINE_FRAGMENT = graphql`
	fragment rewardEditor_RewardInlineFragment on Reward @inline {
		id
		kind
		... on ExternalLicenseReward {
			data {
				pool {
					data {
						name
					}
				}
			}
		}
		... on WordPackageReward {
			data {
				wordPackage {
					id
					name
				}
			}
		}
		... on ParticipationCertificateReward {
			id
			data {
				name
			}
		}
		...rewardForm_RewardFragment
	}
`;

export const REMOVE_REWARD_MUTATION = graphql`
	mutation rewardEditor_DeleteRewardMutation($input: DeleteRewardInput!) {
		Admin {
			Tree {
				deleteReward(input: $input) {
					contentNode {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
