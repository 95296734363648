import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query videoSourceDropdown_Query {
		Admin {
			VimeoVideoSource {
				VimeoVideoSources {
					edges {
						node {
							id
							name
							isDefault
						}
					}
				}
			}
		}
	}
`;
