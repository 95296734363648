/**
 * @generated SignedSource<<e74026ae41ac5d3e8ed5755245cf02aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductIsHiddenColumn_ProductFragment$data = {
  readonly isHidden: boolean;
  readonly " $fragmentType": "ProductIsHiddenColumn_ProductFragment";
};
export type ProductIsHiddenColumn_ProductFragment$key = {
  readonly " $data"?: ProductIsHiddenColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductIsHiddenColumn_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductIsHiddenColumn_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "02b8efce5f0861ea12406f2fcf166607";

export default node;
