/**
 * @generated SignedSource<<8cfb5a1cb6c2bdf8f7fa9a252e4ad393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateContractPartnerInput = {
  clientMutationId?: string | null | undefined;
  data: ContractPartnerDataInput;
  shareAmountPercentage: number;
};
export type ContractPartnerDataInput = {
  association: string;
  city: string;
  country: string;
  email: string;
  houseNumber: string;
  linkToAGB?: string | null | undefined;
  name: string;
  postalCode: string;
  street: string;
  taxNumber: string;
};
export type createContractPartnerButton_CreateContractPartnerMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateContractPartnerInput;
};
export type createContractPartnerButton_CreateContractPartnerMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createContractPartner: {
        readonly edge: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"contractPartnerTable_ContractPartnerFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createContractPartnerButton_CreateContractPartnerMutation = {
  response: createContractPartnerButton_CreateContractPartnerMutation$data;
  variables: createContractPartnerButton_CreateContractPartnerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shareAmountPercentage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stripeConnectAccountId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "association",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createContractPartnerButton_CreateContractPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateContractPartnerPayload",
                "kind": "LinkedField",
                "name": "createContractPartner",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractPartnersEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractPartner",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "contractPartnerTable_ContractPartnerFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContractPartnerData",
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "editContractPartnerButton_ContractPartnerFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createContractPartnerButton_CreateContractPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateContractPartnerPayload",
                "kind": "LinkedField",
                "name": "createContractPartner",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractPartnersEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractPartner",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContractPartnerData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "street",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "houseNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "city",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "postalCode",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "linkToAGB",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ec5f543cf0e96e146d2355786043177",
    "id": null,
    "metadata": {},
    "name": "createContractPartnerButton_CreateContractPartnerMutation",
    "operationKind": "mutation",
    "text": "mutation createContractPartnerButton_CreateContractPartnerMutation(\n  $input: CreateContractPartnerInput!\n) {\n  Admin {\n    Billing {\n      createContractPartner(input: $input) {\n        edge {\n          node {\n            id\n            ...contractPartnerTable_ContractPartnerFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment contractPartnerTable_ContractPartnerFragment on ContractPartner {\n  id\n  shareAmountPercentage\n  stripeConnectAccountId\n  data {\n    name\n    association\n    email\n  }\n  ...editContractPartnerButton_ContractPartnerFragment\n}\n\nfragment editContractPartnerButton_ContractPartnerFragment on ContractPartner {\n  id\n  shareAmountPercentage\n  data {\n    email\n    name\n    association\n    street\n    houseNumber\n    city\n    postalCode\n    country\n    taxNumber\n    linkToAGB\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bb543a3291a9f7d10e0cf84a7671854";

export default node;
