/**
 * @generated SignedSource<<3ad63ea7a09d38125d559aceb98ca8f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type blockOutDatesTable_BlockOutDateFragment$data = {
  readonly from: string;
  readonly to: string;
  readonly " $fragmentSpreads": FragmentRefs<"editBlockOutDateModal_BlockOutDateFragment">;
  readonly " $fragmentType": "blockOutDatesTable_BlockOutDateFragment";
};
export type blockOutDatesTable_BlockOutDateFragment$key = {
  readonly " $data"?: blockOutDatesTable_BlockOutDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"blockOutDatesTable_BlockOutDateFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "blockOutDatesTable_BlockOutDateFragment"
};

(node as any).hash = "2ee79890412c29a030643aa617c606d9";

export default node;
