import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment selectAvgsAccountField_QueryFragment on Query
	@refetchable(queryName: "selectAvgsAccountField_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String", defaultValue: null }
		filterByName: { type: "String", defaultValue: null }
	) {
		AvgsPotentialAnalysis {
			AVGSAccounts(first: $first, after: $after, filterByName: $filterByName)
				@connection(key: "selectAvgsAccountField_AVGSAccounts") {
				edges {
					node {
						...selectAvgsAccountField_AccountFragment
					}
				}
			}
		}
	}
`;

export const ACCOUNT_FRAGMENT = graphql`
	fragment selectAvgsAccountField_AccountFragment on Account @inline {
		id
		name
	}
`;
