import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editBusinessDataButton_CoachProfileFragment$key } from "@relay/editBusinessDataButton_CoachProfileFragment.graphql";
import { type editBusinessDataButton_EditBusinessDataMutation } from "@relay/editBusinessDataButton_EditBusinessDataMutation.graphql";
import {
	COACH_PROFILE_FRAGMENT,
	EDIT_BUSINESS_DATA_MUTATION,
} from "./edit-business-data-button.graphql";
import { type EditBusinessDataButtonProps } from "./edit-business-data-button.types";
import { CoachProfilesScreenContext } from "../../coach-profiles.context";
import { EditBusinessDataForm } from "../edit-business-data-form";
import { type EditBusinessDataFormState } from "../edit-business-data-form/edit-business-data-form.types";

export const EditBusinessDataButton = ({
	coachProfileFragmentRef,
}: EditBusinessDataButtonProps) => {
	const { connectionId } = useContext(CoachProfilesScreenContext);
	const { id: coachProfileId, businessData } =
		useFragment<editBusinessDataButton_CoachProfileFragment$key>(
			COACH_PROFILE_FRAGMENT,
			coachProfileFragmentRef,
		);
	const [edit, isEditing] = useMutation<editBusinessDataButton_EditBusinessDataMutation>(
		EDIT_BUSINESS_DATA_MUTATION,
	);

	const handleOnSubmit = async (values: EditBusinessDataFormState) => {
		return await new Promise((resolve, reject) => {
			edit({
				variables: {
					input: {
						businessData: {
							...values,
						},
						coachProfileId,
					},
					connections: [connectionId],
				},
				onCompleted: () => {
					resolve(true);
					toast.success("BusinessData angepasst.");
				},
				onError: () => {
					reject();
					toast.error("Fehler beim anpassen der BusinessData.");
				},
			});
		});
	};
	const initialState: EditBusinessDataFormState = {
		...businessData,
	};
	return (
		<FormDialogButton<EditBusinessDataFormState>
			disabled={isEditing}
			buttonIcon={EpicIcons.PENCIL}
			dialogTitle="BusinessData ändern"
			tooltip="BusinessData ändern"
		>
			{({ formikRef }) => {
				return (
					<EditBusinessDataForm
						initialState={initialState}
						ref={formikRef}
						onSubmit={handleOnSubmit}
					/>
				);
			}}
		</FormDialogButton>
	);
};
