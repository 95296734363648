import { Button } from "primereact/button";
import { useState } from "react";
import { useMutation } from "react-relay";
import { CREATE_ROOT_TAG_MUTATION } from "./create-academies-tag-button.graphql";
import { CreateAcademiesTagButtonProps } from "./create-academies-tag-button.types";
import { createAcademiesTagButton_CreateRootTagMutation } from "../../../../__generated__/createAcademiesTagButton_CreateRootTagMutation.graphql";
import { AcademiesTagDialog } from "../academies-tag-dialog";
import { AcademiesTagFormState } from "../academies-tag-form/academies-tag-form.types";

export const CreateAcademiesTagButton = ({
	className,
	connectionId,
}: CreateAcademiesTagButtonProps) => {
	const [createRootTag, isCreatingRootTag] =
		useMutation<createAcademiesTagButton_CreateRootTagMutation>(CREATE_ROOT_TAG_MUTATION);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const handleOnClick = () => setIsOpen(true);
	const handleOnHide = () => setIsOpen(false);
	const handleOnSubmit = (value: AcademiesTagFormState) => {
		createRootTag({
			variables: {
				input: {
					data: {
						name: value.name,
						isClickable: value.isClickable,
						isTopic: value.isTopic,
					},
				},
				connections: [connectionId],
			},
		});
	};
	return (
		<>
			<Button
				className={className}
				label="Neu"
				onClick={handleOnClick}
				disabled={isCreatingRootTag}
			/>
			<AcademiesTagDialog
				isOpen={isOpen}
				onHide={handleOnHide}
				isLoading={isCreatingRootTag}
				onSubmit={handleOnSubmit}
				initialValues={undefined}
			/>
		</>
	);
};
