import { ReactComponent as ArrowLoop } from "@assets/icons/arrowLoop.svg";
import { ReactComponent as BridgeOverRiver } from "@assets/icons/bridgeOverRiver.svg";
import { ReactComponent as ComputerWithHeart } from "@assets/icons/computerWithHeart.svg";
import { ReactComponent as DotsConnected } from "@assets/icons/dotsConnected.svg";
import { ReactComponent as FlowerInCircle } from "@assets/icons/flowerInCircle.svg";
import { ReactComponent as HandsWithHeart } from "@assets/icons/handsWithHeart.svg";
import { ReactComponent as HandWithFlower } from "@assets/icons/handWithFlower.svg";
import { ReactComponent as LightBulb } from "@assets/icons/lightBulb.svg";
import { ReactComponent as PeopleHighFive } from "@assets/icons/peopleHighFive.svg";
import { ReactComponent as PersonWithStars } from "@assets/icons/personWithStars.svg";
import { ReactComponent as Rocket } from "@assets/icons/rocket.svg";
import { ReactComponent as WorkingDesk } from "@assets/icons/workingDesk.svg";
import { AdminIcons, type IconProps } from "./icon.types";

export const Icon = ({ icon }: IconProps) => {
	switch (icon) {
		case AdminIcons.DotsConnected:
			return <DotsConnected />;
		case AdminIcons.ArrowLoop:
			return <ArrowLoop />;
		case AdminIcons.BridgeOverRiver:
			return <BridgeOverRiver />;
		case AdminIcons.ComputerWithHeart:
			return <ComputerWithHeart />;
		case AdminIcons.FlowerInCircle:
			return <FlowerInCircle />;
		case AdminIcons.HandsWithHeart:
			return <HandsWithHeart />;
		case AdminIcons.HandWithFlower:
			return <HandWithFlower />;
		case AdminIcons.LightBulb:
			return <LightBulb />;
		case AdminIcons.PeopleHighFive:
			return <PeopleHighFive />;
		case AdminIcons.PersonWithStars:
			return <PersonWithStars />;
		case AdminIcons.WorkingDesk:
			return <WorkingDesk />;
		case AdminIcons.Rocket:
			return <Rocket />;
		default:
			return null;
	}
};
