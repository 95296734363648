/**
 * @generated SignedSource<<76ff4099164b78318fb2fc8e875fedda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exceptionsTable_AvailabilityScheduleFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editExceptionModal_AvailabilityScheduleFragment">;
  readonly " $fragmentType": "exceptionsTable_AvailabilityScheduleFragment";
};
export type exceptionsTable_AvailabilityScheduleFragment$key = {
  readonly " $data"?: exceptionsTable_AvailabilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"exceptionsTable_AvailabilityScheduleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "exceptionsTable_AvailabilityScheduleFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editExceptionModal_AvailabilityScheduleFragment"
    }
  ],
  "type": "AvailabilitySchedule",
  "abstractKey": null
};

(node as any).hash = "aaf4b35d6ffdecd137f70a39a335e95c";

export default node;
