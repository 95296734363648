import { Pagination, Rating } from "@thekeytechnology/framework-react-components";
import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { type showCoachFeedbacksTable_CoachFeedbackFragment$key } from "@relay/showCoachFeedbacksTable_CoachFeedbackFragment.graphql";
import { type showCoachFeedbacksTable_QueryFragment$key } from "@relay/showCoachFeedbacksTable_QueryFragment.graphql";
import { type showCoachFeedbacksTable_Refetch } from "@relay/showCoachFeedbacksTable_Refetch.graphql";
import { COACH_FEEDBACK_FRAGMENT, QUERY_FRAGMENT } from "./show-coach-feedbacks-table.graphql";
import { type ShowCoachFeedbacksTableProps } from "./show-coach-feedbacks-table.types";
import { DataTable } from "../../../../components/data-table";
import { DateTimeDisplay } from "../../../../components/DateTimeDisplay";

export const ShowCoachFeedbacksTable = ({
	coachFeedbackQueryRef,
}: ShowCoachFeedbacksTableProps) => {
	const {
		data: { node },
		hasNext,
		loadNext,
	} = usePaginationFragment<
		showCoachFeedbacksTable_Refetch,
		showCoachFeedbacksTable_QueryFragment$key
	>(QUERY_FRAGMENT, coachFeedbackQueryRef);

	const coachFeedbacks = node?.feedbacks?.edges?.map((e) =>
		readInlineData<showCoachFeedbacksTable_CoachFeedbackFragment$key>(
			COACH_FEEDBACK_FRAGMENT,
			e!.node,
		),
	);

	const handleLoadNextOnClick = () => {
		loadNext(20);
	};

	return (
		<>
			<DataTable value={coachFeedbacks}>
				{(Column) => (
					<>
						<Column
							header={"Bewertung"}
							body={(feedback) => (
								<Rating
									value={feedback.data.score}
									readOnly
									disabled
									cancel={false}
								></Rating>
							)}
						/>
						<Column header={"Kommentar"} body={(feedback) => feedback.data.comment} />
						<Column header={"Von"} body={(feedback) => feedback.feedbackGiver?.name} />
						<Column
							header={"Erstellt am"}
							body={(feedback) => <DateTimeDisplay value={feedback.createdAt} />}
						/>
					</>
				)}
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={handleLoadNextOnClick}></Pagination>
		</>
	);
};
