/**
 * @generated SignedSource<<17e5070468f8d8a365bfde84f44f058e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Icon = "arrowLoop" | "bridgeOverRiver" | "computerWithHeart" | "dotsConnected" | "flowerInCircle" | "handWithFlower" | "handsWithHeart" | "lightBulb" | "peopleHighFive" | "personWithStars" | "rocket" | "workingDesk";
export type TrendMediaKind = "image" | "video";
export type CreateTrendInput = {
  aboutOffersHeadingAndDescription: HeadingAndDescriptionInput;
  clientMutationId?: string | null | undefined;
  coachingHeadingAndDescription: HeadingAndDescriptionInput;
  coachingImageId: string;
  customerData: CustomerDataInput;
  potentialAnalysisHeadingAndDescription: HeadingAndDescriptionInput;
  trendData: TrendDataInput;
  trendMedia: TrendMediaInput;
};
export type TrendDataInput = {
  advantages: ReadonlyArray<AdvantageInput>;
  description: string;
  icon: Icon;
  name: string;
  tagIds: ReadonlyArray<string>;
  visible: boolean;
};
export type AdvantageInput = {
  description: string;
  heading: string;
  icon?: Icon | null | undefined;
};
export type TrendMediaInput = {
  image?: ImageTrendMediaInput | null | undefined;
  video?: VideoTrendMediaInput | null | undefined;
};
export type VideoTrendMediaInput = {
  fileId: string;
  trendMediaType: TrendMediaKind;
};
export type ImageTrendMediaInput = {
  fileId: string;
  trendMediaType: TrendMediaKind;
};
export type CustomerDataInput = {
  amountCustomer: string;
  customerFeedbackIds: ReadonlyArray<string>;
};
export type HeadingAndDescriptionInput = {
  description: string;
  heading: string;
};
export type trendForm_CreateTrendMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateTrendInput;
};
export type trendForm_CreateTrendMutation$data = {
  readonly Admin: {
    readonly Trend: {
      readonly createTrend: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"trends_TrendFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type trendForm_CreateTrendMutation = {
  response: trendForm_CreateTrendMutation$data;
  variables: trendForm_CreateTrendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "position",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TrendData",
    "kind": "LinkedField",
    "name": "trendData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "icon",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "trendForm_CreateTrendMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateTrendPayload",
                "kind": "LinkedField",
                "name": "createTrend",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrendEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trend",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "trends_TrendFragment",
                            "selections": (v3/*: any*/),
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "trendForm_CreateTrendMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrendAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateTrendPayload",
                "kind": "LinkedField",
                "name": "createTrend",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrendEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trend",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd78c9292e6b1797bd358805d4887088",
    "id": null,
    "metadata": {},
    "name": "trendForm_CreateTrendMutation",
    "operationKind": "mutation",
    "text": "mutation trendForm_CreateTrendMutation(\n  $input: CreateTrendInput!\n) {\n  Admin {\n    Trend {\n      createTrend(input: $input) {\n        edge {\n          node {\n            ...trends_TrendFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment trends_TrendFragment on Trend {\n  id\n  position\n  trendData {\n    name\n    description\n    icon\n  }\n}\n"
  }
};
})();

(node as any).hash = "e11b2fa2a1b826a58d44d09be00ab9bc";

export default node;
