/**
 * @generated SignedSource<<8d19977834e5bb9c40dae748f6238a47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AVGSPotentialAnalysis_ForUserKind = "AcceptedForUser" | "InvitedForUser";
import { FragmentRefs } from "relay-runtime";
export type potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment$data = {
  readonly base64EncodedReport: string | null | undefined;
  readonly client: {
    readonly client?: {
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_UserInlineFragment">;
    } | null | undefined;
    readonly email?: string;
    readonly kind: AVGSPotentialAnalysis_ForUserKind;
  };
  readonly coach: {
    readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_UserInlineFragment">;
  } | null | undefined;
  readonly id: string;
  readonly isFinished: boolean;
  readonly linkToPotentialAnalysisOrInvitation: string;
  readonly modules: ReadonlyArray<{
    readonly dimensions: ReadonlyArray<{
      readonly dimensionId: number;
      readonly dimensionText: string;
    }>;
    readonly moduleId: number;
    readonly moduleText: string;
    readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment">;
  }>;
  readonly replacementCoach: {
    readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_UserInlineFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment" | "editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment">;
  readonly " $fragmentType": "potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment";
};
export type potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment$key = {
  readonly " $data"?: potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment"
};

(node as any).hash = "99a3f98aa809d8c890470fae7bae5dd2";

export default node;
