import { useSelector } from "react-redux";
import { type FeatureId } from "@relay/permissionBasedNavigation_Query.graphql";
import { selectActiveFeatureToggles } from "@store/slices/FeatureTogglesSlice";

export const useIsFeatureActive = (featureId: FeatureId | FeatureId[]) => {
	const activeFeatureToogles = useSelector(selectActiveFeatureToggles);
	return Array.isArray(featureId)
		? featureId.every((f) => activeFeatureToogles?.includes(f))
		: activeFeatureToogles?.includes(featureId);
};
