import tw from "twin.macro";

export const MenuWrapper = tw.div`
    tw-flex
    tw-flex-col
    md:tw-w-[min(22rem, 90vw)]
    tw-gap-24
`;

export const AccountRow = tw.div`
    tw-flex
    tw-flex-row
    tw-gap-16
    tw-items-center
    tw-cursor-pointer
`;

export const FlexGrow = tw.div`
    tw-flex-grow
    tw-flex
    tw-flex-row
    tw-gap-16
    tw-items-center
`;

export const CurrentUserWrapper = tw.div`
    tw-flex
    tw-flex-col
    tw-gap-12
    tw-items-center
`;

export const SignOutRow = tw.div`
    tw-flex
    tw-flex-row
    tw-gap-12
    tw-items-center
    tw-justify-center
    tw-cursor-pointer
`;
