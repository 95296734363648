import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { CustomerFeedbackScreen } from "@screens/customer-feedback/customer-feedback.screen";

export const CustomerFeedbackRoutes: RouteDefinition[] = [
	{
		path: Paths.customerFeedback.path,
		element: <CustomerFeedbackScreen />,
		requiredPermissions: ["UserInAccountPermission_AuthAdmin_Read"],
	},
];
