import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFragment, useMutation } from "react-relay";
import { accountSwitchMenu_AuthViewerSchemaFragment$key } from "@relay/accountSwitchMenu_AuthViewerSchemaFragment.graphql";
import { accountSwitchMenu_SwitchAccountMutation } from "@relay/accountSwitchMenu_SwitchAccountMutation.graphql";
import { JwtTokenData, selectCurrentAccountId, setLoggedIn } from "@store/slices/AuthSlice";
import { ReduxStore } from "@store/store.redux";

import {
	AUTH_VIEWER_SCHEMA_FRAGMENT,
	SWITCH_ACCOUNT_MUTATION,
} from "./account-switch-menu.graphql";
import { MenuWrapper } from "./account-switch-menu.styles";
import { AccountSwitchMenuProps } from "./account-switch-menu.types";

export const AccountSwitchMenu = ({
	authViewerSchemaFragmentRef,
	onAccountSelected,
}: AccountSwitchMenuProps) => {
	const auth = useFragment<accountSwitchMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const dispatch = useDispatch();

	const store = ReduxStore.getState();

	const [switchAccount] =
		useMutation<accountSwitchMenu_SwitchAccountMutation>(SWITCH_ACCOUNT_MUTATION);

	const currentAccountId = useSelector(selectCurrentAccountId);
	const accounts = auth.currentUser?.accounts ?? [];

	const handleSwitchAccount = useCallback(
		(accountId: string) => {
			return new Promise((resolve, reject) => {
				const refreshToken = store.auth.loginData?.refreshToken;
				if (!refreshToken) return reject();
				switchAccount({
					variables: {
						input: {
							refreshToken: refreshToken,
							targetAccount: accountId,
						},
					},
					onCompleted: (response) => {
						if (response.Auth.switchAccount) {
							dispatch(
								setLoggedIn({
									tokenData: response.Auth.switchAccount
										?.jwtTokens as JwtTokenData,
								}),
							);
							resolve(response);
						} else {
							reject();
						}
					},
					onError: reject,
				});
			});
		},
		[switchAccount, dispatch],
	);

	const handleAccountOnClick = (id: string) => {
		handleSwitchAccount(id);
		onAccountSelected?.();
	};

	const hasMoreThenOneAccount = accounts.length > 1;
	if (!hasMoreThenOneAccount) return null;

	return (
		<MenuWrapper>
			<Dropdown
				options={accounts.map((account) => {
					return {
						label: account.name,
						value: account.id,
					};
				})}
				onChange={(dropdownChangeEvent: DropdownChangeEvent) => {
					handleAccountOnClick(dropdownChangeEvent.value);
				}}
				value={currentAccountId}
			/>
		</MenuWrapper>
	);
};
