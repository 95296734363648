/**
 * @generated SignedSource<<3a12fa850ab8c634f2e984ad2fa5c283>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editExternalLicensePoolForm_ExternalLicensePoolFragment$data = {
  readonly data: {
    readonly link: string | null | undefined;
    readonly name: string;
    readonly usageInformation: string;
    readonly validNumDaysAfterIssuing: number;
  };
  readonly id: string;
  readonly " $fragmentType": "editExternalLicensePoolForm_ExternalLicensePoolFragment";
};
export type editExternalLicensePoolForm_ExternalLicensePoolFragment$key = {
  readonly " $data"?: editExternalLicensePoolForm_ExternalLicensePoolFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editExternalLicensePoolForm_ExternalLicensePoolFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editExternalLicensePoolForm_ExternalLicensePoolFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalLicensePoolData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usageInformation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "validNumDaysAfterIssuing",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalLicensePool",
  "abstractKey": null
};

(node as any).hash = "32ff74feafcb504c7d45c14557bba1c0";

export default node;
