/**
 * @generated SignedSource<<b5ef353b47f18e9db31b7b983b217f46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateCustomerFeedbackInput = {
  clientMutationId?: string | null | undefined;
  feedback: string;
  name: string;
  position: string;
};
export type createCustomerFeedbackButton_CreateCustomerFeedbackMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateCustomerFeedbackInput;
};
export type createCustomerFeedbackButton_CreateCustomerFeedbackMutation$data = {
  readonly Admin: {
    readonly CustomerFeedback: {
      readonly createCustomerFeedback: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"customerFeedbackTable_CustomerFeedbackFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type createCustomerFeedbackButton_CreateCustomerFeedbackMutation = {
  response: createCustomerFeedbackButton_CreateCustomerFeedbackMutation$data;
  variables: createCustomerFeedbackButton_CreateCustomerFeedbackMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feedback",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createCustomerFeedbackButton_CreateCustomerFeedbackMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerFeedbackAdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCustomerFeedbackPayload",
                "kind": "LinkedField",
                "name": "createCustomerFeedback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerFeedbackEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerFeedback",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "customerFeedbackTable_CustomerFeedbackFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "customerFeedbackActionColumn_CustomerFeedbackFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createCustomerFeedbackButton_CreateCustomerFeedbackMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerFeedbackAdminMutationSchema",
            "kind": "LinkedField",
            "name": "CustomerFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateCustomerFeedbackPayload",
                "kind": "LinkedField",
                "name": "createCustomerFeedback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerFeedbackEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CustomerFeedback",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25e577ca680dfbbfd4afec1095057b61",
    "id": null,
    "metadata": {},
    "name": "createCustomerFeedbackButton_CreateCustomerFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation createCustomerFeedbackButton_CreateCustomerFeedbackMutation(\n  $input: CreateCustomerFeedbackInput!\n) {\n  Admin {\n    CustomerFeedback {\n      createCustomerFeedback(input: $input) {\n        edge {\n          node {\n            ...customerFeedbackTable_CustomerFeedbackFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment customerFeedbackActionColumn_CustomerFeedbackFragment on CustomerFeedback {\n  id\n  ...editCustomerFeedbackButton_CustomerFeedbackFragment\n}\n\nfragment customerFeedbackTable_CustomerFeedbackFragment on CustomerFeedback {\n  id\n  name\n  position\n  feedback\n  ...customerFeedbackActionColumn_CustomerFeedbackFragment\n}\n\nfragment editCustomerFeedbackButton_CustomerFeedbackFragment on CustomerFeedback {\n  id\n  position\n  feedback\n  name\n}\n"
  }
};
})();

(node as any).hash = "30f058c1bea8e756ecb1d6af55421297";

export default node;
