/**
 * @generated SignedSource<<077c016be43811075ee383fe1a57d05b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicenseEditScreen_ExternalLicenseQuery$variables = {
  id: string;
  skip: boolean;
};
export type externalLicenseEditScreen_ExternalLicenseQuery$data = {
  readonly node?: {
    readonly id?: string;
    readonly pool?: {
      readonly data: {
        readonly name: string;
      };
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"editExternalLicenseForm_ExternalLicenseFragment">;
  } | null | undefined;
};
export type externalLicenseEditScreen_ExternalLicenseQuery = {
  response: externalLicenseEditScreen_ExternalLicenseQuery$data;
  variables: externalLicenseEditScreen_ExternalLicenseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "externalLicenseEditScreen_ExternalLicenseQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePool",
                    "kind": "LinkedField",
                    "name": "pool",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePoolData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "editExternalLicenseForm_ExternalLicenseFragment"
                  }
                ],
                "type": "ExternalLicense",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "externalLicenseEditScreen_ExternalLicenseQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePool",
                    "kind": "LinkedField",
                    "name": "pool",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePoolData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "validNumDaysAfterIssuing",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "usageInformation",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicenseData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "issuingInfo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasBeenIssued",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ExternalLicense",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "427cf8f7bd468feb2e2b345f54502181",
    "id": null,
    "metadata": {},
    "name": "externalLicenseEditScreen_ExternalLicenseQuery",
    "operationKind": "query",
    "text": "query externalLicenseEditScreen_ExternalLicenseQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on ExternalLicense {\n      id\n      pool {\n        data {\n          name\n        }\n        id\n      }\n      ...editExternalLicenseForm_ExternalLicenseFragment\n    }\n    id\n  }\n}\n\nfragment editExternalLicenseForm_ExternalLicenseFragment on ExternalLicense {\n  id\n  data {\n    name\n    code\n  }\n  pool {\n    id\n    data {\n      validNumDaysAfterIssuing\n      usageInformation\n    }\n  }\n  issuingInfo {\n    __typename\n    hasBeenIssued\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2b0a8be5dc8de68b67309be4ab9e8b3";

export default node;
