import { BorderRadius } from "@thekeytechnology/epic-ui";
import { type Recursive, type Token } from "@styled-system/types/composition";

/**
 * @deprecated This radius should not be used
 */
export const borderRadius100: BorderRadius = new BorderRadius(100);

export const radii: Recursive<Token<string>> = {
	0: { value: "0rem" },
	4: { value: "0.25rem" },
	6: { value: "0.375rem" },
	8: { value: "0.5rem" },
	10: { value: "0.625rem" },
	12: { value: "0.75rem" },
	16: { value: "1rem" },
	20: { value: "1.25rem" },
	24: { value: "1.5rem" },
	100: { value: "6.25rem" },
	full: { value: "9999rem" },
};
