import * as Sentry from "@sentry/react";
import { ThemeProvider } from "@thekeytechnology/epic-ui";
import { TkComponentsContext } from "@thekeytechnology/framework-react-components";
import { PrimeReactProvider } from "primereact/api";
import { Suspense } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { ToastContainer } from "react-toastify";
import { TkaAdminTheme } from "@corestyle/component-theme/component-theme";
import { Loader } from "./components/Loader";
import { PermissionBasedNavigation } from "./navigation/permission-based-navigation.component";
import { RelayEnvironment } from "./RelayEnvironment";
import { LightTheme } from "./themes/light-theme";

if (
	process.env.REACT_APP_APP_ENVIRONMENT === "prod" ||
	(process.env.REACT_APP_APP_ENVIRONMENT === "staging" &&
		process.env.REACT_APP_API_BASE === "https://staging.api.constellation.academy")
) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 1.0,
		release: process.env.SENTRY_PROJECT_NAME + "@" + process.env.npm_package_version,
	});
}

const primeReactContextValue = {
	zIndex: {
		modal: 500,
		overlay: 3000,
		menu: 3000,
		tooltip: 3100,
		toast: 5200,
	},
};

function App() {
	return (
		<TkComponentsContext.Provider value={TkaAdminTheme}>
			<ThemeProvider theme={LightTheme}>
				<ToastContainer autoClose={5000} newestOnTop={true} />
				<Sentry.ErrorBoundary>
					<Suspense fallback={<Loader />}>
						<RelayEnvironmentProvider environment={RelayEnvironment}>
							<PrimeReactProvider value={primeReactContextValue}>
								<PermissionBasedNavigation />
							</PrimeReactProvider>
						</RelayEnvironmentProvider>
					</Suspense>
				</Sentry.ErrorBoundary>
			</ThemeProvider>
		</TkComponentsContext.Provider>
	);
}

export default App;
