/**
 * @generated SignedSource<<939d565054ccd357d7f2fb569795cee3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$data = {
  readonly dimensions: ReadonlyArray<{
    readonly dimensionId: number;
    readonly dimensionText: string;
  }>;
  readonly moduleId: number;
  readonly moduleText: string;
  readonly " $fragmentType": "selectDimensionsModal_AVGSPotentialAnalysisModuleFragment";
};
export type selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$key = {
  readonly " $data"?: selectDimensionsModal_AVGSPotentialAnalysisModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectDimensionsModal_AVGSPotentialAnalysisModuleFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectDimensionsModal_AVGSPotentialAnalysisModuleFragment"
};

(node as any).hash = "49ca27c45bf65d56142e3f3e4325c2ae";

export default node;
