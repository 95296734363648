import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSelectionSingleChangeEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import {
	selectAvgsCoachField_SearchAVGSCoachesQuery,
	selectAvgsCoachField_SearchAVGSCoachesQuery$data,
} from "@relay/selectAvgsCoachField_SearchAVGSCoachesQuery.graphql";
import { SEARCH_AVGS_COACHES_QUERY } from "./select-avgs-coach-field.grapqhl";
import { SelectAvgsCoachFieldProps } from "./select-avgs-coach-field.types";

export const SelectAvgsCoachField = ({
	updateField,
	fieldValue: selectedUserId,
	accountId,
}: SelectAvgsCoachFieldProps) => {
	const environment = useRelayEnvironment();

	const [filterByNameOrEmail, setFilterByNameOrEmail] = useState("");
	const [allAvgsCoaches, setAllAvgsCoaches] = useState<
		| Writeable<
				selectAvgsCoachField_SearchAVGSCoachesQuery$data["Admin"]["AvgsPotentialAnalysis"]["SearchAVGSCoaches"]["edges"]
		  >
		| undefined
	>();

	const updateAvgsCoaches = useCallback(
		(filterByNameOrEmail: string) => {
			void fetchQuery<selectAvgsCoachField_SearchAVGSCoachesQuery>(
				environment,
				SEARCH_AVGS_COACHES_QUERY,
				{
					accountId,
					filterByNameOrEmail: filterByNameOrEmail,
				},
			)
				.toPromise()
				.then((result) => {
					setAllAvgsCoaches(
						result!.Admin.AvgsPotentialAnalysis.SearchAVGSCoaches.edges?.slice(),
					);
				});
		},
		[accountId],
	);

	const handleOnSubmit = useCallback(() => {
		updateAvgsCoaches(filterByNameOrEmail);
	}, [filterByNameOrEmail, updateAvgsCoaches]);

	useEffect(handleOnSubmit, [accountId]);

	useEffect(() => {
		if (selectedUserId && !allAvgsCoaches?.find((user) => user?.node.id === selectedUserId)) {
			updateField(undefined);
		}
	}, [allAvgsCoaches]);

	const handleClearFormOnClick = () => {
		setFilterByNameOrEmail("");
		updateAvgsCoaches("");
	};

	const handelNameOrEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterByNameOrEmail(e.target.value);
	}, []);

	const handleUserSelected = useCallback((event: DataTableSelectionSingleChangeEvent<any>) => {
		updateField(event.value?.node?.id);
	}, []);

	return (
		<>
			<div className="flex flex-row align-items-center">
				<InputText
					value={filterByNameOrEmail}
					onChange={handelNameOrEmailChange}
					className="mr-2"
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={handleClearFormOnClick}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					onClick={handleOnSubmit}
					type="button"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
			<DataTable
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">
							Es wurde kein Avgs-Coach mit dieser E-Mail adresse oder diesem Namen
							gefunden.
						</div>
					</div>
				}
				className="mb-3"
				selectionMode="single"
				selection={{ node: { id: selectedUserId } }}
				dataKey="node.id"
				onSelectionChange={handleUserSelected}
				value={allAvgsCoaches ?? []}
			>
				<Column header="Name" field="node.name" />
				<Column header="E-Mail" field="node.email" />
			</DataTable>
		</>
	);
};
