/**
 * @generated SignedSource<<69b712c7fcb1c4f95859fb40cbd49a1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type externalLicensePoolScreen_ExternalLicensePoolQuery$variables = {
  id: string;
  skip: boolean;
};
export type externalLicensePoolScreen_ExternalLicensePoolQuery$data = {
  readonly node?: {
    readonly data?: {
      readonly name: string;
    };
    readonly id?: string;
  } | null | undefined;
};
export type externalLicensePoolScreen_ExternalLicensePoolQuery = {
  response: externalLicensePoolScreen_ExternalLicensePoolQuery$data;
  variables: externalLicensePoolScreen_ExternalLicensePoolQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ExternalLicenseData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "externalLicensePoolScreen_ExternalLicensePoolQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "type": "ExternalLicense",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "externalLicensePoolScreen_ExternalLicensePoolQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "ExternalLicense",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4513023124412eb8d29d8cee2754cf2f",
    "id": null,
    "metadata": {},
    "name": "externalLicensePoolScreen_ExternalLicensePoolQuery",
    "operationKind": "query",
    "text": "query externalLicensePoolScreen_ExternalLicensePoolQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on ExternalLicense {\n      id\n      data {\n        name\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "72a8158d0cfcdfb712090f845ad767b7";

export default node;
