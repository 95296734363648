import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import {
	selectAccountField_AccountFragment$data,
	selectAccountField_AccountFragment$key,
} from "@relay/selectAccountField_AccountFragment.graphql";
import { selectAccountField_QueryFragment$key } from "@relay/selectAccountField_QueryFragment.graphql";
import { selectAccountField_Refetch } from "@relay/selectAccountField_Refetch.graphql";
import { ACCOUNT_FRAGMENT, QUERY_FRAGMENT } from "./select-account-field.graphql";
import { SelectAccountFieldProps, SelectAccountFieldState } from "./select-account-field.types";

import { DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";
export const SelectAccountField = ({ queryFragmentRef, ...props }: SelectAccountFieldProps) => {
	const {
		data: {
			Admin: {
				Auth: { SelectAccounts },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<selectAccountField_Refetch, selectAccountField_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ nameOpt: search });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectAccountField_AccountFragment$data>,
	) => {
		props.updateField({ id: event.value.id, name: event.value.name });
	};

	const values =
		SelectAccounts.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectAccountField_AccountFragment$key>(ACCOUNT_FRAGMENT, e!.node),
			) || [];

	return (
		<SelectComplexDataField<SelectAccountFieldState>
			valueField="name"
			valuePlaceholder="Keine Konto ausgewählt"
			actionButtonLabel="Konto auswählen"
			headerLabel="Wähle ein Konto aus"
			{...props}
		>
			<SearchableTable
				selectionMode="single"
				selection={props.fieldValue}
				dataKey="id"
				value={values}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnSelectionChange}
			>
				{(Column) => (
					<>
						<Column header="Name" field="name" />
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
