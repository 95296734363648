/**
 * @generated SignedSource<<b62c3f35b0de31978704ffe83ff447cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectCoachingField_Refetch$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  name?: string | null | undefined;
};
export type SelectCoachingField_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectCoachingField_QueryFragment">;
};
export type SelectCoachingField_Refetch = {
  response: SelectCoachingField_Refetch$data;
  variables: SelectCoachingField_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCoachingField_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectCoachingField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectCoachingField_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CoachingOfferConnection",
                "kind": "LinkedField",
                "name": "CoachingOffers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoachingOfferEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CoachingOffer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CoachingOfferDescription",
                            "kind": "LinkedField",
                            "name": "description",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "name"
                ],
                "handle": "connection",
                "key": "selectCoachingField_CoachingOffers",
                "kind": "LinkedHandle",
                "name": "CoachingOffers"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98d75a1c4993acae5937179d72cf1f97",
    "id": null,
    "metadata": {},
    "name": "SelectCoachingField_Refetch",
    "operationKind": "query",
    "text": "query SelectCoachingField_Refetch(\n  $after: String\n  $first: Int = 20\n  $name: String\n) {\n  ...selectCoachingField_QueryFragment_3cTRis\n}\n\nfragment coachingOffersTable_CoachingOfferConnectionFragment on CoachingOfferConnection {\n  edges {\n    node {\n      ...coachingOffersTable_CoachingOfferFragment\n      id\n    }\n  }\n}\n\nfragment coachingOffersTable_CoachingOfferFragment on CoachingOffer {\n  id\n  description {\n    name\n    description\n  }\n}\n\nfragment selectCoachingField_QueryFragment_3cTRis on Query {\n  Admin {\n    Coaching {\n      CoachingOffers(first: $first, after: $after, name: $name) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...coachingOffersTable_CoachingOfferConnectionFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95ed1b38176c995c0e48c54e1ddf5531";

export default node;
