/**
 * @generated SignedSource<<e0b97ab8adc842a2d0e44e356ac6afbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnsweringTypeV2 = "allowMultipleAnswersAllCorrectMustBeGiven" | "allowMultipleAnswersOnlySomeCorrectMustBeGiven" | "allowOnlyOneAnswer";
import { FragmentRefs } from "relay-runtime";
export type multipleChoiceElementForm_MultipleChoiceElementV2Fragment$data = {
  readonly answerOptions: ReadonlyArray<{
    readonly answer: string;
    readonly image: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly isCorrect: boolean;
  }>;
  readonly answeringType: AnsweringTypeV2;
  readonly answeringTypeText: string | null | undefined;
  readonly id: string;
  readonly image: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly question: string;
  readonly title: string;
  readonly " $fragmentType": "multipleChoiceElementForm_MultipleChoiceElementV2Fragment";
};
export type multipleChoiceElementForm_MultipleChoiceElementV2Fragment$key = {
  readonly " $data"?: multipleChoiceElementForm_MultipleChoiceElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"multipleChoiceElementForm_MultipleChoiceElementV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multipleChoiceElementForm_MultipleChoiceElementV2Fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringTypeText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerOptionV2",
      "kind": "LinkedField",
      "name": "answerOptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCorrect",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceElementV2",
  "abstractKey": null
};
})();

(node as any).hash = "fa4271803922f35ee7b1ed2d034e7a29";

export default node;
