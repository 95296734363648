import { graphql } from "babel-plugin-relay/macro";

export const EDIT_COACHING_UNPUBLISHED_LEARNABLE_FRAGMENT = graphql`
	fragment editCoachingOfferButton_UnpublishedLearnableFragment on UnpublishedLearnable {
		id
		... on UnpublishedGroupCoachingLearnable {
			kind
			groupCoaching {
				id
				associatedCoaches {
					coach {
						id
						name
					}
					id
				}
				setting {
					kind
				}
				description {
					description
					name
					image {
						name
						id
					}
					shortDescription
					tags {
						id
					}
				}
			}
		}
		... on UnpublishedOneToOneCoachingLearnable {
			kind
			oneToOneCoaching {
				id
				associatedCoaches {
					coach {
						id
						name
					}
					id
				}
				setting {
					kind
				}
				description {
					description
					name
					image {
						name
						id
					}
					shortDescription
					tags {
						id
					}
				}
			}
		}
	}
`;
