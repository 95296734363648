import React, { Children, useEffect, useState } from "react";
import { MultiDialogFlowContainerProps } from "./multi-dialog-flow-container.types";

export const MultiDialogFlowContainer = ({
	isVisible,
	startAtStep = 0,
	onDismiss,
	children,
}: MultiDialogFlowContainerProps) => {
	const [step, setStep] = useState(startAtStep);

	const resetFlow = () => {
		setStep(startAtStep);
	};

	const handleOnNext = (nextStep?: number) => {
		setStep(nextStep ?? step + 1);
	};

	const handleOnPrevious = (nextStep?: number) => {
		const previousStep = nextStep ?? step - 1;
		if (previousStep < 0) {
			return onDismiss?.();
		}
		setStep(previousStep);
	};

	const handleOnCancel = (fallbackStep?: number) => {
		const previousStep = fallbackStep ?? -1;
		if (previousStep < 0) {
			return onDismiss?.();
		}
		setStep(previousStep);
	};

	const handleOnClose = () => {
		resetFlow();
		onDismiss?.();
	};

	useEffect(() => {
		!isVisible && resetFlow();
	}, [isVisible]);

	const childrenList = Children.map(
		children?.({
			onNext: handleOnNext,
			onPrevious: handleOnPrevious,
			onCancel: handleOnCancel,
			onClose: handleOnClose,
			currentStep: step,
		}),
		(child) => {
			if (child?.type === React.Fragment) {
				return child?.props.children;
			}
			return child;
		},
	);

	return isVisible ? childrenList?.[step] : null;
};
