import { graphql } from "babel-plugin-relay/macro";

export const EDIT_PROFILE_DATA_MUTATION = graphql`
	mutation editProfileDataButton_EditProfileDataMutation(
		$input: EditProfileDataInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				editProfileData(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...coachProfilesTable_CoachProfileFragment
						}
					}
				}
			}
		}
	}
`;
export const COACH_PROFILE_FRAGMENT = graphql`
	fragment editProfileDataButton_CoachProfileFragment on CoachProfile {
		id
		profileData {
			jobTitles
			quote
			socials {
				others
				facebook
				twitter
				linkedIn
				website
			}
			description
			avatar {
				id
				thumbnail
				name
				url
			}
			basicPersonal {
				salutation
				title
				firstName
				lastName
				email
				phoneNumber
			}
			languages
			studies
			certificateFileIds {
				id
				name
			}
			certificateAnswer
			experience {
				coachingHours
				coachingFileIds {
					id
					name
				}
				branches
				branchesSteps
			}
			educationFreeText
			educationMoreThan150h
			coachingStrongPointsTopics
			methods
			digitalAffinity1
			digitalAffinity2
			personalPhilosophyCoachingStyle
			personalPhilosophyVisionMission
			referencesCoachingExperienceIds {
				id
				name
			}
			referencesPublications
		}
	}
`;
