import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectAccountGroups_Query($id: ID!) {
		Admin {
			Auth {
				GetUserInAccountGroups(id: $id) {
					id
					name
					permissions
				}
			}
		}
	}
`;
