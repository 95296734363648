import { DayOfWeek, type LocalTime } from "@js-joda/core";
import { Dialog, Dropdown } from "@thekeytechnology/epic-ui";
import { type FC, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { type createExceptionModal_FreeCalenderWeeksFragment$key } from "@relay/createExceptionModal_FreeCalenderWeeksFragment.graphql";
import { type DayAndTimesForCalendarWeekInput } from "@relay/editAvailabilityScheduleForm_EditAvailabilityScheduleMutation.graphql";

import {
	type OverrideDaysAndTimesForCalendarWeek,
	type TimeSlotsMap,
} from "@screens/coach-profiles/parts/edit-availability-schedule-form/edit-availability-schedule-form.types";
import { formatCalendarWeekAndYear } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/create-exception-modal/create-exception-modal.util";
import { FREE_CALENDAR_WEEKS_FRAGMENT } from "./create-exception-modal.graphql";
import { TitleWrapper, Wrapper } from "./create-exception-modal.styles";
import { type CreateExceptionModalProps } from "./create-exception-modal.types";
import { DaySchedule } from "../day-schedule";

export const CreateExceptionModal: FC<CreateExceptionModalProps> = ({
	isVisible,
	onDismiss,
	onSubmit,
	queryFragmentRef,
}) => {
	const [selectedCalendarWeek, setSelectedCalendarWeek] = useState<string>("");
	const [temporaryOverrideState, setTemporaryOverrideState] = useState<
		OverrideDaysAndTimesForCalendarWeek[]
	>([]);

	const calendarWeeks = useFragment<createExceptionModal_FreeCalenderWeeksFragment$key>(
		FREE_CALENDAR_WEEKS_FRAGMENT,
		queryFragmentRef ?? null,
	);

	// TODO useSubscribeToInvalidation for userId upon submit
	// client:root:Admin:Coaching:FreeCalendarWeeks(coachUserId:"VXNlcjpVc2VyOmIwYzA4MDkxLTZlZDctNGY0ZS04MDIzLWM5ZjViMGFkY2M5ZQ==")
	const [schedule, setSchedule] = useState<TimeSlotsMap>({});

	const formattedCalendarWeeks = useMemo(() => {
		return (
			calendarWeeks?.map((item) => ({
				label: `KW${item.calendarWeek} ${item.calendarYear}`,
				value: `${item.calendarWeek}_${item.calendarYear}`,
			})) ?? []
		);
	}, [calendarWeeks]);

	const createScheduleOnChangeHandler = (dayOfWeek: DayOfWeek) => (timeSlots?: LocalTime[]) => {
		const formattedCalendarWeekAndYear = formatCalendarWeekAndYear(selectedCalendarWeek);
		const overrideDaysAndTimesForCalendarWeek: DayAndTimesForCalendarWeekInput[] = [
			...temporaryOverrideState,
		];

		const existingEntryIndex = overrideDaysAndTimesForCalendarWeek.findIndex(
			(entry) =>
				entry.dayOfWeek === dayOfWeek.name() &&
				entry.calendarWeek === formattedCalendarWeekAndYear.week &&
				entry.calendarYear === formattedCalendarWeekAndYear.year,
		);

		if (existingEntryIndex >= 0) {
			if (timeSlots && timeSlots.length > 0) {
				overrideDaysAndTimesForCalendarWeek[existingEntryIndex].timeSlots =
					timeSlots?.map((localTime) => localTime.toString()) || [];
			}
		} else {
			if (timeSlots) {
				overrideDaysAndTimesForCalendarWeek.push({
					calendarWeek: formattedCalendarWeekAndYear.week,
					calendarYear: formattedCalendarWeekAndYear.year,
					dayOfWeek: dayOfWeek.name(),
					timeSlots: timeSlots?.map((localTime) => localTime.toString()),
				});
			}
		}
		setSchedule({
			...schedule,
			[dayOfWeek.name()]: timeSlots ?? [],
		});
		setTemporaryOverrideState(overrideDaysAndTimesForCalendarWeek);
	};

	const handleCalendarWeekOnChange = (value: string) => {
		setSelectedCalendarWeek(value);
	};

	const handleOnSave = () => {
		setSelectedCalendarWeek("");
		onDismiss();
		onSubmit(temporaryOverrideState);
		setTemporaryOverrideState([]);
		setSchedule({});
	};
	const handleOnClose = () => {
		setSelectedCalendarWeek("");
		onDismiss();
	};

	return (
		<Dialog visible={isVisible} onHide={handleOnClose} title={"Ausnahme hinzufügen"}>
			<Wrapper>
				<TitleWrapper>
					<div>
						Die wiederkehrende Einstellung wird für die ausgewählte{" "}
						<mark>Woche überschrieben</mark>. Bitte prüfe deine Angaben genau.
					</div>
				</TitleWrapper>
				<Dropdown
					label="Kalenderwoche"
					value={selectedCalendarWeek}
					options={formattedCalendarWeeks}
					onChange={handleCalendarWeekOnChange}
					placeholder="Wähle eine Kalenderwoche aus"
				/>
				{selectedCalendarWeek &&
					DayOfWeek.values().map((day) => (
						<DaySchedule
							key={"create-exception-modal-" + day.name()}
							dayOfWeek={day}
							timeSlots={schedule[day.name()]}
							onChange={createScheduleOnChangeHandler(day)}
						/>
					))}
			</Wrapper>

			<hr />
			<Button onClick={handleOnSave} label={"Erstellen"} />
		</Dialog>
	);
};
