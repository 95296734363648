/**
 * @generated SignedSource<<ae5af84b65e6ec4db2847558d5a4a70c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectWordPackageField_WordPackageFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectWordPackageField_WordPackageFragment";
};
export type selectWordPackageField_WordPackageFragment$key = {
  readonly " $data"?: selectWordPackageField_WordPackageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectWordPackageField_WordPackageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectWordPackageField_WordPackageFragment"
};

(node as any).hash = "0d15a1bf6aba679e897a82bb639c0c28";

export default node;
