/**
 * @generated SignedSource<<ea946736a9003d2ea033420d921e224e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editBlockOutDateModal_BlockOutDateFragment$data = {
  readonly from: string;
  readonly to: string;
  readonly " $fragmentType": "editBlockOutDateModal_BlockOutDateFragment";
};
export type editBlockOutDateModal_BlockOutDateFragment$key = {
  readonly " $data"?: editBlockOutDateModal_BlockOutDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editBlockOutDateModal_BlockOutDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editBlockOutDateModal_BlockOutDateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    }
  ],
  "type": "BlockoutDate",
  "abstractKey": null
};

(node as any).hash = "32760748ce9a1af1c475ffe0af631ff4";

export default node;
