import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query trends_Query {
		...trends_TrendsFragment
	}
`;

export const TRENDS_FRAGMENT = graphql`
	fragment trends_TrendsFragment on Query
	@argumentDefinitions(first: { type: "Int" }, last: { type: "Int" }) {
		Admin {
			Trend {
				trend(first: $first, last: $last) @connection(key: "trends_trend") {
					__id
					edges {
						node {
							...trends_TrendFragment
							...sortTrendsModal_TrendFragment
						}
					}
				}
			}
		}
	}
`;

export const TREND_FRAGMENT = graphql`
	fragment trends_TrendFragment on Trend @inline {
		id
		position
		trendData {
			name
			description
			icon
		}
	}
`;

export const DELETE_TREND_MUTATION = graphql`
	mutation trends_DeleteTrandMutation($input: DeleteTrendInput!, $connections: [ID!]!) {
		Admin {
			Trend {
				deleteTrend(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
					clientMutationId
				}
			}
		}
	}
`;
