export const PaymentMethod: { [key: string]: string } = {
	Card: "Kreditkarte (Stripe)",
	Sepa: "Lastschrift (Stripe)",
	Giropay: "Giropay",
	Sofort: "Sofort",
	Paypal: "Paypal",
	MonthlyTk: "Monatlich (Wir)",
	MonthlyPartner: "Monatlich (Crefo)",
	InvoiceTk: "Rechnung (Wir)",
	InvoicePartner: "Rechnung (Crefo)",
	IapGoogle: "In-App (Google)",
	IapApple: "In-App (Apple)",
};
