import * as Yup from "yup";

export const getVideoSourceFormSchema = (isCreating: boolean) =>
	Yup.object({
		name: Yup.string().required("Name wird benötigt."),
		accessToken: Yup.string().when([], () => {
			return isCreating
				? Yup.string().required("Vimeo-Access-Token wird benötigt.")
				: Yup.string().notRequired();
		}),
	});
