/**
 * @generated SignedSource<<7e375c59b0694aa19b95aeaee41c27e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenerateAbsoluteCartLinkInput = {
  clientMutationId?: string | null | undefined;
  selectedDiscountCodes: ReadonlyArray<string>;
  selectedProducts: ReadonlyArray<CartLinkProductsInput>;
};
export type CartLinkProductsInput = {
  amount: number;
  productId: string;
};
export type generateCartLinkForm_GenerateCartLinkMutation$variables = {
  input: GenerateAbsoluteCartLinkInput;
};
export type generateCartLinkForm_GenerateCartLinkMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly generateAbsoluteCartLink: {
        readonly link: string;
      } | null | undefined;
    };
  };
};
export type generateCartLinkForm_GenerateCartLinkMutation = {
  response: generateCartLinkForm_GenerateCartLinkMutation$data;
  variables: generateCartLinkForm_GenerateCartLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "GenerateAbsoluteCartLinkPayload",
            "kind": "LinkedField",
            "name": "generateAbsoluteCartLink",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "generateCartLinkForm_GenerateCartLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "generateCartLinkForm_GenerateCartLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dd0ab6c8cf1347571a82d1288938d7f5",
    "id": null,
    "metadata": {},
    "name": "generateCartLinkForm_GenerateCartLinkMutation",
    "operationKind": "mutation",
    "text": "mutation generateCartLinkForm_GenerateCartLinkMutation(\n  $input: GenerateAbsoluteCartLinkInput!\n) {\n  Admin {\n    Billing {\n      generateAbsoluteCartLink(input: $input) {\n        link\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd69367546b36543c1affe8a4f3a59cb";

export default node;
