import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment reportsTable_QueryFragment on Query
	@refetchable(queryName: "reportsTable_QueryFragmentRefetch")
	@argumentDefinitions(
		filterByReferenceNumberOpt: { type: "String" }
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
	) {
		Admin {
			Reports {
				Reports(
					first: $first
					after: $after
					last: $last
					before: $before
					referenceNumber: $filterByReferenceNumberOpt
				) @connection(key: "ReportsTable_Reports") {
					__id
					pageInfo {
						endCursor
						startCursor
						hasNextPage
						hasPreviousPage
					}
					edges {
						node {
							id
							...reportsTable_DSAReportInlineFragment
						}
					}
				}
			}
		}
	}
`;

export const DSA_REPORT_INLINE_FRAGMENT = graphql`
	fragment reportsTable_DSAReportInlineFragment on DSAReport @inline {
		id
		createdAt
		referenceNumber
		data {
			url
			email
			firstName
			lastName
			extra
			problemType
			reportType
		}
	}
`;
