/**
 * @generated SignedSource<<5d3fc857e91b1fc8a3cfc8429aabe1fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAssociatedCoachesForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectCoachesField_QueryFragment">;
  readonly " $fragmentType": "editAssociatedCoachesForm_QueryFragment";
};
export type editAssociatedCoachesForm_QueryFragment$key = {
  readonly " $data"?: editAssociatedCoachesForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAssociatedCoachesForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAssociatedCoachesForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectCoachesField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7a6bf58a89be36f25cd279612c3b914c";

export default node;
