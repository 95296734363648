/**
 * @generated SignedSource<<57163bd37a69f66d016481dd529aa9e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editExceptionModal_DayAndTimesForCalendarWeekFragment$data = ReadonlyArray<{
  readonly calendarWeek: number;
  readonly calendarYear: number;
  readonly dayOfWeek: any;
  readonly timeSlots: ReadonlyArray<any>;
  readonly " $fragmentType": "editExceptionModal_DayAndTimesForCalendarWeekFragment";
}>;
export type editExceptionModal_DayAndTimesForCalendarWeekFragment$key = ReadonlyArray<{
  readonly " $data"?: editExceptionModal_DayAndTimesForCalendarWeekFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editExceptionModal_DayAndTimesForCalendarWeekFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "editExceptionModal_DayAndTimesForCalendarWeekFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSlots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    }
  ],
  "type": "DayAndTimesForCalendarWeek",
  "abstractKey": null
};

(node as any).hash = "d53eed5a90833a238da3058d463e2b36";

export default node;
