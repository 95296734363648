import { graphql } from "babel-plugin-relay/macro";

export const EDIT_BASE_DATA_MUTATION = graphql`
	mutation editBaseDataButton_EditBaseDataMutation(
		$input: EditBaseDataInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				editBaseData(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...coachProfilesTable_CoachProfileFragment
						}
					}
				}
			}
		}
	}
`;

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment editBaseDataButton_CoachProfileFragment on CoachProfile {
		id
		coach {
			id
		}
		baseData {
			address {
				postalCode
				lineOne
				lineTwo
				city
			}
			birthDate
			birthPlace
			gender
			nationality
		}
	}
`;
