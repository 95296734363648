/**
 * @generated SignedSource<<19b3edbd0e931f8f30ec0b9e144bfdc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AVGSPotentialAnalysis_ForUserKind = "AcceptedForUser" | "InvitedForUser";
import { FragmentRefs } from "relay-runtime";
export type editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment$data = {
  readonly client: {
    readonly client?: {
      readonly email: string;
    } | null | undefined;
    readonly email?: string;
    readonly kind: AVGSPotentialAnalysis_ForUserKind;
  };
  readonly id: string;
  readonly isFinished: boolean;
  readonly " $fragmentType": "editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment";
};
export type editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key = {
  readonly " $data"?: editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editInvitedForUserButton_AVGSPotentialAnalysisPotentialAnalysisFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFinished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AVGSPotentialAnalysis_InvitedForUser",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "type": "AVGSPotentialAnalysis_AcceptedForUser",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AVGSPotentialAnalysis_PotentialAnalysis",
  "abstractKey": null
};
})();

(node as any).hash = "9be3218aa82e97307ea8032fa2fe0699";

export default node;
