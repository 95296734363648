import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useHasPermissions } from "@hooks/use-has-permissions";
import {
	type EditUploadTaskFormProps,
	type EditUploadTaskFormState,
} from "./edit-upload-task-form.interface";
import { DefaultTextAreaComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import {
	DocumentFileTypeOptions,
	AudioFileTypeOptions,
} from "../../../../features/files/file-filters/file-filters.const";
import {
	type FileV2,
	LimitedTypeOptionsFileSelectionField,
} from "../../../../features/files/file-selection-field";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";

export const EditUploadTaskForm = ({
	loading,
	editable,
	initialValues,
	onSubmit,
}: EditUploadTaskFormProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const formId = "EditUploadTaskForm";
	const formik = useFormik<EditUploadTaskFormState>({
		initialValues: {
			evaluationText: initialValues?.evaluationText,
			file: initialValues?.file,
		},
		validationSchema: Yup.object().shape({
			evaluationText: Yup.string().required("Eine Bewertung wird benötigt"),
			file: Yup.object().required("Eine Korrektur wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_AsyncElementTaskAdmin_Modify"]);

	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditUploadTaskFormState, string>
					name={"evaluationText"}
					label={"Bewertung"}
					component={(props) => <DefaultTextAreaComponent {...props} rows={5} />}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
					disabled={!editable}
				/>
				<ValidatedField<EditUploadTaskFormState, FileV2>
					name={"file"}
					label={"Korrektur"}
					helpText={"Korrektur"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={LimitedTypeOptionsFileSelectionField(
						[...DocumentFileTypeOptions, ...AudioFileTypeOptions],
						canUpload,
						canDelete,
					)}
					formikConfig={formik}
					disabled={!editable}
				/>
				<Button
					disabled={loading || !editable}
					type="submit"
					label="Bewertung speichern"
					className="p-mt-2"
				/>
			</form>
		</>
	);
};
