import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { type createIhkCertificateRewardButton_AddIHKCertificateRewardMutation } from "@relay/createIhkCertificateRewardButton_AddIHKCertificateRewardMutation.graphql";
import { type createIhkCertificateRewardButton_TreeNodeFragment$key } from "@relay/createIhkCertificateRewardButton_TreeNodeFragment.graphql";
import {
	ADD_IHK_CERTIFICATE_REWARD_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./create-ihk-certificate-reward-button.graphql";
import { type CreateIHKCertificateRewardButtonProps } from "./create-ihk-certificate-reward-button.types";

export const CreateIHKCertificateRewardButton = ({
	treeNodeFragmentRef,
}: CreateIHKCertificateRewardButtonProps) => {
	const node = useFragment<createIhkCertificateRewardButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [addIHKCertificateReward, isAddingIHKCertificateReward] =
		useMutation<createIhkCertificateRewardButton_AddIHKCertificateRewardMutation>(
			ADD_IHK_CERTIFICATE_REWARD_MUTATION,
		);

	const handleOnClick = useCallback(() => {
		addIHKCertificateReward({
			variables: {
				input: {
					contentId: node.id,
				},
			},
		});
	}, [node, addIHKCertificateReward]);

	return (
		<Button
			disabled={isAddingIHKCertificateReward}
			tooltip={"IHK Zertifikat hinzufügen"}
			icon={EpicIcons.STAR}
			onClick={handleOnClick}
		/>
	);
};
