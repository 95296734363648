import { type FormikProps, useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { QUERY_FRAGMENT } from "./edit-associated-coaches-form.graphql";
import { type EditAssociatedCoachesFormState } from "./edit-associated-coaches-form.types";
import { type editAssociatedCoachesForm_QueryFragment$key } from "../../../../__generated__/editAssociatedCoachesForm_QueryFragment.graphql";
import { type RenderConfig, ValidatedField } from "../../../../components/ValidatedField";
import { SelectCoachesField } from "../../../../features/coaches/select-coaches-field";
import { type SelectCoachesFieldState } from "../../../../features/coaches/select-coaches-field/select-coaches-field.types";
import { type DefaultFormProps } from "../common";

// eslint-disable-next-line react/display-name
export const EditAssociatedCoachesForm = React.forwardRef<
	FormikProps<EditAssociatedCoachesFormState>,
	DefaultFormProps<EditAssociatedCoachesFormState> & {
		queryFragmentRef: editAssociatedCoachesForm_QueryFragment$key;
	}
>(({ initialState, onSubmit, queryFragmentRef }, ref) => {
	const query = useFragment<editAssociatedCoachesForm_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const formik = useFormik<EditAssociatedCoachesFormState>({
		initialValues: initialState ?? {
			coaches: [],
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			coaches: Yup.array(),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	const buildHandleOnUpdateField = (
		newValue: SelectCoachesFieldState | undefined,
		renderConfig: RenderConfig<SelectCoachesFieldState>,
	) => {
		renderConfig.updateField(newValue);
	};
	return (
		<Card>
			<form onSubmit={formik.handleSubmit} className={"p-fluid"}>
				<ValidatedField<EditAssociatedCoachesFormState, SelectCoachesFieldState>
					name={"coaches"}
					label={"Coaches"}
					formikConfig={formik}
					component={(renderConfig) => {
						return (
							<SelectCoachesField
								queryFragmentRef={query}
								fieldValue={renderConfig.fieldValue}
								updateField={(newValue) => {
									buildHandleOnUpdateField(newValue, renderConfig);
								}}
								fieldName={"coaches"}
								required={true}
								isValid={true}
								disabled={false}
							/>
						);
					}}
				/>
				<Button type={"submit"}>Speichern</Button>
			</form>
		</Card>
	);
});
