/**
 * @generated SignedSource<<729525e974b3348428b57d918d0f5992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createLicensesButton_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"createLicensesForm_QueryFragment">;
  readonly " $fragmentType": "createLicensesButton_QueryFragment";
};
export type createLicensesButton_QueryFragment$key = {
  readonly " $data"?: createLicensesButton_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createLicensesButton_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createLicensesButton_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "createLicensesForm_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "267c6be69f92b4182ff8b030d27aedf5";

export default node;
