/**
 * @generated SignedSource<<e2bab9549c9ff90f4ea037b7a391b0a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageIdColumn_DiscountCodeUsageFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "UsageIdColumn_DiscountCodeUsageFragment";
};
export type UsageIdColumn_DiscountCodeUsageFragment$key = {
  readonly " $data"?: UsageIdColumn_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageIdColumn_DiscountCodeUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageIdColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};

(node as any).hash = "5c7cf088bb5fa87dfa30519e43aa2794";

export default node;
