import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment editCoachAppointmentsTable_AvailabilityQueryFragment on Query
	@refetchable(queryName: "editCoachAppointmentsTable_QueryRefetch")
	@argumentDefinitions(
		coachIds: { type: "[ID!]" }
		onlyBooked: { type: "Boolean", defaultValue: true }
		first: { type: "Int", defaultValue: 20 }
		last: { type: "Int" }
		after: { type: "String" }
		before: { type: "String" }
	) {
		Admin {
			Coaching {
				Availabilities(
					first: $first
					after: $after
					before: $before
					last: $last
					filterByCoachIds: $coachIds
					onlyBooked: $onlyBooked
				) @connection(key: "editCoachAppointmentsTable_Availabilities") {
					edges {
						node {
							...editCoachAppointmentsTable_AvailabilityFragment
						}
					}
				}
			}
		}
	}
`;

export const AVAILABILITY_FRAGMENT = graphql`
	fragment editCoachAppointmentsTable_AvailabilityFragment on Availability @inline {
		id
		booking {
			kind
			... on BookedOneToOne {
				participant {
					participationStatus
					user {
						id
						name
					}
				}
			}
			... on BookedForGroup {
				offer {
					setting {
						... on GroupCoachingSetting {
							maxParticipants
						}
					}
				}
				participants {
					participationStatus
					user {
						id
						name
					}
				}
			}
		}
		data {
			startDateTime
			endDateTime
		}
		...editParticipantsButton_AvailabilityFragment
	}
`;
