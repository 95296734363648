import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { SelectUserFormProps, SelectUserFormState } from "./select-user-form.interface";
import { ValidatedField } from "../../../components/ValidatedField";
import { SelectUserField } from "../select-user-field/select-user-field.component";

export const SelectUserForm = ({ onUserSelected, disabled, accountId }: SelectUserFormProps) => {
	const formik = useFormik<SelectUserFormState>({
		initialValues: {},
		isInitialValid: false,
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			userId: Yup.string().min(1).required("Ein Benutzer wird benötigt."),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onUserSelected(data.userId!);
			setSubmitting(false);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
			<ValidatedField<SelectUserFormState, string>
				className="mb-4"
				name={"userId"}
				label={"Wähle einen Benutzer aus"}
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectUserField accountId={accountId} {...renderConfig}></SelectUserField>
				)}
			/>
			<Button
				disabled={!formik.isValid || disabled}
				label="Benutzer hinzufügen"
				type="submit"
			/>
		</form>
	);
};
