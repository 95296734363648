import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFragment, useMutation } from "react-relay";
import { type createEducationalParticipationCertificateRewardButton_AddParticipationCertificateRewardMutation } from "@relay/createEducationalParticipationCertificateRewardButton_AddParticipationCertificateRewardMutation.graphql";
import { type createEducationalParticipationCertificateRewardButton_TreeNodeFragment$key } from "@relay/createEducationalParticipationCertificateRewardButton_TreeNodeFragment.graphql";
import {
	ADD_EDUCATIONAL_PARTICIPATION_CERTIFICATE_REWARD_MUTATION,
	TREE_NODE_FRAGMENT,
} from "@screens/educational-offer/parts/create-educational-participation-certificate-reward-button/create-educational-participation-certificate-reward-button.graphql";
import { type CreateParticipationCertificateRewardButtonProps } from "@screens/educational-offer/parts/create-educational-participation-certificate-reward-button/create-educational-participation-certificate-reward-button.types";

export const CreateEducationalParticipationCertificateRewardButton = ({
	treeNodeFragmentRef,
}: CreateParticipationCertificateRewardButtonProps) => {
	const node =
		useFragment<createEducationalParticipationCertificateRewardButton_TreeNodeFragment$key>(
			TREE_NODE_FRAGMENT,
			treeNodeFragmentRef,
		);
	const [add, isAdding] =
		useMutation<createEducationalParticipationCertificateRewardButton_AddParticipationCertificateRewardMutation>(
			ADD_EDUCATIONAL_PARTICIPATION_CERTIFICATE_REWARD_MUTATION,
		);
	const handleOnClick = () => {
		add({
			variables: {
				input: {
					contentId: node.id,
					data: {
						html: "",
						variables: [],
						name: "Teilnahme-Zertifikat",
					},
				},
			},
		});
	};

	return (
		<Button
			disabled={isAdding}
			tooltip={"Teilnahme-Zertifikat hinzufügen"}
			icon={EpicIcons.BOOK}
			onClick={handleOnClick}
		/>
	);
};
