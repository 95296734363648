import { styled } from "@styled-system/jsx";

export const H1Span = styled("span", {
	base: {
		textStyle: "h1",
	},
});

export const H2Span = styled("span", {
	base: {
		textStyle: "h2",
	},
});

export const H3Span = styled("span", {
	base: {
		textStyle: "h3",
	},
});

export const H4Span = styled("span", {
	base: {
		textStyle: "h4",
	},
});

export const P1Span = styled("span", {
	base: {
		textStyle: "p1",
	},
});

export const P2Span = styled("span", {
	base: {
		textStyle: "p2",
	},
});

export const P2Anchor = styled("a", {
	base: {
		textStyle: "p2Anchor",
	},
});

export const P3Span = styled("span", {
	base: {
		textStyle: "p3",
	},
});
export const P3Label = styled("label", {
	base: {
		textStyle: "p3",
	},
});
export const P3Anchor = styled("a", {
	base: {
		textStyle: "p3Anchor",
	},
});

export const L1Span = styled("span", {
	base: {
		textStyle: "l1",
	},
});

export const B1Span = styled("span", {
	base: {
		textStyle: "b1",
	},
});
