import { graphql } from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment deleteCoachProfileButton_CoachProfileFragment on CoachProfile {
		id
	}
`;
export const DELETE_COACH_PROFILE_MUTATION = graphql`
	mutation deleteCoachProfileButton_DeleteCoachProfileMutation(
		$input: DeleteCoachProfileInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				deleteCoachProfile(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
