import { useFormik } from "formik";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import {
	type generateCartLinkForm_GenerateCartLinkMutation,
	type GenerateAbsoluteCartLinkInput,
} from "@relay/generateCartLinkForm_GenerateCartLinkMutation.graphql";
import { GENERATE_CART_LINK_MUTATION } from "./generate-cart-link-form.graphql";
import { type GenerateCartLinkFormState } from "./generate-cart-link-form.interface";
import { CartLink, OrderListWithoutControls } from "./generate-cart-link-form.styles";
import { ValidatedField } from "../../../../components/ValidatedField";
import { SelectDiscountCodeFieldDialog } from "../../../../features/discount-codes/select-discount-code-field-dialog";
import { type ProductSelection } from "../../../../features/products/product-selection-form/product-selection-form.interface";
import { AddProductSelectionButton } from "../add-product-selection-button/add-product-selection-button.component";

export const GenerateCartLinkForm = () => {
	const [cartLink, setCartLink] = useState<string | undefined>(undefined);

	const [generateCartLink, isGeneratingCartLink] =
		useMutation<generateCartLinkForm_GenerateCartLinkMutation>(GENERATE_CART_LINK_MUTATION);

	const handleOnClick = () => {
		if (cartLink) {
			void navigator.clipboard.writeText(cartLink);
			toast.success("Link in der Zwischenablage gespeichert!");
		}
	};

	const valuesToInput = (values: GenerateCartLinkFormState): GenerateAbsoluteCartLinkInput => ({
		selectedProducts: values.selectedProducts
			.filter((sp) => sp.product.id)
			.map((sp) => ({
				amount: sp.amount,
				productId: sp.product.id!,
			})),
		selectedDiscountCodes: values.selectedDiscountCodes,
	});

	const formik = useFormik<GenerateCartLinkFormState>({
		initialValues: {
			selectedProducts: [],
			selectedDiscountCodes: [],
		},
		onSubmit: (values, { setSubmitting }) => {
			generateCartLink({
				variables: {
					input: valuesToInput(values),
				},
				onCompleted: (response) => {
					setCartLink(response.Admin.Billing.generateAbsoluteCartLink?.link);
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<div className="flex flex-column p-5">
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<GenerateCartLinkFormState, ProductSelection[]>
					name={"selectedProducts"}
					label={"Produkte"}
					component={({ fieldValue, updateField }) => (
						<>
							<div className="p-buttonset mt-1 w-auto mb-2">
								<AddProductSelectionButton
									onSubmit={(values) => {
										updateField([...(fieldValue ?? []), { ...values }]);
									}}
								/>
							</div>
							<OrderListWithoutControls
								value={fieldValue}
								itemTemplate={(item) => {
									return (
										<div className="flex align-items-center">
											<div>{item.product?.title}</div>
											<div className="ml-2 mr-2">x</div>
											<div>{item.amount}</div>
										</div>
									);
								}}
							/>
						</>
					)}
					formikConfig={formik}
				/>
				<ValidatedField<GenerateCartLinkFormState, string[]>
					name={"selectedDiscountCodes"}
					label={"Rabattcodes"}
					component={({ fieldValue, updateField, isValid, fieldName }) => (
						<>
							<div className="p-buttonset mt-1 w-auto mb-2">
								<SelectDiscountCodeFieldDialog
									fieldValue={undefined}
									updateField={(value) => {
										value?.code &&
											updateField([...(fieldValue ?? []), value?.code]);
									}}
									fieldName={fieldName}
									required={false}
									isValid={isValid}
									disabled={false}
								/>
							</div>
							<OrderListWithoutControls
								value={fieldValue}
								itemTemplate={(item) => {
									return <div className="flex align-items-center">{item}</div>;
								}}
							/>
						</>
					)}
					formikConfig={formik}
				/>
				<Button type="submit" label={"Link erzeugen!"} disabled={isGeneratingCartLink} />
			</form>
			{cartLink && <CartLink onClick={handleOnClick}>{cartLink}</CartLink>}
		</div>
	);
};
