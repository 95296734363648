/**
 * @generated SignedSource<<e6a319adea39ef7e21f7ce3980a9537e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateRandomCodesButton_DiscountActionFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "CreateRandomCodesButton_DiscountActionFragment";
};
export type CreateRandomCodesButton_DiscountActionFragment$key = {
  readonly " $data"?: CreateRandomCodesButton_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateRandomCodesButton_DiscountActionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateRandomCodesButton_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};

(node as any).hash = "a21c82fe2d9afdc64e0c996410040be9";

export default node;
