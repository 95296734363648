/**
 * @generated SignedSource<<96fe5590b0d721e6ca3fcf10cf42ea4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetContractPartnerInConstellationRootExtensionExtensionInput = {
  clientMutationId?: string | null | undefined;
  contractPartner?: string | null | undefined;
  rootId: string;
};
export type setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation$variables = {
  input: SetContractPartnerInConstellationRootExtensionExtensionInput;
};
export type setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setContractPartnerInConstellationRootExtensionExtension: {
        readonly root: {
          readonly structureDefinition: {
            readonly extension?: {
              readonly " $fragmentSpreads": FragmentRefs<"setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment">;
            };
          };
        };
      } | null | undefined;
    };
  };
};
export type setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation = {
  response: setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation$data;
  variables: setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetContractPartnerInConstellationRootExtensionExtensionPayload",
                "kind": "LinkedField",
                "name": "setContractPartnerInConstellationRootExtensionExtension",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "root",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetContractPartnerInConstellationRootExtensionExtensionPayload",
                "kind": "LinkedField",
                "name": "setContractPartnerInConstellationRootExtensionExtension",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "root",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "extension",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ContractPartner",
                                                "kind": "LinkedField",
                                                "name": "contractPartner",
                                                "plural": false,
                                                "selections": [
                                                  (v3/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ContractPartnerData",
                                                    "kind": "LinkedField",
                                                    "name": "data",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "name",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "ConstellationRootExtensionExtension",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "RootExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f14d495e59e0681b5c213a14ce24de9b",
    "id": null,
    "metadata": {},
    "name": "setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation",
    "operationKind": "mutation",
    "text": "mutation setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation(\n  $input: SetContractPartnerInConstellationRootExtensionExtensionInput!\n) {\n  Admin {\n    Tree {\n      setContractPartnerInConstellationRootExtensionExtension(input: $input) {\n        root {\n          structureDefinition {\n            __typename\n            ... on RootStructureDefinition {\n              extension {\n                __typename\n                ...setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment on RootExtensionImpl {\n  extension {\n    __typename\n    ... on ConstellationRootExtensionExtension {\n      contractPartner {\n        id\n        data {\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "305311c723c542bc27fa8ea50d23aff5";

export default node;
