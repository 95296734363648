import { InputSwitch, InputSwitchStatus, RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";

import { type featureTogglesScreen_EditFeatureToggleDataMutation } from "@relay/featureTogglesScreen_EditFeatureToggleDataMutation.graphql";
import {
	type featureTogglesScreen_FeatureToggleFragment$data,
	type featureTogglesScreen_FeatureToggleFragment$key,
} from "@relay/featureTogglesScreen_FeatureToggleFragment.graphql";
import { type featureTogglesScreen_Query } from "@relay/featureTogglesScreen_Query.graphql";
import { type featureTogglesScreen_QueryFragment$key } from "@relay/featureTogglesScreen_QueryFragment.graphql";
import { type featureTogglesScreen_Refetch } from "@relay/featureTogglesScreen_Refetch.graphql";
import {
	EDIT_FEATURE_TOGGLE_DATA_MUTATION,
	FEATURE_TOGGLE_FRAGMENT,
	QUERY,
	QUERY_FRAGMENT,
} from "./feature-toggles.graphql";
import { NoAccess } from "../../components/no-access";
import { BaseScreen } from "../BaseScreen";

export const FeatureTogglesScreen = () => {
	const query = useLazyLoadQuery<featureTogglesScreen_Query>(QUERY, {});

	const isRoot = useHasPermissions("onlyOwnerOfRoot");

	const [editingId, setEditingId] = useState<string>();
	const [editFeatureToggleData, isEditingFeatureToggleData] =
		useMutation<featureTogglesScreen_EditFeatureToggleDataMutation>(
			EDIT_FEATURE_TOGGLE_DATA_MUTATION,
		);

	if (!isRoot) return <NoAccess />;

	const createActiveOnChangeHandler =
		(feature: featureTogglesScreen_FeatureToggleFragment$data) => (checked: boolean) => {
			setEditingId(feature.id);
			editFeatureToggleData({
				variables: {
					input: {
						id: feature.id,
						data: {
							isActive: checked,
							name: feature.data.name,
						},
					},
				},
			});
		};

	return (
		<BaseScreen>
			<h1>Feature Toggles</h1>

			<div className="w-full mt-4">
				<RelayPageDataTable<
					featureTogglesScreen_Refetch,
					featureTogglesScreen_QueryFragment$key,
					featureTogglesScreen_FeatureToggleFragment$key
				>
					queryFragment={QUERY_FRAGMENT}
					parentFragmentRef={query}
					nodeInlineFragment={FEATURE_TOGGLE_FRAGMENT}
					extractConnection={(data) => data.Admin.FeatureToggle.FeatureToggles}
					emptyMessage={"Keine Features Verfügbar"}
				>
					{(Column) => (
						<>
							<Column header="Feature" field="data.name" />
							<Column
								header="Aktiviert"
								field="data.isActive"
								body={(feature) => {
									return (
										<InputSwitch
											checked={feature.data.isActive}
											status={
												editingId === feature.id &&
												isEditingFeatureToggleData
													? InputSwitchStatus.Loading
													: undefined
											}
											onChange={createActiveOnChangeHandler(feature)}
										/>
									);
								}}
							/>
						</>
					)}
				</RelayPageDataTable>
			</div>
		</BaseScreen>
	);
};
