import { RelayPageDataTable } from "@thekeytechnology/epic-ui";
import moment from "moment";
import { useContext } from "react";
import { potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment$key } from "@relay/potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment.graphql";
import { potentialAnalysesTable_QueryFragment$key } from "@relay/potentialAnalysesTable_QueryFragment.graphql";
import { potentialAnalysesTable_Refetch } from "@relay/potentialAnalysesTable_Refetch.graphql";
import { EditPaButton } from "@screens/potential-analysis/parts/edit-pa-button";
import { PotentialAnalysesTableProps } from "@screens/potential-analysis/parts/potential-analyses-table/portential-analyses-table.types";
import {
	POTENTIAL_ANALYSIS_V2_FRAGMENT,
	QUERY_FRAGMENT,
} from "@screens/potential-analysis/parts/potential-analyses-table/potential-analyses-table.graphql";
import { FlexWrapper } from "@screens/potential-analysis/parts/potential-analyses-table/potential-analysis-table.styles";
import { PotentialAnalysisContext } from "@screens/potential-analysis/potential-analysis.context";

export const PotentialAnalysesTable = ({ queryFragmentRef }: PotentialAnalysesTableProps) => {
	const { setConnectionId } = useContext(PotentialAnalysisContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	const formatDateTime = (str: string): string => {
		return moment(str.replace("[UTC]", "")).format("DD/MM/YYYY");
	};
	return (
		<RelayPageDataTable<
			potentialAnalysesTable_Refetch,
			potentialAnalysesTable_QueryFragment$key,
			potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={POTENTIAL_ANALYSIS_V2_FRAGMENT}
			extractConnection={(data) => data.Admin.AvgsPotentialAnalysis.AVGSPotentialAnalysis}
			rowsCount={20}
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					<Column
						header={"Für (Name/Email)"}
						body={(analysis) =>
							analysis.client?.client?.name ?? analysis.client?.email ?? ""
						}
					/>
					<Column header={"Erstellt Am"} body={(pa) => formatDateTime(pa.createdAt)} />
					<Column header={"Coach"} body={(analysis) => analysis.coach?.name} />
					<Column
						header={"Vertretung"}
						body={(analysis) => analysis.replacementCoach?.name}
					/>
					<Column
						header={"Abgeschloßen"}
						body={(analysis) => (analysis.isFinished ? "Ja" : "Nein")}
					/>

					<Column
						header="Aktionen"
						style={{ width: "15%" }}
						body={(pa) => (
							<FlexWrapper>
								<EditPaButton potentialAnalysisV2FragmentRef={pa} />
							</FlexWrapper>
						)}
					/>
				</>
			)}
		</RelayPageDataTable>
	);
};
