import React, { useContext } from "react";
import { useLazyLoadQuery } from "react-relay";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type reportsScreen_Query } from "@relay/reportsScreen_Query.graphql";
import { ReportsTable } from "@screens/reports/parts/reports-table";
import { ReportsTableFilters } from "@screens/reports/parts/reports-table-filters";
import { ReportsContext, ReportsContextProvider } from "@screens/reports/reports.context";
import { QUERY, reportsPerPage } from "@screens/reports/reports.graphql";

export const ReportsScreen = () => {
	const {
		filters: { ...filters },
	} = useContext(ReportsContext);
	const reports = useLazyLoadQuery<reportsScreen_Query>(
		QUERY,
		{
			...filters,
			first: reportsPerPage,
		},
		{ fetchPolicy: "network-only" },
	);

	const canRead = useHasPermissions(["UserInAccountPermission_ReportAdmin_Read"]);

	const canModify = useHasPermissions(["UserInAccountPermission_ReportAdmin_Modify"]);

	if (!canRead) return <NoAccess />;
	return (
		<ReportsContextProvider>
			<h2 className="mb-3 text-center">Reports</h2>
			<ReportsTableFilters />
			<ReportsTable
				reportsFragmentRef={reports}
				canModifyReports={canModify}
				canRead={canRead}
			/>
		</ReportsContextProvider>
	);
};
