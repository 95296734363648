/**
 * @generated SignedSource<<0d863048690968c0793ddd1cb47b4854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDiscountCodeInput = {
  clientMutationId?: string | null | undefined;
  discountCodeId: string;
  newCode: string;
};
export type DiscountCodeForm_EditCodeMutation$variables = {
  input: EditDiscountCodeInput;
};
export type DiscountCodeForm_EditCodeMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly editDiscountCode: {
        readonly discountCode: {
          readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeForm_DiscountCodeFragment">;
        };
      } | null | undefined;
    };
  };
};
export type DiscountCodeForm_EditCodeMutation = {
  response: DiscountCodeForm_EditCodeMutation$data;
  variables: DiscountCodeForm_EditCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountCodeForm_EditCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDiscountCodePayload",
                "kind": "LinkedField",
                "name": "editDiscountCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCode",
                    "kind": "LinkedField",
                    "name": "discountCode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DiscountCodeForm_DiscountCodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscountCodeForm_EditCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDiscountCodePayload",
                "kind": "LinkedField",
                "name": "editDiscountCode",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCode",
                    "kind": "LinkedField",
                    "name": "discountCode",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcee8db14d0149f9bb9380dd7f0dc717",
    "id": null,
    "metadata": {},
    "name": "DiscountCodeForm_EditCodeMutation",
    "operationKind": "mutation",
    "text": "mutation DiscountCodeForm_EditCodeMutation(\n  $input: EditDiscountCodeInput!\n) {\n  Admin {\n    Billing {\n      editDiscountCode(input: $input) {\n        discountCode {\n          ...DiscountCodeForm_DiscountCodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment DiscountCodeForm_DiscountCodeFragment on DiscountCode {\n  id\n  code\n}\n"
  }
};
})();

(node as any).hash = "46b18938d5eef6f66f530b5c99c0996c";

export default node;
