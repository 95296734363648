import { graphql } from "babel-plugin-relay/macro";

export const WORD_FRAGMENT = graphql`
	fragment editWordButton_WordFragment on Word {
		id
		word
		translation
		explanation
	}
`;

export const ADD_WORD_TO_PACKAGE_MUTATION = graphql`
	mutation editWordButton_AddWordToPackageMutation($input: AddWordToPackageInput!) {
		Admin {
			Vocab {
				addWordToPackage(input: $input) {
					wordPackage {
						...wordsTable_WordPackageFragment
					}
				}
			}
		}
	}
`;

export const EDIT_WORD_MUTATION = graphql`
	mutation editWordButton_EditWordMutation($input: EditWordInput!) {
		Admin {
			Vocab {
				editWord(input: $input) {
					wordPackage {
						...wordsTable_WordPackageFragment
					}
				}
			}
		}
	}
`;
