import { Button, ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { type EditAccountFormProps, type EditAccountFormState } from "./edit-account-form.types";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";

export const EditAccountForm = ({ loading, initialValues, onSubmit }: EditAccountFormProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const isEditing = arrayOfEditedForms.length > 0;
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] =
		useCallbackPrompt(isEditing);

	const formId = "EditAccountForm";
	const formik = useFormik<EditAccountFormState>({
		initialValues: {
			name: initialValues?.name,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Kontoname wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditAccountFormState, string>
					name={"name"}
					label={"Kontoname"}
					placeholder="Kontonamen eingeben..."
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<Button
					disabled={loading ?? !isEditing}
					label="Speichern"
					type={ButtonType.Submit}
				/>
			</form>
		</>
	);
};
