import { graphql } from "babel-plugin-relay/macro";

export const WORD_PACKAGE_CONNECTION_FRAGMENT = graphql`
	fragment createWordPackageRewardButton_WordPackageConnectionFragment on WordPackageConnection {
		edges {
			node {
				id
			}
		}
	}
`;
export const TREE_NODE_FRAGMENT = graphql`
	fragment createWordPackageRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
					... on WordPackageReward {
						data {
							wordPackage {
								id
								name
							}
						}
					}
				}
			}
		}
	}
`;

export const ADD_WORD_PACKAGE_REWARD_MUTATION = graphql`
	mutation createWordPackageRewardButton_AddWordPackageRewardMutation(
		$input: AddWordPackageRewardInput!
	) {
		Admin {
			Tree {
				addWordPackageReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
