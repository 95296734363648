import { graphql } from "babel-plugin-relay/macro";

export const COACH_FEEDBACK_FRAGMENT = graphql`
	fragment showCoachFeedbacksTable_CoachFeedbackFragment on CoachFeedback @inline {
		data {
			score
			comment
		}
		id
		feedbackGiver {
			name
		}
		createdAt
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment showCoachFeedbacksTable_QueryFragment on Query
	@refetchable(queryName: "showCoachFeedbacksTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		id: { type: "ID!" }
	) {
		node(id: $id) {
			... on CoachProfile {
				feedbacks(after: $after, first: $first)
					@connection(key: "showCoachFeedbacksTable_feedbacks") {
					edges {
						node {
							...showCoachFeedbacksTable_CoachFeedbackFragment
						}
					}
				}
			}
		}
	}
`;
