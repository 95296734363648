/**
 * @generated SignedSource<<c726580601de3f41f4a926b01a646e9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment$data = {
  readonly data: {
    readonly name?: string;
  };
  readonly id: string;
  readonly " $fragmentType": "editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment";
};
export type editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment$key = {
  readonly " $data"?: editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editMicroLearningLicenseDefinitionButton_LicenseDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "type": "MicroLearningLicenseDefinitionData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LicenseDefinition",
  "abstractKey": null
};

(node as any).hash = "4b2b438cf3577c3a8db022f1d504d3e4";

export default node;
