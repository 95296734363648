import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ResidenceDropdown } from "@components/residence-dropdown";
import { SalutationDropdown, SalutationType } from "@components/salutation-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { CountryCode } from "@relay/accountEditBaseDataScreen_EditBusinessBaseDataMutation.graphql";
import { QUERY_FRAGMENT } from "./private-base-data-form.graphql";
import {
	ColSpan2,
	ColSpan4,
	InputGroupWrapper,
	ShortInputsRow,
	Wrapper,
} from "./private-base-data-form.styles";
import type {
	PrivateBaseDataRef,
	PrivateBaseDataProps,
	PrivateBaseDataFormState,
} from "./private-base-data-form.types";

export const PrivateBaseDataForm = forwardRef<PrivateBaseDataRef, PrivateBaseDataProps>(
	function PrivateBaseDataForm({ baseDataFragmentRef, onSubmit }, ref) {
		const query = useFragment(QUERY_FRAGMENT, baseDataFragmentRef ?? null);

		const form = useFormik<PrivateBaseDataFormState>({
			initialValues: {
				salutation: query?.salutation ?? undefined,
				title: query?.title ?? "",
				firstName: query?.firstName ?? "",
				lastName: query?.lastName ?? "",
				street: query?.street ?? "",
				houseNumber: query?.houseNumber ?? "",
				postalCode: query?.postalCode ?? "",
				city: query?.city ?? "",
				countryCode: query?.countryCode ?? "DE",
				phoneNumber: query?.phoneNumber ?? "",
			},
			validateOnChange: false,
			validateOnBlur: false,
			validationSchema: Yup.object().shape({
				salutation: Yup.string().required("Bitte wähle eine Anrede aus."),
				title: Yup.string().optional(),
				firstName: Yup.string().required("Bitte gib einen Vornamen ein."),
				lastName: Yup.string().required("Bitte gib einen Nachnamen ein."),
				street: Yup.string().required("Bitte gib eine Straße ein."),
				houseNumber: Yup.string().required("Bitte gib eine Hausnummer ein."),
				city: Yup.string().required("Bitte gib einen Ort ein."),
				postalCode: Yup.string()
					.when("countryCode", {
						is: (value: string) => value === "CH" || value === "AT",
						then: (schema) =>
							schema
								.length(4, "Bitte gib eine gültige Postleitzahl ein")
								.matches(/^[0-9]{4}/, "Bitte gib eine gültige Postleitzahl ein"),
					})
					.when("countryCode", {
						is: "DE",
						then: (schema) =>
							schema
								.length(5, "Bitte gib eine gültige Postleitzahl ein")
								.matches(/^[0-9]{5}/, "Bitte gib eine gültige Postleitzahl ein"),
					})
					.required("Bitte gib eine Postleitzahl ein."),
				countryCode: Yup.string().required("Bitte wähle ein Land aus."),
				phoneNumber: Yup.string().optional(),
			}),
			onSubmit: (values) => {
				onSubmit?.(values);
			},
		});

		useImperativeHandle(ref, () => ({
			submit: form.submitForm,
			validate: () => form.validateForm().then((errors) => !errors),
		}));

		return (
			<Wrapper>
				<InputGroupWrapper>
					<ValidatedField<PrivateBaseDataFormState, SalutationType>
						formikConfig={form}
						name="salutation"
						label="Anrede*"
						placeholder="Anrede"
						component={SalutationDropdown}
					/>
					<ValidatedField<PrivateBaseDataFormState, string>
						formikConfig={form}
						name="title"
						label="Titel"
						placeholder="..."
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<PrivateBaseDataFormState, string>
						formikConfig={form}
						name="firstName"
						label={"Name*"}
						placeholder="..."
						component={DefaultTextFieldComponent}
					/>
					<ValidatedField<PrivateBaseDataFormState, string>
						formikConfig={form}
						name="lastName"
						label={"Nachname*"}
						placeholder="..."
						component={DefaultTextFieldComponent}
					/>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedField<PrivateBaseDataFormState, string>
								formikConfig={form}
								name="street"
								label="Straße*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedField<PrivateBaseDataFormState, string>
								formikConfig={form}
								name="houseNumber"
								label="Hausnummer*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ShortInputsRow>
						<ColSpan4>
							<ValidatedField<PrivateBaseDataFormState, string>
								formikConfig={form}
								name="city"
								label="Ort/Stadt*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan4>
						<ColSpan2>
							<ValidatedField<PrivateBaseDataFormState, string>
								formikConfig={form}
								name="postalCode"
								label="PLZ*"
								placeholder="..."
								component={DefaultTextFieldComponent}
							/>
						</ColSpan2>
					</ShortInputsRow>
					<ValidatedField<PrivateBaseDataFormState, CountryCode>
						formikConfig={form}
						name="countryCode"
						label="Land"
						component={ResidenceDropdown}
					/>
					<ValidatedField<PrivateBaseDataFormState, string>
						formikConfig={form}
						name="phoneNumber"
						label="Telefonnummer"
						placeholder="..."
						component={DefaultTextFieldComponent}
					/>
				</InputGroupWrapper>
			</Wrapper>
		);
	},
);
