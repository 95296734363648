import styled from "styled-components";
import tw from "twin.macro";
import { brandStrong80 } from "@themes/colors";

export const TitleWrapper = styled.div`
	mark {
		background-color: ${brandStrong80};
	}
	${tw`
    tw-flex
	tw-flex-col
	tw-gap-4`}
`;

export const Wrapper = tw.div`
	tw-flex
	tw-flex-col
	tw-gap-16
`;
