import styled from "styled-components";
import tw from "twin.macro";

export const TextWrapper = styled.div`
	max-width: 300px;
	overflow: hidden;
	text-verflow: ellipsis;
`;
export const ColumnWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const ActionsWrapper = styled.div`
	display: flex;
	gap: 4px;
`;

export const PaginationWrapper = tw.div`
	tw-flex 
	tw-justify-center 
	tw-items-center
	tw-mt-32
	tw-gap-8
`;

export const AssociatedCoachingsWrapper = tw.div`tw-max-w-[12.5rem] tw-overflow-x-hidden tw-break-keep tw-whitespace-nowrap tw-text-ellipsis`;
