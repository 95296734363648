import { Paths } from "@routes/paths";
import { VideoSourcesScreen } from "@screens/video-sources/video-sources.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const VideoSourcesRoutes: RouteDefinition[] = [
	{
		path: Paths.videoSources.path,
		element: <VideoSourcesScreen />,
		requiredPermissions: ["UserInAccountPermission_VimeoVideoSourceAdmin_Read"],
	},
];
