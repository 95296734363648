import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import { RegistrationFormProps, RegistrationFormState } from "./registration-form.interface";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";

export const RegistrationForm = ({ onUserSubmitted }: RegistrationFormProps) => {
	const formik = useFormik<RegistrationFormState>({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			email2: "",
			password: "",
			password2: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required("Vorname wird benötigt."),
			lastName: Yup.string().required("Nachname wird benötigt."),
			email: Yup.string()
				.email("Bitte eine gültige E-Mail-Adresse eingeben.")
				.required("E-Mail-Adresse wird benötigt."),

			email2: Yup.string()
				.email("Bitte eine gültige E-Mail-Adresse eingeben.")
				.oneOf([Yup.ref("email"), undefined], "E-Mail-Adressen müssen übereinstimmen."),

			password: Yup.string()
				.min(8, "Das Passwort muss mindestens 8 Zeichen haben.")
				.required("Passwort wird benötigt."),
			password2: Yup.string().oneOf(
				[Yup.ref("password"), undefined],
				"Passwörter müssen übereinstimmen.",
			),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onUserSubmitted(data, () => {
				setSubmitting(false);
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
			<ValidatedField<RegistrationFormState, string>
				className="mb-4"
				name={"firstName"}
				label={"Vorname"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<RegistrationFormState, string>
				className="mb-4"
				name={"lastName"}
				label={"Nachname"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<RegistrationFormState, string>
				className="mb-4"
				name={"email"}
				label={"E-Mail"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>

			<ValidatedField<RegistrationFormState, string>
				className="mb-4"
				name={"email2"}
				label={"E-Mail wiederholen"}
				required={true}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>

			<ValidatedField<RegistrationFormState, string>
				className="mb-4"
				name={"password"}
				label={"Passwort (min 8 Zeichen)"}
				required={true}
				formikConfig={formik}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<Password
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onChange={(e) => updateField(e.target.value)}
							toggleMask
							feedback={false}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
			/>

			<ValidatedField<RegistrationFormState, string>
				className="mb-6"
				name={"password2"}
				label={"Passwort wiederholen"}
				required={true}
				formikConfig={formik}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<Password
							id={fieldName}
							name={fieldName}
							value={fieldValue}
							onChange={(e) => updateField(e.target.value)}
							toggleMask
							feedback={false}
							className={classNames({ "p-invalid": !isValid })}
						/>
					);
				}}
			/>
			<Button
				disabled={formik.isSubmitting}
				type="submit"
				label="Benutzer erstellen und hinzufügen"
			/>
		</form>
	);
};
