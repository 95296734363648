/**
 * @generated SignedSource<<cc2347473a76cc8a6bbd108c6bcdc4ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddOnlyAZAVAccountVisibilityCoachingConfigInput = {
  clientMutationId?: string | null | undefined;
  coachingOfferId: string;
};
export type visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation$variables = {
  input: AddOnlyAZAVAccountVisibilityCoachingConfigInput;
};
export type visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation$data = {
  readonly Admin: {
    readonly Coaching: {
      readonly addOnlyAZAVAccountVisibilityCoachingConfig: {
        readonly coachingOffer: {
          readonly " $fragmentSpreads": FragmentRefs<"visibilityCoachingOfferConfigEditor_CoachingOfferFragment">;
        };
      } | null | undefined;
    };
  };
};
export type visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation = {
  response: visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation$data;
  variables: visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyAZAVAccountVisibilityCoachingConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlyAZAVAccountVisibilityCoachingConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoachingOffer",
                    "kind": "LinkedField",
                    "name": "coachingOffer",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "visibilityCoachingOfferConfigEditor_CoachingOfferFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CoachingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Coaching",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOnlyAZAVAccountVisibilityCoachingConfigPayload",
                "kind": "LinkedField",
                "name": "addOnlyAZAVAccountVisibilityCoachingConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoachingOffer",
                    "kind": "LinkedField",
                    "name": "coachingOffer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "visibilityConfigs",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "configType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0fb7edfb7346ef6303d584bd40ac9e36",
    "id": null,
    "metadata": {},
    "name": "visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation",
    "operationKind": "mutation",
    "text": "mutation visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation(\n  $input: AddOnlyAZAVAccountVisibilityCoachingConfigInput!\n) {\n  Admin {\n    Coaching {\n      addOnlyAZAVAccountVisibilityCoachingConfig(input: $input) {\n        coachingOffer {\n          ...visibilityCoachingOfferConfigEditor_CoachingOfferFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment visibilityCoachingOfferConfigEditor_CoachingOfferFragment on CoachingOffer {\n  id\n  visibilityConfigs {\n    __typename\n    id\n    configType\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a40e35b142b33687bf26af512eeaad5";

export default node;
