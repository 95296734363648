import { type Gender } from "@relay/editBaseDataButton_EditBaseDataMutation.graphql";
import { type EditBaseDataFormState } from "./edit-base-data-form.types";

export const GENDER_DROPDOWN_OPTIONS: Array<{ label: string; value: Gender }> = [
	{
		label: "Mann",
		value: "Male",
	},
	{
		label: "Frau",
		value: "Female",
	},
	{
		label: "Divers",
		value: "Diverse",
	},
	{
		label: "Nicht Definiert",
		value: "NotDefined",
	},
];

export const LABELS: Record<keyof EditBaseDataFormState, string> = {
	nationality: "Nationalität",
	birthPlace: "Geburtsort",
	gender: "Gender",
	birthDate: "Geburtstag",
	address: "Adresse",
};

export const PLACEHOLDERS: Omit<
	Record<keyof EditBaseDataFormState, string>,
	"address" | "gender"
> = {
	nationality: "DE",
	birthPlace: "Berlin",
	birthDate: "01/02/2000",
};
export const ADDRESS_PLACEHOLDERS: Record<keyof EditBaseDataFormState["address"], string> = {
	city: "Stadt",
	postalCode: "Postleitzahl",
	lineOne: "Straße & Hausnummer",
	lineTwo: "Zusatz",
};
