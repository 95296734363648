import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { DELETE_TAG_MUTATION } from "./delete-academies-tag-button.graphql";
import { DeleteAcademiesTagButtonProps } from "./delete-academies-tag-button.types";
import { deleteAcademiesTagButton_DeleteTagMutation } from "../../../../__generated__/deleteAcademiesTagButton_DeleteTagMutation.graphql";

export const DeleteAcademiesTagButton = ({
	className,
	id,
	connectionId,
	disabled,
	onComplete,
}: DeleteAcademiesTagButtonProps) => {
	const [deleteTag, isDeletingTag] =
		useMutation<deleteAcademiesTagButton_DeleteTagMutation>(DELETE_TAG_MUTATION);

	const handleOnClick = () => {
		deleteTag({
			variables: {
				input: {
					id: id,
				},
				connections: [connectionId],
			},
			onCompleted: onComplete,
		});
	};

	return (
		<Button
			icon={"pi pi-trash"}
			className={className}
			disabled={isDeletingTag || disabled}
			onClick={handleOnClick}
		/>
	);
};
