/**
 * @generated SignedSource<<1c37cbbcae400759b7ea308792c00f06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type videoSourcesTable_Vimeo_VimeoVideoSourceFragment$data = {
  readonly accessToken: string;
  readonly id: string;
  readonly isDefault: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment">;
  readonly " $fragmentType": "videoSourcesTable_Vimeo_VimeoVideoSourceFragment";
};
export type videoSourcesTable_Vimeo_VimeoVideoSourceFragment$key = {
  readonly " $data"?: videoSourcesTable_Vimeo_VimeoVideoSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"videoSourcesTable_Vimeo_VimeoVideoSourceFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "videoSourcesTable_Vimeo_VimeoVideoSourceFragment"
};

(node as any).hash = "5aeb0f9926cb69e540ab915f152dc9e1";

export default node;
