/**
 * @generated SignedSource<<d642a580eee407e0f146448796af4f1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type featureTogglesScreen_FeatureToggleFragment$data = {
  readonly data: {
    readonly isActive: boolean;
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "featureTogglesScreen_FeatureToggleFragment";
};
export type featureTogglesScreen_FeatureToggleFragment$key = {
  readonly " $data"?: featureTogglesScreen_FeatureToggleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"featureTogglesScreen_FeatureToggleFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "featureTogglesScreen_FeatureToggleFragment"
};

(node as any).hash = "98c4cf0909a8e7367f5e4930c1133fc6";

export default node;
