import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { CoachAppointmentsEditScreen } from ".";

export const CoachAppointmentsRoutes: RouteDefinition[] = [
	{
		path: Paths.coachAppointments.withIdPlaceholder().edit.path,
		element: <CoachAppointmentsEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_CoachingAvailabilityAdmin_ModifyAvailability",
		],
	},
];
