/**
 * @generated SignedSource<<bcf6c4311d4c8d4a468ee3e77083503a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type invoiceProviderLink_CreatedInvoiceDataFragment$data = {
  readonly invoiceId: string;
  readonly invoiceNumber: string;
  readonly " $fragmentType": "invoiceProviderLink_CreatedInvoiceDataFragment";
};
export type invoiceProviderLink_CreatedInvoiceDataFragment$key = {
  readonly " $data"?: invoiceProviderLink_CreatedInvoiceDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"invoiceProviderLink_CreatedInvoiceDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "invoiceProviderLink_CreatedInvoiceDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    }
  ],
  "type": "CreatedInvoiceData",
  "abstractKey": null
};

(node as any).hash = "61cf4fef7bd94e2a2d1e2ed661c0153b";

export default node;
