/**
 * @generated SignedSource<<9bb33f1de91b1ab2eadf0e3390a5c7da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAvailabilityScheduleButton_CoachProfileFragment$data = {
  readonly coach: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleForm_CoachProfileFragment">;
  readonly " $fragmentType": "editAvailabilityScheduleButton_CoachProfileFragment";
};
export type editAvailabilityScheduleButton_CoachProfileFragment$key = {
  readonly " $data"?: editAvailabilityScheduleButton_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleButton_CoachProfileFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAvailabilityScheduleButton_CoachProfileFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editAvailabilityScheduleForm_CoachProfileFragment"
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};
})();

(node as any).hash = "95a3706ee308a3e164d9084e2824c38c";

export default node;
