/**
 * @generated SignedSource<<04af158f12781b104ee2e4ba5f69622a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "ParticipationCertificate" | "WordPackage" | "WordProficiencies";
import { FragmentRefs } from "relay-runtime";
export type createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly rewards?: ReadonlyArray<{
      readonly id: string;
      readonly kind: RewardKind;
    }>;
  };
  readonly " $fragmentType": "createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment";
};
export type createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment$key = {
  readonly " $data"?: createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "3716380530b05944aa21d7d01e08bddb";

export default node;
