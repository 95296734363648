import { authMiddleware } from "react-relay-network-modern";
import { logout, refreshLogin, selectJwtClaimData } from "../store/slices/AuthSlice";
import { ReduxStore } from "../store/store.redux";

export const JwtMiddleware = authMiddleware({
	allowEmptyToken: true,
	token: () => ReduxStore.getState().auth.loginData?.accessToken || "",
	tokenRefreshPromise: async () => {
		const state = ReduxStore.getState();
		const accountId = selectJwtClaimData(state)?.accountId;
		return await fetch(`${process.env.REACT_APP_API_BASE}/api/refresh-token`, {
			method: "POST",
			headers: new Headers({ "content-type": "application/json" }),
			body: JSON.stringify({
				refreshToken: state.auth.loginData?.refreshToken,
				accountId: accountId,
			}),
		})
			.then(async (res) => await res.json())
			.then((json) => {
				const token = json.accessToken;
				ReduxStore.dispatch(refreshLogin({ loginData: json }));
				return token;
			})
			.catch(() => {
				ReduxStore.dispatch(logout());
			});
	},
});
