/**
 * @generated SignedSource<<54519549058ea12d71018f1e13d869b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type potentialAnalysesTable_Refetch$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  first?: number | null | undefined;
  last?: number | null | undefined;
};
export type potentialAnalysesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"potentialAnalysesTable_QueryFragment">;
};
export type potentialAnalysesTable_Refetch = {
  response: potentialAnalysesTable_Refetch$data;
  variables: potentialAnalysesTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "potentialAnalysesTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "potentialAnalysesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "potentialAnalysesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AvgsPotentialAnalysisAdminSchema",
            "kind": "LinkedField",
            "name": "AvgsPotentialAnalysis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PotentialAnalysisConnection",
                "kind": "LinkedField",
                "name": "AVGSPotentialAnalysis",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PotentialAnalysisEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AVGSPotentialAnalysis_PotentialAnalysis",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isFinished",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "coach",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "client",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "client",
                                    "plural": false,
                                    "selections": (v4/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "AVGSPotentialAnalysis_AcceptedForUser",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "type": "AVGSPotentialAnalysis_InvitedForUser",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "replacementCoach",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AVGSPotentialAnalysis_Module",
                            "kind": "LinkedField",
                            "name": "modules",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "moduleId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "moduleText",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AVGSPotentialAnalysis_Dimension",
                                "kind": "LinkedField",
                                "name": "dimensions",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dimensionId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dimensionText",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "potentialAnalysesTable_AVGSPotentialAnalysis",
                "kind": "LinkedHandle",
                "name": "AVGSPotentialAnalysis"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "599d7842b6ce622283dfbf49a2520e74",
    "id": null,
    "metadata": {},
    "name": "potentialAnalysesTable_Refetch",
    "operationKind": "query",
    "text": "query potentialAnalysesTable_Refetch(\n  $after: String\n  $before: String\n  $first: Int = 20\n  $last: Int\n) {\n  ...potentialAnalysesTable_QueryFragment_pbnwq\n}\n\nfragment editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis {\n  id\n}\n\nfragment potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis {\n  id\n  createdAt\n  isFinished\n  coach {\n    id\n    name\n    email\n  }\n  client {\n    __typename\n    kind\n    ... on AVGSPotentialAnalysis_AcceptedForUser {\n      client {\n        id\n        name\n        email\n      }\n    }\n    ... on AVGSPotentialAnalysis_InvitedForUser {\n      email\n    }\n  }\n  replacementCoach {\n    id\n    name\n    email\n  }\n  modules {\n    moduleId\n    moduleText\n    dimensions {\n      dimensionId\n      dimensionText\n    }\n  }\n  ...editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment\n}\n\nfragment potentialAnalysesTable_QueryFragment_pbnwq on Query {\n  Admin {\n    AvgsPotentialAnalysis {\n      AVGSPotentialAnalysis(first: $first, after: $after, last: $last, before: $before) {\n        edges {\n          node {\n            id\n            ...potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f30bf20b58450827e44f32d4e18faa11";

export default node;
