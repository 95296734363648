import { Paths } from "@routes/paths";
import type { RouteDefinition } from "@routes/route-definition";
import { PotentialAnalysisEdit } from "@screens/potential-analysis-edit/potential-analysis-edit.screen";

export const PotentialAnalysisEditRoutes: RouteDefinition[] = [
	{
		path: Paths.potentialAnalysis.withIdPlaceholder().edit.path,
		element: <PotentialAnalysisEdit />,
		requiredPermissions: "logged-in",
	},
];
