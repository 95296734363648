import { Suspense } from "react";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { WordPackagesTable } from "./parts/word-packages-table";
import { Card } from "../../components/card";
import { NoAccess } from "../../components/no-access";
import { BaseScreen } from "../BaseScreen";

export const WordPackagesScreen = () => {
	const canRead = useHasPermissions(["UserInAccountPermission_VocabAdmin_ReadWordPackages"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<Card
				header={
					<div className="flex p-3 align-items-center justify-content-between card-flat">
						<h1 className="mt-0 mr-3 mb-0 ml-0">Wortpakete</h1>
					</div>
				}
			>
				<Suspense fallback={<div>Lade...</div>}>
					<WordPackagesTable />
				</Suspense>
			</Card>
		</BaseScreen>
	);
};
