import { type PassContentConfigType } from "@relay/passContentConfigEditor_TreeNodeFragment.graphql";
import { type RestartIfFailedContentConfigType } from "@relay/RestartIfFailedContentConfigEditor_TreeNodeFragment.graphql";
import { type RestartIfPassedContentConfigType } from "@relay/RestartIfPassedContentConfigEditor_TreeNodeFragment.graphql";
import { type StartContentConfigType } from "@relay/startContentConfigEditor_TreeNodeFragment.graphql";
import { type StartTreeConfigType } from "@relay/StartTreeConfigEditor_TreeNodeFragment.graphql";
import { type VisibilityCoachingConfigType } from "@relay/visibilityCoachingOfferConfigEditor_CoachingOfferFragment.graphql";
import { type VisibilityTreeConfigType } from "@relay/visibilityTreeConfigEditor_TreeNodeFragment.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "./tree";

export type ConfigsLocalization = Partial<
	Record<
		| PassContentConfigType
		| RestartIfFailedContentConfigType
		| RestartIfPassedContentConfigType
		| VisibilityTreeConfigType
		| VisibilityCoachingConfigType
		| StartTreeConfigType
		| StartContentConfigType,
		string
	>
>;

export const FLOW_CONFIG_TRANSLATIONS = {
	FlowELearningContent_SimplyContinue: "Ohne Feedback zum nächsten Element",
	FlowELearningContent_DirectFeedback: "Antwort kann nach X Versuchen angezeigt werden",
	FlowELearningContent_ShowAnswersInBetween: "currently not implemented",
};

export const CONFIGS_TRANSLATIONS: ConfigsLocalization = {
	StartContent_CanIfUnlocked: "Pay-Wall",
	PassContent_Always: "Bestehe unabhängig von der Anzahl korrekter Antworten",
	RestartIfFailedContent_CanNot: `${TREE_I18N_MAP(
		TREE_I18N_KEY.thisContentNodeUppercase,
	)} ist nicht wiederholbar falls es nicht bestanden wurde`,
	RestartIfFailedContent_BlockTemporarily: "Blockiere temporär für X Tage",
	RestartIfFailedContent_BlockPermanently: "Blockiere permanent nach X Fehlversuchen",
	RestartIfPassedContent_Can: `${TREE_I18N_MAP(
		TREE_I18N_KEY.thisContentNodeUppercase,
	)} ist nach dem Bestehen wiederholbar`,
	RestartIfPassedContent_CanNot: `${TREE_I18N_MAP(
		TREE_I18N_KEY.thisContentNodeUppercase,
	)} ist nach dem Bestehen nicht wiederholbar`,
	VisibilityTree_Hide: `${TREE_I18N_MAP(TREE_I18N_KEY.thisContentNodeUppercase)} verstecken`,
	VisibilityTree_OnlyAdmins: `${TREE_I18N_MAP(TREE_I18N_KEY.tree)} verstecken (außer für Admins)`,
	VisibilityTree_OnlyEditors: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Redakteure)`,
	VisibilityTree_OnlyBusinessAccount: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Firmen-Konten)`,
	VisibilityTree_OnlyIfTreeState: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für Nutzer mit aktivem Lernstatus)`,
	VisibilityTree_NotAfterDateTime: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (ab einem bestimmten Datum)`,
	VisibilityTree_AfterDateTime: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} anzeigen (ab einem bestimmten Datum)`,
	VisibilityTree_OnlyAZAVAccount: `${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} verstecken (außer für AZAV Konten)`,
	StartTree_CanNot: "Kann nicht starten",
	StartContent_CanAfterStartingTree: "7 Tage nach Start",
	StartContent_CanIfInTreeFlow: "Starten, falls im Learn-Flow",
	PassContent_IHKFlow: "IHK-Flow",
	VisibilityCoaching_Hide: "Angebot verstecken",
	VisibilityCoaching_OnlyBusinessAccount: "Angebot verstecken (außer für Firmen-Konten)",
	VisibilityCoaching_OnlyWithLicense: "Nur mit Lizenz sichtbar (benutzt und unbenutzt)",
	VisibilityCoaching_OnlyAZAVAccount: "Angebot verstecken (außer für AZAV Konten)",
	...FLOW_CONFIG_TRANSLATIONS,
};
