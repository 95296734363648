import { css } from "@styled-system/css";
import { flex } from "@styled-system/patterns";

export const inputWrapperClass = flex({
	alignItems: "center",
	justifyContent: "space-between",
});
export const videoDisplayWrapper = css({
	py: "16",
});
