export function distinctBy<T, K>(arr: T[], keyFunction: (i: T) => K): T[] {
	const knownKeys: K[] = [];
	return arr.filter((x) => {
		const key = keyFunction(x);
		if (knownKeys.includes(key)) {
			return false;
		} else {
			knownKeys.push(key);
			return true;
		}
	});
}
