/**
 * @generated SignedSource<<5a8f4a331e01896e1a21724c8d1b9a2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAvgsAccountField_QueryFragment$data = {
  readonly AvgsPotentialAnalysis: {
    readonly AVGSAccounts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"selectAvgsAccountField_AccountFragment">;
        };
      } | null | undefined> | null | undefined;
    };
  };
  readonly " $fragmentType": "selectAvgsAccountField_QueryFragment";
};
export type selectAvgsAccountField_QueryFragment$key = {
  readonly " $data"?: selectAvgsAccountField_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAvgsAccountField_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "AvgsPotentialAnalysis",
  "AVGSAccounts"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./selectAvgsAccountField_Refetch.graphql')
    }
  },
  "name": "selectAvgsAccountField_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AvgsPotentialAnalysisQueries",
      "kind": "LinkedField",
      "name": "AvgsPotentialAnalysis",
      "plural": false,
      "selections": [
        {
          "alias": "AVGSAccounts",
          "args": [
            {
              "kind": "Variable",
              "name": "filterByName",
              "variableName": "filterByName"
            }
          ],
          "concreteType": "AccountConnection",
          "kind": "LinkedField",
          "name": "__selectAvgsAccountField_AVGSAccounts_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Account",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "selectAvgsAccountField_AccountFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "810539ca9bc851cd9da60344fd065ce2";

export default node;
