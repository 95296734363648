import { DialogButton, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { type showCoachFeedbacksButton_Query } from "@relay/showCoachFeedbacksButton_Query.graphql";
import { COACH_PROFILE_FRAGMENT, QUERY } from "./show-coach-feedbacks-button.graphql";
import { type ShowCoachFeedbacksButtonProps } from "./show-coach-feedbacks-button.types";
import { ShowCoachFeedbacksTable } from "../show-coach-feedbacks-table/show-coach-feedbacks-table.component";

export const ShowCoachFeedbacksButton = ({
	coachProfileFragmentRef,
}: ShowCoachFeedbacksButtonProps) => {
	const fragment = useFragment(COACH_PROFILE_FRAGMENT, coachProfileFragmentRef);

	const query = useLazyLoadQuery<showCoachFeedbacksButton_Query>(QUERY, { id: fragment.id });
	return (
		<DialogButton
			buttonIcon={EpicIcons.STAR}
			dialogTitle={`Feedback zum Coach: ${fragment?.coach?.name}`}
			tooltip="Feedback zum Coach"
		>
			{() => <ShowCoachFeedbacksTable coachFeedbackQueryRef={query} />}
		</DialogButton>
	);
};
