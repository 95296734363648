import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { useFragment, useMutation } from "react-relay";
import { type editWordButton_AddWordToPackageMutation } from "@relay/editWordButton_AddWordToPackageMutation.graphql";
import { type editWordButton_EditWordMutation } from "@relay/editWordButton_EditWordMutation.graphql";
import { type editWordButton_WordFragment$key } from "@relay/editWordButton_WordFragment.graphql";
import {
	ADD_WORD_TO_PACKAGE_MUTATION,
	EDIT_WORD_MUTATION,
	WORD_FRAGMENT,
} from "./edit-word-button.graphql";
import { type EditWordButtonProps } from "./edit-word-button.types";
import { EditWordForm, type EditWordFormState } from "../edit-word-form";

export const EditWordButton = ({ wordPackageId, wordFragment }: EditWordButtonProps) => {
	const word = useFragment<editWordButton_WordFragment$key>(WORD_FRAGMENT, wordFragment ?? null);

	const [create, isCreating] = useMutation<editWordButton_AddWordToPackageMutation>(
		ADD_WORD_TO_PACKAGE_MUTATION,
	);

	const [edit, isEditing] = useMutation<editWordButton_EditWordMutation>(EDIT_WORD_MUTATION);

	function handleSubmit(values: EditWordFormState, onHide: () => void) {
		if (word) {
			edit({
				variables: {
					input: {
						wordPackageId,
						wordId: word.id,
						word: values.word!,
						translation: values.translation,
						explanation: values.explanation!,
					},
				},
				onCompleted: () => {
					onHide();
				},
			});
		} else {
			create({
				variables: {
					input: {
						wordPackageId,
						word: values.word!,
						translation: values.translation,
						explanation: values.explanation!,
					},
				},
				onCompleted: () => {
					onHide();
				},
			});
		}
	}

	return (
		<FormDialogButton<EditWordFormState>
			buttonContent={{
				label: word ? undefined : "Wort erstellen",
				icon: word ? "pi pi-pencil" : undefined,
			}}
			title={word ? "Wort bearbeiten" : "Wort erstellen"}
			disabled={isCreating || isEditing}
		>
			{(ref, onHide) => {
				return (
					<EditWordForm
						initialState={{
							word: word?.word,
							translation: word?.translation ?? undefined,
							explanation: word?.explanation,
						}}
						ref={ref}
						onSubmit={(values) => {
							handleSubmit(values, onHide);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
