import { useFormik } from "formik";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Suspense, useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { accountGroupProductForm_AccountGroupProductFragment$key } from "@relay/accountGroupProductForm_AccountGroupProductFragment.graphql";
import { accountGroupProductForm_EditAcademiesProductExtensionMutation } from "@relay/accountGroupProductForm_EditAcademiesProductExtensionMutation.graphql";
import { accountGroupProductForm_EditAccountGroupProductMutation } from "@relay/accountGroupProductForm_EditAccountGroupProductMutation.graphql";
import { accountGroupProductForm_EditProductMutation } from "@relay/accountGroupProductForm_EditProductMutation.graphql";
import {
	EDIT_PRODUCT_MUTATION,
	ACCOUNT_GROUP_PRODUCT_FRAGMENT,
	EDIT_ACCOUNT_GROUP_PRODUCT_MUTATION,
	EDIT_ACADEMIES_PRODUCT_EXTENSION_MUTATION,
} from "@screens/product-edit/parts/account-group-product-form/account-group-product-form.graphql";
import {
	AccountGroupProductFormProps,
	AccountGroupProductFormState,
} from "@screens/product-edit/parts/account-group-product-form/account-group-product-form.types";
import { LicenseProductFormState } from "@screens/product-edit/parts/license-product-form/license-product-form.types";
import { ProductForm } from "@screens/product-edit/parts/product-form/product-form.component";

export const AccountGroupProductForm = ({ productFragmentRef }: AccountGroupProductFormProps) => {
	const toast = useRef<Toast>(null);

	const product = useFragment<accountGroupProductForm_AccountGroupProductFragment$key>(
		ACCOUNT_GROUP_PRODUCT_FRAGMENT,
		productFragmentRef,
	);
	const productExtension = product?.extensions?.find(
		(extension) => extension.kind === "Academies",
	);

	const [editProduct] =
		useMutation<accountGroupProductForm_EditProductMutation>(EDIT_PRODUCT_MUTATION);
	const [editAccountGroupProduct] =
		useMutation<accountGroupProductForm_EditAccountGroupProductMutation>(
			EDIT_ACCOUNT_GROUP_PRODUCT_MUTATION,
		);

	const [editAcademiesProductExtension] =
		useMutation<accountGroupProductForm_EditAcademiesProductExtensionMutation>(
			EDIT_ACADEMIES_PRODUCT_EXTENSION_MUTATION,
		);

	const formik = useFormik<AccountGroupProductFormState>({
		enableReinitialize: true,
		initialValues: {
			title: product.title,
			isTaxFree: product.isTaxFree,
			isHidden: product.isHidden,
			netPrice: product.netPrice,
			costUnit: productExtension?.costUnit ?? "",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Ein Titel wird benötigt."),
			netPrice: Yup.number().required(),
			isHidden: Yup.boolean(),
			costUnit: Yup.string().optional(),
		}),
		onSubmit: (values: AccountGroupProductFormState, { setSubmitting }) => {
			editProduct({
				variables: {
					input: {
						productId: product.id,
						newNetPrice: values.netPrice,
						newTitle: values.title,
						newIsHidden: values.isHidden ?? true,
						newIsTaxFree: values.isTaxFree,
					},
				},
				onCompleted: () => {
					editAccountGroupProduct({
						variables: {
							input: {
								id: product.id,
								accountGroupId: product.data.accountGroupId!,
							},
						},
						onCompleted: () => {
							editAcademiesProductExtension({
								variables: {
									input: {
										productId: product.id,
										costUnit: values.costUnit,
									},
								},
							});

							setSubmitting(false);
						},
					});
				},
			});
		},
	});

	return (
		<>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				<ProductForm formik={formik} handleSubmit={formik.handleSubmit}>
					<ValidatedField<LicenseProductFormState, string>
						name={"costUnit"}
						label="Kostenstelle"
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
				</ProductForm>
			</Suspense>
		</>
	);
};
