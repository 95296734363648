import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment potentialAnalysesTable_QueryFragment on Query
	@refetchable(queryName: "potentialAnalysesTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
	) {
		Admin {
			AvgsPotentialAnalysis {
				AVGSPotentialAnalysis(first: $first, after: $after, last: $last, before: $before)
					@connection(key: "potentialAnalysesTable_AVGSPotentialAnalysis") {
					__id
					edges {
						node {
							id
							...potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment
						}
					}
					pageInfo {
						startCursor
						endCursor
						hasNextPage
						hasPreviousPage
					}
				}
			}
		}
	}
`;

export const POTENTIAL_ANALYSIS_V2_FRAGMENT = graphql`
	fragment potentialAnalysesTable_AVGSPotentialAnalysisPotentialAnalysisFragment on AVGSPotentialAnalysis_PotentialAnalysis
	@inline {
		id
		createdAt
		isFinished
		coach {
			id
			name
			email
		}
		client {
			kind
			... on AVGSPotentialAnalysis_AcceptedForUser {
				client {
					id
					name
					email
				}
			}
			... on AVGSPotentialAnalysis_InvitedForUser {
				email
			}
		}
		replacementCoach {
			id
			name
			email
		}
		modules {
			moduleId
			moduleText
			dimensions {
				... on AVGSPotentialAnalysis_Dimension {
					dimensionId
					dimensionText
				}
			}
		}
		...editPaButton_AVGSPotentialAnalysisPotentialAnalysisFragment
	}
`;
