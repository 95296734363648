import graphql from "babel-plugin-relay/macro";

export const CREATE_CONNECT_ACCOUNT_MUTATION = graphql`
	mutation createConnectAccountButton_CreateConnectAccountMutation(
		$input: CreateStripeConnectAccountInput!
	) {
		Admin {
			Billing {
				createStripeConnectAccount(input: $input) {
					contractPartner {
						node {
							...contractPartnerTable_ContractPartnerFragment
						}
					}
				}
			}
		}
	}
`;
