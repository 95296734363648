import { Button, EpicIcons } from "@thekeytechnology/epic-ui";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { type createExternalLicenseRewardButton_AddExternalLicenseRewardMutation } from "@relay/createExternalLicenseRewardButton_AddExternalLicenseRewardMutation.graphql";
import { type createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment$key } from "@relay/createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment.graphql";
import { type createExternalLicenseRewardButton_TreeNodeFragment$key } from "@relay/createExternalLicenseRewardButton_TreeNodeFragment.graphql";
import {
	ADD_EXTERNAL_LICENSE_REWARD_MUTATION,
	EXTERNAL_LICENSE_POOL_CONNECTION_FRAGMENT,
	TREE_NODE_FRAGMENT,
} from "./create-external-license-reward-button.graphql";
import { type CreateExternalLicenseRewardButtonProps } from "./create-external-license-reward-button.types";

export const CreateExternalLicenseRewardButton = ({
	treeNodeFragmentRef,
	externalLicensePoolConnectionFragmentRef,
}: CreateExternalLicenseRewardButtonProps) => {
	const node = useFragment<createExternalLicenseRewardButton_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const externalLicensePoolConnection =
		useFragment<createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment$key>(
			EXTERNAL_LICENSE_POOL_CONNECTION_FRAGMENT,
			externalLicensePoolConnectionFragmentRef,
		);
	const [addExternalLicenseReward, isAddingExternalLicense] =
		useMutation<createExternalLicenseRewardButton_AddExternalLicenseRewardMutation>(
			ADD_EXTERNAL_LICENSE_REWARD_MUTATION,
		);

	const noExternalLicensePools = externalLicensePoolConnection.edges?.length === 0;
	const tooltip = noExternalLicensePools
		? "Bitte legen Sie zuerst einen Externen Lizenzpool an"
		: "Externe Lizenzen vergeben";
	const disabled = isAddingExternalLicense || noExternalLicensePools;
	const handleOnClick = useCallback(() => {
		const poolId = externalLicensePoolConnection?.edges?.map((e) => e?.node).shift()?.id;
		if (!poolId) return;

		addExternalLicenseReward({
			variables: {
				input: {
					contentId: node.id,
					data: {
						pool: poolId,
					},
				},
			},
		});
	}, [externalLicensePoolConnection, node, addExternalLicenseReward]);

	return (
		<Button
			disabled={disabled}
			tooltip={tooltip}
			icon={EpicIcons.KEY}
			onClick={handleOnClick}
		/>
	);
};
