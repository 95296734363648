/**
 * @generated SignedSource<<430a4f2287139ca50f666306b6d44bc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetSimplyContinueFlowELearningContentConfigInput = {
  clientMutationId?: string | null | undefined;
  contentId: string;
};
export type flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation$variables = {
  input: SetSimplyContinueFlowELearningContentConfigInput;
};
export type flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setSimplyContinueFlowELearningContentConfig: {
        readonly content: {
          readonly " $fragmentSpreads": FragmentRefs<"flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment">;
        };
      } | null | undefined;
    };
  };
};
export type flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation = {
  response: flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation$data;
  variables: flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetSimplyContinueFlowELearningContentConfigPayload",
                "kind": "LinkedField",
                "name": "setSimplyContinueFlowELearningContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetSimplyContinueFlowELearningContentConfigPayload",
                "kind": "LinkedField",
                "name": "setSimplyContinueFlowELearningContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "flowConfig",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "minNumTriesTillShowAnswer",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "DirectFeedbackFlowELearningContentConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ELearningContentTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f68efe65e0b7adb40e4752d4832538b",
    "id": null,
    "metadata": {},
    "name": "flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation",
    "operationKind": "mutation",
    "text": "mutation flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation(\n  $input: SetSimplyContinueFlowELearningContentConfigInput!\n) {\n  Admin {\n    Tree {\n      setSimplyContinueFlowELearningContentConfig(input: $input) {\n        content {\n          ...flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment flowAndCurrentElementContentConfigEditor_Flow_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ELearningContentTypeDefinition {\n      flowConfig {\n        __typename\n        id\n        configType\n        ... on DirectFeedbackFlowELearningContentConfig {\n          minNumTriesTillShowAnswer\n        }\n        ...setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment\n      }\n    }\n  }\n  ...setDirectFeedbackFlowConfigForm_TreeNodeFragment\n}\n\nfragment setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment on DirectFeedbackFlowELearningContentConfig {\n  id\n  configType\n  minNumTriesTillShowAnswer\n}\n\nfragment setDirectFeedbackFlowConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n"
  }
};
})();

(node as any).hash = "2a1f54487330c51ed9cc5797201e4660";

export default node;
