import { Button } from "primereact/button";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { ACADEMIES_TAG_FRAGMENT, EDIT_TAG_MUTATION } from "./edit-academies-tag-button.graphql";
import { EditAcademiesTagButtonProps } from "./edit-academies-tag-button.types";
import { editAcademiesTagButton_AcademiesTagFragment$key } from "../../../../__generated__/editAcademiesTagButton_AcademiesTagFragment.graphql";
import { editAcademiesTagButton_EditTagMutation } from "../../../../__generated__/editAcademiesTagButton_EditTagMutation.graphql";
import { AcademiesTagDialog } from "../academies-tag-dialog";
import { AcademiesTagFormState } from "../academies-tag-form/academies-tag-form.types";

export const EditAcademiesTagButton = ({
	className,
	academiesTagFragmentRef,
}: EditAcademiesTagButtonProps) => {
	const tag = useFragment<editAcademiesTagButton_AcademiesTagFragment$key>(
		ACADEMIES_TAG_FRAGMENT,
		academiesTagFragmentRef,
	);

	const [editTag, isEditingTag] =
		useMutation<editAcademiesTagButton_EditTagMutation>(EDIT_TAG_MUTATION);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const handleOnClick = () => setIsOpen(true);
	const handleOnHide = () => setIsOpen(false);
	const handleOnSubmit = (value: AcademiesTagFormState) => {
		editTag({
			variables: {
				input: {
					data: value,
					id: tag.id,
				},
			},
		});
	};
	return (
		<>
			<Button
				className={className}
				icon="pi pi-pencil"
				onClick={handleOnClick}
				disabled={isEditingTag}
			/>
			<AcademiesTagDialog
				isOpen={isOpen}
				onHide={handleOnHide}
				onSubmit={handleOnSubmit}
				isLoading={isEditingTag}
				initialValues={{
					name: tag.data?.name || "",
					isClickable: tag.data?.isClickable || false,
					isTopic: tag.data?.isTopic || false,
				}}
			/>
		</>
	);
};
