import { useLazyLoadQuery } from "react-relay";
import { Navigate } from "react-router-dom";
import { useIsFeatureActive } from "@hooks/use-is-feature-active";
import { useCanReadAvgsPotentialAnalysis } from "@permissions/avgs-potential-analysis";
import { potentialAnalysis_Query } from "@relay/potentialAnalysis_Query.graphql";
import { BaseScreen } from "@screens/BaseScreen";
import { CreatePaButton } from "@screens/potential-analysis/parts/create-pa-button";
import { PotentialAnalysesTable } from "@screens/potential-analysis/parts/potential-analyses-table";
import { QUERY } from "@screens/potential-analysis/potential-analysis.graphql";
import { PotentialAnalysisContextProvider } from "@screens/potential-analysis/potential-analysis.provider";
import { H1Span, P1Span } from "@themes/font-tags";
import { buttonWrapperClass } from "./potential-analysis.styles";

const PotentialAnalysisScreen = () => {
	const query = useLazyLoadQuery<potentialAnalysis_Query>(QUERY, {});
	const numAvailableLicenses = query.Admin.AvgsPotentialAnalysis.Info?.numAvailableLicenses ?? 0;

	return (
		<BaseScreen>
			<PotentialAnalysisContextProvider>
				<H1Span>Potential-Analyse</H1Span>
				<div className={buttonWrapperClass}>
					<CreatePaButton queryFragmentRef={query} />
				</div>
				<P1Span>Verfügbare Lizenzen: {numAvailableLicenses}</P1Span>
				<PotentialAnalysesTable queryFragmentRef={query} />
			</PotentialAnalysisContextProvider>
		</BaseScreen>
	);
};

export const PotentialAnalysis = () => {
	const isEnabled = useIsFeatureActive("potentialAnalysisV2");
	const canReadAvgsPotentialAnalysis = useCanReadAvgsPotentialAnalysis();

	if (isEnabled === undefined || canReadAvgsPotentialAnalysis === undefined) {
		return <div>Loading...</div>;
	}
	if (!isEnabled || !canReadAvgsPotentialAnalysis) return <Navigate to={"/"} />;
	return <PotentialAnalysisScreen />;
};
