import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type createFileElementButton_CreateFileElementMutation } from "@relay/createFileElementButton_CreateFileElementMutation.graphql";
import { CREATE_FILE_ELEMENT_MUTATION } from "./create-file-element-button.graphql";
import { type CreateFileElementButtonProps } from "./create-file-element-button.types";
import { E_LEARNING_I18N_MAP, E_LEARNING_I18N_KEY } from "../../../../translations/tree";

export const CreateFileElementButton = ({
	eLearningContentNodeId,
}: CreateFileElementButtonProps) => {
	const [createFileElement, isCreatingTextElement] =
		useMutation<createFileElementButton_CreateFileElementMutation>(
			CREATE_FILE_ELEMENT_MUTATION,
		);

	const handleCreateOnClick = () => {
		createFileElement({
			variables: {
				input: {
					eLearningContentNodeId,
					title: "Neues Element",
					fileId: undefined,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingTextElement}
			tooltip={`${E_LEARNING_I18N_MAP(E_LEARNING_I18N_KEY.fileElement)} anlegen`}
			icon={EpicIcons.FILE}
			onClick={handleCreateOnClick}
		/>
	);
};
