/**
 * @generated SignedSource<<2655e36f1a0b20426d5337be13db7bb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AttachmentKind = "DefaultFile";
import { FragmentRefs } from "relay-runtime";
export type DefaultFileAttachmentForm_AttachmentV2Fragment$data = {
  readonly description?: string | null | undefined;
  readonly file?: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly kind: AttachmentKind;
  readonly title?: string | null | undefined;
  readonly " $fragmentType": "DefaultFileAttachmentForm_AttachmentV2Fragment";
};
export type DefaultFileAttachmentForm_AttachmentV2Fragment$key = {
  readonly " $data"?: DefaultFileAttachmentForm_AttachmentV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DefaultFileAttachmentForm_AttachmentV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DefaultFileAttachmentForm_AttachmentV2Fragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "type": "DefaultFileAttachment",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "AttachmentV2",
  "abstractKey": "__isAttachmentV2"
};

(node as any).hash = "b7325cd07d995e327765284a87971ef2";

export default node;
