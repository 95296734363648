import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Permission } from "@relay/permissionBasedNavigation_Query.graphql";
import { type ReduxState } from "../store.redux";

export type CurrentUserData = {
	permissionsInAccount: Permission[];
	user: {
		id: string;
		name: string;
	};
	accounts: Array<{
		id: string;
		name: string;
	}>;
};

export type CurrentUserState = {
	currentUser?: CurrentUserData;
};

const INITIAL_STATE: CurrentUserState = {};

const currentUserSlice = createSlice({
	name: "current-user",
	initialState: INITIAL_STATE,
	reducers: {
		setCurrentUser: (state, action: PayloadAction<CurrentUserData>) => {
			state.currentUser = action.payload;
		},
	},
});

export const { setCurrentUser } = currentUserSlice.actions;
export const CurrentUserSliceReducer = currentUserSlice.reducer;

const selectCurrentUserSlice = (state: ReduxState) => state.currentUser;

export const selectCurrentUser = createSelector(selectCurrentUserSlice, (e) => e.currentUser);

export const selectPermissionsInAccount = createSelector(
	selectCurrentUserSlice,
	(state) => state.currentUser?.permissionsInAccount || [],
);

export const selectAccountMetaNameByBase64DecodedAccountId = createSelector(
	selectCurrentUserSlice,
	(e) => (id: string) =>
		e.currentUser?.accounts.find((a) => atob(a.id).includes(id))?.name || "Account Not Found",
);
