import { graphql } from "babel-plugin-relay/macro";

export const UNPUBLISHED_LEARNABLE_FRAGMENT = graphql`
	fragment editCourseLearnableButton_UnpublishedLearnableFragment on UnpublishedLearnable {
		id
		... on UnpublishedCourseLearnable {
			kind
			root {
				id
			}
		}
		... on UnpublishedMicroLearningLearnable {
			kind
			root {
				id
			}
		}
	}
`;
