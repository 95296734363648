import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { type FormikProps } from "formik";
import React, { useContext } from "react";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type createMicroLearningLicenseDefinitionButton_CreateTagLicenseDefinitionMutation } from "@relay/createMicroLearningLicenseDefinitionButton_CreateTagLicenseDefinitionMutation.graphql";
import { CREATE_MICRO_LEARNING_LICENSE_DEFINITION_MUTATION } from "./create-micro-learning-license-definition-button.graphql";
import { LicenseDefinitionsScreenContext } from "../../../screens/license-definitions/license-defintions.context";
import { MicroLearningLicenseDefinitionForm } from "../micro-learning-license-definition-form";
import { type MicroLearningLicenseDefinitionFormState } from "../micro-learning-license-definition-form/micro-learning-license-definition-form.types";

export const CreateMicroLearningLicenseDefinitionButton = () => {
	const { connectionId } = useContext(LicenseDefinitionsScreenContext);

	const [create, isCreating] =
		useMutation<createMicroLearningLicenseDefinitionButton_CreateTagLicenseDefinitionMutation>(
			CREATE_MICRO_LEARNING_LICENSE_DEFINITION_MUTATION,
		);
	const createHandleOnSubmit = (
		ref: React.MutableRefObject<FormikProps<MicroLearningLicenseDefinitionFormState> | null>,
		onHide: () => void,
		values: MicroLearningLicenseDefinitionFormState,
	) => {
		create({
			variables: {
				input: {
					data: {
						name: values.name,
					},
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				ref.current?.setSubmitting(false);
				onHide();
				toast.success("MicroLearning-Lizenzdefinition erstellt.");
			},
			onError: () => {
				ref.current?.setSubmitting(false);
				toast.error("Fehler beim erstellen der MicroLearning-Lizenzdefinition.");
			},
		});
	};

	return (
		<FormDialogButton<MicroLearningLicenseDefinitionFormState>
			disabled={isCreating}
			buttonContent={{
				label: "Micro-Learning-Lizenzdefinition erstellen",
			}}
			title={"Micro-Learning-Lizenzdefintion"}
		>
			{(ref, onHide) => {
				return (
					<MicroLearningLicenseDefinitionForm
						ref={ref}
						initialState={{
							name: "",
						}}
						onSubmit={(values) => {
							createHandleOnSubmit(ref, onHide, values);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
