export const CompanyType: { [key: string]: string } = {
	Ag: "Aktiengesellschaft (AG)",
	Einzelunternehmen: "Einzelunternehmen",
	Ev: "Eingetragener Verein (eV)",
	Gbr: "Gesellschaft bürgerlichen Rechts (gbR)",
	Gmbh: "Gesellschaft mit beschränkter Haftung (GmbH)",
	GmbhCoKg: "GmbH & Co. KG",
	Kg: "Kommanditgesellschaft (KG)",
	OeffentlicheEinrichtung: "Öffentliche Einrichtung",
	Ohg: "Offene Handelsgesellschaft (OHG)",
	Ug: "Unternehmergesellschaft (UG)",
};
