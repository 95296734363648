/**
 * @generated SignedSource<<6e1c7526a4ee613c6f9be67096e0cd1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectCoachesField_CoachProfileFragment$data = {
  readonly coach: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly profileData: {
    readonly avatar: {
      readonly id: string;
      readonly thumbnail: string | null | undefined;
    } | null | undefined;
    readonly description: string | null | undefined;
  };
  readonly " $fragmentType": "selectCoachesField_CoachProfileFragment";
};
export type selectCoachesField_CoachProfileFragment$key = {
  readonly " $data"?: selectCoachesField_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectCoachesField_CoachProfileFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectCoachesField_CoachProfileFragment"
};

(node as any).hash = "c4e5a790321f9d1a4939932e08768003";

export default node;
