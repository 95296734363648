/**
 * @generated SignedSource<<d1bf09779da324f20595ce187d5148a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type limitedCartDiscountForm_ProductSelectionConditionFragment$data = {
  readonly amount: number;
  readonly id: string;
  readonly product: {
    readonly title: string;
  } | null | undefined;
  readonly productId: string;
  readonly " $fragmentType": "limitedCartDiscountForm_ProductSelectionConditionFragment";
};
export type limitedCartDiscountForm_ProductSelectionConditionFragment$key = {
  readonly " $data"?: limitedCartDiscountForm_ProductSelectionConditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"limitedCartDiscountForm_ProductSelectionConditionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "limitedCartDiscountForm_ProductSelectionConditionFragment"
};

(node as any).hash = "9eac779fec6bca607f685caced37d64b";

export default node;
