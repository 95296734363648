import { DeleteButton, Table } from "@thekeytechnology/framework-react-components";
import { useFragment, useMutation } from "react-relay";
import {
	HorizontalElementSpacingWrapper,
	VerticalContainerSpacingWrapper,
} from "@corestyle/component-theme/default-spacings.component";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type wordsTable_DeleteWordMutation } from "@relay/wordsTable_DeleteWordMutation.graphql";
import { type wordsTable_WordPackageFragment$key } from "@relay/wordsTable_WordPackageFragment.graphql";
import { DELETE_WORD_MUTATION, WORD_PACKAGE_FRAGMENT } from "./words-table.graphql";
import { ButtonWrapper } from "./words-table.styles";
import { EditWordButton } from "../edit-word-button/edit-word-button.component";

export type WordsTableProps = {
	wordPackageFragmentRef: wordsTable_WordPackageFragment$key;
};

export const WordsTable = ({ wordPackageFragmentRef }: WordsTableProps) => {
	const wordPackage = useFragment<wordsTable_WordPackageFragment$key>(
		WORD_PACKAGE_FRAGMENT,
		wordPackageFragmentRef,
	);

	const [deleteWord, isDeletingWord] =
		useMutation<wordsTable_DeleteWordMutation>(DELETE_WORD_MUTATION);

	const handleOnDelete = (wordIds: string[]) => {
		deleteWord({
			variables: {
				input: {
					wordPackageId: wordPackage.id,
					wordId: wordIds[0],
				},
			},
		});
	};

	const canModify = useHasPermissions(["UserInAccountPermission_VocabAdmin_ModifyWordPackages"]);

	return (
		<>
			<VerticalContainerSpacingWrapper>
				<EditWordButton wordPackageId={wordPackage.id} />
			</VerticalContainerSpacingWrapper>

			<Table
				emptyMessage="Es wurden keine Wörter gefunden."
				items={[...wordPackage.associatedWords]}
			>
				{(Column) => (
					<>
						<Column header="Wort" field="word" />
						<Column header="Erklärung" field="explanation" />
						{canModify && (
							<Column
								header="Aktionen"
								body={(row) => {
									return (
										<ButtonWrapper>
											<HorizontalElementSpacingWrapper>
												<EditWordButton
													wordFragment={row}
													wordPackageId={wordPackage.id}
												/>
											</HorizontalElementSpacingWrapper>
											<DeleteButton
												hideLabel={true}
												isDeleting={isDeletingWord}
												selectedIds={[row.id]}
												singularName={"Wort"}
												pluralName={"Wörter"}
												doDelete={handleOnDelete}
											/>
										</ButtonWrapper>
									);
								}}
							/>
						)}
					</>
				)}
			</Table>
		</>
	);
};
