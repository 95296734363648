import { graphql } from "babel-plugin-relay/macro";

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment showCoachAppointmentsButton_CoachProfileFragment on CoachProfile {
		id
		coach {
			id
		}
	}
`;
