import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query selectCoachingField_Query {
		...selectCoachingField_QueryFragment
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment selectCoachingField_QueryFragment on Query
	@refetchable(queryName: "SelectCoachingField_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		name: { type: "String" }
	) {
		Admin {
			Coaching {
				CoachingOffers(first: $first, after: $after, name: $name)
					@connection(key: "selectCoachingField_CoachingOffers") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
						}
					}
					...coachingOffersTable_CoachingOfferConnectionFragment
				}
			}
		}
	}
`;
