import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useFragment, useMutation } from "react-relay";
import { type EditRootNodePartForm_EditRootNodePartMutation } from "../../../__generated__/EditRootNodePartForm_EditRootNodePartMutation.graphql";
import { type EditRootNodePartForm_TreeNodeFragment$key } from "../../../__generated__/EditRootNodePartForm_TreeNodeFragment.graphql";
import {
	DefaultCalendarComponent,
	DefaultTextAreaComponent,
} from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";
import { FileSelectionField, type FileV2 } from "../../../features/files/file-selection-field";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
} from "../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../store/store.redux";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../translations/tree";

const TREE_NODE_FRAGMENT = graphql`
	fragment EditRootNodePartForm_TreeNodeFragment on TreeNode {
		id
		structureDefinition {
			... on RootStructureDefinition {
				firstReleasedAt
				icon {
					id
					name
				}
				searchWords
			}
		}
	}
`;

const EDIT_ROOT_NODE_PART_MUTATION = graphql`
	mutation EditRootNodePartForm_EditRootNodePartMutation($input: EditRootNodePartInput!) {
		Admin {
			Tree {
				editRootNodePart(input: $input) {
					editedNode {
						...EditRootNodePartForm_TreeNodeFragment
						...Node_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type FormState = {
	searchWords?: string;
	icon?: FileV2;
	tags?: string[];
	firstReleasedAt?: string;
};

type OwnProps = {
	treeNodeFragmentRef: EditRootNodePartForm_TreeNodeFragment$key;
	canUploadFiles: boolean;
	canDeleteFiles: boolean;
};

export const EditRootNodePartForm = ({
	treeNodeFragmentRef,
	canUploadFiles,
	canDeleteFiles,
}: OwnProps) => {
	const node = useFragment<EditRootNodePartForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [editRootNodePart, isEditingRootNodePart] =
		useMutation<EditRootNodePartForm_EditRootNodePartMutation>(EDIT_ROOT_NODE_PART_MUTATION);

	const dispatch = useTypedDispatch();

	const formId = "EditRootNodePartForm";
	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			searchWords: node.structureDefinition.searchWords || undefined,
			icon: (node.structureDefinition.icon as FileV2) || undefined,
			tags: [],
			firstReleasedAt: node.structureDefinition.firstReleasedAt || undefined,
		},
		onSubmit: (values, { setSubmitting }) => {
			editRootNodePart({
				variables: {
					input: {
						rootNodeId: node.id,
						iconId: values.icon?.id,
						tagIds: [],
						firstReleasedAt: values.firstReleasedAt,
						searchWords: values.searchWords,
					},
				},
				onCompleted: () => {
					formik.setTouched({});
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	return (
		<Card className="mb-2">
			<h2>{TREE_I18N_MAP(TREE_I18N_KEY.tree)}-Einstellungen</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"searchWords"}
					label={"Suchworte"}
					helpText={`Dieser Text wird in der Suche mit durchsucht aber nirgendwo angezeigt.`}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextAreaComponent}
					formikConfig={formik}
				/>

				<ValidatedField<FormState, FileV2>
					name={"icon"}
					label={"Icon"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={({ fieldName, fieldValue, updateField, onChange }) => (
						<FileSelectionField
							name={fieldName}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
							onChange={onChange}
							canUploadFiles={canUploadFiles}
							canDeleteFiles={canDeleteFiles}
						/>
					)}
					formikConfig={formik}
				/>
				{node.structureDefinition.firstReleasedAt && (
					<ValidatedField<FormState, string>
						name={"firstReleasedAt"}
						label={"Veröffentlichungszeitpunkt"}
						helpText={
							"Der Zeitpunkt der Veröffentlichung wird für die Sortierung verwendet. Diese Einstellung hat KEINEN Einfluss auf Sichtbarkeit."
						}
						component={DefaultCalendarComponent}
						onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
						formikConfig={formik}
					/>
				)}

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditingRootNodePart}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};
