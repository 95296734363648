/**
 * @generated SignedSource<<216be2e40ed0402a5afd02f252e11467>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type searchablePublishedRootTable_QueryRefetch$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  titleMatchRegex?: string | null | undefined;
};
export type searchablePublishedRootTable_QueryRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"searchablePublishedRootTable_QueryFragment">;
};
export type searchablePublishedRootTable_QueryRefetch = {
  response: searchablePublishedRootTable_QueryRefetch$data;
  variables: searchablePublishedRootTable_QueryRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "titleMatchRegex"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "titleMatchRegex",
    "variableName": "titleMatchRegex"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "searchablePublishedRootTable_QueryRefetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "searchablePublishedRootTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchablePublishedRootTable_QueryRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnV2AdminSchema",
            "kind": "LinkedField",
            "name": "LearnV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "LearnOpportunitiesV2Connection",
                "kind": "LinkedField",
                "name": "SearchLearnOpportunityV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunitiesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnOpportunityV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "structureDefinition",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "titleMatchRegex"
                ],
                "handle": "connection",
                "key": "searchablePublishedRootTable_SearchLearnOpportunityV2",
                "kind": "LinkedHandle",
                "name": "SearchLearnOpportunityV2"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "11c7c8c16e9935d7b4cda335a61b6a85",
    "id": null,
    "metadata": {},
    "name": "searchablePublishedRootTable_QueryRefetch",
    "operationKind": "query",
    "text": "query searchablePublishedRootTable_QueryRefetch(\n  $after: String\n  $first: Int = 20\n  $titleMatchRegex: String\n) {\n  ...searchablePublishedRootTable_QueryFragment_4mEXVv\n}\n\nfragment searchablePublishedRootTable_LearnOpportunityV2Fragment on LearnOpportunityV2 {\n  id\n  structureDefinition {\n    __typename\n    title\n  }\n}\n\nfragment searchablePublishedRootTable_QueryFragment_4mEXVv on Query {\n  Admin {\n    LearnV2 {\n      SearchLearnOpportunityV2(first: $first, after: $after, titleMatchRegex: $titleMatchRegex) {\n        edges {\n          node {\n            ...searchablePublishedRootTable_LearnOpportunityV2Fragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74828583f359763721b27c3b82d5ab4d";

export default node;
