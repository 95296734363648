import { RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type customerFeedbackTable_CustomerFeedbackFragment$key } from "@relay/customerFeedbackTable_CustomerFeedbackFragment.graphql";
import { type customerFeedbackTable_QueryFragment$key } from "@relay/customerFeedbackTable_QueryFragment.graphql";
import { type CustomerFeedbackTable_QueryRefetch } from "@relay/CustomerFeedbackTable_QueryRefetch.graphql";
import { CustomerFeedbackScreenContext } from "@screens/customer-feedback/customer-feedback.context";
import { CustomerFeedbackActionColumn } from "@screens/customer-feedback/parts/customer-feedback-action-column/customer-feedback-action-column.component";
import { CUSTOMER_FEEDBACK_PER_PAGE } from "@screens/customer-feedback/parts/customer-feedback-table/customer-feedback-table.const";
import {
	QUERY_FRAGMENT,
	CUSTOMER_FEEDBACK_FRAGMENT,
} from "@screens/customer-feedback/parts/customer-feedback-table/customer-feedback-table.graphql";
import { type CustomerFeedbackTableProps } from "@screens/customer-feedback/parts/customer-feedback-table/customer-feedback-table.types";

export const CustomerFeedbackTable = ({ queryFragmentRef }: CustomerFeedbackTableProps) => {
	const { setConnectionId } = useContext(CustomerFeedbackScreenContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_CustomerFeedback_Modify"]);

	return (
		<RelayPageDataTable<
			CustomerFeedbackTable_QueryRefetch,
			customerFeedbackTable_QueryFragment$key,
			customerFeedbackTable_CustomerFeedbackFragment$key
		>
			queryFragment={QUERY_FRAGMENT}
			parentFragmentRef={queryFragmentRef}
			nodeInlineFragment={CUSTOMER_FEEDBACK_FRAGMENT}
			extractConnection={(data) => data.CustomerJourney.CustomerFeedback}
			rowsCount={CUSTOMER_FEEDBACK_PER_PAGE}
			onConnectionIdChange={handleOnConnectionIdChange}
		>
			{(Column) => (
				<>
					<Column header={"Name"} body={(customerFeedback) => customerFeedback?.name} />
					<Column
						header={"Position"}
						body={(customerFeedback) => customerFeedback?.position}
					/>
					<Column
						header={"Feedback"}
						body={(customerFeedback) => customerFeedback?.feedback}
					/>
					{canModify && (
						<Column
							header="Aktionen"
							body={(customerFeedback) => (
								<CustomerFeedbackActionColumn
									customerFeedbackFragmentRef={customerFeedback}
								/>
							)}
						/>
					)}
				</>
			)}
		</RelayPageDataTable>
	);
};
