/**
 * @generated SignedSource<<de6a795487be8a76b63f40b2e119c1a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAccountField_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectAccountField_AccountFragment";
};
export type selectAccountField_AccountFragment$key = {
  readonly " $data"?: selectAccountField_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAccountField_AccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectAccountField_AccountFragment"
};

(node as any).hash = "475cea4a49f2a031f20deb2b616939fc";

export default node;
