import React from "react";
import { useFragment } from "react-relay";
import { invoiceProviderLink_CreatedInvoiceDataFragment$key } from "@relay/invoiceProviderLink_CreatedInvoiceDataFragment.graphql";
import { INVOICE_DATA_FRAGMENT } from "./invoice-provider-link.graphql";
import { type InvoiceProviderLinkProps } from "./invoice-provider-link.interface";

export const InvoiceProviderLink = ({ invoiceDataFragmentRef }: InvoiceProviderLinkProps) => {
	const invoiceData = useFragment<invoiceProviderLink_CreatedInvoiceDataFragment$key>(
		INVOICE_DATA_FRAGMENT,
		invoiceDataFragmentRef,
	);

	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://c635444.easybill.de/doc-editor/${invoiceData.invoiceId}`}
		>
			{invoiceData.invoiceNumber}
		</a>
	);
};
