/**
 * @generated SignedSource<<9a21d1691e9d8a17689469805749a412>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransformBusinessAccountToAVGSAccountInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
};
export type transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation$variables = {
  input: TransformBusinessAccountToAVGSAccountInput;
};
export type transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation$data = {
  readonly Admin: {
    readonly AvgsPotentialAnalysis: {
      readonly transformBusinessAccountToAVGSAccount: {
        readonly clientMutationId: string | null | undefined;
      } | null | undefined;
    };
  };
};
export type transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation = {
  response: transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation$data;
  variables: transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AvgsPotentialAnalysisAdminMutationSchema",
        "kind": "LinkedField",
        "name": "AvgsPotentialAnalysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "TransformBusinessAccountToAVGSAccountPayload",
            "kind": "LinkedField",
            "name": "transformBusinessAccountToAVGSAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e3d21212092c4b83ecdd36ed137ca49e",
    "id": null,
    "metadata": {},
    "name": "transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation",
    "operationKind": "mutation",
    "text": "mutation transformToAvgsAccountButton_TransformBusinessAccountToAvgsAccountMutation(\n  $input: TransformBusinessAccountToAVGSAccountInput!\n) {\n  Admin {\n    AvgsPotentialAnalysis {\n      transformBusinessAccountToAVGSAccount(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b6717b8d0cdc2bbdd572ec2f95fc3b4";

export default node;
