import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { type Permission } from "@relay/permissionBasedNavigation_Query.graphql";
import { selectPermissionsInAccount } from "@store/slices/CurrentUserSlice";

export const useCheckPermissions = () => {
	const permissions = useSelector(selectPermissionsInAccount);

	const isOwnerOfRoot = useMemo(
		() =>
			permissions.includes("UserInAccountPermission_System_Owner") &&
			permissions.includes("AccountPermission_System_Root"),
		[permissions],
	);

	const checkPermissions = useCallback(
		(necessaryPermissions: Permission[] | "onlyOwnerOfRoot", acceptRoot = true) => {
			if (necessaryPermissions === "onlyOwnerOfRoot") return isOwnerOfRoot;
			return (
				(isOwnerOfRoot && acceptRoot) ||
				necessaryPermissions.every((permission) => permissions.includes(permission))
			);
		},
		[permissions, isOwnerOfRoot],
	);

	return checkPermissions;
};
