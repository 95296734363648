import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { ACADEMIES_TAG_FRAGMENT, QUERY_FRAGMENT } from "./academies-tags-table.graphql";
import { AcademiesTagsTableProps } from "./academies-tags-table.types";
import { tagsToTreeNode } from "./academies-tags-table.utils";
import { academiesTagsTable_AcademiesTagFragment$key } from "../../../../__generated__/academiesTagsTable_AcademiesTagFragment.graphql";
import { academiesTagsTable_QueryFragment$key } from "../../../../__generated__/academiesTagsTable_QueryFragment.graphql";
import { academiesTagsTable_Refetch } from "../../../../__generated__/academiesTagsTable_Refetch.graphql";
import { AcademiesTagsTableFilter } from "../academies-tags-table-filter/academies-tags-table-filter.component";
import { AcademiesTagsTableFilterState } from "../academies-tags-table-filter/academies-tags-table-filter.types";
import { CreateAcademiesTagButton } from "../create-academies-tag-button";
import { CreateChildTag } from "../create-child-tag-button";
import { DeleteAcademiesTagButton } from "../delete-academies-tag-button/delete-academies-tag-button.component";
import { EditAcademiesTagButton } from "../edit-academies-tag-button";

export const AcademiesTagsTable = ({ queryFragmentRef }: AcademiesTagsTableProps) => {
	const {
		data: {
			Admin: {
				AcademiesTag: { RootTags },
			},
		},
		refetch,
	} = usePaginationFragment<academiesTagsTable_Refetch, academiesTagsTable_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const tags = RootTags.edges?.map((e) =>
		readInlineData<academiesTagsTable_AcademiesTagFragment$key>(
			ACADEMIES_TAG_FRAGMENT,
			e!.node,
		),
	);

	const handleOnComplete = () => refetch({}, { fetchPolicy: "network-only" });
	const handleOnRefetch = (values: AcademiesTagsTableFilterState) =>
		refetch({
			name: values.name,
			isTopic: values.isTopic,
			isClickable: values.isClickable,
		});

	const connectionId = RootTags.__id;

	return (
		<div>
			<TreeTable
				value={tagsToTreeNode(tags)}
				emptyMessage="Keine Schlagwörter gefunden"
				header={
					<div className="flex w-full flex-column">
						<AcademiesTagsTableFilter refetch={handleOnRefetch} />
						<CreateAcademiesTagButton
							className="align-self-end m-2 mr-0"
							connectionId={connectionId}
						/>
					</div>
				}
			>
				<Column header={"Name"} body={(a) => a.data?.name} expander />
				<Column
					header={"ist Thema"}
					body={(a: any) =>
						a?.data?.isTopic ? (
							<i className="pi pi-check-circle text-green-500"></i>
						) : (
							<i className="pi pi-times-circle text-red-500"></i>
						)
					}
				/>
				<Column
					header={"ist Klickbar"}
					body={(a: any) =>
						a.data?.isClickable ? (
							<i className="pi pi-check-circle text-green-500"></i>
						) : (
							<i className="pi pi-times-circle text-red-500"></i>
						)
					}
				/>
				<Column
					header={"Aktionen"}
					body={(a) => (
						<>
							<EditAcademiesTagButton academiesTagFragmentRef={a} />
							<DeleteAcademiesTagButton
								connectionId={connectionId}
								disabled={!a.canBeDeleted}
								className="ml-2 mr-2"
								id={a.id}
								onComplete={handleOnComplete}
							/>
							{a.kind == "Root" && (
								<CreateChildTag parentId={a.id} onComplete={handleOnComplete} />
							)}
						</>
					)}
				/>
			</TreeTable>
		</div>
	);
};
