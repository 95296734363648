import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query transformToAzavAccountButton_Query($id: ID!) {
		Admin {
			AccountBaseData {
				AccountBaseData(filterByAccountId: $id) {
					__typename
				}
			}
		}
	}
`;

export const ACCOUNT_FRAGMENT = graphql`
	fragment transformToAzavAccountButton_AccountFragment on Account {
		groupAssociations {
			group {
				id
				name
			}
		}
	}
`;

export const TRANSFORM_PRIVATE_ACCOUNT_TO_AZAV_ACCOUNT_MUTATION = graphql`
	mutation transformToAzavAccountButton_TransformPrivateAccountToAzavAccountMutation(
		$input: TransformPrivateAccountToAZAVAccountInput!
	) {
		Admin {
			Azav {
				transformPrivateAccountToAZAVAccount(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const TRANSFORM_BUSINESS_ACCOUNT_TO_AZAV_ACCOUNT_MUTATION = graphql`
	mutation transformToAzavAccountButton_TransformBusinessAccountToAzavAccountMutation(
		$input: TransformBusinessAccountToAZAVAccountInput!
	) {
		Admin {
			Azav {
				transformBusinessAccountToAZAVAccount(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
