import { PathBase, PathWithId } from "@thekeytechnology/epic-ui";

export class PotentialAnalysisEditPath extends PathBase {
	static readonly pathKey = "edit";
}

export class PotentialAnalysisPaths extends PathWithId<typeof PotentialAnalysisPaths> {
	static readonly pathKey = "potentialAnalysis";
	static readonly idKey = "potentialAnalysisId";
	static readonly childPaths = [PotentialAnalysisEditPath];
}
