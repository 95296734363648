import { MiddlewareNextFn, RelayRequestAny } from "react-relay-network-modern";
import { toast } from "react-toastify";
import { logout } from "@store/slices/AuthSlice";
import { ReduxStore } from "@store/store.redux";
import { ERROR_MESSAGES, LOGOUT_ERRORS } from "../translations/error-messages";

const resolveErrorMessage = (errorKey: string): string =>
	ERROR_MESSAGES[errorKey] ?? "Ein unbekannter Fehler ist aufgetreten.";

export const ErrorHandlingMiddleware = (next: MiddlewareNextFn) => async (req: RelayRequestAny) => {
	const res = await next(req);

	const errors = res.errors?.map((error) => error.message?.toLowerCase()) || [];

	const needsToLogout = errors.some((error) => error in LOGOUT_ERRORS);
	if (needsToLogout) {
		ReduxStore.dispatch(logout());
	}

	errors.forEach((errorKey) => {
		const message = resolveErrorMessage(errorKey);
		toast.error(message);
	});

	return res;
};
