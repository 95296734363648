/**
 * @generated SignedSource<<003fcc1c402af10e42189d8aefa5a58b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type creditNoteDataForm_OrderFragment$data = {
  readonly creditNoteData: {
    readonly creditNoteFile: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly creditNoteId: string;
    readonly creditNoteNumber: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "creditNoteDataForm_OrderFragment";
};
export type creditNoteDataForm_OrderFragment$key = {
  readonly " $data"?: creditNoteDataForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"creditNoteDataForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "creditNoteDataForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreditNoteData",
      "kind": "LinkedField",
      "name": "creditNoteData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creditNoteId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creditNoteNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "creditNoteFile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "4479fa425f25a88cba38b853d0b6ae55";

export default node;
