import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type customerFeedbackScreen_Query } from "@relay/customerFeedbackScreen_Query.graphql";
import { BaseScreen } from "@screens/BaseScreen";
import { CustomerFeedbackScreenContext } from "@screens/customer-feedback/customer-feedback.context";
import { QUERY } from "@screens/customer-feedback/customer-feedback.graphql";
import { CreateCustomerFeedbackButton } from "@screens/customer-feedback/parts/create-customer-feedback-button/create-customer-feedback-button.component";
import { CustomerFeedbackTable } from "@screens/customer-feedback/parts/customer-feedback-table/customer-feedback-table.component";
import { Wrapper } from "@screens/products/products.styles";

export const CustomerFeedbackScreen = () => {
	const query = useLazyLoadQuery<customerFeedbackScreen_Query>(QUERY, {});
	const [connectionId, setConnectionId] = useState("");

	const canRead = useHasPermissions(["UserInAccountPermission_CustomerFeedback_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<CustomerFeedbackScreenContext.Provider value={{ connectionId, setConnectionId }}>
			<BaseScreen>
				<h1>Kundenfeedback</h1>
				<Wrapper>
					<CreateCustomerFeedbackButton />
				</Wrapper>
				<CustomerFeedbackTable queryFragmentRef={query} />
			</BaseScreen>
		</CustomerFeedbackScreenContext.Provider>
	);
};
