import { Button, Dialog, DataTable, EpicP1Span } from "@thekeytechnology/epic-ui";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useState, type FC } from "react";
import { Spacing32 } from "@features/licenses/licenses-table/licenses-table.styles";
import { type TrendCustomerFeedbackFormProps } from "./trend-customer-feedback-form.types";
import { StyledP1Span, NameWrapper } from "../trend-form/trend-form.styles";
import { type CustomerFeedback } from "../trend-form/trend-form.types";

export const TrendCustomerFeedbackForm: FC<TrendCustomerFeedbackFormProps> = ({
	formik,
	feedbacks,
}) => {
	const [selectedFeedbacks, setSelectedFeedbacks] = useState<CustomerFeedback[] | undefined>([]);
	const [isCustomerFeedbackModalOpen, setIsCustomerFeedbackModalOpen] = useState<boolean>(false);

	useEffect(() => {
		const selectedIds = formik.values.customerFeedbackIds || [];
		const initialSelectedFeedbacks = feedbacks?.filter((feedback) =>
			selectedIds.includes(feedback.id!),
		);
		setSelectedFeedbacks(initialSelectedFeedbacks);
	}, [formik.values.customerFeedbackIds, feedbacks]);

	const handleSelectAllFeedbacks = () => {
		if (selectedFeedbacks?.length === feedbacks?.length) {
			setSelectedFeedbacks([]);
		} else {
			setSelectedFeedbacks(feedbacks?.map((feedback) => feedback));
		}
	};

	const handleFeedbackCheckboxChange = (feedback: CustomerFeedback) => {
		const isSelected = selectedFeedbacks?.some(
			(selectedFeedback) => selectedFeedback.id === feedback.id,
		);
		if (isSelected) {
			setSelectedFeedbacks(
				selectedFeedbacks?.filter(
					(selectedFeedback) => selectedFeedback.id !== feedback.id,
				),
			);
		} else {
			selectedFeedbacks && setSelectedFeedbacks([...selectedFeedbacks, feedback]);
		}
	};

	const handleSubmitCustomerFeedbacks = () => {
		formik.setFieldValue(
			"customerFeedbackIds",
			selectedFeedbacks?.map((feedback) => feedback.id),
		);
		setIsCustomerFeedbackModalOpen(false);
	};
	return (
		<>
			<h4>Kunden Info</h4>
			<Button
				label={
					formik.values.customerFeedbackIds?.length > 0
						? "Kundenfeedback Auswahl bearbeiten"
						: "Kundenfeedback hinzufügen"
				}
				onClick={() => {
					setIsCustomerFeedbackModalOpen(true);
				}}
			/>
			<Dialog
				title={
					formik.values.customerFeedbackIds?.length > 0
						? "Auswahl bearbeiten"
						: "Kundenfeedback hinzufügen"
				}
				visible={isCustomerFeedbackModalOpen}
				onHide={() => {
					setIsCustomerFeedbackModalOpen(false);
				}}
			>
				<StyledP1Span onClick={handleSelectAllFeedbacks}>
					{selectedFeedbacks?.length === feedbacks?.length
						? "Alle abwählen"
						: `Alle auswählen (${feedbacks?.length})`}
				</StyledP1Span>
				<DataTable
					emptyMessage={<p>Du hast noch kein Kundenfeedback angelegt.</p>}
					value={feedbacks}
				>
					{(Column) => (
						<>
							<Column
								header="Name"
								body={(rowData) => {
									return (
										<NameWrapper>
											<Checkbox
												checked={
													selectedFeedbacks?.some(
														(selectedFeedback) =>
															selectedFeedback.id === rowData.id,
													) ?? false
												}
												onChange={() => {
													handleFeedbackCheckboxChange(rowData);
												}}
											/>
											<p>{rowData.name}</p>
										</NameWrapper>
									);
								}}
							/>
							<Column header="Feedback" field="feedback" />
						</>
					)}
				</DataTable>
				<Spacing32 />
				<Button label="Ausgewählte hinzufügen" onClick={handleSubmitCustomerFeedbacks} />
			</Dialog>
			<EpicP1Span>
				{formik.values.customerFeedbackIds?.length} Kundenfeedbacks ausgewählt
			</EpicP1Span>
		</>
	);
};
