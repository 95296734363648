import { graphql } from "babel-plugin-relay/macro";

export const EDIT_BUSINESS_DATA_MUTATION = graphql`
	mutation editBusinessDataButton_EditBusinessDataMutation(
		$input: EditBusinessDataInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				editBusinessData(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...coachProfilesTable_CoachProfileFragment
						}
					}
				}
			}
		}
	}
`;

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment editBusinessDataButton_CoachProfileFragment on CoachProfile {
		id
		businessData {
			bic
			employerNumber
			iban
			insuranceNumber
			taxNumber
		}
	}
`;
