import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ContractPartnerScreen } from "./contract-partners.screen";

export const ContractPartnersRoutes: RouteDefinition[] = [
	{
		path: Paths.contractPartners.path,
		element: <ContractPartnerScreen />,
		requiredPermissions: ["UserInAccountPermission_ContractPartnerAdmin_Read"],
	},
];
