/**
 * @generated SignedSource<<abfe8d5e9ddbecdc139c8125ef1e6ecc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editExternalLicenseForm_ExternalLicenseFragment$data = {
  readonly data: {
    readonly code: string;
    readonly name: string;
  };
  readonly id: string;
  readonly issuingInfo: {
    readonly hasBeenIssued: boolean;
  };
  readonly pool: {
    readonly data: {
      readonly usageInformation: string;
      readonly validNumDaysAfterIssuing: number;
    };
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "editExternalLicenseForm_ExternalLicenseFragment";
};
export type editExternalLicenseForm_ExternalLicenseFragment$key = {
  readonly " $data"?: editExternalLicenseForm_ExternalLicenseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editExternalLicenseForm_ExternalLicenseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editExternalLicenseForm_ExternalLicenseFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalLicenseData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalLicensePool",
      "kind": "LinkedField",
      "name": "pool",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ExternalLicensePoolData",
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "validNumDaysAfterIssuing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usageInformation",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "issuingInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasBeenIssued",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalLicense",
  "abstractKey": null
};
})();

(node as any).hash = "94bb3bcba4acb93aee5892d148869b8a";

export default node;
