import { graphql } from "babel-plugin-relay/macro";

export const REWARD_FRAGMENT = graphql`
	fragment educationalParticipationCertificateRewardForm_RewardFragment on Reward {
		... on ParticipationCertificateReward {
			id
			data {
				name
				html
				variables
			}
		}
	}
`;

export const EDIT_EDUCATIONAL_PARTICIPATION_CERTIFICATE_REWARD_MUTATION = graphql`
	mutation educationalParticipationCertificateRewardForm_EditEducationalParticipationCertificateRewardMutation(
		$input: EditEducationalParticipationCertificateRewardInput!
	) {
		Admin {
			Tree {
				editEducationalParticipationCertificateReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
