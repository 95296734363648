import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextAreaComponent } from "@components/DefaultTextInput";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { ValidatedField } from "@components/ValidatedField";
import { type educationalParticipationCertificateRewardForm_EditEducationalParticipationCertificateRewardMutation } from "@relay/educationalParticipationCertificateRewardForm_EditEducationalParticipationCertificateRewardMutation.graphql";
import { type educationalParticipationCertificateRewardForm_RewardFragment$key } from "@relay/educationalParticipationCertificateRewardForm_RewardFragment.graphql";
import {
	EDIT_EDUCATIONAL_PARTICIPATION_CERTIFICATE_REWARD_MUTATION,
	REWARD_FRAGMENT,
} from "@screens/educational-offer/parts/educational-participation-certificate-reward-form/educational-participation-certificate-reward-form.graphql";
import {
	type EducationalParticipationCertificateRewardFormProps,
	type EducationalParticipationCertificateRewardFormState,
} from "@screens/educational-offer/parts/educational-participation-certificate-reward-form/educational-participation-certificate-reward-form.types";
import { addEditedFormToEditedFormsArray, resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";

export const EducationalParticipationCertificateRewardForm = ({
	contentNodeId,
	participationCertificateRewardFragmentRef,
	onBack: handleOnClick,
}: EducationalParticipationCertificateRewardFormProps) => {
	const reward = useFragment<educationalParticipationCertificateRewardForm_RewardFragment$key>(
		REWARD_FRAGMENT,
		participationCertificateRewardFragmentRef,
	);
	const [editReward, isEditingReward] =
		useMutation<educationalParticipationCertificateRewardForm_EditEducationalParticipationCertificateRewardMutation>(
			EDIT_EDUCATIONAL_PARTICIPATION_CERTIFICATE_REWARD_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formik = useFormik<EducationalParticipationCertificateRewardFormState>({
		initialValues: {
			name: reward.data?.name ?? "",
			html: reward.data?.html ?? "",
			variables: reward.data?.variables ? [...reward.data?.variables] : [],
		},
		validationSchema: Yup.object().shape({
			wordPackage: Yup.object()
				.shape({
					id: Yup.string(),
					name: Yup.string(),
				})
				.required("Bitte wähle ein Wortpaket aus"),
		}),
		onSubmit: (values, formikHelpers) => {
			if (!values.html && !values.variables) return;
			editReward({
				variables: {
					input: {
						contentId: contentNodeId,
						rewardId: reward.id!,
						newData: { html: values.html, variables: [], name: values.name },
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});
	const isDisabled = Object.entries(formik.touched).length === 0 || isEditingReward;
	const handleFormEdited = useCallback(
		() =>
			dispatch(
				addEditedFormToEditedFormsArray({
					form: "participation-certificate-reward-form-" + reward.id,
				}),
			),
		[dispatch],
	);

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<EducationalParticipationCertificateRewardFormState, string>
				required
				name={"name"}
				label="Name"
				placeholder="Name..."
				formikConfig={formik}
				component={(renderConfig) => <DefaultTextAreaComponent {...renderConfig} />}
			/>

			<ValidatedField<EducationalParticipationCertificateRewardFormState, string>
				required
				name={"html"}
				label="Html"
				placeholder="Html..."
				formikConfig={formik}
				component={(renderConfig) => (
					<MemoizedRichTextEditor
						{...renderConfig}
						toolbar={RichTextEditorToolbar.Default}
						onChange={handleFormEdited}
					/>
				)}
			/>
			<div>
				<h3>Verfügbare Variabeln</h3>
				{reward.data?.variables?.map((k, i, arr) => (
					<span key={k}>
						{k}
						{i + 1 === arr.length ? "" : ", "}
					</span>
				))}
			</div>

			<Button disabled={isDisabled} type="submit" label="Speichern" className="mt-2" />

			<Button
				type="button"
				onClick={handleOnClick}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
