import React from "react";

export type ICustomerFeedbackContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
};

export const CustomerFeedbackScreenContext = React.createContext<ICustomerFeedbackContext>({
	connectionId: "",
	setConnectionId: () => {},
});
