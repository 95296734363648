/**
 * @generated SignedSource<<64a3774f144031926edf3d6b9380eb5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment$data = ReadonlyArray<{
  readonly calendarWeek: number;
  readonly calendarYear: number;
  readonly dayOfWeek: any;
  readonly timeSlots: ReadonlyArray<any>;
  readonly " $fragmentSpreads": FragmentRefs<"editExceptionModal_DayAndTimesForCalendarWeekFragment" | "exceptionsTable_DayAndTimesForCalendarWeekFragment">;
  readonly " $fragmentType": "editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment";
}>;
export type editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment$key = ReadonlyArray<{
  readonly " $data"?: editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSlots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "exceptionsTable_DayAndTimesForCalendarWeekFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editExceptionModal_DayAndTimesForCalendarWeekFragment"
    }
  ],
  "type": "DayAndTimesForCalendarWeek",
  "abstractKey": null
};

(node as any).hash = "1a00fce41d1ceea41b3f5e2a609f7daa";

export default node;
