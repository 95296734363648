import { type LearnableKind } from "../../../../__generated__/offersTable_QueryFragment.graphql";

export const learnableKindTranslations: Record<LearnableKind, string> = {
	Course: "Weiterbildung",
	MicroLearning: "Micro-Learning",
	GroupCoaching: "Gruppencoachingangebot",
	OneToOneCoaching: "1-1 Coachingsangebot",
};

export const DEFAULT_ROWS_COUNT = 20;
