/**
 * @generated SignedSource<<5c394ab6ba2c092ae71b7d0e7bfd27bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectWordPackagesField_WordPackageFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectWordPackagesField_WordPackageFragment";
};
export type selectWordPackagesField_WordPackageFragment$key = {
  readonly " $data"?: selectWordPackagesField_WordPackageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectWordPackagesField_WordPackageFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectWordPackagesField_WordPackageFragment"
};

(node as any).hash = "719062e09c1c9cac32366d762e399aac";

export default node;
