import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation } from "@relay/setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation.graphql";
import { setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment$key } from "@relay/setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment.graphql";
import {
	SET_VIMEO_VIDEO_SOURCE_AS_DEFAULT_MUTATION,
	VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
} from "@screens/video-sources/parts/set-video-source-as-default-button/set-video-source-as-default-button.graphql";
import { SetVideoSourceAsDefaultButtonProps } from "@screens/video-sources/parts/set-video-source-as-default-button/set-video-source-as-default-button.types";

export const SetVideoSourceAsDefaultButton = ({
	videoSourceFragmentRef,
	onRefresh,
}: SetVideoSourceAsDefaultButtonProps) => {
	const toast = useRef<Toast>(null);

	const videoSource =
		useFragment<setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment$key>(
			VIMEO_VIMEO_VIDEO_SOURCE_FRAGMENT,
			videoSourceFragmentRef,
		);

	const [setVideoSourceAsDefault, isSettingVideoSourceAsDefault] =
		useMutation<setVideoSourceAsDefaultButton_setVimeoVideoSourceAsDefaultMutation>(
			SET_VIMEO_VIDEO_SOURCE_AS_DEFAULT_MUTATION,
		);

	const handleSetVideoSourceAsDefault = () =>
		setVideoSourceAsDefault({
			variables: {
				input: {
					id: videoSource.id,
				},
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Video-Quelle als Standard gesetzt",
					detail: "Die Video-Quelle wurde erfolgreich als Standard gesetzt",
				});
				onRefresh();
			},
		});

	return (
		<>
			<Toast ref={toast} />
			<Button
				icon={EpicIcons.BOOKMARK}
				variant={ButtonVariant.Strong}
				tooltip="Video-Quelle als Standard setzen"
				loading={isSettingVideoSourceAsDefault}
				disabled={isSettingVideoSourceAsDefault || videoSource.isDefault}
				onClick={handleSetVideoSourceAsDefault}
			/>
		</>
	);
};
