/**
 * @generated SignedSource<<52514571f22893b78d6e0f474cf3ab9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordPackageRewardForm_Query$variables = Record<PropertyKey, never>;
export type wordPackageRewardForm_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectWordPackageField_QueryFragment">;
};
export type wordPackageRewardForm_Query = {
  response: wordPackageRewardForm_Query$data;
  variables: wordPackageRewardForm_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "wordPackageRewardForm_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "selectWordPackageField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "wordPackageRewardForm_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WordPackageConnection",
                "kind": "LinkedField",
                "name": "WordPackages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackageEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordPackage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "filterByName",
                  "alwaysInclude"
                ],
                "handle": "connection",
                "key": "selectWordPackagesField_WordPackages",
                "kind": "LinkedHandle",
                "name": "WordPackages"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4121c4c423805153d3f48473b8435f63",
    "id": null,
    "metadata": {},
    "name": "wordPackageRewardForm_Query",
    "operationKind": "query",
    "text": "query wordPackageRewardForm_Query {\n  ...selectWordPackageField_QueryFragment\n}\n\nfragment selectWordPackageField_QueryFragment on Query {\n  Admin {\n    Vocab {\n      WordPackages {\n        edges {\n          node {\n            ...selectWordPackageField_WordPackageFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n\nfragment selectWordPackageField_WordPackageFragment on WordPackage {\n  id\n  name\n}\n"
  }
};

(node as any).hash = "57080a40c2a50b9d8ac14d05e665c92c";

export default node;
