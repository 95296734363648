/**
 * @generated SignedSource<<197ac909cc4f1734b691127dfacc89a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TriggerResetPasswordInput = {
  clientMutationId?: string | null | undefined;
  userId: string;
};
export type userEditScreen_TriggerResetPasswordMutation$variables = {
  input: TriggerResetPasswordInput;
};
export type userEditScreen_TriggerResetPasswordMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly triggerResetPassword: {
        readonly __typename: "TriggerResetPasswordPayload";
      } | null | undefined;
    };
  };
};
export type userEditScreen_TriggerResetPasswordMutation = {
  response: userEditScreen_TriggerResetPasswordMutation$data;
  variables: userEditScreen_TriggerResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "TriggerResetPasswordPayload",
            "kind": "LinkedField",
            "name": "triggerResetPassword",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userEditScreen_TriggerResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userEditScreen_TriggerResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7d18a4816bd3cb8c79cd4aabf34743b7",
    "id": null,
    "metadata": {},
    "name": "userEditScreen_TriggerResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation userEditScreen_TriggerResetPasswordMutation(\n  $input: TriggerResetPasswordInput!\n) {\n  Admin {\n    Auth {\n      triggerResetPassword(input: $input) {\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c8c47ed3f9a01f8984f7992d25c9e59";

export default node;
