import { graphql } from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment rewardForm_TreeNodeFragment on TreeNode {
		id
	}
`;

export const REWARD_CONFIGURATION_FRAGMENT = graphql`
	fragment rewardForm_RewardFragment on Reward {
		id
		kind
		... on ExternalLicenseReward {
			...externalLicenseRewardForm_ExternalLicenseRewardFragment
		}
		... on WordPackageReward {
			...wordPackageRewardForm_WordPackageRewardFragment
		}
		...educationalParticipationCertificateRewardForm_RewardFragment
	}
`;
