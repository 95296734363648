import currencyFormatter from "currency-formatter";
import React from "react";

type OwnProps = {
	value: number;
	className?: string;
};

export const CurrencyDisplay = ({ value, className }: OwnProps) => {
	const price = currencyFormatter.format(value, {
		code: "EUR",
	});
	return <div className={className}>{price}</div>;
};
