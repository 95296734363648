import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useMutation } from "react-relay";
import { Button } from "@components/button";
import { type createMatrixElementButton_CreateMatrixElementMutation } from "@relay/createMatrixElementButton_CreateMatrixElementMutation.graphql";
import { CREATE_MATRIX_ELEMENT_MUTATION } from "./create-matrix-element-button.graphql";
import { type CreateMatrixElementButtonProps } from "./create-matrix-element-button.types";

export const CreateMatrixElementButton = ({
	eLearningContentNodeId,
}: CreateMatrixElementButtonProps) => {
	const [createMatrixElement, isCreatingMatrixElement] =
		useMutation<createMatrixElementButton_CreateMatrixElementMutation>(
			CREATE_MATRIX_ELEMENT_MUTATION,
		);

	const handleCreateOnClick = () => {
		createMatrixElement({
			variables: {
				input: {
					title: "Neues Element",
					correctAnswer: "korrekte Antwort",
					firstColumnTitle: "Neue Spalte",
					firstRowTitle: "Neue Zeile",
					eLearningContentId: eLearningContentNodeId,
				},
			},
		});
	};

	return (
		<Button
			disabled={isCreatingMatrixElement}
			tooltip={`Matrix-Baustein anlegen`}
			icon={EpicIcons.TH_LARGE}
			onClick={handleCreateOnClick}
		/>
	);
};
