/**
 * @generated SignedSource<<0a4b1ed40762a00ec6ac59b9144a0031>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductExtensionKind = "Academies";
import { FragmentRefs } from "relay-runtime";
export type placementProductForm_PlacementProductFragment$data = {
  readonly extensions: ReadonlyArray<{
    readonly costUnit?: string | null | undefined;
    readonly kind: ProductExtensionKind;
  }>;
  readonly id: string;
  readonly isHidden: boolean;
  readonly isTaxFree: boolean;
  readonly netPrice: any;
  readonly title: string;
  readonly " $fragmentType": "placementProductForm_PlacementProductFragment";
};
export type placementProductForm_PlacementProductFragment$key = {
  readonly " $data"?: placementProductForm_PlacementProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"placementProductForm_PlacementProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "placementProductForm_PlacementProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extensions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "costUnit",
              "storageKey": null
            }
          ],
          "type": "AcademiesProductExtension",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "6904c1ba118057c7efb6959172100a06";

export default node;
