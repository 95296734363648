import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { type FormikProps } from "formik";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation } from "@relay/editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation.graphql";
import { type editCoachingLicenseDefinitionButton_LicenseDefinitionFragment$key } from "@relay/editCoachingLicenseDefinitionButton_LicenseDefinitionFragment.graphql";
import {
	EDIT_COACHING_LICENSE_DEFINITION_MUTATION,
	LICENSE_DEFINITION_FRAGMENT,
} from "./edit-coaching-license-definition-button.graphql";
import { type EditCoachingLicenseDefinitionButtonProps } from "./edit-coaching-license-definition-button.types";

import { CoachingLicenseDefinitionForm } from "../coaching-license-definition-form";
import { type CoachingLicenseDefinitionFormState } from "../coaching-license-definition-form/coaching-license-definition-form.types";

export const EditCoachingLicenseDefinitionButton = ({
	licenseDefinitionFragmentRef,
}: EditCoachingLicenseDefinitionButtonProps) => {
	const licenseDefinition =
		useFragment<editCoachingLicenseDefinitionButton_LicenseDefinitionFragment$key>(
			LICENSE_DEFINITION_FRAGMENT,
			licenseDefinitionFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<editCoachingLicenseDefinitionButton_editCoachingLicenseDefinitionMutation>(
			EDIT_COACHING_LICENSE_DEFINITION_MUTATION,
		);
	const createHandleOnSubmit = (
		ref: React.MutableRefObject<FormikProps<CoachingLicenseDefinitionFormState> | null>,
		onHide: () => void,
		values: CoachingLicenseDefinitionFormState,
	) => {
		edit({
			variables: {
				input: {
					data: {
						coachingId: values.coachingId,
						name: values.name,
					},
					licenseDefinitionId: licenseDefinition.id,
				},
			},
			onCompleted: () => {
				ref.current?.setSubmitting(false);
				onHide();
				toast.success("Coaching-Lizenzdefinition bearbeitet.");
			},
			onError: () => {
				ref.current?.setSubmitting(false);
				toast.error("Fehler beim Bearbeiten der Coaching-Lizenzdefinition.");
			},
		});
	};

	return (
		<FormDialogButton<CoachingLicenseDefinitionFormState>
			disabled={isEditing}
			buttonContent={{
				icon: "pi pi-pencil",
			}}
			title={"Coaching-Lizenzdefintion bearbeiten"}
		>
			{(ref, onHide) => {
				return (
					<CoachingLicenseDefinitionForm
						initialState={{
							coachingId: licenseDefinition?.data?.coachingInfo?.coachingId ?? "",
							name: licenseDefinition?.data?.name ?? "",
						}}
						ref={ref}
						onSubmit={(values) => {
							createHandleOnSubmit(ref, onHide, values);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
