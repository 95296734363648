/**
 * @generated SignedSource<<8be4936c861573400951ea4f6b1b2a38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ordersTable_OrdersFragment$data = {
  readonly Admin: {
    readonly Billing: {
      readonly Orders: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"ordersTable_OrderFragment">;
          };
        } | null | undefined> | null | undefined;
      };
    };
  };
  readonly " $fragmentType": "ordersTable_OrdersFragment";
};
export type ordersTable_OrdersFragment$key = {
  readonly " $data"?: ordersTable_OrdersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ordersTable_OrdersFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Billing",
  "Orders"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "email"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "username"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ordersTable_OrdersFragmentRefetch.graphql')
    }
  },
  "name": "ordersTable_OrdersFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingAdminSchema",
          "kind": "LinkedField",
          "name": "Billing",
          "plural": false,
          "selections": [
            {
              "alias": "Orders",
              "args": [
                {
                  "kind": "Variable",
                  "name": "accountId",
                  "variableName": "accountId"
                },
                {
                  "kind": "Variable",
                  "name": "email",
                  "variableName": "email"
                },
                {
                  "kind": "Variable",
                  "name": "userId",
                  "variableName": "userId"
                },
                {
                  "kind": "Variable",
                  "name": "username",
                  "variableName": "username"
                }
              ],
              "concreteType": "OrdersConnection",
              "kind": "LinkedField",
              "name": "__OrdersTable_Orders_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrdersEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Order",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "ordersTable_OrderFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "createdAt",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "status",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "selectedPaymentMethod",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "selectedPaymentMethodType",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "orderPriceDisplay_OrderFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "paymentProviderLink_OrderFragment"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ec528d99d3b3d851a7899e6d37e1925b";

export default node;
