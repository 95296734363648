import { graphql } from "babel-plugin-relay/macro";

export const COACHING_OFFER_FRAGMENT = graphql`
	fragment deleteCoachingOfferButton_CoachingOfferFragment on UnpublishedLearnable {
		id
		... on UnpublishedGroupCoachingLearnable {
			groupCoaching {
				id
				description {
					name
				}
			}
		}
		... on UnpublishedOneToOneCoachingLearnable {
			oneToOneCoaching {
				id
				description {
					name
				}
			}
		}
	}
`;
export const DELETE_MUTATION = graphql`
	mutation deleteCoachingOfferButton_DeleteCoachingOfferMutation(
		$input: DeleteCoachingOfferInput!
		$connections: [ID!]!
	) {
		Admin {
			Coaching {
				deleteCoachingOffer(input: $input) {
					deletedUnpublishedLearnableId @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
