import tw from "twin.macro";

export const ModalContent = tw.div`
	tw-min-h-[30rem]
	tw-flex
	tw-flex-col
`;
export const ButtonWrapper = tw.div`
	tw-mt-auto
`;
export const SortableWrapper = tw.div`
	tw-flex
`;
export const PositionWrapper = tw.div`
	tw-p-[0.8rem]
`;
