/**
 * @generated SignedSource<<2628bbc650bc1ef6791b0b213bd358e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditContractPartnerDataInput = {
  clientMutationId?: string | null | undefined;
  data: ContractPartnerDataInput;
  id: string;
  shareAmountPercentage: number;
};
export type ContractPartnerDataInput = {
  association: string;
  city: string;
  country: string;
  email: string;
  houseNumber: string;
  linkToAGB?: string | null | undefined;
  name: string;
  postalCode: string;
  street: string;
  taxNumber: string;
};
export type editContractPartnerButton_EditContractPartnerMutation$variables = {
  input: EditContractPartnerDataInput;
};
export type editContractPartnerButton_EditContractPartnerMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly editContractPartnerData: {
        readonly contractPartner: {
          readonly " $fragmentSpreads": FragmentRefs<"editContractPartnerButton_ContractPartnerFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editContractPartnerButton_EditContractPartnerMutation = {
  response: editContractPartnerButton_EditContractPartnerMutation$data;
  variables: editContractPartnerButton_EditContractPartnerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editContractPartnerButton_EditContractPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditContractPartnerDataPayload",
                "kind": "LinkedField",
                "name": "editContractPartnerData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractPartner",
                    "kind": "LinkedField",
                    "name": "contractPartner",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editContractPartnerButton_ContractPartnerFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editContractPartnerButton_EditContractPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditContractPartnerDataPayload",
                "kind": "LinkedField",
                "name": "editContractPartnerData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractPartner",
                    "kind": "LinkedField",
                    "name": "contractPartner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shareAmountPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractPartnerData",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "association",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "street",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "houseNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "taxNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkToAGB",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e62284315281830bf0b4ad5645589ddf",
    "id": null,
    "metadata": {},
    "name": "editContractPartnerButton_EditContractPartnerMutation",
    "operationKind": "mutation",
    "text": "mutation editContractPartnerButton_EditContractPartnerMutation(\n  $input: EditContractPartnerDataInput!\n) {\n  Admin {\n    Billing {\n      editContractPartnerData(input: $input) {\n        contractPartner {\n          ...editContractPartnerButton_ContractPartnerFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editContractPartnerButton_ContractPartnerFragment on ContractPartner {\n  id\n  shareAmountPercentage\n  data {\n    email\n    name\n    association\n    street\n    houseNumber\n    city\n    postalCode\n    country\n    taxNumber\n    linkToAGB\n  }\n}\n"
  }
};
})();

(node as any).hash = "a153f5246356094b7a6824bc331845da";

export default node;
