import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type FeatureId } from "@relay/permissionBasedNavigation_Query.graphql";
import { type ReduxState } from "../store.redux";

export type FeatureTogglesState = {
	activeFeatureToggles?: FeatureId[];
};

const INITIAL_STATE: FeatureTogglesState = {};

const featureTogglesSlice = createSlice({
	name: "feature-toggles",
	initialState: INITIAL_STATE,
	reducers: {
		setActiveFeatureToggles: (state, action: PayloadAction<readonly FeatureId[]>) => {
			state.activeFeatureToggles = action.payload.slice();
		},
	},
});

export const { setActiveFeatureToggles } = featureTogglesSlice.actions;
export const FeatureTogglesSliceReducer = featureTogglesSlice.reducer;

const selectFeatureTogglesSlice = (state: ReduxState) => state.featureToggles;

export const selectActiveFeatureToggles = createSelector(
	selectFeatureTogglesSlice,
	(e) => e.activeFeatureToggles,
);
