import { graphql } from "babel-plugin-relay/macro";

export const AVAILABILITY_FRAGMENT = graphql`
	fragment editParticipantsButton_AvailabilityFragment on Availability {
		booking {
			kind
		}
		...editParticipantsTable_AvailabilityFragment
	}
`;
