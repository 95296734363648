import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, type DataTableSelectionSingleChangeEvent } from "primereact/datatable";

import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { type selectUserField_EditorsQuery } from "@relay/selectUserField_EditorsQuery.graphql";
import {
	type selectUserField_UsersQuery,
	type selectUserField_UsersQuery$data,
} from "@relay/selectUserField_UsersQuery.graphql";
import { EDITORS_QUERY, USERS_QUERY } from "./select-user-field.graphql";
import { type SelectUserFieldProps } from "./select-user-field.interface";

export const SelectUserField = ({
	updateField,
	fieldValue: selectedUserId,
	selectEditors,
	accountId,
}: SelectUserFieldProps) => {
	const environment = useRelayEnvironment();

	const [nameOrEmail, setNameOrEmail] = useState("");
	const [allUsers, setAllUsers] = useState<
		| Writeable<selectUserField_UsersQuery$data["Admin"]["Auth"]["SelectUsers"]["edges"]>
		| undefined
	>();

	const updateUsers = useCallback((updateNameOrEmail: string, accountId?: string) => {
		if (selectEditors) {
			void fetchQuery<selectUserField_EditorsQuery>(environment, EDITORS_QUERY, {
				nameOrEmail: updateNameOrEmail,
			})
				.toPromise()
				.then((result) => {
					setAllUsers(result!.Admin.Auth.SearchEditors.edges?.slice());
				});
		} else {
			void fetchQuery<selectUserField_UsersQuery>(environment, USERS_QUERY, {
				nameOrEmail: updateNameOrEmail,
				accountId,
			})
				.toPromise()
				.then((result) => {
					setAllUsers(result!.Admin.Auth.SelectUsers.edges?.slice());
				});
		}
	}, []);

	const handleOnSubmit = useCallback(() => {
		updateUsers(nameOrEmail, accountId);
	}, [nameOrEmail, updateUsers, accountId]);

	useEffect(handleOnSubmit, [accountId]);

	useEffect(() => {
		if (selectedUserId && !allUsers?.find((user) => user?.node.id === selectedUserId)) {
			updateField(undefined);
		}
	}, [allUsers]);

	const handleClearFormOnClick = () => {
		setNameOrEmail("");
		updateUsers("", accountId);
	};

	const handelNameOrEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setNameOrEmail(e.target.value);
	}, []);

	const handleUserSelected = useCallback((event: DataTableSelectionSingleChangeEvent<any>) => {
		updateField(event.value.node.id);
	}, []);

	return (
		<>
			<div className="flex flex-row align-items-center">
				<InputText
					value={nameOrEmail}
					onChange={handelNameOrEmailChange}
					className="mr-2"
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={handleClearFormOnClick}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					onClick={handleOnSubmit}
					type="button"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
			<DataTable
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">
							Es wurde kein Benutzer mit dieser E-Mail adresse oder diesem Namen
							gefunden.
						</div>
					</div>
				}
				className="mb-3"
				selectionMode="single"
				selection={{ node: { id: selectedUserId } }}
				dataKey="node.id"
				onSelectionChange={handleUserSelected}
				value={allUsers ?? []}
			>
				<Column header="Name" field="node.name" />
				<Column header="E-Mail" field="node.email" />
				<Column
					header="Aktiviert"
					body={(user) => {
						return user?.node?.activated ? "Aktiviert" : "Nicht Aktiviert";
					}}
				/>
			</DataTable>
		</>
	);
};
