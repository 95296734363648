import { DefaultSwitchComponent } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import * as React from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import { type editConstellationRootExtensionExtensionForm_RootExtensionImplFragment$key } from "@relay/editConstellationRootExtensionExtensionForm_RootExtensionImplFragment.graphql";
import { type editConstellationRootExtensionExtensionForm_SetConstellationRootExtensionExtensionMutation } from "@relay/editConstellationRootExtensionExtensionForm_SetConstellationRootExtensionExtensionMutation.graphql";
import {
	removeEditedFormFromEditedFormsArray,
	addEditedFormToEditedFormsArray,
} from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import {
	ROOT_EXTENSION_IMPL_FRAGMENT,
	SET_CONSTELLATION_ROOT_EXTENSION_EXTENSION_MUTATION,
} from "./edit-constellation-root-extension-extension.graphql";
import {
	type EditConstellationRootExtensionExtensionFormState,
	type EditConstellationRootExtensionExtensionFormProps,
} from "./edit-constellation-root-extension-extension.types";

export const EditConstellationRootExtensionExtensionForm = ({
	rootId,
	rootExtensionImplFragmentRef,
}: EditConstellationRootExtensionExtensionFormProps) => {
	const extension =
		useFragment<editConstellationRootExtensionExtensionForm_RootExtensionImplFragment$key>(
			ROOT_EXTENSION_IMPL_FRAGMENT,
			rootExtensionImplFragmentRef,
		);

	const dispatch = useTypedDispatch();

	const [setConstellationRootExtensionExtension, isSettingConstellationRootExtensionExtension] =
		useMutation<editConstellationRootExtensionExtensionForm_SetConstellationRootExtensionExtensionMutation>(
			SET_CONSTELLATION_ROOT_EXTENSION_EXTENSION_MUTATION,
		);

	const formId = "EditNodeExtensionForm";
	const formik = useFormik<EditConstellationRootExtensionExtensionFormState>({
		enableReinitialize: true,
		initialValues: {
			isMicroLearning: extension.extension.isMicroLearning,
		},
		validationSchema: Yup.object().shape({
			prefixTitle: Yup.string(),
			price: Yup.number().optional(),
			supplier: Yup.string().optional(),
			costOpt: Yup.object().optional(),
		}),
		onSubmit: (values, { setSubmitting }) => {
			setConstellationRootExtensionExtension({
				variables: {
					input: {
						rootId,
						isMicroLearning: !!values.isMicroLearning,
					},
				},
				onCompleted: () => {
					void formik.setTouched({});
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	const handleFormEdited = React.useCallback(
		() => dispatch(addEditedFormToEditedFormsArray({ form: formId })),
		[dispatch, formId],
	);

	return (
		<Card className="mb-2">
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditConstellationRootExtensionExtensionFormState, boolean>
					name={"isMicroLearning"}
					label={"ist Micro-Learning"}
					component={DefaultSwitchComponent}
					onChange={handleFormEdited}
					formikConfig={formik}
				/>

				<Button
					disabled={
						Object.entries(formik.touched).length === 0 ||
						isSettingConstellationRootExtensionExtension
					}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</Card>
	);
};
