/**
 * @generated SignedSource<<5466f98342e9705e024f5a62fd39f1ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookingKind = "BookedForGroup" | "BookedOneToOne" | "NotBooked";
import { FragmentRefs } from "relay-runtime";
export type editParticipantsButton_AvailabilityFragment$data = {
  readonly booking: {
    readonly kind: BookingKind;
  };
  readonly " $fragmentSpreads": FragmentRefs<"editParticipantsTable_AvailabilityFragment">;
  readonly " $fragmentType": "editParticipantsButton_AvailabilityFragment";
};
export type editParticipantsButton_AvailabilityFragment$key = {
  readonly " $data"?: editParticipantsButton_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editParticipantsButton_AvailabilityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editParticipantsButton_AvailabilityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editParticipantsTable_AvailabilityFragment"
    }
  ],
  "type": "Availability",
  "abstractKey": null
};

(node as any).hash = "22abb759433e7a62a470f4551453035a";

export default node;
