/**
 * @generated SignedSource<<78cfb65a56823132775c10a57090f251>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookingKind = "BookedForGroup" | "BookedOneToOne" | "NotBooked";
import { FragmentRefs } from "relay-runtime";
export type editParticipantsTable_AvailabilityFragment$data = {
  readonly booking: {
    readonly kind: BookingKind;
    readonly offer?: {
      readonly setting: {
        readonly maxParticipants?: number;
      };
    } | null | undefined;
    readonly participant?: {
      readonly " $fragmentSpreads": FragmentRefs<"editParticipantsTable_ParticipantFragment">;
    };
    readonly participants?: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"editParticipantsTable_ParticipantFragment">;
    }>;
  };
  readonly data: {
    readonly endDateTime: string;
    readonly startDateTime: string;
  };
  readonly id: string;
  readonly " $fragmentType": "editParticipantsTable_AvailabilityFragment";
};
export type editParticipantsTable_AvailabilityFragment$key = {
  readonly " $data"?: editParticipantsTable_AvailabilityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editParticipantsTable_AvailabilityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "editParticipantsTable_ParticipantFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "participationStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGroupAssociation",
            "kind": "LinkedField",
            "name": "groupAssociations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editParticipantsTable_AvailabilityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "booking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participant",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "BookedOneToOne",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CoachingOffer",
              "kind": "LinkedField",
              "name": "offer",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "setting",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "maxParticipants",
                          "storageKey": null
                        }
                      ],
                      "type": "GroupCoachingSetting",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Participant",
              "kind": "LinkedField",
              "name": "participants",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "BookedForGroup",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AvailabilityData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDateTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Availability",
  "abstractKey": null
};
})();

(node as any).hash = "f2b649cef926af4e4bc74a787390870e";

export default node;
