export const convertGroupName = (name: string) => {
	switch (name) {
		case "Owner":
			return "Besitzer";
		case "Editor":
			return "Redakteur";
		default:
			return name;
	}
};
