import { ButtonType, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import * as Yup from "yup";
import { Button } from "@components/button";
import { selectCoachingFieldDialog_Query } from "@relay/selectCoachingFieldDialog_Query.graphql";
import { QUERY } from "./select-coaching-field-dialog.graphql";
import {
	SelectCoachingFieldDialogProps,
	SelectCoachingFieldDialogState,
} from "./select-coaching-field-dialog.interface";

import { ButtonContainer } from "./select-coaching-field.styles";
import { ValidatedField } from "../../../components/ValidatedField";
import { SelectCoachingField } from "../select-coaching-field/select-coaching-field.component";

export const SelectCoachingFieldDialog = ({
	updateField,
	fieldValue: selectedCoachingId,
	disabled,
	onChange,
}: SelectCoachingFieldDialogProps) => {
	const { node: coaching } = useLazyLoadQuery<selectCoachingFieldDialog_Query>(
		QUERY,
		{
			coachingId: selectedCoachingId || "",
			skip: !selectedCoachingId,
		},
		{ fetchPolicy: "network-only" },
	);

	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const formik = useFormik<SelectCoachingFieldDialogState>({
		initialValues: {
			coachingId: selectedCoachingId,
		},
		validationSchema: Yup.object().shape({
			coachingId: Yup.string()
				.min(1, "Ein Coachingangebot wird benötigt.")
				.required("Ein Coachingangebot wird benötigt."),
		}),
		onSubmit: (values) => {
			updateField(values.coachingId);
			setModalOpen(false);
			onChange?.();
		},
	});

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectRootOnClick = () => {
		setModalOpen(true);
	};

	const handleUnselectRootOnClick = () => {
		updateField(undefined);
	};

	const name = selectedCoachingId
		? coaching?.description?.name
		: "Kein Coachingangebot ausgewählt";

	return (
		<>
			<ButtonContainer>
				<div className="flex-grow-1 flex bg-gray-100 border-gray-300 border-1 border-round  px-3 py-2">
					{name}
				</div>
				<Button
					type={ButtonType.Button}
					icon={EpicIcons.TIMES}
					disabled={!selectedCoachingId || disabled}
					onClick={handleUnselectRootOnClick}
				/>
				<Button
					type={ButtonType.Button}
					disabled={disabled}
					onClick={handleSelectRootOnClick}
					label="Coachingangebot auswählen"
				/>
			</ButtonContainer>

			<Dialog
				header="Coachingangebot auswählen"
				onHide={handleDialogOnHide}
				className="w-10"
				visible={isModalOpen}
				baseZIndex={3102}
			>
				<form onSubmit={formik.handleSubmit}>
					<ValidatedField<SelectCoachingFieldDialogState, string>
						className="mb-4"
						name={"coachingId"}
						label={"Wähle ein Coachingangebot aus"}
						formikConfig={{
							...formik,
							errors: { ...formik.errors, coachingId: undefined },
						}}
						component={SelectCoachingField}
					/>
					<Button
						disabled={!formik.isValid}
						label={"Auswählen"}
						icon={EpicIcons.CHECK}
						type={ButtonType.Submit}
					/>
				</form>
			</Dialog>
		</>
	);
};
