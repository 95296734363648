import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { ProductIdColumn_ProductFragment$key } from "@relay/ProductIdColumn_ProductFragment.graphql";

const PRODUCT_FRAGMENT = graphql`
	fragment ProductIdColumn_ProductFragment on Product {
		id
	}
`;

type OwnProps = {
	productFragmentRef: ProductIdColumn_ProductFragment$key;
};

export const ProductIdColumn = ({ productFragmentRef }: OwnProps) => {
	const product = useFragment<ProductIdColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return (
		<div className="cursor-pointer" onClick={() => navigator.clipboard.writeText(product.id)}>
			<b>
				ID<i className="pi pi-copy"></i>
			</b>
		</div>
	);
};
