import { PathBase, PathWithId } from "@thekeytechnology/epic-ui";

export class CoachingEditPath extends PathBase {
	static readonly pathKey = "edit";
}

export class CoachingsPath extends PathWithId<typeof CoachingsPath> {
	static readonly pathKey = "coachings";
	static readonly idKey = "coachingId";
	static readonly childPaths = [CoachingEditPath];
}
