import { useCheckPermissions } from "@hooks/use-check-permissions";

export const useCanReadAvgsPotentialAnalysis = () => {
	const checkPermissions = useCheckPermissions();

	const isOwnerOfRoot = checkPermissions("onlyOwnerOfRoot");

	const isAvgsAccount = checkPermissions([
		"AccountPermission_AVGSPotentialAnalysis_IsAVGSAccount",
	]);
	const isRootAccount = checkPermissions(["AccountPermission_System_Root"]);
	const isAvgsAdmin = checkPermissions([
		"UserInAccountPermission_AVGSPotentialAnalysis_IsAVGSAdmin",
	]);
	const isAvgsCoach = checkPermissions([
		"UserInAccountPermission_AVGSPotentialAnalysis_IsAVGSCoach",
	]);
	const isAccountOwner = checkPermissions(["UserInAccountPermission_System_Owner"]);
	const canReadAvgsPotentialAnalysis = checkPermissions([
		"UserInAccountPermission_AVGSPotentialAnalysis_ReadAdmin",
	]);

	return (
		isOwnerOfRoot ||
		(isRootAccount && canReadAvgsPotentialAnalysis) ||
		(isAvgsAccount && (isAccountOwner || isAvgsAdmin || isAvgsCoach))
	);
};

export const useCanModifyAvgsPotentialAnalysis = () => {
	const checkPermissions = useCheckPermissions();

	const isOwnerOfRoot = checkPermissions("onlyOwnerOfRoot");

	const isAvgsAccount = checkPermissions([
		"AccountPermission_AVGSPotentialAnalysis_IsAVGSAccount",
	]);
	const isRootAccount = checkPermissions(["AccountPermission_System_Root"]);
	const isAvgsCoach = checkPermissions([
		"UserInAccountPermission_AVGSPotentialAnalysis_IsAVGSCoach",
	]);
	const isAvgsAdmin = checkPermissions([
		"UserInAccountPermission_AVGSPotentialAnalysis_IsAVGSAdmin",
	]);
	const isAccountOwner = checkPermissions(["UserInAccountPermission_System_Owner"]);
	const canModifyAvgsPotentialAnalysis = checkPermissions([
		"UserInAccountPermission_AVGSPotentialAnalysis_ModifyAdmin",
	]);

	return (
		isOwnerOfRoot ||
		(isRootAccount && canModifyAvgsPotentialAnalysis) ||
		(isAvgsAccount && (isAccountOwner || isAvgsAdmin || isAvgsCoach))
	);
};
