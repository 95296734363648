import { EpicIcons, FormDialogButton } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type createCoachProfileButton_CreateCoachProfileMutation } from "@relay/createCoachProfileButton_CreateCoachProfileMutation.graphql";
import { type createCoachProfileButton_QueryFragment$key } from "@relay/createCoachProfileButton_QueryFragment.graphql";
import {
	CREATE_COACH_PROFILE_MUTATION,
	QUERY_FRAGMENT,
} from "./create-coach-profile-button.graphql";
import { type EditCoachProfileButtonProps } from "./create-coach-profile-button.types";
import { CoachProfilesScreenContext } from "../../coach-profiles.context";
import { CreateCoachProfileForm } from "../create-coach-profile-form";
import { type CreateCoachProfileFormState } from "../create-coach-profile-form/create-coach-profile-form.types";

export const CreateCoachProfileButton = ({ queryFragmentRef }: EditCoachProfileButtonProps) => {
	const { connectionId } = useContext(CoachProfilesScreenContext);
	const query = useFragment<createCoachProfileButton_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const [create, isCreating] = useMutation<createCoachProfileButton_CreateCoachProfileMutation>(
		CREATE_COACH_PROFILE_MUTATION,
	);
	const handleOnSubmit = async (values: CreateCoachProfileFormState) => {
		return await new Promise((resolve, reject) => {
			create({
				variables: {
					input: {
						baseData: values.baseData,
						accountId: values.account?.id ?? "",
						userId: values.userId,
					},
					connections: [connectionId],
				},
				onCompleted: () => {
					resolve(true);
					toast.success("Coach Profil angelegt.");
				},
				onError: () => {
					reject();
					toast.error("Fehler beim Anlegen des Coaches");
				},
			});
		});
	};

	const placeholderBirthDateString = new Date().toISOString().split("T")[0];

	return (
		<FormDialogButton<CreateCoachProfileFormState>
			dialogTitle="Coaching-Lizenzdefinition"
			disabled={isCreating}
			buttonIcon={EpicIcons.PLUS}
			tooltip="Coach erstellen"
		>
			{({ formikRef }) => {
				return (
					<CreateCoachProfileForm
						queryFragmentRef={query}
						initialState={{
							baseData: {
								birthDate: placeholderBirthDateString,
								address: {
									lineOne: "",
									lineTwo: "",
									city: "",
									postalCode: "",
								},
								birthPlace: "",
								gender: "Male",
								nationality: "",
							},
							account: {},
							userId: "",
						}}
						ref={formikRef}
						onSubmit={handleOnSubmit}
					/>
				);
			}}
		</FormDialogButton>
	);
};
