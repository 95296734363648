import { DefaultTextFieldComponent } from "@thekeytechnology/framework-react-components";
import { type FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { type EditBusinessDataFormState, type FormProps } from "./edit-business-data-form.types";
import { ValidatedField } from "../../../../components/ValidatedField";

export const EditBusinessDataForm = React.forwardRef<
	FormikProps<EditBusinessDataFormState>,
	FormProps<EditBusinessDataFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditBusinessDataFormState>({
		initialValues: initialState ?? {
			bic: "",
			iban: "",
			employerNumber: "",
			insuranceNumber: "",
			taxNumber: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<div className={"p-fluid w-30rem"}>
			<ValidatedField<EditBusinessDataFormState, string>
				name={"bic"}
				label={"BIC"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditBusinessDataFormState, string>
				name={"employerNumber"}
				label={"Arbeitsgebernummer"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditBusinessDataFormState, string>
				name={"iban"}
				label={"IBAN"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditBusinessDataFormState, string>
				name={"insuranceNumber"}
				label={"Versicherungsnummer"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<EditBusinessDataFormState, string>
				name={"taxNumber"}
				label={"Steuernummer"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
		</div>
	);
});
EditBusinessDataForm.displayName = "EditBusinessDataForm";
