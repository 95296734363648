import { EpicIcons, Icon } from "@thekeytechnology/epic-ui";
import React from "react";
import { useFragment } from "react-relay";
import { productTaxFreeColumn_ProductFragment$key } from "@relay/productTaxFreeColumn_ProductFragment.graphql";
import { PRODUCT_FRAGMENT } from "@screens/products/parts/products-tax-free-column/product-tax-free-column.graphql";
import { ProductTaxFreeColumnProps } from "@screens/products/parts/products-tax-free-column/product-tax-free-column.types";

export const ProductTaxFreeColumn = ({ productFragmentRef }: ProductTaxFreeColumnProps) => {
	const product = useFragment<productTaxFreeColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return <Icon icon={product.isTaxFree ? EpicIcons.CHECK : EpicIcons.TIMES} sizeRem={1.5} />;
};
