import { useFragment } from "react-relay";
import { videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$key } from "@relay/videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment.graphql";
import { DeleteVideoSourceButton } from "@screens/video-sources/parts/delete-video-source-button/delete-video-source-button.component";
import { EditVideoSourceButton } from "@screens/video-sources/parts/edit-video-source-button/edit-video-source-button.component";
import { SetVideoSourceAsDefaultButton } from "@screens/video-sources/parts/set-video-source-as-default-button/set-video-source-as-default-button.component";

import { VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT } from "@screens/video-sources/parts/video-sources-table-action-column/video-sources-table-action-column.graphql";
import { wrapperClass } from "@screens/video-sources/parts/video-sources-table-action-column/video-sources-table-action-column.styles";
import { VideoSourcesTableActionColumnProps } from "@screens/video-sources/parts/video-sources-table-action-column/video-sources-table-action-column.types";

export const VideoSourcesTableActionColumn = ({
	videoSourceFragmentRef,
	onRefresh,
}: VideoSourcesTableActionColumnProps) => {
	const videoSource =
		useFragment<videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment$key>(
			VIDEO_SOURCE_VIDEO_SOURCE_FRAGMENT,
			videoSourceFragmentRef,
		);

	return (
		<div className={wrapperClass}>
			<EditVideoSourceButton videoSourceFragmentRef={videoSource} />
			<SetVideoSourceAsDefaultButton
				videoSourceFragmentRef={videoSource}
				onRefresh={onRefresh}
			/>
			<DeleteVideoSourceButton videoSourceFragmentRef={videoSource} onRefresh={onRefresh} />
		</div>
	);
};
