/**
 * @generated SignedSource<<41c0c00a934fa6e0d618470c5b645e23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeUsageTable_DiscountCodeFragment$data = {
  readonly node: {
    readonly searchUsages?: {
      readonly usages: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeUsageTable_DiscountCodeUsageFragment">;
          };
        } | null | undefined> | null | undefined;
      };
      readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment">;
    };
  } | null | undefined;
  readonly " $fragmentType": "DiscountCodeUsageTable_DiscountCodeFragment";
};
export type DiscountCodeUsageTable_DiscountCodeFragment$key = {
  readonly " $data"?: DiscountCodeUsageTable_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeUsageTable_DiscountCodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "searchUsages",
  "usages"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByUsedAtFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByUsedToFrom"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./DiscountCodeUsageTable_Refetch.graphql')
    }
  },
  "name": "DiscountCodeUsageTable_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "after",
                  "variableName": "after"
                },
                {
                  "kind": "Variable",
                  "name": "filterByUsedAtFrom",
                  "variableName": "filterByUsedAtFrom"
                },
                {
                  "kind": "Variable",
                  "name": "filterByUsedToFrom",
                  "variableName": "filterByUsedToFrom"
                },
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "first"
                }
              ],
              "concreteType": "SearchDiscountCodeUsagesResult",
              "kind": "LinkedField",
              "name": "searchUsages",
              "plural": false,
              "selections": [
                {
                  "alias": "usages",
                  "args": null,
                  "concreteType": "DiscountCodeUsagesConnection",
                  "kind": "LinkedField",
                  "name": "__DiscountCodeUsageTable_usages_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DiscountCodeUsagesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DiscountCodeUsage",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "InlineDataFragmentSpread",
                              "name": "DiscountCodeUsageTable_DiscountCodeUsageFragment",
                              "selections": [
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "UsageUsedAtColumn_DiscountCodeUsageFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "UsageOrderColumn_DiscountCodeUsageFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "UsageIdColumn_DiscountCodeUsageFragment"
                                },
                                {
                                  "args": null,
                                  "kind": "FragmentSpread",
                                  "name": "UsageInvoiceColumn_DiscountCodeUsageFragment"
                                }
                              ],
                              "args": null,
                              "argumentDefinitions": []
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DiscountCode",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a11ba6c0f22423ce42f5aeb398a08e20";

export default node;
