import graphql from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment createCrmTreeHeadUpdaterRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

export const ADD_CRM_TREE_HEAD_UPDATER_REWARD_MUTATION = graphql`
	mutation createCrmTreeHeadUpdaterRewardButton_AddCrmTreeHeadUpdaterRewardMutation(
		$input: AddCrmTreeHeadUpdaterRewardInput!
	) {
		Admin {
			Tree {
				addCrmTreeHeadUpdaterReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
