/**
 * @generated SignedSource<<7f6852812486b0813d572dcfb67a2c0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteExternalLicensePoolInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteExternalLicensePoolInput;
};
export type deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly deleteExternalLicensePool: {
        readonly clientMutationId: string | null | undefined;
        readonly id: string;
      } | null | undefined;
    };
  };
};
export type deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation = {
  response: deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation$data;
  variables: deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteExternalLicensePoolPayload",
                "kind": "LinkedField",
                "name": "deleteExternalLicensePool",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteExternalLicensePoolPayload",
                "kind": "LinkedField",
                "name": "deleteExternalLicensePool",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ed7648fff97a36884a60e9ff3234f9f",
    "id": null,
    "metadata": {},
    "name": "deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation",
    "operationKind": "mutation",
    "text": "mutation deleteExternalLicensePoolButton_DeleteExternalLicensePoolMutation(\n  $input: DeleteExternalLicensePoolInput!\n) {\n  Admin {\n    Billing {\n      deleteExternalLicensePool(input: $input) {\n        clientMutationId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7e8e491dd6465d88050e7c290f3b832";

export default node;
