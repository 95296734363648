import { Button } from "primereact/button";
import React from "react";

type OwnProps = {
	hasNext: boolean;
	loadNext: () => void;
};

export const Pagination = ({ hasNext, loadNext }: OwnProps) => {
	return hasNext ? (
		<div className="flex pt-4 justify-content-center">
			<Button label="Mehr laden..." onClick={loadNext} />
		</div>
	) : null;
};
