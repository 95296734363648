import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

export type ICreatePaModalsFlowContext = {
	contextState: ContextState;
	setContextState: Dispatch<SetStateAction<ContextState>>;
	resetContextState: () => void;
};
export type ContextState = {
	email: string;
	substituteCoachId?: string;
	coachId?: string;
	accountId?: string;
	moduleWithDimensionIds: number[];
};

export const CreatePAModalsFlowContext = createContext<ICreatePaModalsFlowContext>({
	contextState: {
		email: "",
		moduleWithDimensionIds: [],
	},
	setContextState: () => {},
	resetContextState: () => {},
});

export const CreatePAModalsFlowContextProvider = ({ children }: PropsWithChildren) => {
	const [values, setValues] = useState<ContextState>({
		email: "",
		moduleWithDimensionIds: [],
	});
	const handleOnReset = () => {
		setValues(
			() =>
				({
					substituteCoachId: undefined,
					coachId: undefined,
					email: "",
					moduleWithDimensionIds: [],
				}) satisfies ContextState,
		);
	};
	const value: ICreatePaModalsFlowContext = {
		contextState: values,
		setContextState: setValues,
		resetContextState: handleOnReset,
	};
	return (
		<CreatePAModalsFlowContext.Provider value={value}>
			{children}
		</CreatePAModalsFlowContext.Provider>
	);
};
