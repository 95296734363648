import React from "react";

export type ICoachProfilesContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
};

export const CoachProfilesScreenContext = React.createContext<ICoachProfilesContext>({
	connectionId: "",
	setConnectionId: () => {},
});
