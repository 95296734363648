/**
 * @generated SignedSource<<9ba333db791b0c2bed9aa093afc38723>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductIdColumn_ProductFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "ProductIdColumn_ProductFragment";
};
export type ProductIdColumn_ProductFragment$key = {
  readonly " $data"?: ProductIdColumn_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductIdColumn_ProductFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductIdColumn_ProductFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "0c7b7ca0731af21c91955f97c02c29cb";

export default node;
