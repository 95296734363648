/**
 * @generated SignedSource<<1bf847f294c3e538d288c9340ded4350>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate" | "ParticipationCertificate" | "WordPackage" | "WordProficiencies";
import { FragmentRefs } from "relay-runtime";
export type rewardEditor_RewardInlineFragment$data = {
  readonly data?: {
    readonly name?: string;
    readonly pool: {
      readonly data: {
        readonly name: string;
      };
    } | null | undefined;
    readonly wordPackage?: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
  };
  readonly id: string;
  readonly kind: RewardKind;
  readonly " $fragmentSpreads": FragmentRefs<"rewardForm_RewardFragment">;
  readonly " $fragmentType": "rewardEditor_RewardInlineFragment";
};
export type rewardEditor_RewardInlineFragment$key = {
  readonly " $data"?: rewardEditor_RewardInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rewardEditor_RewardInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "rewardEditor_RewardInlineFragment"
};

(node as any).hash = "996c3564559994a34d4a1e91349f0c0c";

export default node;
