import { EpicIcons, Button } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useMutation } from "react-relay";
import { deleteCustomerFeedbackButton_DeleteCustomerFeedbackMutation } from "@relay/deleteCustomerFeedbackButton_DeleteCustomerFeedbackMutation.graphql";
import { CustomerFeedbackScreenContext } from "@screens/customer-feedback/customer-feedback.context";
import { DELETE_CUSTOMER_FEEDBACK_MUTATION } from "@screens/customer-feedback/parts/delete-customer-feedback-button/delete-customer-feedback-button.graphql";
import { DeleteCustomerFeedbackButtonProps } from "@screens/customer-feedback/parts/delete-customer-feedback-button/delete-customer-feedback-button.types";

export const DeleteCustomerFeedbackButton = ({
	customerFeedbackId,
}: DeleteCustomerFeedbackButtonProps) => {
	const toast = useRef<Toast>(null);

	const { connectionId } = useContext(CustomerFeedbackScreenContext);
	const [deleteCustomerFeedback, isDeletingCustomerFeedback] =
		useMutation<deleteCustomerFeedbackButton_DeleteCustomerFeedbackMutation>(
			DELETE_CUSTOMER_FEEDBACK_MUTATION,
		);

	const handleDeleteCustomerFeedback = () =>
		deleteCustomerFeedback({
			variables: {
				input: {
					ids: [customerFeedbackId],
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Kundenfeedback gelöscht",
					detail: "Das Kundenfeedback wurde erfolgreich gelöscht",
				});
			},
		});

	return (
		<>
			<Toast ref={toast} />
			<Button
				icon={EpicIcons.TRASH}
				tooltip="Kundenfeedback löschen"
				loading={isDeletingCustomerFeedback}
				disabled={isDeletingCustomerFeedback}
				onClick={handleDeleteCustomerFeedback}
			/>
		</>
	);
};
