import { useFormik } from "formik";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useHasPermissions } from "@hooks/use-has-permissions";
import {
	type TimestampWithImage,
	type TimestampWithImageFormProps,
} from "./timestamp-with-image-form.interface";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { FileSelectionField, type FileV2 } from "../../../../features/files/file-selection-field";
import { addEditedFormToEditedFormsArray } from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

export const TimestampWithImageForm = ({
	timestampWithImage,
	onSave,
	onBack,
}: TimestampWithImageFormProps) => {
	const formId = "TimestampWithImageFormComponent";

	const dispatch = useTypedDispatch();

	const formik = useFormik<TimestampWithImage>({
		initialValues: {
			tempId: timestampWithImage.tempId,
			start: timestampWithImage.start,
			end: timestampWithImage.end,
			imageFile: timestampWithImage.imageFile,
		},
		validationSchema: Yup.object().shape({
			start: Yup.string()
				.matches(RegExp("^(?:(?:([01]?\\d|2[0-3]):)?([0-5]?\\d):)?([0-5]?\\d)$"))
				.required("Das Feld Start wird benötigt."),
			end: Yup.string()
				.matches(RegExp("^(?:(?:([01]?\\d|2[0-3]):)?([0-5]?\\d):)?([0-5]?\\d)$"))
				.required("Das Feld Ende wird benötigt."),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSave({ ...values, imageFileId: values.imageFile?.id });
			setSubmitting(false);
			toast.success("Zeitintervall gespeichert");
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);

	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<TimestampWithImage, string>
				name={"start"}
				label={"Start"}
				required
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<DefaultTextFieldComponent
							fieldName={fieldName + "-string"}
							isValid={isValid}
							disabled={false}
							fieldValue={fieldValue}
							required
							updateField={(value) => {
								updateField(value);
								dispatch(addEditedFormToEditedFormsArray({ form: formId }));
							}}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<TimestampWithImage, string>
				name={"end"}
				label={"Ende"}
				required
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<DefaultTextFieldComponent
							fieldName={fieldName + "-string"}
							isValid={isValid}
							disabled={false}
							fieldValue={fieldValue}
							required
							updateField={(value) => {
								updateField(value);
								dispatch(addEditedFormToEditedFormsArray({ form: formId }));
							}}
						/>
					);
				}}
				formikConfig={formik}
			/>
			<ValidatedField<TimestampWithImage, FileV2>
				name={"imageFile"}
				label={"Bild Datei"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldName, fieldValue, updateField, onChange }) => {
					return (
						<FileSelectionField
							name={fieldName}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							onChange={onChange}
							canUploadFiles={canUpload}
							canDeleteFiles={canDelete}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button type="submit" label="Speichern" className="mt-2" />

			<Button
				type="button"
				onClick={onBack}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
