export type FileTypeOption = {
	label: string;
	value: string;
};

export const PNGFileTypeOption: FileTypeOption = { label: "png", value: "image/png" };
export const JPGFileTypeOption: FileTypeOption = { label: "jpg", value: "image/jpg" };
export const JPEGFileTypeOption: FileTypeOption = { label: "jpeg", value: "image/jpeg" };
export const BMPFileTypeOption: FileTypeOption = { label: "bmp", value: "image/bmp" };
export const SVGFileTypeOption: FileTypeOption = { label: "svg", value: "image/svg+xml" };
export const GIFFileTypeOption: FileTypeOption = { label: "gif", value: "image/gif" };
export const PDFFileTypeOption: FileTypeOption = { label: "pdf", value: "application/pdf" };
export const HTMLFileTypeOption: FileTypeOption = { label: "html", value: "text/html" };
export const MP4FileTypeOption: FileTypeOption = { label: "mp4", value: "video/mp4" };
export const WAVFileTypeOption: FileTypeOption = { label: "wav", value: "audio/wav" };
export const MP3FileTypeOption: FileTypeOption = { label: "mp3", value: "audio/mpeg" };

export const AllFileTypeOptions: FileTypeOption[] = [
	PNGFileTypeOption,
	JPGFileTypeOption,
	JPEGFileTypeOption,
	BMPFileTypeOption,
	SVGFileTypeOption,
	GIFFileTypeOption,
	PDFFileTypeOption,
	HTMLFileTypeOption,
	MP4FileTypeOption,
];

export const ImageFileTypeOptions: FileTypeOption[] = [
	PNGFileTypeOption,
	JPGFileTypeOption,
	JPEGFileTypeOption,
	BMPFileTypeOption,
	SVGFileTypeOption,
	GIFFileTypeOption,
];

export const VideoFileTypeOptions: FileTypeOption[] = [MP4FileTypeOption];

export const DocumentFileTypeOptions: FileTypeOption[] = [PDFFileTypeOption];

export const AudioFileTypeOptions: FileTypeOption[] = [WAVFileTypeOption, MP3FileTypeOption];

export const NonMediaFileTypeOptions: FileTypeOption[] = [HTMLFileTypeOption, PDFFileTypeOption];
