/**
 * @generated SignedSource<<748db6c14dd66e33334c6ddca5bec053>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type wordPackagesTable_WordPackagesInlineFragment$data = {
  readonly associatedWords: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly language: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"editWordPackageButton_WordPackage" | "wordsTable_WordPackageFragment">;
  readonly " $fragmentType": "wordPackagesTable_WordPackagesInlineFragment";
};
export type wordPackagesTable_WordPackagesInlineFragment$key = {
  readonly " $data"?: wordPackagesTable_WordPackagesInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"wordPackagesTable_WordPackagesInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "wordPackagesTable_WordPackagesInlineFragment"
};

(node as any).hash = "f8fe1fa7c407784b389c609b42b6e2cd";

export default node;
