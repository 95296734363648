import { useContext, useEffect, type FC } from "react";
import { useSelector } from "react-redux";
import { useFragment } from "react-relay";
import { MultiDialogFlowContainer } from "@components/multi-dialog-flow-container";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { createPaModalsFlow_QueryFragment$key } from "@relay/createPaModalsFlow_QueryFragment.graphql";
import { SelectDimensionsModal } from "@screens/potential-analysis/parts/create-pa-modals-flow/parts/select-dimensions-modal";
import { selectCurrentAccountId } from "@store/slices/AuthSlice";
import { selectCurrentUser } from "@store/slices/CurrentUserSlice";
import { CreatePAModalsFlowContext } from "./create-pa-modals-flow.context";
import { QUERY_FRAGMENT } from "./create-pa-modals-flow.graphql";
import { CreatePAModalsFlowProps } from "./create-pa-modals-flow.types";
import { ForClientModal } from "./parts/for-client-modal";
import { SelectCoachModal } from "./parts/select-coach-modal";
import { SelectSubstituteCoachModal } from "./parts/select-substitute-coach-modal";

export const CreatePAModalsFlow: FC<CreatePAModalsFlowProps> = ({
	isVisible,
	onDismiss,
	startAtStep,
	queryFragmentRef,
}) => {
	const queryFragment = useFragment<createPaModalsFlow_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);

	const { contextState, setContextState } = useContext(CreatePAModalsFlowContext);

	const isAvgsCoach = useHasPermissions(
		["UserInAccountPermission_AVGSPotentialAnalysis_IsAVGSCoach"],
		false,
	);
	const isAvgsAccount = useHasPermissions(
		["AccountPermission_AVGSPotentialAnalysis_IsAVGSAccount"],
		false,
	);
	const accountId = useSelector(selectCurrentAccountId);
	const user = useSelector(selectCurrentUser);
	const userId = user?.user.id;

	useEffect(() => {
		setContextState({
			...contextState,
			coachId: isAvgsCoach ? userId : undefined,
			accountId: isAvgsAccount ? accountId : undefined,
		});
	}, [isAvgsCoach]);

	const createHandler = (handler: (step?: number) => void, step?: number) => {
		return () => {
			handler(step);
		};
	};
	return (
		<MultiDialogFlowContainer
			isVisible={isVisible}
			startAtStep={startAtStep}
			onDismiss={onDismiss}
		>
			{({ onNext, onClose }) => (
				<>
					<ForClientModal
						isVisible
						onSuccess={createHandler(onNext)}
						onDismiss={createHandler(onClose)}
					/>
					{!isAvgsCoach && (
						<SelectCoachModal
							isVisible
							onDismiss={createHandler(onClose)}
							onSuccess={createHandler(onNext)}
							queryFragmentRef={queryFragment}
						/>
					)}
					<SelectSubstituteCoachModal
						isVisible
						onDismiss={createHandler(onClose)}
						onSuccess={createHandler(onNext)}
					/>
					<SelectDimensionsModal
						isVisible
						onDismiss={createHandler(onClose)}
						queryFragmentRef={queryFragment}
					/>
				</>
			)}
		</MultiDialogFlowContainer>
	);
};
