import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query potentialAnalysis_Query {
		...potentialAnalysesTable_QueryFragment
		...createPaButton_QueryFragment
		Admin {
			AvgsPotentialAnalysis {
				Info {
					numAvailableLicenses
				}
			}
		}
	}
`;
