import React, { useState } from "react";
import {
	type ContractPartnerContext,
	ContractPartnerScreenContext,
} from "@screens/contract-partners/contract-partners.context";
import { type ContractPartnerFiltersState } from "@screens/contract-partners/parts/contract-partner-form-filter/contract-partner-form-filter.types";

export function ContractPartnerContextProvider<T>({ children }: React.PropsWithChildren<T>) {
	const [connectionId, setConnectionId] = useState("");
	const [filters, setFilters] = useState<ContractPartnerFiltersState>({
		name: undefined,
	});
	const value: ContractPartnerContext = {
		connectionId,
		setConnectionId,
		filters,
		setFilters,
	};
	return (
		<ContractPartnerScreenContext.Provider value={value}>
			{children}
		</ContractPartnerScreenContext.Provider>
	);
}
