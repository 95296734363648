/**
 * @generated SignedSource<<fec549ea34534f8cacede317a207e01c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditFeatureToggleDataInput = {
  clientMutationId?: string | null | undefined;
  data: FeatureToggleDataInput;
  id: string;
};
export type FeatureToggleDataInput = {
  isActive: boolean;
  name: string;
};
export type featureTogglesScreen_EditFeatureToggleDataMutation$variables = {
  input: EditFeatureToggleDataInput;
};
export type featureTogglesScreen_EditFeatureToggleDataMutation$data = {
  readonly Admin: {
    readonly FeatureToggle: {
      readonly editFeatureToggleData: {
        readonly featureToggle: {
          readonly data: {
            readonly isActive: boolean;
            readonly name: string;
          };
          readonly id: string;
        };
      } | null | undefined;
    };
  };
};
export type featureTogglesScreen_EditFeatureToggleDataMutation = {
  response: featureTogglesScreen_EditFeatureToggleDataMutation$data;
  variables: featureTogglesScreen_EditFeatureToggleDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureToggleAdminMutationSchema",
        "kind": "LinkedField",
        "name": "FeatureToggle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "EditFeatureToggleDataPayload",
            "kind": "LinkedField",
            "name": "editFeatureToggleData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeatureToggle",
                "kind": "LinkedField",
                "name": "featureToggle",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeatureToggleData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "featureTogglesScreen_EditFeatureToggleDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "featureTogglesScreen_EditFeatureToggleDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6ea8bd9611aafb9e538736784a43c8b",
    "id": null,
    "metadata": {},
    "name": "featureTogglesScreen_EditFeatureToggleDataMutation",
    "operationKind": "mutation",
    "text": "mutation featureTogglesScreen_EditFeatureToggleDataMutation(\n  $input: EditFeatureToggleDataInput!\n) {\n  Admin {\n    FeatureToggle {\n      editFeatureToggleData(input: $input) {\n        featureToggle {\n          id\n          data {\n            isActive\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3983045b1429ea4d1189148e50fe62b1";

export default node;
