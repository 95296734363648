import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { GlobalDiscountsScreen } from "./global-discounts.screen";

export const GlobalDiscountsRoutes: RouteDefinition[] = [
	{
		path: Paths.settings.globalDiscounts.path,
		element: <GlobalDiscountsScreen />,
		requiredPermissions: "onlyOwnerOfRoot",
	},
];
