import { graphql } from "babel-plugin-relay/macro";

export const LICENSE_DEFINITION_FRAGMENT = graphql`
	fragment editTreeLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {
		id
		data {
			... on TreeLicenseDefinitionData {
				name
				rootInfo {
					rootId
					title
				}
			}
		}
	}
`;

export const EDIT_TREE_LICENSE_DEFINITION_MUTATION = graphql`
	mutation editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation(
		$input: EditTreeLicenseDefinitionAdminMutationInput!
	) {
		Admin {
			LicenseDefinition {
				editTreeLicenseDefinitionAdminMutation(input: $input) {
					licenseDefinition {
						...editTreeLicenseDefinitionButton_LicenseDefinitionFragment
					}
				}
			}
		}
	}
`;
