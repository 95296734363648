/**
 * @generated SignedSource<<0f1767ec33e0c3afe16824ffab820329>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectLicenseDefinitionField_LicenseDefinitionFragment$data = {
  readonly data: {
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "selectLicenseDefinitionField_LicenseDefinitionFragment";
};
export type selectLicenseDefinitionField_LicenseDefinitionFragment$key = {
  readonly " $data"?: selectLicenseDefinitionField_LicenseDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectLicenseDefinitionField_LicenseDefinitionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectLicenseDefinitionField_LicenseDefinitionFragment"
};

(node as any).hash = "e2cd009539587cd4a408d46ff787e9f4";

export default node;
