/**
 * @generated SignedSource<<f8dc65b760ccea8f287d4bdee23bbf6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type videoSourcesScreen_Query$variables = Record<PropertyKey, never>;
export type videoSourcesScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"videoSourcesTable_QueryFragment">;
};
export type videoSourcesScreen_Query = {
  response: videoSourcesScreen_Query$data;
  variables: videoSourcesScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "videoSourcesScreen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "videoSourcesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "videoSourcesScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VimeoVideoSourceAdminSchema",
            "kind": "LinkedField",
            "name": "VimeoVideoSource",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "Vimeo_VimeoVideoSourceConnection",
                "kind": "LinkedField",
                "name": "VimeoVideoSources",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Vimeo_VimeoVideoSourceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Vimeo_VimeoVideoSource",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDefault",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accessToken",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "VimeoVideoSources(first:20)"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "videoSourcesTable_VimeoVideoSources",
                "kind": "LinkedHandle",
                "name": "VimeoVideoSources"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abb5ae221db58c57be7ba20582b89bbf",
    "id": null,
    "metadata": {},
    "name": "videoSourcesScreen_Query",
    "operationKind": "query",
    "text": "query videoSourcesScreen_Query {\n  ...videoSourcesTable_QueryFragment\n}\n\nfragment deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  isDefault\n}\n\nfragment editVideoSourceButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  ...videoSourceForm_Vimeo_VimeoVideoSourceFragment\n}\n\nfragment setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  isDefault\n}\n\nfragment videoSourceForm_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  name\n}\n\nfragment videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  ...deleteVideoSourceButton_Vimeo_VimeoVideoSourceFragment\n  ...setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment\n  ...editVideoSourceButton_Vimeo_VimeoVideoSourceFragment\n}\n\nfragment videoSourcesTable_QueryFragment on Query {\n  Admin {\n    VimeoVideoSource {\n      VimeoVideoSources(first: 20) {\n        edges {\n          node {\n            ...videoSourcesTable_Vimeo_VimeoVideoSourceFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n\nfragment videoSourcesTable_Vimeo_VimeoVideoSourceFragment on Vimeo_VimeoVideoSource {\n  id\n  name\n  isDefault\n  accessToken\n  ...videoSourcesTableActionColumn_Vimeo_VimeoVideoSourceFragment\n}\n"
  }
};
})();

(node as any).hash = "89d86144d496b8a883a32cff2f501870";

export default node;
