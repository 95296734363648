/**
 * @generated SignedSource<<7697ee982f4b56129ddba9fefe9a510c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddProductSelectionConditionInput = {
  amount: number;
  clientMutationId?: string | null | undefined;
  limitedCartDiscountId: string;
  productId: string;
};
export type addProductSelectionConditionButton_AddProductSelectionConditionMutation$variables = {
  input: AddProductSelectionConditionInput;
};
export type addProductSelectionConditionButton_AddProductSelectionConditionMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly addProductSelectionCondition: {
        readonly limitedCartDiscount: {
          readonly " $fragmentSpreads": FragmentRefs<"limitedCartDiscountForm_LimitedCartDiscountFragment">;
        };
      } | null | undefined;
    };
  };
};
export type addProductSelectionConditionButton_AddProductSelectionConditionMutation = {
  response: addProductSelectionConditionButton_AddProductSelectionConditionMutation$data;
  variables: addProductSelectionConditionButton_AddProductSelectionConditionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addProductSelectionConditionButton_AddProductSelectionConditionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddProductSelectionConditionPayload",
                "kind": "LinkedField",
                "name": "addProductSelectionCondition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LimitedCartDiscount",
                    "kind": "LinkedField",
                    "name": "limitedCartDiscount",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "limitedCartDiscountForm_LimitedCartDiscountFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addProductSelectionConditionButton_AddProductSelectionConditionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddProductSelectionConditionPayload",
                "kind": "LinkedField",
                "name": "addProductSelectionCondition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LimitedCartDiscount",
                    "kind": "LinkedField",
                    "name": "limitedCartDiscount",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "conditions",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "productId",
                                "storageKey": null
                              }
                            ],
                            "type": "ProductSelectionCondition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "productSelectionConfig",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "type": "PercentageProductSelectionConfig",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "056109cbfa0e3f741dc49d595c791469",
    "id": null,
    "metadata": {},
    "name": "addProductSelectionConditionButton_AddProductSelectionConditionMutation",
    "operationKind": "mutation",
    "text": "mutation addProductSelectionConditionButton_AddProductSelectionConditionMutation(\n  $input: AddProductSelectionConditionInput!\n) {\n  Admin {\n    Billing {\n      addProductSelectionCondition(input: $input) {\n        limitedCartDiscount {\n          ...limitedCartDiscountForm_LimitedCartDiscountFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment limitedCartDiscountForm_LimitedCartDiscountFragment on LimitedCartDiscount {\n  id\n  title\n  conditions {\n    __typename\n    kind\n    ... on ProductSelectionCondition {\n      product {\n        title\n        id\n      }\n      id\n      amount\n      productId\n      ...limitedCartDiscountForm_ProductSelectionConditionFragment\n    }\n    id\n  }\n  productSelectionConfig {\n    __typename\n    ... on PercentageProductSelectionConfig {\n      value\n    }\n  }\n}\n\nfragment limitedCartDiscountForm_ProductSelectionConditionFragment on ProductSelectionCondition {\n  id\n  amount\n  productId\n  product {\n    title\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "121c332bf1524e3b43a9b72f90bbc6f0";

export default node;
