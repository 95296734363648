import { Paths } from "@routes/paths";
import { type RouteDefinition } from "../../routes/route-definition";
import { WordPackagesScreen } from ".";

export const WordPackagesRoutes: RouteDefinition[] = [
	{
		path: Paths.wordPackages.path,
		element: <WordPackagesScreen />,
		requiredPermissions: ["UserInAccountPermission_VocabAdmin_ReadWordPackages"],
	},
];
