import React from "react";
import { ProductsTableFiltersState } from "@screens/products/parts/products-table-filters/products-table-filters.types";

export type IProductsContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
	filters: ProductsTableFiltersState;
	setFilters: (e: ProductsTableFiltersState) => void;
};

export const ProductsScreenContext = React.createContext<IProductsContext>({
	connectionId: "",
	setConnectionId: () => {},
	filters: {
		titleOpt: undefined,
		isHiddenOpt: undefined,
	},
	setFilters: () => {},
});
