import { type FormikProps } from "formik";
import { type UseMutationConfig } from "react-relay";
import { type NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { type AdminIcons } from "@components/icon/icon.types";
import { type trendForm_CreateTrendMutation } from "@relay/trendForm_CreateTrendMutation.graphql";
import { Paths } from "@routes/paths";
import { transformAdvantages, transformMediaData } from "./transformData";
import { type Advantage, type TrendInputFormState } from "../parts/trend-form/trend-form.types";

export const createNewTrend = (
	values: TrendInputFormState,
	formik: FormikProps<TrendInputFormState>,
	advantages: Advantage[],
	createTrend: (config: UseMutationConfig<trendForm_CreateTrendMutation>) => void,
	navigate: NavigateFunction,
	connectionId: string,
) => {
	formik.setSubmitting(true);
	const mediaData = transformMediaData(values);
	createTrend({
		variables: {
			input: {
				trendData: {
					name: values.name,
					description: values.description,
					icon: values.icon as AdminIcons,
					visible: values.visible,
					tagIds: values.tagIds,
					advantages: transformAdvantages(advantages),
				},
				coachingImageId: values.coachImage.id,
				trendMedia: mediaData,
				customerData: {
					amountCustomer: values.amountCustomer,
					customerFeedbackIds: values.customerFeedbackIds,
				},
				aboutOffersHeadingAndDescription: {
					heading: values.offersInfoHeading,
					description: values.offersInfoDescription,
				},
				potentialAnalysisHeadingAndDescription: {
					heading: values.potentialAnalysisHeading,
					description: values.potentialAnalysisDescription,
				},
				coachingHeadingAndDescription: {
					heading: values.coachingInfoHeading,
					description: values.coachingInfoDescription,
				},
			},
			connections: [connectionId] ?? [],
		},

		onCompleted: () => {
			formik.setSubmitting(false);
			toast.success("Trend erfolgreich erstellt.");
			navigate(Paths.trends.path);
		},
		onError: () => {
			formik.setSubmitting(false);
			toast.error("Fehler beim Erstellen des Trends.");
		},
	});
};
