import { Button, Dialog, EpicIcons, DataTable } from "@thekeytechnology/epic-ui";
import {
	DefaultTextFieldComponent,
	DefaultTextAreaComponent,
} from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { type FC, useState } from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { IconDropdown } from "@components/icon-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { Spacing32 } from "@features/licenses/licenses-table/licenses-table.styles";
import { type TrendAdvantageFormProps } from "./trend-advantages-form.types";
import { ButtonContainer } from "../trend-form/trend-form.styles";
import { type AdvantageSubFormState, type Advantage } from "../trend-form/trend-form.types";

export const TrendAdvantagesForm: FC<TrendAdvantageFormProps> = ({
	advantages,
	setAdvantages,
	formik,
	editableAdvantages,
}) => {
	const [isAdvantageModalOpen, setIsAdvantageModalOpen] = useState<boolean>(false);
	const [editAdvantage, setEditAdvantage] = useState<boolean>(false);
	const [updatedAdvantage, setUpdatedAdvantage] = useState<Advantage | undefined>();
	const subform = useFormik<AdvantageSubFormState>({
		initialValues: {
			heading: "",
			icon: undefined,
			description: "",
		},
		onSubmit: () => {
			const index = advantages.findIndex((adv) => adv.tempId === updatedAdvantage?.tempId);
			if (updatedAdvantage) {
				const newUpdatedAdvantage = {
					tempId: updatedAdvantage.tempId,
					heading: subform.values.heading,
					icon: subform.values.icon,
					description: subform.values.description,
				};
				if (index !== -1) {
					setAdvantages((prevAdvantages) => {
						const updatedAdvantages = [...prevAdvantages];
						updatedAdvantages[index] = newUpdatedAdvantage ?? updatedAdvantages[index];
						return updatedAdvantages;
					});
				} else {
					setAdvantages((prevAdvantages) => [...prevAdvantages, newUpdatedAdvantage]);
				}
			} else {
				const newAdvantage = {
					tempId: v4(),
					heading: subform.values.heading,
					icon: subform.values.icon,
					description: subform.values.description,
				};
				setAdvantages([...advantages, newAdvantage]);
			}
			setEditAdvantage(false);
			setIsAdvantageModalOpen(false);
		},
		validationSchema: Yup.object().shape({
			heading: Yup.string().required("Bitte gib eine Überschrift ein."),
			description: Yup.string().required("Bitte gib eine Beschreibung ein."),
		}),
	});
	const handleDeleteAdvantage = (selectedAdvantage: Advantage) => {
		setAdvantages(advantages.filter((adv) => adv.tempId !== selectedAdvantage.tempId));
	};

	const handleEditAdvantage = (advantage: Advantage) => {
		setIsAdvantageModalOpen(true);
		setEditAdvantage(true);
		const editedAdvantage = advantages.find((adv) => adv.tempId === advantage.tempId) || null;
		editedAdvantage && setUpdatedAdvantage(editedAdvantage);
		void subform.setFieldValue("heading", editedAdvantage?.heading);
		void subform.setFieldValue("description", editedAdvantage?.description);
		void subform.setFieldValue("icon", editedAdvantage?.icon);
	};

	const clearAdvantageForm = () => {
		if (!editAdvantage) {
			void subform.setFieldValue("heading", "");
			void subform.setFieldValue("description", "");
			void subform.setFieldValue("icon", undefined);
		}
		setEditAdvantage(false);
	};

	const showAdvantageList =
		advantages.length > 0 || (editableAdvantages && editableAdvantages?.length > 0);

	return (
		<>
			<h4>Vorteile</h4>
			<Button
				label="Neuen Vorteil erstellen"
				onClick={() => {
					clearAdvantageForm();
					setIsAdvantageModalOpen(true);
				}}
			/>
			<Dialog
				title={editAdvantage ? "Vorteil bearbeiten" : "Vorteil erstellen"}
				visible={isAdvantageModalOpen}
				onHide={() => {
					clearAdvantageForm();
					setIsAdvantageModalOpen(false);
				}}
			>
				<ValidatedField<AdvantageSubFormState, string>
					name="heading"
					label="Überschrift Vorteil"
					formikConfig={subform}
					required
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<AdvantageSubFormState, EpicIcons>
					name="icon"
					label="Icon"
					formikConfig={subform}
					component={IconDropdown}
				/>
				<ValidatedField<AdvantageSubFormState, string>
					name="description"
					label="Beschreibung Vorteil"
					required
					formikConfig={subform}
					component={DefaultTextAreaComponent}
				/>
				<Spacing32 />
				<Button
					label="Vorteil speichern"
					onClick={() => {
						formik.setFieldValue(
							"advantages",
							advantages.map((adv) => {
								return {
									icon: adv.icon,
									heading: adv.heading,
									description: adv.description,
								};
							}),
						);
						subform.handleSubmit();
					}}
				/>
			</Dialog>
			<Spacing32 />
			{showAdvantageList && (
				<DataTable
					emptyMessage={<p>Du hast noch keine Vorteile angelegt.</p>}
					value={advantages ?? editableAdvantages}
				>
					{(Column) => (
						<>
							<Column header="Überschrift" field="heading" />
							<Column header="Beschreibung" field="description" />
							<Column
								header="Aktionen"
								body={(rowData: Advantage) => {
									return (
										<ButtonContainer>
											<Button
												icon={EpicIcons.PENCIL}
												onClick={() => {
													handleEditAdvantage(rowData);
												}}
											/>
											<Button
												icon={EpicIcons.TRASH}
												onClick={() => {
													handleDeleteAdvantage(rowData);
												}}
											/>
										</ButtonContainer>
									);
								}}
							/>
						</>
					)}
				</DataTable>
			)}
		</>
	);
};
