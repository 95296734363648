import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Table } from "@thekeytechnology/framework-react-components";
import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import { type exceptionsTable_AvailabilityScheduleFragment$key } from "@relay/exceptionsTable_AvailabilityScheduleFragment.graphql";
import { type exceptionsTable_CoachProfileFragment$key } from "@relay/exceptionsTable_CoachProfileFragment.graphql";
import { type exceptionsTable_DayAndTimesForCalendarWeekFragment$key } from "@relay/exceptionsTable_DayAndTimesForCalendarWeekFragment.graphql";
import { type CalendarWeekAndYear } from "@screens/coach-profiles/parts/edit-availability-schedule-form/edit-availability-schedule-form.types";
import { sortByCalendarWeeks } from "@screens/coach-profiles/parts/edit-availability-schedule-form/edit-availibility-schedule-form.utils";
import { EditExceptionModal } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/edit-exception-modal/edit-exception-modal.component";
import {
	AVAILABILITY_SCHEDULE_FRAGMENT,
	COACH_PROFILE_FRAGMENT,
	DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT,
} from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/exceptions-table/exceptions-table.graphql";
import { type ExceptionsTableProps } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/exceptions-table/exceptions-table.types";
import { distinctBy } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/exceptions-table/exceptions-table.util";
import { hstack } from "@styled-system/patterns";

export const ExceptionsTable = ({
	dayAndTimesForCalendarWeekFragmentRef,
	coachProfileFragmentRef,
	availabilityScheduleFragmentRef,
	onDelete,
}: ExceptionsTableProps) => {
	const exceptions = useFragment<exceptionsTable_DayAndTimesForCalendarWeekFragment$key>(
		DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT,
		dayAndTimesForCalendarWeekFragmentRef,
	);
	const distinctExceptions = useMemo(
		() =>
			sortByCalendarWeeks(
				distinctBy([...exceptions], (e) => e.calendarWeek + e.calendarYear + ""),
			),
		[exceptions],
	);
	const availabilitySchedule = useFragment<exceptionsTable_AvailabilityScheduleFragment$key>(
		AVAILABILITY_SCHEDULE_FRAGMENT,
		availabilityScheduleFragmentRef,
	);
	const coachProfile = useFragment<exceptionsTable_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const [selectedCalendarWeek, setSelectedCalendarWeek] = useState<
		CalendarWeekAndYear | undefined
	>(undefined);
	const handleOnDismiss = () => {
		setSelectedCalendarWeek(undefined);
	};
	const createOnEditHandler = (calendarWeek: CalendarWeekAndYear) => () => {
		setSelectedCalendarWeek(calendarWeek);
	};

	const handleOnDeleteClick = (row: CalendarWeekAndYear) => {
		onDelete?.(row.calendarWeek, row.calendarYear);
	};
	return (
		<>
			{/* @ts-expect-error */}
			<Table
				emptyMessage="Es wurden keine KW Ausnahmen gefunden."
				items={[...distinctExceptions]}
			>
				{(Column) => (
					<>
						<Column header="KW" field="calendarWeek" />
						<Column header="Jahr" field="calendarYear" />
						<Column
							header="Aktionen"
							body={(row) => (
								<div className={hstack()}>
									<Button
										onClick={createOnEditHandler({
											calendarWeek: row.calendarWeek,
											calendarYear: row.calendarYear,
										})}
										label="Bearbeiten"
									/>
									<Button
										icon={EpicIcons.TRASH}
										onClick={() => handleOnDeleteClick(row)}
									/>
								</div>
							)}
						/>
					</>
				)}
			</Table>
			<EditExceptionModal
				selectedCalendarWeek={selectedCalendarWeek}
				onDismiss={handleOnDismiss}
				dayAndTimesForCalendarWeekFragmentRef={exceptions}
				coachProfileFragmentRef={coachProfile}
				availabilityScheduleFragmentRef={availabilitySchedule}
			/>
		</>
	);
};
