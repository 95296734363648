import { type FormikProps } from "formik";
import { type UseMutationConfig } from "react-relay";
import { type NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { type AdminIcons } from "@components/icon/icon.types";
import { type trendForm_EditTrendMutation } from "@relay/trendForm_EditTrendMutation.graphql";
import { Paths } from "@routes/paths";
import { transformAdvantages, transformMediaData } from "./transformData";
import { type Advantage, type TrendInputFormState } from "../parts/trend-form/trend-form.types";

export const editExistingTrend = (
	values: TrendInputFormState,
	formik: FormikProps<TrendInputFormState>,
	advantages: Advantage[],
	editTrend: (config: UseMutationConfig<trendForm_EditTrendMutation>) => void,
	navigate: NavigateFunction,
	trendId?: string,
) => {
	const mediaData = transformMediaData(values);

	if (!trendId) return;
	formik.setSubmitting(true);

	editTrend({
		variables: {
			input: {
				trendId,
				trendData: {
					name: values.name,
					description: values.description,
					icon: values.icon as AdminIcons,
					visible: values.visible,
					tagIds: values.tagIds,
					advantages: transformAdvantages(advantages),
				},
				coachingImageId: values.coachImage.id,
				trendMedia: mediaData,
				customerData: {
					amountCustomer: values.amountCustomer,
					customerFeedbackIds: values.customerFeedbackIds,
				},
				aboutOffersHeadingAndDescription: {
					heading: values.offersInfoHeading,
					description: values.offersInfoDescription,
				},
				potentialAnalysisHeadingAndDescription: {
					heading: values.potentialAnalysisHeading,
					description: values.potentialAnalysisDescription,
				},
				coachingHeadingAndDescription: {
					heading: values.coachingInfoHeading,
					description: values.coachingInfoDescription,
				},
			},
		},
		onCompleted: () => {
			formik.setSubmitting(false);
			toast.success("Trend erfolgreich bearbeitet.");
			navigate(Paths.trends.path);
		},
		onError: () => {
			toast.error("Fehler beim Bearbeiten des Trends.");
			formik.setSubmitting(false);
		},
	});
};
