import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment setContractPartnerInRootExtensionImplForm_QueryFragment on Query {
		...selectContractPartnerField_QueryFragment
	}
`;

export const ROOT_EXTENSION_IMPL_FRAGMENT = graphql`
	fragment setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment on RootExtensionImpl {
		extension {
			... on ConstellationRootExtensionExtension {
				contractPartner {
					id
					data {
						name
					}
				}
			}
		}
	}
`;

export const SET_CONTRACT_PARTNER_IN_CONSTELLATION_ROOT_EXTENSION_EXTENSION_MUTATION = graphql`
	mutation setContractPartnerInRootExtensionImplForm_SetContractPartnerInConstellationRootExtensionExtensionMutation(
		$input: SetContractPartnerInConstellationRootExtensionExtensionInput!
	) {
		Admin {
			Tree {
				setContractPartnerInConstellationRootExtensionExtension(input: $input) {
					root {
						structureDefinition {
							... on RootStructureDefinition {
								extension {
									...setContractPartnerInRootExtensionImplForm_RootExtensionImplFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;
