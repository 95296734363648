import { DefaultTextFieldComponent } from "@thekeytechnology/framework-react-components";
import React, { useContext } from "react";
import { ContractPartnerScreenContext } from "@screens/contract-partners/contract-partners.context";
import { FilterWrapper } from "@screens/contract-partners/parts/contract-partner-form-filter/contract-partner-form-filter.styles";

export const ContractPartnerFilters = () => {
	const { filters, setFilters } = useContext(ContractPartnerScreenContext);

	const handleNameOnUpdate = (name?: string) => {
		setFilters({ ...filters, name });
	};
	return (
		<FilterWrapper>
			<DefaultTextFieldComponent
				fieldValue={filters.name ?? ""}
				placeholder={"Name"}
				updateField={handleNameOnUpdate}
				fieldName={"text"}
				required={false}
				isValid={true}
				disabled={false}
			/>
		</FilterWrapper>
	);
};
