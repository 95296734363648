/**
 * @generated SignedSource<<c54d25c7c4ab0f04774223f9d4ca3db0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAvailabilityScheduleForm_AvailabilityScheduleFragment$data = {
  readonly coachRef: string;
  readonly data: {
    readonly blockoutDates: ReadonlyArray<{
      readonly from: string;
      readonly to: string;
      readonly " $fragmentSpreads": FragmentRefs<"blockOutDatesTable_BlockOutDateFragment">;
    }>;
    readonly daysAndTimes: ReadonlyArray<{
      readonly dayOfWeek: any;
      readonly timeSlots: ReadonlyArray<any>;
    }>;
    readonly overrideDaysAndTimesForCalendarWeek: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment" | "editExceptionModal_DayAndTimesForCalendarWeekFragment" | "exceptionsTable_DayAndTimesForCalendarWeekFragment">;
    }>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"exceptionsTable_AvailabilityScheduleFragment">;
  readonly " $fragmentType": "editAvailabilityScheduleForm_AvailabilityScheduleFragment";
};
export type editAvailabilityScheduleForm_AvailabilityScheduleFragment$key = {
  readonly " $data"?: editAvailabilityScheduleForm_AvailabilityScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleForm_AvailabilityScheduleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "from",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "to",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAvailabilityScheduleForm_AvailabilityScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleData",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AvailabilityScheduleDayAndTimes",
          "kind": "LinkedField",
          "name": "daysAndTimes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayOfWeek",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timeSlots",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BlockoutDate",
          "kind": "LinkedField",
          "name": "blockoutDates",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "blockOutDatesTable_BlockOutDateFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "editBlockOutDateModal_BlockOutDateFragment"
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DayAndTimesForCalendarWeek",
          "kind": "LinkedField",
          "name": "overrideDaysAndTimesForCalendarWeek",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "exceptionsTable_DayAndTimesForCalendarWeekFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "editExceptionModal_DayAndTimesForCalendarWeekFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "exceptionsTable_AvailabilityScheduleFragment"
    }
  ],
  "type": "AvailabilitySchedule",
  "abstractKey": null
};
})();

(node as any).hash = "61f480462928891196e856eba174c0c4";

export default node;
