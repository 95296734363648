import { EpicIcons, Icon, RelayPageDataTable } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { type contractPartnerTable_ContractPartnerFragment$key } from "@relay/contractPartnerTable_ContractPartnerFragment.graphql";
import { type contractPartnerTable_ContractPartnerListFragment$key } from "@relay/contractPartnerTable_ContractPartnerListFragment.graphql";
import { type contractPartnerTable_Refetch } from "@relay/contractPartnerTable_Refetch.graphql";
import { ContractPartnerScreenContext } from "@screens/contract-partners/contract-partners.context";
import { ContractPartnerFilters } from "@screens/contract-partners/parts/contract-partner-form-filter";
import { ROW_COUNT } from "@screens/contract-partners/parts/contract-partner-table/contract-partner-table.const";
import {
	CONTRACT_PARTNER_FRAGMENT,
	QUERY_FRAGMENT,
} from "@screens/contract-partners/parts/contract-partner-table/contract-partner-table.graphql";
import {
	ButtonWrapper,
	HeaderWrapper,
	IconWrapper,
} from "@screens/contract-partners/parts/contract-partner-table/contract-partner-table.styles";
import { type ContractPartnerTableProps } from "@screens/contract-partners/parts/contract-partner-table/contract-partner-table.types";
import { CreateConnectedAccountButton } from "@screens/contract-partners/parts/create-connect-account-button";
import { CreateContractPartnerButton } from "@screens/contract-partners/parts/create-contract-partner-button";
import { DeleteContractPartnerButton } from "@screens/contract-partners/parts/delete-contract-partner-button";
import { EditContractPartnerButton } from "@screens/contract-partners/parts/edit-contract-partner-button";

export const ContractPartnerTable = ({ contractPartnerFragmentRef }: ContractPartnerTableProps) => {
	const { setConnectionId, filters } = useContext(ContractPartnerScreenContext);

	const handleOnConnectionIdChange = (id?: string | null) => {
		id && setConnectionId(id);
	};

	return (
		<>
			<HeaderWrapper>
				<ContractPartnerFilters />
				<CreateContractPartnerButton />
			</HeaderWrapper>
			<RelayPageDataTable<
				contractPartnerTable_Refetch,
				contractPartnerTable_ContractPartnerListFragment$key,
				contractPartnerTable_ContractPartnerFragment$key
			>
				queryFragment={QUERY_FRAGMENT}
				parentFragmentRef={contractPartnerFragmentRef}
				nodeInlineFragment={CONTRACT_PARTNER_FRAGMENT}
				extractConnection={(data) => data.Admin.Billing.ContractPartners}
				filters={filters}
				onConnectionIdChange={handleOnConnectionIdChange}
				rowsCount={ROW_COUNT}
				emptyMessage={"Keine Vertrags-Partner"}
			>
				{(Column) => (
					<>
						<Column header="Name" body={(item) => item.data.name} />
						<Column header="E-Mail" body={(item) => item.data.email} />
						<Column header="Gesellschaft" body={(item) => item.data.association} />
						<Column header="Anteil in %" body={(item) => item.shareAmountPercentage} />
						<Column
							header="Stripe"
							body={(item) => {
								return item.stripeConnectAccountId ? (
									<IconWrapper>
										<Link
											target="_blank"
											rel="noopener noreferrer"
											to={
												"https://dashboard.stripe.com/test/connect/accounts/" +
												item.stripeConnectAccountId
											}
										>
											<Icon icon={EpicIcons.VERIFIED} sizeRem={1.5} />
										</Link>
									</IconWrapper>
								) : (
									<CreateConnectedAccountButton contractPartnerId={item.id} />
								);
							}}
						/>
						<Column
							header="Aktionen"
							style={{ width: "20%" }}
							body={(item) => (
								<ButtonWrapper>
									<EditContractPartnerButton contractPartnerFragmentRef={item} />
									<DeleteContractPartnerButton contractPartnerId={item.id} />
								</ButtonWrapper>
							)}
						/>
					</>
				)}
			</RelayPageDataTable>
		</>
	);
};
