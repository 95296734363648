/**
 * @generated SignedSource<<e6d4be6dbc91242aa46d380c49493680>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createCoachProfileButton_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"createCoachProfileForm_QueryFragment">;
  readonly " $fragmentType": "createCoachProfileButton_QueryFragment";
};
export type createCoachProfileButton_QueryFragment$key = {
  readonly " $data"?: createCoachProfileButton_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createCoachProfileButton_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createCoachProfileButton_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "createCoachProfileForm_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8c91c52e03ff2607dd389eeaf73040d9";

export default node;
