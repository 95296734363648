/**
 * @generated SignedSource<<9e6bbd18faafd9f52ba38033d704447a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LicenseCreationInfoKind = "Admin" | "Order" | "Placement" | "PotentialAnalysis" | "Registration";
export type LicenseUsageKind = "Free" | "UsedForAVGSPotentialAnalysis" | "UsedForCoaching" | "UsedForTree";
import { FragmentRefs } from "relay-runtime";
export type licensesTable_LicenseFragment$data = {
  readonly creationInfo: {
    readonly createdAt: string;
    readonly creatorId?: string;
    readonly fromUserId?: string;
    readonly kind: LicenseCreationInfoKind;
    readonly licenseDefinition: {
      readonly data: {
        readonly name: string;
      };
    } | null | undefined;
    readonly orderId?: string;
    readonly userId?: string;
  };
  readonly id: string;
  readonly usage: {
    readonly at?: string;
    readonly forUser?: {
      readonly email: string;
    } | null | undefined;
    readonly kind: LicenseUsageKind;
  };
  readonly " $fragmentType": "licensesTable_LicenseFragment";
};
export type licensesTable_LicenseFragment$key = {
  readonly " $data"?: licensesTable_LicenseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesTable_LicenseFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "licensesTable_LicenseFragment"
};

(node as any).hash = "48c7620fc3760bb4ee0aef34c1a8285e";

export default node;
