import { graphql } from "babel-plugin-relay/macro";

export const VIDEO_ELEMENT_FRAGMENT = graphql`
	fragment videoElementForm_VideoElementFragment on VideoElement {
		id
		title
		vimeoId
		vimeoVideoSource {
			id
		}
	}
`;

export const EDIT_VIDEO_ELEMENT_MUTATION = graphql`
	mutation videoElementForm_EditVideoElementMutation($input: EditVideoElementV2Input!) {
		Admin {
			ElearningV2 {
				editVideoElement(input: $input) {
					videoElement {
						...videoElementForm_VideoElementFragment
					}
				}
			}
		}
	}
`;
