import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query showCoachFeedbacksButton_Query($id: ID!) {
		...showCoachFeedbacksTable_QueryFragment @arguments(id: $id)
	}
`;

export const COACH_PROFILE_FRAGMENT = graphql`
	fragment showCoachFeedbacksButton_CoachProfileFragment on CoachProfile {
		id
		coach {
			name
		}
		averageFeedbackScore
	}
`;
