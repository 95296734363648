/**
 * @generated SignedSource<<dcee6b61188850218639c9663dea1121>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type exceptionsTable_DayAndTimesForCalendarWeekFragment$data = ReadonlyArray<{
  readonly calendarWeek: number;
  readonly calendarYear: number;
  readonly dayOfWeek: any;
  readonly timeSlots: ReadonlyArray<any>;
  readonly " $fragmentSpreads": FragmentRefs<"editExceptionModal_DayAndTimesForCalendarWeekFragment">;
  readonly " $fragmentType": "exceptionsTable_DayAndTimesForCalendarWeekFragment";
}>;
export type exceptionsTable_DayAndTimesForCalendarWeekFragment$key = ReadonlyArray<{
  readonly " $data"?: exceptionsTable_DayAndTimesForCalendarWeekFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"exceptionsTable_DayAndTimesForCalendarWeekFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "exceptionsTable_DayAndTimesForCalendarWeekFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSlots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "calendarWeek",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editExceptionModal_DayAndTimesForCalendarWeekFragment"
    }
  ],
  "type": "DayAndTimesForCalendarWeek",
  "abstractKey": null
};

(node as any).hash = "dd5666250e28b34bbb85ea2a28f32f10";

export default node;
