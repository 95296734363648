/**
 * @generated SignedSource<<108b50204d22842df30f109971156aa1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddUserToAccountAdminInput = {
  accountId: string;
  clientMutationId?: string | null | undefined;
  userId: string;
};
export type addUserToAccount_AddUserToAccountMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddUserToAccountAdminInput;
};
export type addUserToAccount_AddUserToAccountMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly addUserToAccount: {
        readonly clientMutationId: string | null | undefined;
        readonly userInAccount: {
          readonly node: {
            readonly groups: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
            readonly user: {
              readonly activated: boolean;
              readonly email: string;
              readonly id: string;
              readonly name: string;
            };
          };
        };
      } | null | undefined;
    };
  };
};
export type addUserToAccount_AddUserToAccountMutation = {
  response: addUserToAccount_AddUserToAccountMutation$data;
  variables: addUserToAccount_AddUserToAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserInAccountGroup",
  "kind": "LinkedField",
  "name": "groups",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "addUserToAccount_AddUserToAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AddUserToAccountAdminPayload",
                "kind": "LinkedField",
                "name": "addUserToAccount",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "userInAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "addUserToAccount_AddUserToAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AddUserToAccountAdminPayload",
                "kind": "LinkedField",
                "name": "addUserToAccount",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "userInAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "userInAccount",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5b82951b7977528929227d83f4a9713",
    "id": null,
    "metadata": {},
    "name": "addUserToAccount_AddUserToAccountMutation",
    "operationKind": "mutation",
    "text": "mutation addUserToAccount_AddUserToAccountMutation(\n  $input: AddUserToAccountAdminInput!\n) {\n  Admin {\n    Auth {\n      addUserToAccount(input: $input) {\n        clientMutationId\n        userInAccount {\n          node {\n            groups {\n              id\n              name\n            }\n            user {\n              id\n              name\n              email\n              activated\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ee282b8fc4002dcc903b655b74d14be";

export default node;
