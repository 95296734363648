/**
 * @generated SignedSource<<6dd4f1b4e071282fded3d43841f36e56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditWordInput = {
  clientMutationId?: string | null | undefined;
  explanation: string;
  translation?: string | null | undefined;
  word: string;
  wordId: string;
  wordPackageId: string;
};
export type editWordButton_EditWordMutation$variables = {
  input: EditWordInput;
};
export type editWordButton_EditWordMutation$data = {
  readonly Admin: {
    readonly Vocab: {
      readonly editWord: {
        readonly wordPackage: {
          readonly " $fragmentSpreads": FragmentRefs<"wordsTable_WordPackageFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editWordButton_EditWordMutation = {
  response: editWordButton_EditWordMutation$data;
  variables: editWordButton_EditWordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editWordButton_EditWordMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditWordPayload",
                "kind": "LinkedField",
                "name": "editWord",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackage",
                    "kind": "LinkedField",
                    "name": "wordPackage",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "wordsTable_WordPackageFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editWordButton_EditWordMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VocabAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Vocab",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditWordPayload",
                "kind": "LinkedField",
                "name": "editWord",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordPackage",
                    "kind": "LinkedField",
                    "name": "wordPackage",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Word",
                        "kind": "LinkedField",
                        "name": "associatedWords",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "word",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "explanation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "translation",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5af2a2a466d6f68aae2cdd69c5284b7c",
    "id": null,
    "metadata": {},
    "name": "editWordButton_EditWordMutation",
    "operationKind": "mutation",
    "text": "mutation editWordButton_EditWordMutation(\n  $input: EditWordInput!\n) {\n  Admin {\n    Vocab {\n      editWord(input: $input) {\n        wordPackage {\n          ...wordsTable_WordPackageFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editWordButton_WordFragment on Word {\n  id\n  word\n  translation\n  explanation\n}\n\nfragment wordsTable_WordPackageFragment on WordPackage {\n  id\n  associatedWords {\n    id\n    word\n    explanation\n    ...editWordButton_WordFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6495745c3ef613d1152c434e221dea2";

export default node;
