/**
 * @generated SignedSource<<5099074472be425ad8434ad55fc57df6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductKind = "AccountGroup" | "License" | "Placement";
export type SelectProductField_Refetch$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  inKinds?: ReadonlyArray<ProductKind> | null | undefined;
  isHiddenOpt?: boolean | null | undefined;
  titleOpt?: string | null | undefined;
};
export type SelectProductField_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectProductField_QueryFragment">;
};
export type SelectProductField_Refetch = {
  response: SelectProductField_Refetch$data;
  variables: SelectProductField_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": [
      "License"
    ],
    "kind": "LocalArgument",
    "name": "inKinds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isHiddenOpt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "titleOpt"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "inKinds",
    "variableName": "inKinds"
  },
  {
    "kind": "Variable",
    "name": "isHiddenOpt",
    "variableName": "isHiddenOpt"
  },
  {
    "kind": "Variable",
    "name": "titleOpt",
    "variableName": "titleOpt"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectProductField_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "selectProductField_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectProductField_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "SearchProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "titleOpt",
                  "isHiddenOpt",
                  "inKinds"
                ],
                "handle": "connection",
                "key": "SelectProductField_SearchProducts",
                "kind": "LinkedHandle",
                "name": "SearchProducts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a397990819edf70dadcc7be1c77d053f",
    "id": null,
    "metadata": {},
    "name": "SelectProductField_Refetch",
    "operationKind": "query",
    "text": "query SelectProductField_Refetch(\n  $after: String\n  $first: Int = 20\n  $inKinds: [ProductKind!] = [License]\n  $isHiddenOpt: Boolean\n  $titleOpt: String\n) {\n  ...selectProductField_QueryFragment_3AKXGU\n}\n\nfragment productsTable_LicenseProductFragment on Product {\n  id\n  title\n}\n\nfragment productsTable_ProductsConnectionFragment on ProductsConnection {\n  edges {\n    node {\n      ...productsTable_LicenseProductFragment\n      id\n    }\n  }\n}\n\nfragment selectProductField_QueryFragment_3AKXGU on Query {\n  Admin {\n    Billing {\n      SearchProducts(first: $first, after: $after, titleOpt: $titleOpt, isHiddenOpt: $isHiddenOpt, inKinds: $inKinds) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...productsTable_ProductsConnectionFragment\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "76b5801386ec766250d3d2549cf7d46d";

export default node;
