/**
 * @generated SignedSource<<6d1dc1d0f9980d64868f7cd657f46491>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CodeCodeColumn_DiscountCodeFragment$data = {
  readonly code: string;
  readonly " $fragmentType": "CodeCodeColumn_DiscountCodeFragment";
};
export type CodeCodeColumn_DiscountCodeFragment$key = {
  readonly " $data"?: CodeCodeColumn_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodeCodeColumn_DiscountCodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeCodeColumn_DiscountCodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "type": "DiscountCode",
  "abstractKey": null
};

(node as any).hash = "a539aef852f7e1505256ded5c740612a";

export default node;
