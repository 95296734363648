import { FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import type { FormProps } from "@screens/coach-profiles/parts/edit-base-data-form/edit-base-data-form.types";
import { EditInvitedForUserFormState } from "@screens/potential-analysis-edit/parts/edit-invited-for-user-form/edit-invited-for-user-form.types";

export const EditInvitedForUserForm = React.forwardRef<
	FormikProps<EditInvitedForUserFormState>,
	FormProps<EditInvitedForUserFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<EditInvitedForUserFormState>({
		initialValues: initialState ?? {
			email: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email()
				.required("E-Mail-Adresse ist notwendig.")
				.test(
					"Different E-Mail",
					`Bitte wählen Sie eine andere E-Mail als vorher. Vorher: "${initialState?.email}"`,
					(value, ctx) => {
						if (!value) return false;
						if (value.toLowerCase() === initialState?.email?.toLowerCase()) {
							return false;
						}

						return true;
					},
				),
		}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	return (
		<div className={"p-fluid w-30rem"}>
			<ValidatedField<EditInvitedForUserFormState, string>
				name={"email"}
				label={"E-Mail-Adresse"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
		</div>
	);
});
EditInvitedForUserForm.displayName = "EditInvitedForUserForm";
