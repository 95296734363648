/**
 * @generated SignedSource<<3adb1d4f6fe7297251f081ff2f6300fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAvailabilityScheduleForm_CoachProfileFragment$data = {
  readonly coach: {
    readonly id: string;
  } | null | undefined;
  readonly coachAccountId: string;
  readonly " $fragmentSpreads": FragmentRefs<"exceptionsTable_CoachProfileFragment">;
  readonly " $fragmentType": "editAvailabilityScheduleForm_CoachProfileFragment";
};
export type editAvailabilityScheduleForm_CoachProfileFragment$key = {
  readonly " $data"?: editAvailabilityScheduleForm_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAvailabilityScheduleForm_CoachProfileFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAvailabilityScheduleForm_CoachProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "coach",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coachAccountId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "exceptionsTable_CoachProfileFragment"
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};

(node as any).hash = "609b2df5f0a94c7e73641f31ac430813";

export default node;
