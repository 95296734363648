import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { type coachProfilesScreen_Query } from "@relay/coachProfilesScreen_Query.graphql";
import { CoachProfilesScreenContext } from "./coach-profiles.context";
import { QUERY } from "./coach-profiles.graphql";
import { CoachProfilesTable } from "./parts/coach-profiles-table";
import { CreateCoachProfileButton } from "./parts/create-coach-profile-button";
import { BaseScreen } from "../BaseScreen";

export const CoachProfilesScreen = () => {
	const [fetchKey, setFetchKey] = useState<string>();
	const query = useLazyLoadQuery<coachProfilesScreen_Query>(
		QUERY,
		{},
		{
			fetchPolicy: "store-and-network",
			fetchKey,
		},
	);
	const [connectionId, setConnectionId] = useState("");

	const handleFetchKeyOnChange = (toSet: string) => {
		setFetchKey(toSet);
	};

	return (
		<CoachProfilesScreenContext.Provider value={{ connectionId, setConnectionId }}>
			<BaseScreen>
				<h1>Coaches</h1>
				<CreateCoachProfileButton queryFragmentRef={query} />
				<CoachProfilesTable
					queryFragmentRef={query}
					onFetchKeyChanged={handleFetchKeyOnChange}
				/>
			</BaseScreen>
		</CoachProfilesScreenContext.Provider>
	);
};
