/**
 * @generated SignedSource<<94fc55a93f5cb8f4d853f58b9752fcd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type Icon = "arrowLoop" | "bridgeOverRiver" | "computerWithHeart" | "dotsConnected" | "flowerInCircle" | "handWithFlower" | "handsWithHeart" | "lightBulb" | "peopleHighFive" | "personWithStars" | "rocket" | "workingDesk";
export type TrendMediaKind = "image" | "video";
import { FragmentRefs } from "relay-runtime";
export type trendForm_TrendFragment$data = {
  readonly node?: {
    readonly __id?: string;
    readonly aboutOffersHeadingAndDescription?: {
      readonly description: string;
      readonly heading: string;
    };
    readonly coachingHeadingAndDescription?: {
      readonly description: string;
      readonly heading: string;
    };
    readonly coachingImage?: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly customerData?: {
      readonly amountCustomer: string;
      readonly customerFeedbacks: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly potentialAnalysisHeadingAndDescription?: {
      readonly description: string;
      readonly heading: string;
    };
    readonly trendData?: {
      readonly advantages: ReadonlyArray<{
        readonly description: string;
        readonly heading: string;
        readonly icon: Icon | null | undefined;
      }>;
      readonly description: string;
      readonly icon: Icon;
      readonly name: string;
      readonly tags: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly visible: boolean;
    };
    readonly trendMedia?: {
      readonly file: {
        readonly fileType: string;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly kind: TrendMediaKind;
    };
  } | null | undefined;
  readonly " $fragmentType": "trendForm_TrendFragment";
};
export type trendForm_TrendFragment$key = {
  readonly " $data"?: trendForm_TrendFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"trendForm_TrendFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heading",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./trendFragment_Refetch.graphql')
    }
  },
  "name": "trendForm_TrendFragment",
  "selections": [
    {
      "condition": "skip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "id"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TrendData",
                  "kind": "LinkedField",
                  "name": "trendData",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "visible",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "tags",
                      "plural": true,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Advantage",
                      "kind": "LinkedField",
                      "name": "advantages",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v5/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "trendMedia",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "file",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fileType",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "coachingImage",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomerData",
                  "kind": "LinkedField",
                  "name": "customerData",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amountCustomer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomerFeedback",
                      "kind": "LinkedField",
                      "name": "customerFeedbacks",
                      "plural": true,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HeadingAndDescription",
                  "kind": "LinkedField",
                  "name": "aboutOffersHeadingAndDescription",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HeadingAndDescription",
                  "kind": "LinkedField",
                  "name": "coachingHeadingAndDescription",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HeadingAndDescription",
                  "kind": "LinkedField",
                  "name": "potentialAnalysisHeadingAndDescription",
                  "plural": false,
                  "selections": (v6/*: any*/),
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "type": "Trend",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "81973a62c9fd75f1c1266ffed45dd355";

export default node;
