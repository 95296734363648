import React from "react";

export type IPotentialAnalysisContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
};

export const PotentialAnalysisContext = React.createContext<IPotentialAnalysisContext>({
	connectionId: "",
	setConnectionId: () => {},
});
