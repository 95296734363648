import { Chip } from "primereact/chip";
import { Divider } from "primereact/divider";
import { useFragment } from "react-relay";
import { cartForm_OrderFragment$key } from "@relay/cartForm_OrderFragment.graphql";
import { CarFormProps } from "./car-form.interface";
import { ORDER_FRAGMENT } from "./cart-form.graphql";
export const CartForm = ({ orderFragmentRef }: CarFormProps) => {
	const { cart } = useFragment<cartForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	return (
		<div>
			{cart?.selection.selectedProducts && cart?.selection.selectedProducts.length > 0 && (
				<div>
					<h4>Produkte: </h4>
					{cart.selection.selectedProducts.map((selectedProduct, index) => {
						const label = `${selectedProduct.product?.title} x ${selectedProduct.amount}`;
						return <Chip key={index} label={label} />;
					})}
				</div>
			)}
			<Divider layout="horizontal" />
			{cart?.selection.selectedDiscountCodes &&
				cart?.selection.selectedDiscountCodes.length > 0 && (
					<div>
						<h4>Rabattcodes: </h4>
						{cart.selection.selectedDiscountCodes.map((selectedDiscountCode, index) => (
							<Chip key={index} label={selectedDiscountCode} />
						))}
					</div>
				)}
		</div>
	);
};
