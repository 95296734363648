import React, { useState } from "react";
import { type IOffersContext, OffersScreenContext } from "./offers.context";
import { type OffersFiltersState } from "./parts/offers-filters/offers-filters.types";
export function OffersContextProvider<T>({ children }: React.PropsWithChildren<T>) {
	const [connectionId, setConnectionId] = useState("");
	const [filters, setFilters] = useState<OffersFiltersState>({
		kinds: ["Course", "GroupCoaching", "MicroLearning", "OneToOneCoaching"],
		tagIds: [],
		tags: [],
	});
	const value: IOffersContext = {
		connectionId,
		setConnectionId,
		filters,
		setFilters,
	};
	return <OffersScreenContext.Provider value={value}>{children}</OffersScreenContext.Provider>;
}
