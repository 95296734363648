import { DayOfWeek, type LocalTime } from "@js-joda/core";
import { Button } from "@thekeytechnology/epic-ui";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { type editAvailabilityScheduleForm_AvailabilityScheduleFragment$key } from "@relay/editAvailabilityScheduleForm_AvailabilityScheduleFragment.graphql";
import { type editAvailabilityScheduleForm_CoachProfileFragment$key } from "@relay/editAvailabilityScheduleForm_CoachProfileFragment.graphql";
import { type editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment$key } from "@relay/editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment.graphql";
import {
	type editAvailabilityScheduleForm_EditAvailabilityScheduleMutation,
	type ScheduleDataInput,
} from "@relay/editAvailabilityScheduleForm_EditAvailabilityScheduleMutation.graphql";
import { type editAvailabilityScheduleForm_Query } from "@relay/editAvailabilityScheduleForm_Query.graphql";
import { CreateExceptionModal } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/create-exception-modal";
import { ExceptionsTable } from "@screens/coach-profiles/parts/edit-availability-schedule-form/parts/exceptions-table/exceptions-table.component";
import {
	AVAILABILITY_SCHEDULE_FRAGMENT,
	COACH_PROFILE_FRAGMENT,
	DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT,
	EDIT_AVAILABILITY_SCHEDULE_MUTATION,
	QUERY,
} from "./edit-availability-schedule-form.graphql";
import {
	type DaysAndTimes,
	type EditAvailabilityScheduleFormProps,
	type OverrideDaysAndTimesForCalendarWeek,
	type TimeSlotsMap,
} from "./edit-availability-schedule-form.types";
import { addOffset, removeOffset, toTimeSlotsMap } from "./edit-availibility-schedule-form.utils";
import { BlockOutDatesTable } from "./parts/block-out-dates-table/block-out-dates-table.component";
import { DaySchedule } from "./parts/day-schedule";

export const EditAvailabilitySchedulesForm = ({
	coachProfileFragmentRef,
	availabilityScheduleFragmentRef,
	onFetchKeyChanged,
}: EditAvailabilityScheduleFormProps) => {
	const availabilitySchedule =
		useFragment<editAvailabilityScheduleForm_AvailabilityScheduleFragment$key>(
			AVAILABILITY_SCHEDULE_FRAGMENT,
			availabilityScheduleFragmentRef,
		);

	const exceptions =
		useFragment<editAvailabilityScheduleForm_DayAndTimesForCalendarWeekFragment$key>(
			DAY_AND_TIMES_FOR_CALENDAR_WEEK_FRAGMENT,
			availabilitySchedule?.data.overrideDaysAndTimesForCalendarWeek,
		) || [];
	const offsettedExceptions = useMemo(
		() => addOffset(exceptions.map((e) => ({ ...e, timeSlots: [...e.timeSlots] }))),
		[exceptions],
	);

	const coachProfile = useFragment<editAvailabilityScheduleForm_CoachProfileFragment$key>(
		COACH_PROFILE_FRAGMENT,
		coachProfileFragmentRef,
	);
	const [createExceptionModalKey, setCreateExceptionModalKey] = useState("");
	const query = useLazyLoadQuery<editAvailabilityScheduleForm_Query>(
		QUERY,
		{
			coachingUserId: coachProfile.coach!.id,
		},
		{ fetchPolicy: "network-only", fetchKey: createExceptionModalKey },
	);

	const [exceptionModalIsVisible, setExceptionModalIsVisible] = useState(false);
	const [updateAvailabilitySchedule] =
		useMutation<editAvailabilityScheduleForm_EditAvailabilityScheduleMutation>(
			EDIT_AVAILABILITY_SCHEDULE_MUTATION,
		);

	const [reocurringDaysAndTimes, setReocurringDaysAndTimes] = useState<TimeSlotsMap>(
		toTimeSlotsMap(
			addOffset(
				availabilitySchedule?.data.daysAndTimes.map((e) => ({
					...e,
					timeSlots: [...e.timeSlots],
				})),
			),
		),
	);

	useEffect(() => {
		setReocurringDaysAndTimes(
			toTimeSlotsMap(
				addOffset(
					availabilitySchedule?.data.daysAndTimes.map((e) => ({
						...e,
						timeSlots: [...e.timeSlots],
					})),
				),
			),
		);
	}, [availabilitySchedule?.data.daysAndTimes]);

	const updateScheduleData = useCallback(
		(scheduleData: ScheduleDataInput) => {
			updateAvailabilitySchedule({
				variables: {
					input: {
						scheduleData: {
							daysAndTimes: removeOffset(
								scheduleData.daysAndTimes.map((e) => ({
									...e,
									timeSlots: [...e.timeSlots],
								})),
							),
							blockoutDates: scheduleData.blockoutDates,
							overrideDaysAndTimesForCalendarWeek: removeOffset(
								scheduleData.overrideDaysAndTimesForCalendarWeek.map((e) => ({
									...e,
									timeSlots: [...e.timeSlots],
								})),
							),
						},
						coachAccountId: coachProfile.coachAccountId,
						coachId: coachProfile.coach?.id!,
					},
				},
				onCompleted: (data) => {
					onFetchKeyChanged(data.Admin.Coaching.editAvailabilitySchedule?.schedule.id!);
					setCreateExceptionModalKey(Math.floor(Math.random() * 1000) + "");
				},
			});
		},
		[availabilitySchedule, exceptions],
	);

	const prepareScheduleData = useCallback(() => {
		const daysAndTimes: DaysAndTimes[] = [];
		Object.keys(reocurringDaysAndTimes).forEach((dayOfWeek) => {
			daysAndTimes.push({
				dayOfWeek,
				timeSlots: reocurringDaysAndTimes[dayOfWeek].map((timeSlot) => timeSlot.toString()),
			});
		});
		const blockoutDates =
			availabilitySchedule?.data.blockoutDates.map(({ from, to }) => ({
				from,
				to,
			})) ?? [];

		const returned = {
			daysAndTimes,
			blockoutDates,
			overrideDaysAndTimesForCalendarWeek: offsettedExceptions.map((e) => ({
				timeSlots: [...e.timeSlots],
				dayOfWeek: e.dayOfWeek,
				calendarWeek: e.calendarWeek,
				calendarYear: e.calendarYear,
			})),
		};
		return returned;
	}, [availabilitySchedule, reocurringDaysAndTimes, offsettedExceptions]);

	const createScheduleOnChangeHandler = (dayOfWeek: DayOfWeek) => (timeSlots?: LocalTime[]) => {
		const scheduleData = prepareScheduleData();

		scheduleData.daysAndTimes = scheduleData.daysAndTimes.filter(
			(daySchedule) => !dayOfWeek.equals(DayOfWeek.valueOf(daySchedule.dayOfWeek)),
		);

		if (timeSlots) {
			scheduleData.daysAndTimes.push({
				dayOfWeek: dayOfWeek.name(),
				timeSlots: timeSlots?.map((localTime) => localTime.toString()),
			});
		}
		setReocurringDaysAndTimes({
			...reocurringDaysAndTimes,
			[dayOfWeek.name()]: timeSlots ?? [],
		});
		updateScheduleData(scheduleData);
	};

	const handleOnBlockOutDatesChanged = (index: number, from?: Date, to?: Date) => {
		const scheduleData = prepareScheduleData();
		if (!scheduleData.blockoutDates[index]) return;
		if (!from || !to) {
			scheduleData.blockoutDates.splice(index, 1);
		} else {
			scheduleData.blockoutDates[index] = {
				from: from.toISOString(),
				to: to.toISOString(),
			};
		}
		updateScheduleData(scheduleData);
	};

	const handleOnBlockOutDateCreated = (from: Date, to: Date) => {
		const scheduleData = prepareScheduleData();
		scheduleData.blockoutDates.push({ from: from.toISOString(), to: to.toISOString() });

		updateScheduleData(scheduleData);
	};

	const handleOpenExceptionModal = () => {
		setExceptionModalIsVisible(true);
	};
	const handleDismissExceptionModal = () => {
		setExceptionModalIsVisible(false);
	};

	const handleOnSubmit = useCallback(
		(overrides: OverrideDaysAndTimesForCalendarWeek[]) => {
			const scheduleDataInput = prepareScheduleData();
			updateScheduleData({
				...scheduleDataInput,
				overrideDaysAndTimesForCalendarWeek: [
					...scheduleDataInput.overrideDaysAndTimesForCalendarWeek,
					...overrides,
				],
			});
		},
		[exceptions, availabilitySchedule],
	);

	const deleteOverridesForCalendarWeek = useCallback(
		(selectedCalendarWeek: number, selectedCalendarYear: number) => {
			const scheduleData = prepareScheduleData();

			const updatedOverrides = scheduleData.overrideDaysAndTimesForCalendarWeek.filter(
				(override) =>
					!(
						override.calendarWeek === selectedCalendarWeek &&
						override.calendarYear === selectedCalendarYear
					),
			);

			updateScheduleData({
				...scheduleData,
				overrideDaysAndTimesForCalendarWeek: updatedOverrides,
			});
		},
		[prepareScheduleData, updateScheduleData],
	);

	const handleDeleteOverrides = (calendarWeek: number, calendarYear: number) => {
		deleteOverridesForCalendarWeek(calendarWeek, calendarYear);
	};

	return (
		<TabView>
			<TabPanel header={"Verfügbarkeiten"}>
				{DayOfWeek.values().map((day) => {
					return (
						<DaySchedule
							key={day.name()}
							dayOfWeek={day}
							timeSlots={reocurringDaysAndTimes[day.name()]}
							onChange={createScheduleOnChangeHandler(day)}
						></DaySchedule>
					);
				})}
			</TabPanel>
			<TabPanel header={"Abwesenheit & Urlaub"}>
				<BlockOutDatesTable
					blockOutDateFragmentRef={
						availabilitySchedule?.data.blockoutDates.map((d) => d) ?? []
					}
					onChange={handleOnBlockOutDatesChanged}
					onCreate={handleOnBlockOutDateCreated}
				></BlockOutDatesTable>
			</TabPanel>
			<TabPanel header={"KW Ausnahmen"}>
				<ExceptionsTable
					dayAndTimesForCalendarWeekFragmentRef={
						availabilitySchedule?.data.overrideDaysAndTimesForCalendarWeek
					}
					availabilityScheduleFragmentRef={availabilitySchedule}
					coachProfileFragmentRef={coachProfile}
					onDelete={handleDeleteOverrides}
				/>
				<Button onClick={handleOpenExceptionModal} label={"KW Ausnahme erstellen"} />
				<CreateExceptionModal
					isVisible={exceptionModalIsVisible}
					onDismiss={handleDismissExceptionModal}
					onSubmit={handleOnSubmit}
					queryFragmentRef={query.Admin.Coaching.FreeCalendarWeeks}
					key={createExceptionModalKey}
				/>
			</TabPanel>
		</TabView>
	);
};
