/**
 * @generated SignedSource<<0b35b2743442b121f0ab34c500e661ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editBusinessDataButton_CoachProfileFragment$data = {
  readonly businessData: {
    readonly bic: string | null | undefined;
    readonly employerNumber: string | null | undefined;
    readonly iban: string | null | undefined;
    readonly insuranceNumber: string | null | undefined;
    readonly taxNumber: string | null | undefined;
  };
  readonly id: string;
  readonly " $fragmentType": "editBusinessDataButton_CoachProfileFragment";
};
export type editBusinessDataButton_CoachProfileFragment$key = {
  readonly " $data"?: editBusinessDataButton_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editBusinessDataButton_CoachProfileFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editBusinessDataButton_CoachProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessData",
      "kind": "LinkedField",
      "name": "businessData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bic",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employerNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iban",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "insuranceNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};

(node as any).hash = "3e86bc17870ec6cef907601ce05e28ee";

export default node;
