import { EpicH1Span, EpicH2Span, EpicIcons, type PathParams } from "@thekeytechnology/epic-ui";
import { Column } from "primereact/column";
import { TreeNode } from "primereact/treenode";
import { TreeTable } from "primereact/treetable";
import { readInlineData, useLazyLoadQuery } from "react-relay";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import tw from "twin.macro";
import { Button, ButtonVariant } from "@components/button";
import { NoAccess } from "@components/no-access";
import { useIsFeatureActive } from "@hooks/use-is-feature-active";
import { useCanModifyAvgsPotentialAnalysis } from "@permissions/avgs-potential-analysis";
import { potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment$key } from "@relay/potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment.graphql";
import { potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment$key } from "@relay/potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment.graphql";
import { potentialAnalysisEdit_Query } from "@relay/potentialAnalysisEdit_Query.graphql";
import { potentialAnalysisEdit_UserInlineFragment$key } from "@relay/potentialAnalysisEdit_UserInlineFragment.graphql";
import { BaseScreen } from "@screens/BaseScreen";

import { modulesToNodes } from "@screens/potential-analysis/parts/create-pa-modals-flow/parts/select-dimensions-modal/select-dimensions-modal.utils";
import { PotentialAnalysisPaths } from "@screens/potential-analysis/potential-analysis.paths";
import { EditInvitedForUserButton } from "@screens/potential-analysis-edit/parts/edit-invited-for-user-button";
import { EditSubstituteCoachButton } from "@screens/potential-analysis-edit/parts/edit-substitute-coach-button";
import { P1Span } from "@themes/font-tags";
import {
	POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT,
	POTENTIAL_ANALYSIS_V2_INLINE_FRAGMENT,
	QUERY,
	USER_FRAGMENT,
} from "./potential-analysis-edit.graphql";

export const PotentialAnalysisEditComponent = () => {
	const { potentialAnalysisId } = useParams<PathParams<typeof PotentialAnalysisPaths>>();
	const query = useLazyLoadQuery<potentialAnalysisEdit_Query>(QUERY, {
		id: potentialAnalysisId!,
	});

	const pa =
		readInlineData<potentialAnalysisEdit_AVGSPotentialAnalysisPotentialAnalysisFragment$key>(
			POTENTIAL_ANALYSIS_V2_INLINE_FRAGMENT,
			query.node!,
		);
	const substituteCoach = pa?.replacementCoach
		? readInlineData<potentialAnalysisEdit_UserInlineFragment$key>(
				USER_FRAGMENT,
				pa.replacementCoach,
		  )
		: undefined;
	const coach = readInlineData<potentialAnalysisEdit_UserInlineFragment$key>(
		USER_FRAGMENT,
		pa!.coach!,
	);

	const forUserNameOrEmail = pa.client?.client?.name ?? pa.client?.email ?? "";

	const nodes = pa!.modules!.map((module) =>
		readInlineData<potentialAnalysisEdit_AVGSPotentialAnalysisModuleFragment$key>(
			POTENTIAL_ANALYSIS_DIMENSION_DATA_FRAGMENT,
			module,
		),
	);

	const treeTableNodes: TreeNode[] = modulesToNodes(nodes);

	const handleDownloadReportOnClick = () => {
		if (!pa.isFinished || !pa.base64EncodedReport) return;
		const linkSource = `data:application/pdf;base64,${pa.base64EncodedReport}`;
		const downloadLink = document.createElement("a");
		const fileName = `Potenzialanalyse-${forUserNameOrEmail ?? pa.id}.pdf`;

		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	};

	const handleCopyLinkOnClick = () => {
		if (!pa.linkToPotentialAnalysisOrInvitation) return;
		navigator.clipboard.writeText(pa.linkToPotentialAnalysisOrInvitation);
		toast.success("Link kopiert");
	};

	return (
		<BaseScreen>
			<EpicH1Span>Potential-Analyse: {pa?.id}</EpicH1Span>
			<ActionsWrapper>
				<Button
					label={"Link zur Potential-Analyse kopieren"}
					icon={EpicIcons.COPY}
					variant={ButtonVariant.Strong}
					onClick={handleCopyLinkOnClick}
				/>
				{pa.isFinished && pa.base64EncodedReport && (
					<Button label="Auswertung runterladen" onClick={handleDownloadReportOnClick} />
				)}
				<EditSubstituteCoachButton potentialAnalysisV2FragmentRef={pa} />
				<EditInvitedForUserButton potentialAnalysisV2FragmentRef={pa} />
			</ActionsWrapper>

			<UserWrapper>
				<P1Span>
					<strong>Coach:</strong> {coach.name}
				</P1Span>
				<P1Span>
					<strong>AVGS-Client:</strong> {forUserNameOrEmail}
				</P1Span>
				<P1Span>
					<strong>Vertretung:</strong> {substituteCoach?.name ?? "Keine"}
				</P1Span>
				<P1Span>
					<strong>Abgeschloßen:</strong> {pa.isFinished ? "Ja" : "Nein"}
				</P1Span>
			</UserWrapper>

			<EpicH2Span>Module/Dimensionen</EpicH2Span>
			<TreeTable resizableColumns showGridlines value={treeTableNodes}>
				<Column
					style={{ width: "15rem" }}
					body={(module: TreeNode) => module.id}
					header="ID"
					expander
				/>
				<Column body={(module: TreeNode) => module.label} header="Text" />
			</TreeTable>
		</BaseScreen>
	);
};

export const PotentialAnalysisEdit = () => {
	const canModifyAvgsPotentialAnalysis = useCanModifyAvgsPotentialAnalysis();
	const isEnabled = useIsFeatureActive("potentialAnalysisV2");

	if (!isEnabled || !canModifyAvgsPotentialAnalysis) {
		return <NoAccess />;
	}
	return <PotentialAnalysisEditComponent />;
};

export const ActionsWrapper = tw.div`tw-w-full tw-flex tw-flex-row-reverse tw-gap-4`;
export const UserWrapper = tw.div`tw-flex tw-flex-col tw-gap-4 tw-mb-4`;
