import { Paths } from "@routes/paths";
import type { RouteDefinition } from "@routes/route-definition";
import { PotentialAnalysis } from "@screens/potential-analysis/potential-analysis.screen";

export const PotentialAnalysisRoutes: RouteDefinition[] = [
	{
		path: Paths.potentialAnalysis.path,
		element: <PotentialAnalysis />,
		requiredPermissions: "logged-in",
	},
];
