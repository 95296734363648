/**
 * @generated SignedSource<<b354599a4b468b83579a4e655830276b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type selectCoachingFieldDialog_Query$variables = {
  coachingId: string;
  skip: boolean;
};
export type selectCoachingFieldDialog_Query$data = {
  readonly node?: {
    readonly description?: {
      readonly name: string;
    } | null | undefined;
    readonly id?: string;
  } | null | undefined;
};
export type selectCoachingFieldDialog_Query = {
  response: selectCoachingFieldDialog_Query$data;
  variables: selectCoachingFieldDialog_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "coachingId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "coachingId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CoachingOfferDescription",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectCoachingFieldDialog_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "type": "CoachingOffer",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectCoachingFieldDialog_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "CoachingOffer",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "e761687064325d95bc4c580fb144673c",
    "id": null,
    "metadata": {},
    "name": "selectCoachingFieldDialog_Query",
    "operationKind": "query",
    "text": "query selectCoachingFieldDialog_Query(\n  $coachingId: ID!\n  $skip: Boolean!\n) {\n  node(id: $coachingId) @skip(if: $skip) {\n    __typename\n    ... on CoachingOffer {\n      id\n      description {\n        name\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9845f7552d82e11d36595b18bc6192e8";

export default node;
