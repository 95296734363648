import { graphql } from "babel-plugin-relay/macro";

export const CREATE_MUTATION = graphql`
	mutation createCourseLearnableButton_ConstellationCreateBranchRootMutation(
		$input: ConstellationCreateBranchRootInput!
		$connections: [ID!]!
	) {
		Admin {
			Tree {
				constellationCreateBranchRoot(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							... on UnpublishedCourseLearnable {
								root {
									id
								}
							}
							... on UnpublishedMicroLearningLearnable {
								root {
									id
								}
							}
							...offersTable_UnpublishedLearnableFragment
						}
					}
				}
			}
		}
	}
`;
