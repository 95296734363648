/**
 * @generated SignedSource<<6c07aa4a6c03f54553ce5c3290b1170b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createCoachProfileForm_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAccountField_QueryFragment">;
  readonly " $fragmentType": "createCoachProfileForm_QueryFragment";
};
export type createCoachProfileForm_QueryFragment$key = {
  readonly " $data"?: createCoachProfileForm_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createCoachProfileForm_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createCoachProfileForm_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectAccountField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7d840f0741ff008b1ae8b493fb4945ab";

export default node;
