import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment offersTable_QueryFragment on Query
	@refetchable(queryName: "offersTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
		text: { type: "String" }
		tagIds: { type: "[ID!]!" }
		isVisible: { type: "Boolean" }
		kinds: { type: "[LearnableKind!]!" }
	) {
		Admin {
			Learnable {
				UnpublishedLearnables(
					first: $first
					after: $after
					last: $last
					before: $before
					text: $text
					tagIds: $tagIds
					isVisible: $isVisible
					kinds: $kinds
				) @connection(key: "offersTable_UnpublishedLearnables") {
					__id
					edges {
						node {
							id
							kind
							...offersTable_UnpublishedLearnableFragment
						}
					}
					pageInfo {
						startCursor
						endCursor
						hasNextPage
						hasPreviousPage
					}
				}
			}
		}
	}
`;

export const UNPUBLISHED_LEARNABLE_FRAGMENT = graphql`
	fragment offersTable_UnpublishedLearnableFragment on UnpublishedLearnable @inline {
		id
		kind
		... on UnpublishedGroupCoachingLearnable {
			kind
			groupCoaching {
				id
				description {
					description
					name
					image {
						name
						thumbnail
					}
					shortDescription
				}
				associatedCoaches {
					coach {
						id
						name
					}
				}
				setting {
					kind
				}
			}
		}
		... on UnpublishedOneToOneCoachingLearnable {
			kind
			oneToOneCoaching {
				id
				description {
					description
					name
					image {
						name
						thumbnail
					}
					shortDescription
				}
				associatedCoaches {
					coach {
						id
						name
					}
				}
				setting {
					kind
				}
			}
		}
		... on UnpublishedCourseLearnable {
			kind
			root {
				id
				structureDefinition {
					title
				}
			}
		}
		... on UnpublishedMicroLearningLearnable {
			kind
			root {
				id
				structureDefinition {
					title
				}
			}
		}
		...editCoachingOfferButton_UnpublishedLearnableFragment
		...editCourseLearnableButton_UnpublishedLearnableFragment
		...deleteCoachingOfferButton_CoachingOfferFragment
		...deleteCourseLearnableButton_UnpublishedLearnableFragment
	}
`;
