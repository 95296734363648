/**
 * @generated SignedSource<<200c7e5c16d0617b7eb797b59ee12701>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deleteCoachProfileButton_CoachProfileFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "deleteCoachProfileButton_CoachProfileFragment";
};
export type deleteCoachProfileButton_CoachProfileFragment$key = {
  readonly " $data"?: deleteCoachProfileButton_CoachProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteCoachProfileButton_CoachProfileFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteCoachProfileButton_CoachProfileFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CoachProfile",
  "abstractKey": null
};

(node as any).hash = "e23346dce2ede2d81a5017a10fb0862e";

export default node;
