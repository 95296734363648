import { EpicIcons } from "@thekeytechnology/epic-ui";
import React from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@components/button";
import { type editCoachingOfferButton_UnpublishedLearnableFragment$key } from "@relay/editCoachingOfferButton_UnpublishedLearnableFragment.graphql";
import { Paths } from "@routes/paths";
import { EDIT_COACHING_UNPUBLISHED_LEARNABLE_FRAGMENT } from "./edit-coaching-offer-button.graphql";
import { type EditCoachingOfferButtonProps } from "./edit-coaching-offer-button.types";

export const EditCoachingOfferButton = ({ offerFragmentRef }: EditCoachingOfferButtonProps) => {
	const navigate = useNavigate();
	const offer = useFragment<editCoachingOfferButton_UnpublishedLearnableFragment$key>(
		EDIT_COACHING_UNPUBLISHED_LEARNABLE_FRAGMENT,
		offerFragmentRef,
	);

	const handleOnClick = () => {
		if (!offer?.groupCoaching?.id && !offer?.oneToOneCoaching?.id)
			return toast.error("Fehlende Coaching-ID");
		navigate(
			Paths.coachings.withId(offer?.groupCoaching?.id ?? offer?.oneToOneCoaching?.id ?? "")
				.edit.path,
		);
	};
	return (
		<Button
			icon={EpicIcons.PENCIL}
			tooltip={"Coaching anpassen"}
			onClick={handleOnClick}
		></Button>
	);
};
