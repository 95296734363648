import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Fieldset } from "primereact/fieldset";
import { MultiSelect, type MultiSelectProps } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { type MouseEvent, useContext, useRef } from "react";
import { useFragment } from "react-relay";
import { Button } from "@components/button";
import {
	SelectAcademiesTagField,
	type SelectAcademiesTagFieldState,
} from "@features/tags/select-academies-tag-field";
import { kindsOptions } from "./offers-filters.consts";
import { QUERY_FRAGMENT } from "./offers-filters.graphql";
import { type OffersFiltersProps } from "./offers-filters.types";
import {
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "../../../../components/DefaultTextInput";
import { OffersScreenContext } from "../../offers.context";

export const OffersFilters = ({ queryFragmentRef }: OffersFiltersProps) => {
	const query = useFragment(QUERY_FRAGMENT, queryFragmentRef);
	const { filters, setFilters } = useContext(OffersScreenContext);
	const ref = useRef<OverlayPanel>(null);
	const handleTextOnUpdate = (text?: string) => {
		setFilters({ ...filters, text });
	};
	const handleIsVisibleUpdate = (isVisible?: boolean) => {
		setFilters({ ...filters, isVisible });
	};
	const handleTagsOnUpdate = (tags: SelectAcademiesTagFieldState | undefined = []) => {
		setFilters({ ...filters, tagIds: tags?.map((t) => t.id!), tags });
	};
	const handleKindsOnChange = (e: MultiSelectProps) => {
		setFilters({ ...filters, kinds: e.value });
	};

	const handleButtonOnClick = (e: MouseEvent<HTMLButtonElement>) => {
		ref.current?.toggle(e);
	};
	return (
		<div>
			<Button icon={EpicIcons.FILTER} onClick={handleButtonOnClick} tooltip={"Filter"} />
			<OverlayPanel ref={ref}>
				<Fieldset className={"field"} legend={"Filter"}>
					<p>Suchen nach</p>
					<DefaultTextFieldComponent
						fieldValue={filters.text ?? ""}
						placeholder={"Name, Beschreibung, etc."}
						updateField={handleTextOnUpdate}
						fieldName={"text"}
						required={false}
						isValid={true}
						disabled={false}
					/>
					<p>Sichtbarkeit</p>
					<DefaultSwitchComponent
						fieldValue={filters.isVisible ?? undefined}
						updateField={handleIsVisibleUpdate}
						fieldName={"isVisible"}
						required={false}
						isValid={true}
						disabled={false}
					/>
					<p>Schlagworte</p>
					<SelectAcademiesTagField
						queryFragmentRef={query}
						fieldValue={filters.tags}
						updateField={handleTagsOnUpdate}
						fieldName={"tags"}
						required={false}
						isValid={true}
						disabled={false}
					/>
					<p>Angebotstyp</p>
					<MultiSelect
						options={kindsOptions}
						value={filters.kinds}
						onChange={handleKindsOnChange}
					/>
				</Fieldset>
			</OverlayPanel>
		</div>
	);
};
