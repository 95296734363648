/**
 * @generated SignedSource<<98697939e792f4193e94675bfaf873ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssueExternalLicenseInput = {
  clientMutationId?: string | null | undefined;
  externalLicenseId: string;
  issuedToUser: string;
};
export type issueExternalLicenseToUserButton_IssueExternalLicenseMutation$variables = {
  connections: ReadonlyArray<string>;
  input: IssueExternalLicenseInput;
};
export type issueExternalLicenseToUserButton_IssueExternalLicenseMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly issueExternalLicense: {
        readonly edge: {
          readonly node: {
            readonly id: string;
            readonly issuingInfo: {
              readonly hasBeenIssued: boolean;
            };
            readonly " $fragmentSpreads": FragmentRefs<"externalLicensesTable_ExternalLicenseFragment">;
          };
        };
      } | null | undefined;
    };
  };
};
export type issueExternalLicenseToUserButton_IssueExternalLicenseMutation = {
  response: issueExternalLicenseToUserButton_IssueExternalLicenseMutation$data;
  variables: issueExternalLicenseToUserButton_IssueExternalLicenseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasBeenIssued",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ExternalLicenseData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ExternalLicensePoolData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usageInformation",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validUntil",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "issueExternalLicenseToUserButton_IssueExternalLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "IssueExternalLicensePayload",
                "kind": "LinkedField",
                "name": "issueExternalLicense",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensesEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicense",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "issuingInfo",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "externalLicensesTable_ExternalLicenseFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ExternalLicensePool",
                                "kind": "LinkedField",
                                "name": "pool",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "issuingInfo",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "issuedTo",
                                        "plural": false,
                                        "selections": [
                                          (v9/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "ExternalLicenseIssuedToUserIssuingInfoType",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueExternalLicenseToUserButton_IssueExternalLicenseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "IssueExternalLicensePayload",
                "kind": "LinkedField",
                "name": "issueExternalLicense",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensesEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicense",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "issuingInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "issuedTo",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ExternalLicenseIssuedToUserIssuingInfoType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalLicensePool",
                            "kind": "LinkedField",
                            "name": "pool",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab0fe150f394589285968e5af8676929",
    "id": null,
    "metadata": {},
    "name": "issueExternalLicenseToUserButton_IssueExternalLicenseMutation",
    "operationKind": "mutation",
    "text": "mutation issueExternalLicenseToUserButton_IssueExternalLicenseMutation(\n  $input: IssueExternalLicenseInput!\n) {\n  Admin {\n    Billing {\n      issueExternalLicense(input: $input) {\n        edge {\n          node {\n            id\n            issuingInfo {\n              __typename\n              hasBeenIssued\n            }\n            ...externalLicensesTable_ExternalLicenseFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment externalLicensesTable_ExternalLicenseFragment on ExternalLicense {\n  id\n  data {\n    name\n    code\n  }\n  pool {\n    data {\n      usageInformation\n    }\n    id\n  }\n  issuingInfo {\n    __typename\n    hasBeenIssued\n    ... on ExternalLicenseIssuedToUserIssuingInfoType {\n      issuedAt\n      validUntil\n      issuedTo {\n        email\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c325544bb3ca26dfc4b210104a44d021";

export default node;
