/**
 * @generated SignedSource<<6dcaa228344561259ef191d1320a0f12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTreeLicenseDefinitionAdminMutationInput = {
  clientMutationId?: string | null | undefined;
  data: TreeLicenseDefinitionDataInput;
  licenseDefinitionId: string;
};
export type TreeLicenseDefinitionDataInput = {
  name: string;
  rootId: string;
};
export type editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation$variables = {
  input: EditTreeLicenseDefinitionAdminMutationInput;
};
export type editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation$data = {
  readonly Admin: {
    readonly LicenseDefinition: {
      readonly editTreeLicenseDefinitionAdminMutation: {
        readonly licenseDefinition: {
          readonly " $fragmentSpreads": FragmentRefs<"editTreeLicenseDefinitionButton_LicenseDefinitionFragment">;
        };
      } | null | undefined;
    };
  };
};
export type editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation = {
  response: editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation$data;
  variables: editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTreeLicenseDefinitionAdminMutationPayload",
                "kind": "LinkedField",
                "name": "editTreeLicenseDefinitionAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinition",
                    "kind": "LinkedField",
                    "name": "licenseDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editTreeLicenseDefinitionButton_LicenseDefinitionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LicenseDefinitionAdminMutationSchema",
            "kind": "LinkedField",
            "name": "LicenseDefinition",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTreeLicenseDefinitionAdminMutationPayload",
                "kind": "LinkedField",
                "name": "editTreeLicenseDefinitionAdminMutation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LicenseDefinition",
                    "kind": "LinkedField",
                    "name": "licenseDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LicenseDefinitionRootInfo",
                                "kind": "LinkedField",
                                "name": "rootInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rootId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "TreeLicenseDefinitionData",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3564207a87c70c1baa854b8e0d32036d",
    "id": null,
    "metadata": {},
    "name": "editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation(\n  $input: EditTreeLicenseDefinitionAdminMutationInput!\n) {\n  Admin {\n    LicenseDefinition {\n      editTreeLicenseDefinitionAdminMutation(input: $input) {\n        licenseDefinition {\n          ...editTreeLicenseDefinitionButton_LicenseDefinitionFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editTreeLicenseDefinitionButton_LicenseDefinitionFragment on LicenseDefinition {\n  id\n  data {\n    __typename\n    ... on TreeLicenseDefinitionData {\n      name\n      rootInfo {\n        rootId\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d725acbabe8ee965968804148a1f65c3";

export default node;
