import { type FormikProps, useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
	type FormProps,
	type CoachingLicenseDefinitionFormState,
} from "./coaching-license-definition-form.types";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";
import { addEditedFormToEditedFormsArray } from "../../../store/slices/CoreSlice";
import { SelectCoachingFieldDialog } from "../../coaching-offers/select-coaching-field-dialog";

export const CoachingLicenseDefinitionForm = React.forwardRef<
	FormikProps<CoachingLicenseDefinitionFormState>,
	FormProps<CoachingLicenseDefinitionFormState>
>(({ initialState, onSubmit }, ref) => {
	const formik = useFormik<CoachingLicenseDefinitionFormState>({
		initialValues: initialState ?? {
			coachingId: "",
			name: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({}),
		onSubmit,
	});

	useImperativeHandle(ref, () => ({
		...formik,
	}));

	const formId = "CoachingLicenseDefinitionForm";
	const dispatch = useDispatch();
	const handleOnChange = () => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	};
	return (
		<form onSubmit={formik.handleSubmit} className={"p-fluid w-30rem"}>
			<ValidatedField<CoachingLicenseDefinitionFormState, string>
				name={"name"}
				label={"Name"}
				placeholder="Namen eingeben..."
				component={DefaultTextFieldComponent}
				onChange={handleOnChange}
				formikConfig={formik}
			/>
			<ValidatedField<CoachingLicenseDefinitionFormState, string>
				name={"coachingId"}
				label={"Coachingangebot"}
				placeholder="Coachingangebot auswählen..."
				component={SelectCoachingFieldDialog}
				onChange={handleOnChange}
				formikConfig={formik}
			/>
		</form>
	);
});
CoachingLicenseDefinitionForm.displayName = "CoachingLicenseDefinitionForm";
