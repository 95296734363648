import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import {
	type FileV2,
	LimitedFileSelectionField,
} from "../../../../features/files/file-selection-field";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";

export type FormState = {
	name: string;
	position?: string;
	shortDescription?: string;
	description?: string;
	image?: FileV2;
};

type OwnProps = {
	loading?: boolean;
	initialValues?: FormState;
	onSubmit: (values: FormState) => void;
};

export const EditInstructorForm = ({ loading, initialValues, onSubmit }: OwnProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const formId = "EditInstructorForm";
	const formik = useFormik<FormState>({
		initialValues: {
			name: initialValues?.name ?? "Neuer Trainer",
			position: initialValues?.position,
			shortDescription: initialValues?.shortDescription,
			description: initialValues?.description,
			image: initialValues?.image as FileV2,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				...values,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_Instructors_UpdateInstructors"]);

	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"name"}
					label={"Name"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"position"}
					label={"Position"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"shortDescription"}
					label={"Kurze Beschreibung"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, string>
					name={"description"}
					label={"Beschreibung"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<FormState, FileV2>
					name={"image"}
					label={"Profilbild"}
					helpText={"Profilbild"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={LimitedFileSelectionField(
						["image/png", "image/jpg", "image/jpeg"],
						canUpload,
						canDelete,
					)}
					formikConfig={formik}
				/>
				<Button disabled={loading} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</>
	);
};
