/**
 * @generated SignedSource<<05d6caa0f18ed854bde20c217e45bded>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment$data = {
  readonly id: string;
  readonly isDefault: boolean;
  readonly " $fragmentType": "setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment";
};
export type setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment$key = {
  readonly " $data"?: setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "setVideoSourceAsDefaultButton_Vimeo_VimeoVideoSourceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    }
  ],
  "type": "Vimeo_VimeoVideoSource",
  "abstractKey": null
};

(node as any).hash = "3081ebd09cf922000c7fc2274da1834e";

export default node;
