import { FormDialogButton } from "@thekeytechnology/epic-ui";
import React, { Fragment } from "react";
import { useFragment, useMutation } from "react-relay";
import { useCanModifyAvgsPotentialAnalysis } from "@permissions/avgs-potential-analysis";
import { editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key } from "@relay/editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment.graphql";
import { editSubstituteCoachButton_ChangeReplacementCoachMutation } from "@relay/editSubstituteCoachButton_ChangeReplacementCoachMutation.graphql";
import {
	CHANGE_REPLACEMENT_COACH_MUTATION,
	POTENTIAL_ANALYSIS_V2_FRAGMENT,
} from "@screens/potential-analysis-edit/parts/edit-substitute-coach-button/edit-substitute-coach-button.graphql";
import { EditSubstituteCoachButtonProps } from "@screens/potential-analysis-edit/parts/edit-substitute-coach-button/edit-substitute-coach-button.types";
import { EditSubstituteCoachForm } from "@screens/potential-analysis-edit/parts/edit-substitute-coach-form";
import { EditSubstituteCoachFormState } from "@screens/potential-analysis-edit/parts/edit-substitute-coach-form/edit-substitute-coach-form.types";

export const EditSubstituteCoachButton = ({
	potentialAnalysisV2FragmentRef,
}: EditSubstituteCoachButtonProps) => {
	const canModifyAvgsPotentialAnalysis = useCanModifyAvgsPotentialAnalysis();

	const pa =
		useFragment<editSubstituteCoachButton_AVGSPotentialAnalysisPotentialAnalysisFragment$key>(
			POTENTIAL_ANALYSIS_V2_FRAGMENT,
			potentialAnalysisV2FragmentRef,
		);
	const [edit, isEditing] = useMutation<editSubstituteCoachButton_ChangeReplacementCoachMutation>(
		CHANGE_REPLACEMENT_COACH_MUTATION,
	);

	function handleSubmit(values: EditSubstituteCoachFormState, onHide: () => void) {
		if (!pa.id) return;
		edit({
			variables: {
				input: {
					potentialAnalysisId: pa.id,
					newReplacementCoachId: values.substitueCoachId?.length
						? values.substitueCoachId
						: undefined,
				},
			},
			onCompleted: () => {
				onHide();
			},
		});
	}

	const coachAccountId = pa.coachAccount?.id;

	if (!canModifyAvgsPotentialAnalysis) return <Fragment />;
	return (
		<FormDialogButton<EditSubstituteCoachFormState>
			disabled={isEditing}
			dialogTitle={"Vertretung ändern"}
			buttonLabel={"Vertretung ändern"}
		>
			{({ formikRef, onHide }) => {
				return (
					coachAccountId && (
						<EditSubstituteCoachForm
							accountId={coachAccountId}
							initialState={{
								substitueCoachId: undefined,
							}}
							ref={formikRef}
							onSubmit={(values) => {
								handleSubmit(values, onHide);
							}}
						/>
					)
				);
			}}
		</FormDialogButton>
	);
};
