import { Card } from "primereact/card";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import {
	type AddHideVisibilityCoachingConfigInput,
	type visibilityCoachingOfferConfigEditor_AddHideVisibilityConfigMutation,
} from "@relay/visibilityCoachingOfferConfigEditor_AddHideVisibilityConfigMutation.graphql";
import {
	type AddOnlyAZAVAccountVisibilityCoachingConfigInput,
	type visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation,
} from "@relay/visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation.graphql";
import {
	type AddOnlyBusinessAccountVisibilityCoachingConfigInput,
	type visibilityCoachingOfferConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation,
} from "@relay/visibilityCoachingOfferConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation.graphql";
import {
	type AddOnlyWithLicenseVisibilityCoachingConfigInput,
	type visibilityCoachingOfferConfigEditor_AddOnlyWithLicenseVisibilityConfigMutation,
} from "@relay/visibilityCoachingOfferConfigEditor_AddOnlyWithLicenseVisibilityConfigMutation.graphql";
import {
	type VisibilityCoachingConfigType,
	type visibilityCoachingOfferConfigEditor_CoachingOfferFragment$key,
} from "@relay/visibilityCoachingOfferConfigEditor_CoachingOfferFragment.graphql";
import { type visibilityCoachingOfferConfigEditor_RemoveVisibilityConfigMutation } from "@relay/visibilityCoachingOfferConfigEditor_RemoveVisibilityConfigMutation.graphql";
import {
	type Config,
	type ConfigurationGraphqlInterface,
} from "@screens/educational-offer/parts/Config.interfaces";
import {
	ConfigDropdown,
	type ConfigDropdownOptions,
} from "@screens/educational-offer/parts/ConfigDropdown";
import { ConfigItem } from "@screens/educational-offer/parts/ConfigItem";
import { ConfigPreview } from "@screens/educational-offer/parts/ConfigPreview";
import {
	REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
	ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
	COACHING_OFFER_FRAGMENT,
	ADD_ONLY_BUSINESS_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_WITH_LICENSE_CONDITION_CONFIGURATION_MUTATION,
	ADD_ONLY_AZAV_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
} from "@screens/offers/parts/visibility-coaching-offer-config-editor/visibility-coaching-offer-config-editor.graphql";
import { type VisibilityCoachingConfigEditorProps } from "@screens/offers/parts/visibility-coaching-offer-config-editor/visibility-coaching-offer-config-editor.types";
import { VISIBILITY_LABEL } from "../../../../translations/visibility-label";

export const VisibilityCoachingOfferConfigEditor = ({
	coachingOfferFragmentRef,
}: VisibilityCoachingConfigEditorProps) => {
	const coachingOffer =
		useFragment<visibilityCoachingOfferConfigEditor_CoachingOfferFragment$key>(
			COACHING_OFFER_FRAGMENT,
			coachingOfferFragmentRef,
		);
	const [removeVisibilityConfig, isRemovingVisibilityConfig] =
		useMutation<visibilityCoachingOfferConfigEditor_RemoveVisibilityConfigMutation>(
			REMOVE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addHideVisibilityConfig] =
		useMutation<visibilityCoachingOfferConfigEditor_AddHideVisibilityConfigMutation>(
			ADD_HIDE_VISIBILITY_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addOnlyBusinessAccountVisibilityConfig] =
		useMutation<visibilityCoachingOfferConfigEditor_AddOnlyBusinessAccountVisibilityConfigMutation>(
			ADD_ONLY_BUSINESS_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addOnlyWithLicenseVisibilityConfig] =
		useMutation<visibilityCoachingOfferConfigEditor_AddOnlyWithLicenseVisibilityConfigMutation>(
			ADD_ONLY_WITH_LICENSE_CONDITION_CONFIGURATION_MUTATION,
		);

	const [addOnlyAZAVAccountVisibilityConfig] =
		useMutation<visibilityCoachingOfferConfigEditor_AddOnlyAZAVAccountVisibilityConfigMutation>(
			ADD_ONLY_AZAV_ACCOUNT_CONDITION_CONFIGURATION_MUTATION,
		);

	const hideVisibilityConfig: Config<
		VisibilityCoachingConfigType,
		AddHideVisibilityCoachingConfigInput
	> = {
		configKey: "VisibilityCoaching_Hide",
		addMutation: (input: AddHideVisibilityCoachingConfigInput) => {
			addHideVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyBusinessAccountVisibilityConfig: Config<
		VisibilityCoachingConfigType,
		AddOnlyBusinessAccountVisibilityCoachingConfigInput
	> = {
		configKey: "VisibilityCoaching_OnlyBusinessAccount",
		addMutation: (input: AddOnlyBusinessAccountVisibilityCoachingConfigInput) => {
			addOnlyBusinessAccountVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyWithLicenseVisibilityConfig: Config<
		VisibilityCoachingConfigType,
		AddOnlyWithLicenseVisibilityCoachingConfigInput
	> = {
		configKey: "VisibilityCoaching_OnlyWithLicense",
		addMutation: (input: AddOnlyWithLicenseVisibilityCoachingConfigInput) => {
			addOnlyWithLicenseVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onlyAZAVAccountVisibilityConfig: Config<
		VisibilityCoachingConfigType,
		AddOnlyAZAVAccountVisibilityCoachingConfigInput
	> = {
		configKey: "VisibilityCoaching_OnlyAZAVAccount",
		addMutation: (input: AddOnlyAZAVAccountVisibilityCoachingConfigInput) => {
			addOnlyAZAVAccountVisibilityConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const configs: Array<Config<VisibilityCoachingConfigType, any | never>> = [
		hideVisibilityConfig,
		onlyBusinessAccountVisibilityConfig,
		onlyWithLicenseVisibilityConfig,
		onlyAZAVAccountVisibilityConfig,
	];

	const options: Array<ConfigDropdownOptions<VisibilityCoachingConfigType>> = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const previewOptions: Array<ConfigDropdownOptions<VisibilityCoachingConfigType>> = configs.map(
		(c) => {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		},
	);

	return (
		<Card className="mb-2">
			<h2>{VISIBILITY_LABEL.visibility_label}</h2>

			<ConfigDropdown<VisibilityCoachingConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							coachingOfferId: coachingOffer.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<VisibilityCoachingConfigType>>
				selectedConfigs={coachingOffer.visibilityConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<VisibilityCoachingConfigType>,
				) => (
					<>
						<ConfigItem<VisibilityCoachingConfigType, string>
							isPresentational={true}
							isLoading={isRemovingVisibilityConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeVisibilityConfig({
									variables: {
										input: {
											coachingOfferId: coachingOffer.id,
											configId: configuration.id,
										},
									},
								});
							}}
						/>
					</>
				)}
			/>
		</Card>
	);
};
