import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { useFragment } from "react-relay";
import { MATRIX_ELEMENT_FRAGMENT } from "./matrix-field-element.graphql";
import { MatrixFieldElementProps } from "./matrix-field-element.types";
import { AddColumnToMatrixElementButton } from "../add-column-to-matrix-element-button";
import { AddRowToMatrixElementButton } from "../add-row-to-matrix-element-button";
import { AnswerMatrixElementCellForm } from "../answer-matrix-element-cell-form";
import { DisplayMatrixElementCellForm } from "../display-matrix-element-cell-form";

export const MatrixFieldElement = ({ matrixElementFragmentRef }: MatrixFieldElementProps) => {
	const matrixElement = useFragment(MATRIX_ELEMENT_FRAGMENT, matrixElementFragmentRef);

	function cellsToMatrix(
		cells: readonly {
			readonly xIdx: number;
			readonly yIdx: number;
		}[],
	) {
		const numberColumns = new Set(cells.map((cell) => cell.xIdx)).size;
		const numberRows = new Set(cells.map((cell) => cell.yIdx)).size;
		const matrix = Array(numberColumns)
			.fill(0)
			.map(() => new Array(numberRows).fill(null));
		cells.forEach((cell) => (matrix[cell.xIdx][cell.yIdx] = cell));
		return matrix;
	}

	return (
		<div>
			<div className="flex flex-nowrap flex-grow-1">
				<AddColumnToMatrixElementButton matrixElementId={matrixElement.id} />
				<AddRowToMatrixElementButton matrixElementId={matrixElement.id} />
			</div>
			<hr />
			<ScrollPanel className="flex flex-grow-1 flex-column">
				<div className="flex flex-grow-1 align-items-center m-2">
					{cellsToMatrix(matrixElement.cells).map((columns, index) => {
						return (
							<div key={index} className="flex flex-column align-items-end pr-4">
								{columns.map((cell) => {
									if (cell.xIdx === 0 && cell.yIdx === 0) {
										return (
											<div
												key="matrix-corner"
												style={{ width: "295px" }}
												className="flex flex-grow-1 justify-content-center"
											>
												<Button
													disabled={true}
													icon="pi pi-times"
													className="m-1 opacity-0"
												/>
											</div>
										);
									}
									return cell.kind === "Display" ? (
										<DisplayMatrixElementCellForm
											academiesDisplayMatrixElementCellFragmentRef={cell}
											matrixElementId={matrixElement.id}
										/>
									) : (
										<AnswerMatrixElementCellForm
											answerMatrixElementCellFragmentRef={cell}
											matrixElementId={matrixElement.id}
										/>
									);
								})}
							</div>
						);
					})}
				</div>
			</ScrollPanel>
		</div>
	);
};
