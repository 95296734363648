import { FormDialogButton } from "@thekeytechnology/framework-react-components";
import { type FormikProps } from "formik";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation } from "@relay/editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation.graphql";
import { type editTreeLicenseDefinitionButton_LicenseDefinitionFragment$key } from "@relay/editTreeLicenseDefinitionButton_LicenseDefinitionFragment.graphql";
import {
	EDIT_TREE_LICENSE_DEFINITION_MUTATION,
	LICENSE_DEFINITION_FRAGMENT,
} from "./edit-tree-license-definition-button.graphql";
import { type EditTreeLicenseDefinitionButtonProps } from "./edit-tree-license-definition-button.types";
import { TreeLicenseDefinitionForm } from "../tree-license-definition-form";
import { type TreeLicenseDefinitionFormState } from "../tree-license-definition-form/tree-license-definition-form.types";

export const EditTreeLicenseDefinitionButton = ({
	licenseDefinitionFragmentRef,
}: EditTreeLicenseDefinitionButtonProps) => {
	const licenseDefinition =
		useFragment<editTreeLicenseDefinitionButton_LicenseDefinitionFragment$key>(
			LICENSE_DEFINITION_FRAGMENT,
			licenseDefinitionFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<editTreeLicenseDefinitionButton_editTreeLicenseDefinitionMutation>(
			EDIT_TREE_LICENSE_DEFINITION_MUTATION,
		);
	const createHandleOnSubmit = (
		ref: React.MutableRefObject<FormikProps<TreeLicenseDefinitionFormState> | null>,
		onHide: () => void,
		values: TreeLicenseDefinitionFormState,
	) => {
		edit({
			variables: {
				input: {
					licenseDefinitionId: licenseDefinition.id,
					data: {
						rootId: values.root?.id!,
						name: values.name,
					},
				},
			},
			onCompleted: () => {
				ref.current?.setSubmitting(false);
				onHide();
				toast.success("Weiterbildung-Lizenzdefinition berarbeitet.");
			},
			onError: () => {
				ref.current?.setSubmitting(false);
				toast.error("Fehler beim bearbeiten der Weiterbildung-Lizenzdefinition.");
			},
		});
	};

	return (
		<FormDialogButton<TreeLicenseDefinitionFormState>
			disabled={isEditing}
			buttonContent={{
				icon: "pi pi-pencil",
			}}
			title={"Weiterbildung-Lizenzdefinition bearbeiten"}
		>
			{(ref, onHide) => {
				return (
					<TreeLicenseDefinitionForm
						initialState={{
							root: licenseDefinition?.data.rootInfo
								? {
										id: licenseDefinition.data.rootInfo.rootId,
										title: licenseDefinition.data.rootInfo.title,
								  }
								: undefined,
							name: licenseDefinition?.data?.name ?? "",
						}}
						ref={ref}
						onSubmit={(values) => {
							createHandleOnSubmit(ref, onHide, values);
						}}
					/>
				);
			}}
		</FormDialogButton>
	);
};
