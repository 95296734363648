import { type AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS as DNDCSS } from "@dnd-kit/utilities";

import { useFragment } from "react-relay";
import { Handle } from "@components/sortable-tree-item/sortable-tree-item.styles";
import { type sortableTrend_TrendFragment$key } from "@relay/sortableTrend_TrendFragment.graphql";
import { TREND_FRAGMENT } from "@screens/trends/parts/sortable-trend-item/sortable-trend.graphql";
import { type SortableTrendProps } from "@screens/trends/parts/sortable-trend-item/sortable-trend.types";
import { TrendItem, Wrapper } from "./sortable-trend.styles";

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
	isSorting || wasDragging;

export const SortableTrend = ({ id, trendFragmentRef }: SortableTrendProps) => {
	const {
		isDragging,
		attributes,
		listeners,
		setDraggableNodeRef,
		setDroppableNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges,
	});

	const trend = useFragment<sortableTrend_TrendFragment$key>(
		TREND_FRAGMENT,
		trendFragmentRef ?? null,
	);

	return (
		<Wrapper ref={setDroppableNodeRef} isDragging={isDragging}>
			<TrendItem
				className="TreeItem"
				ref={setDraggableNodeRef}
				style={{ transform: DNDCSS.Translate.toString(transform), transition }}
				isDragging={isDragging}
			>
				<Handle {...attributes} {...listeners}>
					<i className="pi pi-ellipsis-v"></i>
				</Handle>
				{`${trend?.trendData.name}`}
			</TrendItem>
		</Wrapper>
	);
};
