import { ZonedDateTime } from "@js-joda/core";
import {
	Button,
	DialogButton,
	InputDropdown,
	Table,
} from "@thekeytechnology/framework-react-components";
import React, { useEffect, useMemo, useState } from "react";
import { readInlineData, useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type editParticipantsTable_AddParticipantMutation } from "@relay/editParticipantsTable_AddParticipantMutation.graphql";
import { type editParticipantsTable_AvailabilityFragment$key } from "@relay/editParticipantsTable_AvailabilityFragment.graphql";
import {
	type editParticipantsTable_ParticipantFragment$data,
	type editParticipantsTable_ParticipantFragment$key,
	type ParticipationStatus,
} from "@relay/editParticipantsTable_ParticipantFragment.graphql";
import { type editParticipantsTable_RegisterParticipationMutation } from "@relay/editParticipantsTable_RegisterParticipationMutation.graphql";
import { type editParticipantsTable_RemoveParticipantMutation } from "@relay/editParticipantsTable_RemoveParticipantMutation.graphql";
import {
	ADD_PARTICIPANT_MUTATION,
	AVAILABILITY_FRAGMENT,
	PARTICIPANT_FRAGMENT,
	REGISTER_PARTICIPATION_MUTATION,
	REMOVE_PARTICIPANT_MUTATION,
} from "./edit-participants-table.graphql";
import { ButtonWrapper } from "./edit-participants-table.styles";
import { type EditParticipantsTableProps } from "./edit-participants-table.types";
import { getParticipationStatusOptions } from "./edit-participants-table.utils";
import { SelectUserForm } from "../../../../features/users/select-user-form";

export const EditParticipantsTable = ({ availabilityFragmentRef }: EditParticipantsTableProps) => {
	const availability = useFragment<editParticipantsTable_AvailabilityFragment$key>(
		AVAILABILITY_FRAGMENT,
		availabilityFragmentRef,
	);

	const [accountId, setAccountId] = useState<string>("");

	const participants = useMemo(() => {
		return availability.booking.kind === "BookedForGroup"
			? availability.booking.participants?.map((e) =>
					readInlineData<editParticipantsTable_ParticipantFragment$key>(
						PARTICIPANT_FRAGMENT,
						e,
					),
			  )
			: availability.booking.kind === "BookedOneToOne"
			? [
					readInlineData<editParticipantsTable_ParticipantFragment$key>(
						PARTICIPANT_FRAGMENT,
						availability.booking.participant!,
					),
			  ]
			: [] ?? [];
	}, [availability]);

	const [removeParticipant] = useMutation<editParticipantsTable_RemoveParticipantMutation>(
		REMOVE_PARTICIPANT_MUTATION,
	);
	const [addParticipant] =
		useMutation<editParticipantsTable_AddParticipantMutation>(ADD_PARTICIPANT_MUTATION);

	const [registerParticipation] =
		useMutation<editParticipantsTable_RegisterParticipationMutation>(
			REGISTER_PARTICIPATION_MUTATION,
		);

	useEffect(() => {
		if (participants) {
			const id = participants[0]?.user?.groupAssociations[0]?.account?.id!;
			setAccountId(id);
		}
	}, [participants]);

	const isBookingInFuture = useMemo(
		() =>
			new Date(
				ZonedDateTime.parse(availability.data.endDateTime).toOffsetDateTime().toString(),
			) > new Date(),
		[availability.data.endDateTime],
	);

	const isBookedForGroup = availability.booking.kind === "BookedForGroup";

	const buildChangeParticipationStatusHandler =
		(participant: editParticipantsTable_ParticipantFragment$data) =>
		(status: ParticipationStatus | undefined) => {
			registerParticipation({
				variables: {
					input: {
						participantId: participant.user?.id!,
						availabilityId: availability.id,
						participationStatus: status ?? "NotConfirmed",
					},
				},
			});
		};

	const buildOnDeleteHandler =
		(participant: editParticipantsTable_ParticipantFragment$data) => () => {
			removeParticipant({
				variables: {
					input: {
						participantId: participant.user?.id!,
						availabilityId: availability.id,
					},
				},
			});
		};

	const buildAddUserHandler = (hide: () => void) => (userId: string) => {
		addParticipant({
			variables: {
				input: {
					participantId: userId,
					availabilityId: availability.id,
				},
			},
			onCompleted: () => {
				hide();
				toast.success("Teilnehmer erfolgreich hinzugefügt");
			},
		});
	};

	const isParticipantBooker = (participant: editParticipantsTable_ParticipantFragment$data) => {
		return participants?.indexOf(participant) === 0;
	};

	const isGroupSizeReached =
		participants?.length === availability.booking.offer?.setting.maxParticipants ?? 1;

	return (
		<>
			<Table items={participants}>
				{(Column) => {
					return (
						<>
							<Column
								header={"Teilnehmer"}
								body={(participant) => <>{participant?.user?.name}</>}
							/>
							<Column
								header={"Teilnahmestatus"}
								body={(participant) => (
									<InputDropdown
										selectedItem={participant?.participationStatus}
										items={getParticipationStatusOptions()}
										disabled={isBookingInFuture}
										onItemChange={buildChangeParticipationStatusHandler(
											participant,
										)}
										placeholder={"Nicht bestätigt"}
									/>
								)}
							/>
							{isBookedForGroup && (
								<Column
									header={"Bearbeiten"}
									body={(participant) => (
										<Button
											content={{ icon: "pi pi-trash" }}
											tooltip={{ content: "Teilnehmer entfernen" }}
											onClick={buildOnDeleteHandler(participant)}
											disabled={isParticipantBooker(participant)}
										/>
									)}
								/>
							)}
						</>
					);
				}}
			</Table>
			{isBookedForGroup && (
				<ButtonWrapper>
					<DialogButton
						title={"Benutzer Wählen"}
						buttonContent={{ icon: "pi pi-plus" }}
						disabled={!accountId || isGroupSizeReached}
						tooltip={{ content: "Teilnehmer hinzufügen" }}
					>
						{(hide) => (
							<SelectUserForm
								onUserSelected={buildAddUserHandler(hide)}
								accountId={accountId}
							/>
						)}
					</DialogButton>
				</ButtonWrapper>
			)}
		</>
	);
};
